import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "src/common/components/Loading";
import RegexSpecial from "src/helpers/RegexSpecial";
import { deleteOptimizeVariables, getAgniographicSupplies, updateOptimizeVariables } from "src/services/api.services";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { setGlobalLoading, setGlobalToastr } from "../../../store/actions";
import { ExpandSVG } from "src/common/components/icons/SVGIcon";
const { Option } = Select;
let timer = null;

const VariableCapture = ({ conditionVariable, rowAction, setConditionVariable }) => {
  const [activeDeleteQuestion, setActiveDeleteQuestion] = useState(null);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [dataRowSearch, setDataRowSearch] = useState([]);
  const [conditionVariableLocal, setConditionVariableLocal] = useState([]);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setConditionVariableLocal(conditionVariable);

    return () => {
      setConditionVariableLocal([]);
    };
  }, [conditionVariable]);

  useEffect(() => {
    if (rowAction) {
      rowAction({
        index: null,
        selectedVariable: null,
      });
    }
  }, []);

  const handleClickRow = (row, index) => {
    if (rowAction) {
      rowAction({ index, selectedVariable: row });
    }
  };

  const handleUpdateAPI = async (data, index) => {
    if (data?.question && data?.inventory_id) {
      dispatch(setGlobalLoading(true));
      try {
        const updateSuccess = await updateOptimizeVariables(data, data?.id);
        const tempVariable = [...conditionVariableLocal];
        if (updateSuccess && updateSuccess?.id) {
          const objFix = {
            ...updateSuccess,
            keyInventory:
              updateSuccess?.inventory[0]?.inventory?.name && updateSuccess?.inventory[0]?.inventory?.id
                ? `${updateSuccess?.inventory[0]?.inventory?.name} - ${updateSuccess?.inventory[0]?.inventory?.id}`
                : null,
            cost: updateSuccess?.inventory[0]?.inventory?.cost || null,
            inventory_id: updateSuccess?.inventory[0]?.inventory?.id || null,
          };

          tempVariable.splice(index, 1, objFix);
          setConditionVariable([...tempVariable]);
          dispatch(setGlobalLoading(false));
          dispatch(
            setGlobalToastr({
              header: "Alert",
              message: "update Successfully!",
              type: "success",
            })
          );
        }
      } catch (error) {
        dispatch(setGlobalLoading(false));
        dispatch(
          setGlobalToastr({
            header: "Alert",
            message: `${error?.message}`,
            type: "failed",
          })
        );
      }
    }
  };

  const updateProviders = async (type, index, value) => {
    const tempVariable = [...conditionVariableLocal];

    if (type === "keyInventory") {
      tempVariable[index][type] = value;
      if (value && dataRowSearch) {
        // Fentanyl Hydrochloride 50 mcg/mL - 69
        const idKey = value.split(" - ")[0];
        const nameKey = value.split(" - ")[1];
        const findItem = dataRowSearch.find((r) => r?.id == idKey && r?.name == nameKey);
        if (findItem) {
          tempVariable[index]["cost"] = findItem?.cost;
          tempVariable[index]["inventory"] = findItem;
          tempVariable[index]["inventory_id"] = findItem?.id;
        }
      }
      setConditionVariableLocal([...tempVariable]);
      if (tempVariable[index]) {
        handleUpdateAPI(tempVariable[index], index);
      }
    } else {
      tempVariable[index][type] = value;
      setConditionVariableLocal([...tempVariable]);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        if (tempVariable[index]) {
          handleUpdateAPI(tempVariable[index], index);
        }
      }, 500);
    }
  };

  const removeProvider = async (type, index, id) => {
    if (!activeDeleteQuestion) {
      setActiveDeleteQuestion(`question-${index}`);
    } else {
      if (activeDeleteQuestion === `question-${index}`) {
        if (id) {
          dispatch(setGlobalLoading(true));
          try {
            const deleteSuccess = await deleteOptimizeVariables(id);
            const tempVariable = [...conditionVariableLocal];
            tempVariable.splice(index, 1);
            setConditionVariableLocal([...tempVariable]);
            setConditionVariable([...tempVariable]);
            if (deleteSuccess) {
              dispatch(
                setGlobalToastr({
                  header: "Alert",
                  message: "Remove Successfully!",
                  type: "success",
                })
              );
              setActiveDeleteQuestion(null);
            }
          } catch (error) {
            dispatch(
              setGlobalToastr({
                header: "Alert",
                message: `${error?.message}`,
                type: "failed",
              })
            );
          }
          dispatch(setGlobalLoading(false));
        }
      } else {
        setActiveDeleteQuestion(`question-${index}`);
      }
    }
  };

  const handleError = (type, index) => {
    setError({
      type,
      index,
    });
    dispatch(
      setGlobalToastr({
        header: "Alert",
        type: "failed",
        message: "Invalid characters not allowed!",
      })
    );
  };

  const handleSearch = async (value) => {
    setLoadingLocal(true);
    // getInventories
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const dataSuccess = await getAgniographicSupplies(value, 1);
      setDataRowSearch([...(dataSuccess?.results || []).slice(0, 50)]);
      setLoadingLocal(false);
    }, 500);
  };

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {conditionVariableLocal?.map((r, index) => (
        <div
          key={index}
          style={{
            background: "#dbe3ee",
            padding: 12,
            marginBottom: index !== conditionVariableLocal?.length - 1 ? "1rem" : 0,
          }}
          onClick={() => handleClickRow(r, index)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5px 0 8px",
            }}
          >
            <div
              className="fix-height"
              style={{ width: "calc(100% - 32px)", marginRight: "8px", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <EditableFormInput
                fixHeight={true}
                required={true}
                label="Specifications"
                value={r.question}
                fullWidth
                focusWhite
                handleChange={(value) => {
                  if (RegexSpecial(value)) {
                    handleError("question", index);
                  } else {
                    updateProviders("question", index, value, r);
                  }
                }}
              />
            </div>
            <DeleteIcon
              icon="can"
              selected={activeDeleteQuestion === `question-${index}`}
              handleClick={() => removeProvider("question", index, r?.id)}
            />
          </div>
          <div style={{ marginBottom: 10, marginTop: 10 }} className="resourceInfo d-flex">
            <div style={{ width: "auto", justifyContent: "flex-start", alignItems: "center", display: "flex", marginRight: 10 }}>
              <b>Variable Name: </b>
            </div>
            <div style={{ width: "85%", justifyContent: "flex-start", alignItems: "center", display: "flex" }}>{r?.variable_name || null}</div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "5px 0 8px",
            }}
          >
            <div style={{ width: "80%", marginRight: "8px" }}>
              <Select
                showSearch
                placeholder="Inventory"
                value={r?.keyInventory || undefined}
                onSearch={(value) => {
                  handleSearch(value);
                }}
                notFoundContent={
                  loadingLocal ? (
                    <div style={{ height: 100 }}>
                      <Loading />
                    </div>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                autoClearSearchValue={false}
                suffixIcon={<ExpandSVG />}
                allowClear={false}
                onChange={(value) => {
                  if (RegexSpecial(value)) {
                    handleError("keyInventory", index);
                  } else {
                    updateProviders("keyInventory", index, value);
                  }
                }}
                style={{ width: "100%", borderBottom: 0 }}
              >
                {(dataRowSearch || []).map((r, index) => (
                  <Select.Option key={r?.id} value={`${r.id} - ${r.name}`}>
                    {r.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ width: "20%" }}>
              <div style={{ marginBottom: 10, marginTop: 10 }} className="resourceInfo d-flex">
                <div
                  style={{ width: "81%", justifyContent: "flex-end", alignItems: "center", display: "flex", fontWeight: "bold", color: "#005ef6" }}
                >
                  {currencyFormatter.format(r?.cost)}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VariableCapture;
