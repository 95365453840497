import { Dropdown, Menu, Select, Slider } from "antd";
import React, { useState } from "react";
import AutoCompleteSearch from "../../../../common/components/AutoComplete/AutoCompleteSearch";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";

const { Option } = Select;

const OverviewFilter = ({
  autoCompleteOptions,
  valueSearch,
  providers,
  onChangeDate,
  onChangeProvider,
  onChangeAssignment,
  onChangeSlary,
  onSelectCustomReport,
  hideProvider,
}) => {
  const [search, setSearch] = useState("");
  const [slary, setSlary] = useState([0, 10]);

  const changeSearch = (value) => {
    setSearch(value);
    valueSearch(value);
  };

  const handleItemClick = (item) => {
    const { first_name, last_name } = item;
    const value = `${first_name} ${last_name}`;
    if (value) {
      valueSearch(value);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Slider
          range
          style={{ width: "100%" }}
          step={1}
          defaultValue={slary}
          onChange={(value) => setSlary(value)}
          onAfterChange={(value) => onChangeSlary(value)}
        />
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <AutoCompleteSearch
            isNotTargetInput={true}
            isEmployeeWorkFlow={true}
            handlePopulate={(item) => handleItemClick(item)}
            title="Patient-Search"
            options={autoCompleteOptions}
            handleSearch={(value) => changeSearch(value)}
            placeholder="Search Patient"
            keyLabel="patient_name"
            actionLabel="Search"
            backgroundWhite={"white"}
          />
        </div>
      </div>

      <div className="statusFilter" style={{ minWidth: "200px" }}>
        <Select placeholder="Filter by Assignment" suffixIcon={<ExpandSVG />} onChange={onChangeAssignment} allowClear={true}>
          {[
            {
              name: "Less Than 30 Days",
              id: "green",
            },
            {
              name: "Correction Team",
              id: "correction",
            },
            {
              name: "Research Team - Action Required",
              id: "research",
            },
            {
              name: "Research Team - Call Logged",
              id: "contacted",
            },
            {
              name: "Payment Pending",
              id: "payment_posting",
            },
            {
              name: "Collection",
              id: "positive_patient_balance",
            },
            {
              name: "Adjust Off",
              id: "adjust_off",
            },
            {
              name: "Billing Error",
              id: "billing_error",
            },
          ].map((items) => {
            return (
              <Option key={items?.id} value={items?.id}>
                {items?.name}
              </Option>
            );
          })}
        </Select>
      </div>

      <div className="statusFilter" style={{ minWidth: "200px" }}>
        <Select placeholder="Custom Reports" suffixIcon={<ExpandSVG />} onChange={onSelectCustomReport} allowClear={true}>
          {[
            {
              name: "Insurance Aging",
              id: "insurance_aging",
            },
          ].map((items) => {
            return (
              <Option key={items?.id} value={items?.id}>
                {items?.name}
              </Option>
            );
          })}
        </Select>
      </div>

      <div className="statusFilterContainer">
        {hideProvider ? null : (
          <div className="statusFilter" style={{ minWidth: "200px" }}>
            <Select placeholder="Filter by Provider" suffixIcon={<ExpandSVG />} onChange={onChangeProvider} allowClear={true}>
              {providers.map((items) => {
                return (
                  <Option key={items?.id} value={items?.id}>
                    {items?.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        )}

        <Dropdown overlay={menu} placement="bottomCenter">
          <div
            className="statusFilter"
            style={{
              minWidth: "200px",
              backgroundColor: "#fff",
              marginTop: 11,
              flexDirection: "column",
              minHeight: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: 13 }}>{`${slary[0]}$ <--> ${slary[1]}$`}</p>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default OverviewFilter;
