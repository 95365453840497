import { Collapse, Drawer, Icon, Input, Select, Upload } from "antd";
import moment from "moment";
import pdf2base64 from "pdf-to-base64";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PharmacyAddForm from "src/common/components/PharmacyAddForm";
import PharmacyEditForm from "src/common/components/PharmacyEditForm";
import ProviderAddForm from "src/common/components/ProviderAddForm";
import ProviderEditForm from "src/common/components/ProviderEditForm";
import { getFaxLogsLocal } from "src/services/api.services";
import { useDebounce } from "use-lodash-debounce";

import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import EditableMaterialText from "../../../common/components/EditableText/MaterialInput";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { SortingArrowIcon } from "../../../common/components/icons/SortingIcon";
import { ArrowDownSVG, ExpandSVG } from "../../../common/components/icons/SVGIcon";
import ScrollWrapper from "../../../common/components/ScrollBar";
import ToastMessage from "../../../common/components/Toast";
import { useOutsideDetect } from "../../../common/utils";
import { getLocal } from "../../../helpers/Local";
import { validateField, validateSteps } from "../../../services/validations";
import Dropzone from "react-dropzone";
import {
  assignFax,
  clearFax,
  deleteFaxLog,
  detailFaxLog,
  emailFax,
  getActiveStaff,
  getFaxLogs,
  getPatientsByQuery,
  getSortedFaxLogs,
  get_list_patients,
  get_ocr_category,
  printFax,
  reset_fax_scan_document_log,
  saveFaxToDocument,
  sendFax,
  resendFax,
  setGlobalLoading,
  set_default_toast,
  updateFaxLog,
  uploadFax,
  uploadFaxImage,
  setGlobalToastr,
} from "../../../store/actions";
import { abbreviations } from "../constant";

const { Option } = Select;
const { Panel } = Collapse;

let per_page = 25;
const FaxingSidebar = React.forwardRef(({ resource, isShow, activeTab, handleCloseSidebar }, ref) => {
  const tableRef = useRef(null);

  const title = activeTab === "incoming-fax" ? "INCOMING FAX HISTORY" : "OUTGOING FAX HISTORY";
  const dispatch = useDispatch();
  const fax_logs_data = useSelector((state) => state.patientRecords.fax_logs_data);
  const faxLoading = useSelector((state) => state.patientRecords.loadingFaxLogs);
  const print_fax = useSelector((state) => state.patientRecords.print_fax);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const detail_fax_logs = useSelector((state) => state.patientRecords.detail_fax_logs);
  const existingPCP = useSelector((state) => state.patientRecords.detail_fax_logs?.patient?.primary_care_provider);
  const existingProvider = useSelector((state) => state.patientRecords.detail_fax_logs?.patient?.referral_provider_detail);
  const patient_id = getLocal("current_patient_id");
  const user_id = getLocal("cvai-current-user");

  const [page, setPage] = useState(1);
  const [isEnd, setIsEnd] = useState(false);
  const [activeRow, setActiveRow] = useState(0);
  const [activeDelete, setActiveDelete] = useState(-1);
  const [tableLoad, setTableLoad] = useState([]);
  const [fax_logs, setFaxLogs] = useState([]);
  const [reset, setReset] = useState(true);
  const [printLink, setPrintLink] = useState("");
  const [fax_id, setFax_id] = useState(undefined);
  const [faxValue, setFaxValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [assignmentRow, setAssignmentRow] = useState(null);
  const [openPanel, setOpen] = useState(false);
  const [search, setSearch] = useState({});
  const ref1 = useRef(null);
  const patients = useSelector((state) => state.dashboard.patients);
  const activeStaff = useSelector((state) => state.common.activeStaff);
  const [activeSearch, setActiveSearch] = useState(-1);
  const debouncedValue = useDebounce(search, 400);
  const [offset, setOffset] = useState(0);
  const [typeComponent, setTypeComponent] = useState({});
  const [pcp, setPCP] = useState(null);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(true);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const toastAction = useSelector((state) => state.dashboard.toastAction);
  const [isToastr, setIsToastr] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [enableErrorEmail, setEnableErrorEmail] = useState(false);
  const [orcCategory, setOrcCategory] = useState([]);
  const orc_category = useSelector((state) => state.setting.orc_category);
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState(null);
  const [uploadCategory, setUploadCategory] = useState(null);
  const uploadProps = useMemo(() => {
    return {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setImage(file);
        setFileList([file]);
        return true;
      },
      onChange: (info) => {
        const { status } = info.file;
        if (status === "done") {
          setFileList([]); // Clear file list after upload success
        } else if (status === "error") {
        }
      },
      fileList,
    };
  }, [fileList]);
  const [sortOption, setSortOption] = useState({
    outgoing: {
      date: "",
      patient_name: "",
      type: "",
    },
    incoming: {
      date: "",
      patient_name: "",
      type: "",
    },
  });
  const [types, setCategories] = useState({ outgoing: "", incoming: "" });
  const [isOpenFax, setOpenFax] = useState(false);
  useEffect(() => {
    if (Object.keys(fax_logs_data).length > 0) {
      if (fax_logs_data?.data?.length) {
        setFaxLogs(fax_logs_data?.data);
      } else {
        setFaxLogs([]);
      }
    }
  }, [fax_logs_data]);

  useEffect(() => {
    if (orc_category?.length > 0) {
      let tempCategory = orc_category?.map((cat) => {
        let category = checkName(cat.category);
        return {
          ...cat,
          category,
        };
      });
      setOrcCategory([...tempCategory]);
    }
  }, [orc_category]);

  const checkName = (str) => {
    if (!str) return;
    let name = str.toLowerCase();
    name = ` ${name} `;
    abbreviations.map((abbr) => {
      if (name.indexOf(abbr) !== -1) {
        name = name.replace(abbr, abbr.toUpperCase());
      }
    });
    return name;
  };

  const handleFaxUpload = async (uploadFiles) => {
    // Do something with the files
    const formData = new FormData();
    uploadFiles.forEach((file) => {
      formData.append("file", file);
      formData.append("category", uploadCategory);
    });
    await dispatch(setGlobalLoading(true));
    const postUpload = await dispatch(uploadFax(formData));
    if (postUpload) {
      fetchAPI();
    } else {
      await dispatch(
        setGlobalToastr({
          header: "Upload File",
          message: "Upload File successfully!",
          type: "warning",
        })
      );
      await dispatch(setGlobalLoading(false));
    }
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append("file", image);
    formData.append("category", uploadCategory);
    await dispatch(setGlobalLoading(true));
    const postUpload = await dispatch(uploadFaxImage(formData));
    if (postUpload) {
      fetchAPI();
      setImage(null);
      setFileList([]);
      setUploadCategory(null);
      await dispatch(
        setGlobalToastr({
          header: "Upload File",
          message: "Upload File successfully!",
          type: "warning",
        })
      );
      await dispatch(setGlobalLoading(false));
    } else {
      await dispatch(
        setGlobalToastr({
          header: "Upload File",
          message: "Upload File fails! Please try again",
          type: "warning",
        })
      );
      await dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    setPage(1);
    setIsEnd(false);
  }, [activeTab]);

  const toggleOpenFax = (e) => {
    e.stopPropagation();
    setOpenFax(!isOpenFax);
  };

  useOutsideDetect(ref1, openPanel, setOpen);
  useEffect(() => {
    if (debouncedValue && openPanel) {
      dispatch(getPatientsByQuery(search && search[activeRow] && search[activeRow].value));
    }
  }, [debouncedValue]);

  const tableRows = useMemo(() => {
    if (patients) {
      setOffset(0);
      setActiveSearch(0);
      return patients;
    }
    return [];
  }, [patients]);

  const fetchApiSelect = async () => {
    await dispatch(get_ocr_category());
  };

  useEffect(() => {
    fetchApiSelect();
    dispatch(get_list_patients(1, 1, ""));
  }, []);

  useEffect(() => {
    setFaxValue(undefined);
    setEmailValue(undefined);
  }, [fax_id]);

  useEffect(() => {
    if (activeRow) {
      setOpen(false);
    }
  }, [activeRow]);

  const selectedPatient = useMemo(() => {
    return "";
  }, []);

  const rowData = useMemo(() => {
    if (!tableRows || !tableRows.length) {
      return [];
    }
    if (tableRows && tableRows.length && offset <= tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      return tableRows.slice(0, offset + count);
    }
  }, [offset, tableRows]);

  const onScroll = (values) => {
    if (values.scrollTop > rowData?.length * 20 && offset < tableRows.length) {
      const count = Math.min(15, tableRows.length - offset);
      setOffset(offset + count);
    }
  };

  const emptyRows = useMemo(() => {
    if (!tableRef || !tableRef.current) {
      return [];
    }
    const totalRowsCount = Math.floor(tableRef.current.offsetHeight / 40);
    const emptyRowsCount = totalRowsCount - tableLoad.length;
    if (emptyRowsCount < 1) {
      return [];
    }

    return new Array(emptyRowsCount).fill({ document: null });
  }, [tableLoad]);

  const fetchAPI = async () => {
    dispatch(setGlobalLoading(true));
    await dispatch(
      getFaxLogs({
        direction: activeTab === "incoming-fax" ? "incoming" : "outgoing",
        patient_id,
        user_id,
        per_page,
        page: 1,
      })
    );
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    if (tableLoad.length > 0 && tableLoad.length <= 25) {
      setActiveRow(0);
      setFax_id(tableLoad[0].id);
      dispatch(detailFaxLog(tableLoad[0].id));
    }
  }, [tableLoad, activeTab]);

  const handleRow = (index, id) => async () => {
    if (activeRow != index) {
      setActiveRow(index);
      setFax_id(id);
      setActiveDelete(-1);
      dispatch(detailFaxLog(id));
    }
  };

  const handleSortOption = async (key, type, value) => {
    let dateSort = "";
    let patientSort = "";
    setIsEnd(false);
    if (type == "date") {
      dateSort = value;
      patientSort = "";
      setSortOption({
        ...sortOption,
        [key]: {
          [type]: value,
          ["patient_name"]: "",
        },
      });
    } else if (type == "patient_name") {
      dateSort = "";
      patientSort = value;
      setSortOption({
        ...sortOption,
        [key]: {
          [type]: value,
          ["date"]: "",
        },
      });
    }
    dispatch(
      getSortedFaxLogs({
        patient_id,
        user_id,
        direction: key,
        dateSort,
        patientSort,
        per_page,
        page,
      })
    );
  };

  const changeSearch = (value) => {
    let timer;
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getActiveStaff(value));
    }, 1000);
  };

  const assignDocumentToStaff = async () => {
    if (!assignmentRow?.id) {
      return;
    }
    if (!detail_fax_logs?.type || detail_fax_logs?.type === "" || !detail_fax_logs?.patient_id || detail_fax_logs?.patient_id === "") {
      dispatch(
        setGlobalToastr({
          header: "Assign Fax",
          message: "Please select a category/patient before assign a fax.",
          type: "failed",
        })
      );
      return;
    }

    const params = {
      user_id: assignmentRow?.id,
    };
    const response = await dispatch(assignFax(fax_id, params));
    if (response) {
      setMessage("Fax assigned successfully!");
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
      }, 2000);
    }
  };

  const fetchApiDetail = () => {
    if (!fax_logs || !fax_logs.length) {
      dispatch(clearFax());
    }
  };

  const faxSend = async () => {
    if (fax_id) {
      setEnableError(false);
      const formData = new FormData();
      formData.append("fax_to", faxValue);
      formData.append("user_id", user_id);
      formData.append("pcp", pcp);
      formData.append("rp", null);
      await dispatch(sendFax(fax_id, formData));
      setMessage("Sending Fax Successfully!");
      setFaxValue("");
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const resendFaxHandle = async () => {
    if (fax_id) {
      await dispatch(resendFax(fax_id));
      await dispatch(
        getFaxLogs({
          direction: activeTab === "incoming-fax" ? "incoming" : "outgoing",
          patient_id,
          user_id,
          per_page,
          page: 1,
        })
      );
      setMessage("Resending Fax Successfully!");
      setFaxValue("");
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const sendEmail = () => {
    if (fax_id) {
      const data = {
        email: emailValue,
      };
      if (!validateSteps(data, [{ value: "email", type: "email" }])) {
        setEnableErrorEmail(true);
        return;
      } else {
        setEnableErrorEmail(false);
        const formData = new FormData();
        formData.append("email_address", emailValue);
        dispatch(emailFax(fax_id, formData));
        setMessage("Send Email Successfully!");
        setEmailValue("");
      }
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const saveFaxToDocumentRow = (id, typeId) => {
    const formData = new FormData();
    if (typeComponent && typeComponent[typeId] && typeComponent[typeId].id) {
      formData.append("type", typeComponent[typeId].id);
      formData.append("patient_id", search && search[id] && search[id].id);
      dispatch(saveFaxToDocument(fax_id, formData));
    }
  };

  const sendPrint = async () => {
    if (fax_id) {
      dispatch(printFax(fax_id));
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const checkPrint = async () => {
    const base64 = await pdf2base64(print_fax.url);
    setMessage("Print Successfully!");
    setIsToastr(true);
    setPrintLink(base64);
    await dispatch(reset_fax_scan_document_log());
    setTimeout(() => {
      setIsToastr(false);
      setMessage(null);
    }, 2000);
  };

  useEffect(() => {
    if (print_fax && Object.keys(print_fax).length > 0) {
      checkPrint();
    }
  }, [print_fax]);

  const showToast = () => {
    return <ToastMessage type="Faxing Module" status={status ? "success" : "failed"} msg={message ? message : "Update Successfully!"} width={400} />;
  };

  useEffect(() => {
    if (detail_fax_logs) {
      setReset(true);
    }
  }, [detail_fax_logs]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  const handleSearch = (e, index) => {
    setSearch({ ...search, [index]: { value: e.target?.value } });
    setOpen(true);
  };

  const selectTypeComponent = (value, id) => {
    setTypeComponent({
      ...typeComponent,
      [`${id}`]: { value: value, id: value },
    });
    dispatch(
      updateFaxLog(fax_id, {
        type: value,
      })
    );
  };

  const handleSearchSelect = (row) => {
    setSearch({
      ...search,
      [activeRow]: {
        value: `${row?.first_name || ""} ${row?.last_name || ""} - ${row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")}`,
        id: row?.id,
      },
    });
    setOpen(false);
    dispatch(
      updateFaxLog(fax_id, {
        patient_id: row?.id,
      })
    );
  };

  useEffect(() => {
    const arrSender = [];
    const arrRecipi = [];
    const arrPatient = [];
    const arrType = [];

    setTableLoad(fax_logs);
    if (fax_logs) {
      fetchApiDetail();
      var state = {};
      var arrTypeComponent = {};
      fax_logs.map((r, index) => {
        if (r.type) {
          arrTypeComponent = {
            ...arrTypeComponent,
            [`${r?.id}`]: {
              value: r.type?.category,
              id: r.type?.id,
            },
          };
        }
        if (r.patient) {
          state = {
            ...state,
            [index]: {
              value: `${r?.patient?.first_name || ""} ${r?.patient?.last_name || ""}`,
              id: r.patient?.id,
            },
          };
          setSearch(state);
        }
        if (r.user && r.user?.id) {
          if (arrSender && !arrSender.some((check) => check?.id && check?.id === r.user?.id)) {
            arrSender.push(r.user);
          }
        }
        if (r.pcp && r.pcp?.id) {
          if (arrRecipi && !arrRecipi.some((check) => check?.id && check?.id === r.pcp?.id)) {
            arrRecipi.push(r.pcp);
          }
        }
        if (r.patient && r.patient?.id) {
          if (arrPatient && !arrPatient.some((check) => check?.id && check?.id === r.patient?.id)) {
            arrPatient.push(r.patient);
          }
        }
        if (r.type) {
          if (arrType && !arrType.some((check) => check === r.type?.id)) {
            arrType.push(r.type);
          }
        }
      });
    }
    setTypeComponent(arrTypeComponent);
  }, [fax_logs]);

  useEffect(() => {
    if (printLink) {
      printPdf(printLink);
      setTimeout(() => {
        setPrintLink("");
      }, 1000);
    }
  }, [printLink]);

  let printPdf = function (b64) {
    const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize),
          byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    const contentType = "application/pdf",
      blob = b64toBlob(b64, contentType),
      blobUrl = URL.createObjectURL(blob);

    var iframe = null;

    iframe = document.createElement("iframe");
    document.body.appendChild(iframe);

    iframe.style.display = "none";
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow.print();
      }, 500);
    };

    iframe.src = blobUrl;
  };

  useEffect(() => {
    if (toastAction) {
      setIsToastr(true);
      setTimeout(() => {
        dispatch(set_default_toast());
        setIsToastr(false);
      }, 2000);
    }
  }, [toastAction]);

  useEffect(() => {
    if (activeTab == "incoming-fax") {
      if (types && types.incoming && types.incoming.length > 0 && fax_logs) {
        const arrClone = [...fax_logs];
        let arrFilter = [];
        arrFilter = arrClone.filter((r) => {
          if (r.type && r.type.category && r.type.category == types.incoming) {
            return r;
          }
        });
        setTableLoad([...arrFilter]);
      } else if ((types && types.incoming && types.incoming.length <= 0) || !types.incoming) {
        setTableLoad([...fax_logs]);
      } else {
        setTableLoad([]);
      }
    } else {
      if (types && types.outgoing && types.outgoing.length > 0 && fax_logs) {
        const arrClone = [...fax_logs];
        let arrFilter = [];
        arrFilter = arrClone.filter((r) => {
          if (r.type && r.type.category && r.type.category == types.outgoing) {
            return r;
          }
        });
        setTableLoad([...arrFilter]);
      } else if ((types && types.outgoing && types.outgoing.length <= 0) || !types.outgoing) {
        setTableLoad([...fax_logs]);
      } else {
        setTableLoad([]);
      }
    }
  }, [types, fax_logs, activeTab]);

  useEffect(() => {
    setActiveRow(-1);
    setFax_id(-1);
    setActiveDelete(-1);
    fetchAPI();
  }, [activeTab]);

  const DeleteApi = async (docID) => {
    if (docID) {
      await dispatch(deleteFaxLog(docID));
      setMessage("Delete Fax Log Successfully!");
      if (fax_id === activeDelete) {
        setFax_id(-1);
      }
      setActiveDelete(-1);
    } else {
      setMessage("Please Click Fax You Need!");
      setStatus(false);
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
        setMessage(null);
        setStatus(true);
      }, 2000);
    }
  };

  const loadMore = async (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const checkT = scrollTop / (scrollHeight - clientHeight);
    const t = Number(scrollTop / (scrollHeight - clientHeight)).toFixed(2);
    if (t && t >= 1 && Number.isFinite(checkT) && !faxLoading && !isEnd) {
      dispatch(setGlobalLoading(true));
      const success = await getFaxLogsLocal({
        patient_id: patient_id,
        user_id: user_id,
        direction: activeTab === "incoming-fax" ? "incoming" : "outgoing",
        per_page,
        page: page + 1,
      });
      dispatch(setGlobalLoading(false));
      if (success?.data?.length > 0) {
        setTableLoad([...tableLoad, ...success?.data]);
        setPage(page + 1);
      } else {
        setIsEnd(true);
      }
    }
  };

  const [isProviderAdding, setIsProviderAdding] = useState(false);
  const [valueProviderEdit, setValueProviderEdit] = useState(null);
  const [showNewTab, setShowNewTab] = useState(false);
  const [isProviderEdit, setIsProviderEdit] = useState(false);

  const [isPharmacyAdding, setIsPharmacyAdding] = useState(false);
  const [isPharmacyEdit, setIsPharmacyEdit] = useState(false);
  const [valuePharmacyEdit, setValuePharmacyEdit] = useState(null);

  const setProviderAddForm = (value) => {
    setIsProviderAdding(value);
  };

  const setProviderEditForm = (status, valueDetail) => {
    setIsProviderEdit(status);
    setValueProviderEdit(valueDetail);
  };

  const setPharmacyAddForm = (value) => {
    setIsPharmacyAdding(value);
  };

  const setPharmacyEditForm = (value, valueDetail) => {
    setIsPharmacyEdit(value);
    setValuePharmacyEdit(valueDetail);
  };

  useEffect(() => {
    if (isProviderAdding || isProviderEdit || isPharmacyAdding || isPharmacyEdit) {
      setShowNewTab(true);
    } else {
      setShowNewTab(false);
    }
  }, [isProviderAdding, isProviderEdit, isPharmacyAdding, isPharmacyEdit]);

  const onReload = async () => {
    if (fax_id) {
      dispatch(setGlobalLoading(true));
      setActiveDelete(-1);
      await dispatch(detailFaxLog(fax_id));
      dispatch(setGlobalLoading(false));
    }
  };

  const contructRender = () => {
    return (
      <div className="formStepContainer">
        <div key={`step`} className={`formStepper active`}>
          <div id={`step`} className="formStepper__header">
            <div className="formStepper__status"></div>
            <div className="formStepper__title">
              {isPharmacyAdding || isPharmacyEdit ? "Pharmacy Information" : "Healthcare Provider Location Edit"}
            </div>
          </div>

          <div className="formStepper__content">
            {isPharmacyEdit ? (
              <PharmacyEditForm
                isMedication={true}
                onReloadClick={onReload}
                isPharmacyAdding={isPharmacyAdding}
                isPharmacyEdit={isPharmacyEdit}
                valuePharmacyEdit={valuePharmacyEdit}
                setPharmacyAddForm={setPharmacyAddForm}
                setPharmacyEditForm={setPharmacyEditForm}
              />
            ) : isPharmacyAdding ? (
              <PharmacyAddForm
                isMedication={true}
                onReloadClick={onReload}
                isPharmacyAdding={isPharmacyAdding}
                isPharmacyEdit={isPharmacyEdit}
                setPharmacyAddForm={setPharmacyAddForm}
                setPharmacyEditForm={setPharmacyEditForm}
              />
            ) : isProviderAdding ? (
              <ProviderAddForm isMedication={true} onReloadClick={onReload} setProviderAddForm={setIsProviderAdding} />
            ) : isProviderEdit ? (
              <ProviderEditForm
                valueProviderEdit={valueProviderEdit}
                setProviderEditForm={setIsProviderEdit}
                isMedication={true}
                onReloadClick={onReload}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Drawer
      title={title}
      placement="right"
      variant="permanent"
      className={sideNavStatus ? "wide" : ""}
      closable
      onClose={handleCloseSidebar}
      mask={false}
      visible={isShow}
      destroyOnClose
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && !errorRest && showToast()}
        <ScrollWrapper css="x-hidden">
          {showNewTab ? (
            contructRender()
          ) : (
            <div
              className="resourceContent fix-icon"
              style={{
                flex: "unset",
                paddingLeft: "24px",
                paddingBottom: "2rem",
                marginTop: "1.5rem",
                height: "calc(100% - 70px)",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {activeTab === "incoming-fax" && (
                <div
                  className="resourceInfo d-flex tableHeader sortableHeader"
                  style={{
                    alignItems: "flex-end",
                    background: "#f4f7fb",
                    borderBottom: "1px solid #dfe3e6",
                  }}
                >
                  <div
                    className={`th sortable ${!sortOption.incoming.date ? "hide-icon" : ""}`}
                    style={{
                      width: "30%",
                      minWidth: "30%",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <b
                      style={{
                        width: "100%",
                        minWidth: "20%",
                        borderBottom: "none",
                        paddingLeft: "0.75rem",
                      }}
                    >
                      Date
                    </b>
                    <SortingArrowIcon
                      sort={sortOption.incoming.date}
                      handleSortASC={() => handleSortOption("incoming", "date", "asc")}
                      handleSortDESC={() => handleSortOption("incoming", "date", "desc")}
                    />
                  </div>
                  <div
                    className={`th sortable ${!sortOption.incoming.patient_name ? "hide-icon" : ""}`}
                    style={{
                      width: "38%",
                      maxWidth: "38%",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                  >
                    <b
                      style={{
                        width: "100%",
                        minWidth: "20%",
                        borderBottom: "none",
                        paddingLeft: "0.75rem",
                      }}
                    >
                      Patient Name
                    </b>

                    <SortingArrowIcon
                      sort={sortOption.incoming.patient_name}
                      handleSortASC={() => handleSortOption("incoming", "patient_name", "asc")}
                      handleSortDESC={() => handleSortOption("incoming", "patient_name", "desc")}
                    />
                  </div>
                  <div
                    className={`th sortable hide-icon`}
                    style={{
                      width: "calc(42%)",
                      maxWidth: "calc(42%)",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                  >
                    <Select
                      placeholder="Categorization"
                      suffixIcon={<span />}
                      mod="multiple"
                      value={types.incoming && types.incoming.length > 0 ? types.incoming : undefined}
                      onClear={() => {
                        setCategories({ ...types, incoming: "" });
                      }}
                      onChange={(value) => {
                        setCategories({ ...types, incoming: value });
                      }}
                      allowClear={true}
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        backgroundColor: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                    >
                      {orcCategory?.map((r) => (
                        <Option key={r.category} value={r.category}>
                          <span style={{ textTransform: "capitalize" }}>{r.category}</span>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
              {activeTab !== "incoming-fax" && (
                <div
                  className="resourceInfo d-flex sortableHeader"
                  style={{
                    width: "6%",
                    minWidth: "6%",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  <div
                    className={`th sortable ${!sortOption.outgoing.date ? "hide-icon" : ""}`}
                    style={{
                      width: "8%",
                      minWidth: "8%",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                    }}
                  >
                    <b
                      style={{
                        width: "100%",
                        minWidth: "20%",
                        borderBottom: "none",
                        paddingLeft: "0.75rem",
                      }}
                    ></b>
                  </div>
                  <div
                    className={`th sortable ${!sortOption.outgoing.date ? "hide-icon" : ""}`}
                    style={{
                      width: "30%",
                      minWidth: "30%",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                  >
                    <b
                      style={{
                        width: "100%",
                        minWidth: "20%",
                        borderBottom: "none",
                        paddingLeft: "0.75rem",
                      }}
                    >
                      Date
                    </b>

                    <SortingArrowIcon
                      sort={sortOption.outgoing.date}
                      handleSortASC={() => handleSortOption("outgoing", "date", "asc")}
                      handleSortDESC={() => handleSortOption("outgoing", "date", "desc")}
                    />
                  </div>

                  <div
                    className={`th sortable ${!sortOption.outgoing.patient_name ? "hide-icon" : ""}`}
                    style={{
                      width: "38%",
                      maxWidth: "38%",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                  >
                    <b
                      style={{
                        width: "100%",
                        minWidth: "20%",
                        borderBottom: "none",
                        paddingLeft: "0.75rem",
                      }}
                    >
                      Patient Name
                    </b>

                    <SortingArrowIcon
                      sort={sortOption.outgoing.patient_name}
                      handleSortASC={() => handleSortOption("outgoing", "patient_name", "asc")}
                      handleSortDESC={() => handleSortOption("outgoing", "patient_name", "desc")}
                    />
                  </div>

                  <div
                    className={`th sortable hide-icon`}
                    style={{
                      width: "calc(42%)",
                      maxWidth: "calc(42%)",
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      borderLeft: "1px solid #dfe3e6",
                    }}
                  >
                    <Select
                      placeholder="Categorization"
                      suffixIcon={<span />}
                      mod="multiple"
                      value={types.outgoing && types.outgoing.length > 0 ? types.outgoing : undefined}
                      allowClear={true}
                      onClear={() => {
                        setCategories({ ...types, outgoing: "" });
                      }}
                      onChange={(value) => {
                        setCategories({ ...types, outgoing: value });
                      }}
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        backgroundColor: "transparent",
                        borderBottom: 0,
                        boxShadow: "none",
                      }}
                    >
                      {orcCategory?.map((r) => (
                        <Option key={r.category} value={r.category}>
                          <span style={{ textTransform: "capitalize" }}>{r.category}</span>
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              <div className="resourceInfo d-flex" style={{ flex: 1, alignItems: "flex-start" }}>
                {activeTab === "incoming-fax" && (
                  <div className="tableList" ref={tableRef} style={{ width: "100%", height: "calc(100% - 50px)", minHeight: "25vh" }}>
                    <ScrollWrapper css="no-padding x-hidden" onScrollFrame={(values) => loadMore(values)}>
                      {(tableLoad || []).map((item, index) => (
                        <div
                          className={`tableItem yellow ${activeRow === index ? "active" : ""}`}
                          key={`fax-${index}`}
                          onClick={handleRow(index, item?.id)}
                        >
                          <div className="tableItemField" style={{ width: "30%", minWidth: "30%" }}>
                            <p>{moment(item.updated_at).format("MM/DD/YYYY hh:mm A")}</p>
                          </div>
                          <div className="tableItemField" style={{ width: "38%" }}>
                            <div style={{ paddingLeft: "0px", display: activeRow === index ? "block" : "none" }}>
                              <div className="patientSearch">
                                <div className="patientSearch__filter">
                                  <div className="patientSearch__filter-search">
                                    <Input
                                      style={{
                                        width: "100%",
                                        textTransform: "capitalize !important",
                                      }}
                                      value={(search && search[index] && search[index].value) || item?.patient?.name}
                                      placeholder={"Patient Name"}
                                      onChange={(value) => {
                                        handleSearch(value, index);
                                      }}
                                      onFocus={() => {
                                        setActiveRow(index);
                                        if (search && search[index] && search[index].value && search[index].value.length > 0) {
                                          setOpen(true);
                                        } else {
                                          setOpen(false);
                                        }
                                      }}
                                      allowClear={false}
                                    />
                                  </div>
                                </div>
                                {openPanel && activeRow === index && (
                                  <div
                                    style={{
                                      zIndex: 10,
                                      position: "absolute",
                                      width: 350,
                                      height: 400,
                                    }}
                                    key={`resultTable-${title}-${index}`}
                                    ref={ref1}
                                    className="fullContainer patientSearch__result"
                                  >
                                    <div
                                      className="tableSection"
                                      style={{
                                        height: "100%",
                                        maxHeight: "201px",
                                        position: "relative",
                                      }}
                                    >
                                      <div className="tableContainer">
                                        <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
                                          <div className="tableList">
                                            {search &&
                                              (rowData || []).map((row, index) => (
                                                <div
                                                  className={`tableItem ${index === 0 ? "no-border" : ""} ${index === activeSearch ? "active" : ""}`}
                                                  key={`resultTable-${index}`}
                                                  onClick={() => handleSearchSelect(row)}
                                                >
                                                  <div className="td with-icon icon-big" style={{ width: "100%" }}>
                                                    <p>{`${row?.first_name || ""} ${row?.last_name || ""} - ${
                                                      row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                                                    }`}</p>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </ScrollWrapper>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <p style={{ display: activeRow !== index ? "block" : "none" }}>
                              {(search && search[index] && search[index].value) || item?.patient?.name}
                            </p>
                          </div>
                          <div className="tableItemField" style={{ width: "42%" }}>
                            <Select
                              className={
                                (typeComponent && typeComponent[`${item?.id}`] && typeComponent[`${item?.id}`].value) || item?.type?.category
                                  ? ""
                                  : "input-error"
                              }
                              placeholder="Categorization"
                              suffixIcon={<ExpandSVG />}
                              value={typeComponent && typeComponent[`${item?.id}`] ? typeComponent[`${item?.id}`]["value"] : item?.type?.category}
                              onChange={(value) => {
                                selectTypeComponent(value, item?.id);
                              }}
                              allowClear={false}
                              style={{
                                flex: 1,
                                width: "100%",
                                maxWidth: "calc(100% - 54px)",
                                marginRight: 6,
                                borderBottom: 0,
                                boxShadow: "none",
                                display: activeRow !== index ? "none" : "flex",
                                backgroundColor: "#fff",
                                alignItems: "center",
                              }}
                            >
                              {orcCategory?.map((r) => (
                                <Option key={r.category} value={r.category}>
                                  <span style={{ textTransform: "capitalize" }}>{r.category}</span>
                                </Option>
                              ))}
                            </Select>

                            <p
                              style={{
                                width: "100%",
                                maxWidth: "calc(100% - 48px)",
                                textTransform: "capitalize",
                                display: activeRow !== index ? "block" : "none",
                              }}
                            >
                              {typeComponent && typeComponent[`${item?.id}`] ? typeComponent[`${item?.id}`]["value"] : item?.type?.category}
                            </p>

                            <Icon className="action" type="cloud-upload" onClick={() => saveFaxToDocumentRow(index, item?.id)} />

                            <DeleteIcon
                              extraStyle={{
                                marginLeft: "6px",
                              }}
                              icon="can"
                              selected={activeDelete === item?.id}
                              handleClick={(e) => {
                                e.stopPropagation();
                                if (activeDelete !== item?.id) {
                                  setActiveDelete(item?.id);
                                } else {
                                  DeleteApi(item?.id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      {emptyRows.map((item, index) => (
                        <div className="tableItem" key={`chart-empty-table-${index}`}>
                          <div className="tableItemField" style={{ width: "30%", minWidth: "30%" }} />
                          <div className="tableItemField" style={{ width: "40%" }} />
                          <div className="tableItemField" style={{ width: "30%", paddingRight: "10px" }} />
                        </div>
                      ))}
                    </ScrollWrapper>
                  </div>
                )}
                {activeTab !== "incoming-fax" && (
                  <div className="tableList" ref={tableRef} style={{ width: "100%", height: "calc(100% - 50px)", minHeight: "25vh" }}>
                    <ScrollWrapper css="no-padding x-hidden" onScrollFrame={(values) => loadMore(values)}>
                      {(tableLoad || []).map((item, index) => (
                        <div
                          className={`tableItem yellow ${activeRow === index ? "active" : ""}`}
                          onClick={handleRow(index, item?.id)}
                          key={`fax-${index}`}
                        >
                          <div className="tableItemField" style={{ width: "6%", minWidth: "6%" }}>
                            {item.fax_status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                            {item.fax_status === 2 && <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />}
                            {(item.fax_status === 0 || !item.fax_status || item.fax_status === 3) && (
                              <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />
                            )}
                          </div>
                          <div className="tableItemField" style={{ width: "30%", minWidth: "30%", marginLeft: "4px" }}>
                            <p>{moment(item.updated_at).format("MM/DD/YYYY hh:mm A")}</p>
                          </div>
                          <div className="tableItemField" style={{ width: "38%" }}>
                            <div style={{ paddingLeft: "0px", display: activeRow !== index && "none" }}>
                              <div className="patientSearch">
                                <div className="patientSearch__filter">
                                  <div className="patientSearch__filter-search">
                                    <Input
                                      style={{
                                        width: "100%",
                                        textTransform: "capitalize !important",
                                      }}
                                      value={(search && search[index] && search[index].value) || item?.patient?.name}
                                      placeholder={"Patient Name"}
                                      onChange={(value) => {
                                        handleSearch(value, index);
                                      }}
                                      onFocus={() => {
                                        setActiveRow(index);
                                        if (search && search[index] && search[index].value && search[index].value.length > 0) {
                                          setOpen(true);
                                        } else {
                                          setOpen(false);
                                        }
                                      }}
                                      allowClear={false}
                                    />
                                  </div>
                                </div>
                                {openPanel && activeRow === index && (
                                  <div
                                    style={{
                                      zIndex: 10,
                                      position: "absolute",
                                      width: 350,
                                      height: 400,
                                    }}
                                    key={`resultTable-${title}-${index}`}
                                    ref={ref1}
                                    className="fullContainer patientSearch__result"
                                  >
                                    <div
                                      className="tableSection"
                                      style={{
                                        height: "100%",
                                        maxHeight: "201px",
                                        position: "relative",
                                      }}
                                    >
                                      <div className="tableContainer">
                                        <ScrollWrapper css="no-padding x-hidden" onScrollFrame={onScroll}>
                                          <div className="tableList">
                                            {search &&
                                              (rowData || []).map((row, index) => (
                                                <div
                                                  className={`tableItem ${index === 0 ? "no-border" : ""} ${index === activeSearch ? "active" : ""}`}
                                                  key={`resultTable-${index}`}
                                                  onClick={() => handleSearchSelect(row)}
                                                >
                                                  <div className="td with-icon icon-big" style={{ width: "100%" }}>
                                                    <p>{`${row?.first_name || ""} ${row?.last_name || ""} - ${
                                                      row?.date_of_birth && moment(row?.date_of_birth).format("MM/DD/YYYY")
                                                    }`}</p>
                                                  </div>
                                                </div>
                                              ))}
                                          </div>
                                        </ScrollWrapper>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>

                            <p style={{ display: activeRow !== index ? "block" : "none" }}>
                              {(search && search[index] && search[index].value) || item?.patient?.name}
                            </p>
                          </div>
                          <div className="tableItemField" style={{ width: "42%" }}>
                            <Select
                              className={
                                (typeComponent && typeComponent[`${item?.id}`] && typeComponent[`${item?.id}`].value) || item?.type?.category
                                  ? ""
                                  : "input-error"
                              }
                              placeholder="Categorization Report"
                              suffixIcon={<ExpandSVG />}
                              value={typeComponent && typeComponent[`${item?.id}`] ? typeComponent[`${item?.id}`]["value"] : item?.type?.category}
                              onChange={(value) => {
                                selectTypeComponent(value, item?.id);
                              }}
                              allowClear={false}
                              style={{
                                flex: 1,
                                width: "100%",
                                maxWidth: "calc(100% - 54px)",
                                marginRight: 6,
                                borderBottom: 0,
                                boxShadow: "none",
                                display: activeRow !== index ? "none" : "flex",
                                alignItems: "center",
                                backgroundColor: "#fff",
                              }}
                            >
                              {orcCategory?.map((r) => (
                                <Option key={r.category} value={r.category}>
                                  <span style={{ textTransform: "capitalize" }}>{r.category}</span>
                                </Option>
                              ))}
                            </Select>

                            <p
                              style={{
                                width: "100%",
                                maxWidth: "calc(100% - 48px)",
                                textTransform: "capitalize",
                                display: activeRow !== index ? "block" : "none",
                              }}
                            >
                              {typeComponent && typeComponent[`${item?.id}`] ? typeComponent[`${item?.id}`]["value"] : item?.type?.category}
                            </p>

                            <Icon className="action" type="cloud-upload" onClick={() => saveFaxToDocumentRow(index, item?.id)} />

                            <DeleteIcon
                              extraStyle={{
                                marginLeft: "6px",
                              }}
                              icon="can"
                              selected={activeDelete === item?.id}
                              handleClick={(e) => {
                                e.stopPropagation();
                                if (activeDelete !== item?.id) {
                                  setActiveDelete(item?.id);
                                } else {
                                  DeleteApi(item?.id);
                                }
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      {emptyRows.map((item, index) => (
                        <div className="tableItem" key={`chart-empty-table-${index}`}>
                          <div className="tableItemField" style={{ width: "30%", minWidth: "30%" }} />
                          <div className="tableItemField" style={{ width: "35%" }} />
                          <div className="tableItemField" style={{ width: "35%", paddingRight: "10px" }} />
                        </div>
                      ))}
                    </ScrollWrapper>
                  </div>
                )}
              </div>

              <div className="resourceInfo d-flex" style={{ justifyContent: "flex-end", marginTop: "0.5rem" }} />

              <Collapse accordion x={{ marginTop: "2.5rem" }}>
                {activeTab !== "incoming-fax" ? (
                  <Panel header="Detail" key="detail">
                    <div className="resourceContent pr-7px" style={{ position: "relative" }}>
                      <div
                        className="resourceInfo d-flex required-field ins_info"
                        style={{ flexDirection: "column", justifyContent: "space-around" }}
                      >
                        {detail_fax_logs?.pcp_id ? (
                          <>
                            <EditableMaterialText
                              isErrorTextSpacing={true}
                              reset={reset}
                              value={detail_fax_logs?.pcp?.full_name}
                              label={"PCP"}
                              required={true}
                              enableErrorText={enableErrorEmail}
                              isError={validateField("email", emailValue)}
                              style={{ marginBottom: "10px" }}
                            />
                            <span
                              style={{
                                width: 18,
                                position: "absolute",
                                left: 415,
                                height: "2.5rem",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                top: "6px",
                              }}
                              onClick={() => setProviderAddForm(true)}
                            >
                              <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                            </span>
                            <span
                              style={{
                                width: 18,
                                position: "absolute",
                                left: 440,
                                height: "2.5rem",
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                top: "6px",
                              }}
                              onClick={() => setProviderEditForm(true, detail_fax_logs?.pcp)}
                            >
                              <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                            </span>
                          </>
                        ) : null}
                        <div className="resourceContent pr-7px" style={{ position: "relative" }}>
                          {detail_fax_logs?.fax_number && (
                            <EditableMaterialText
                              isErrorTextSpacing={true}
                              reset={reset}
                              value={detail_fax_logs?.fax_number}
                              label={"Outgoing fax number"}
                              required={true}
                              enableErrorText={enableErrorEmail}
                              isError={validateField("email", emailValue)}
                              style={{ marginBottom: "10px" }}
                            />
                          )}

                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                            <EditableMaterialText
                              isErrorTextSpacing={true}
                              reset={reset}
                              value={detail_fax_logs?.user && detail_fax_logs?.user.name}
                              label={"Sender"}
                              required={true}
                              enableErrorText={enableErrorEmail}
                              isError={validateField("email", emailValue)}
                            />
                            <button onClick={resendFaxHandle} className="common-btn blue-btn" style={{ border: 0 }}>
                              Re-send
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                ) : null}

                <Panel header="Fax" key="fax">
                  <div className="resourceInfo d-flex" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div
                      style={{
                        flex: 1,
                        position: "relative",
                        maxWidth: "400px",
                      }}
                      onClick={() => setOpenFax(false)}
                    >
                      <EditableFormInput
                        isErrorTextSpacing={true}
                        handleChange={(value) => {
                          setFaxValue(value);
                        }}
                        value={faxValue}
                        label={"Fax No"}
                        mask={"phone"}
                        required={true}
                        enableErrorText={enableError}
                        isError={validateField("fax_to", faxValue)}
                        helperText={"Fax No is Required!"}
                      />

                      {((existingPCP && existingPCP.fax_number) || (existingProvider && existingProvider.fax_number)) && (
                        <div
                          style={{
                            position: "absolute",
                            display: "inline-flex",
                            width: "40px",
                            height: "40px",
                            zIndex: 20,
                            top: "2px",
                            right: "2px",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            transform: isOpenFax ? "rotate(180deg)" : "rotate(0deg)",
                          }}
                          className="dropdown-button"
                          onClick={toggleOpenFax}
                        >
                          <ArrowDownSVG />
                        </div>
                      )}

                      {isOpenFax && ((existingPCP && existingPCP.fax_number) || (existingProvider && existingProvider.fax_number)) && (
                        <div
                          style={{
                            position: "absolute",
                            zIndex: 20,
                            left: 0,
                            right: 0,
                            top: "42px",
                            background: "#fff",
                            border: "1px solid #ddd",
                          }}
                        >
                          {existingPCP && existingPCP.fax_number && (
                            <div
                              style={{
                                width: "100%",
                                height: "40px",
                                borderBottom: "1px solid #ddd",
                                padding: "0 14px",
                                fontSize: "14px",
                                color: "#000",
                                lineHeight: "40px",
                                cursor: "pointer",
                                background: pcp === "pcp" ? "#dbe3ee" : "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFaxValue(existingPCP.fax_number);
                                if (existingPCP.email) {
                                  setEmailValue(existingPCP.email);
                                }
                                setPCP("pcp");
                                setOpenFax(false);
                              }}
                            >
                              PCP
                            </div>
                          )}
                          {existingProvider && existingProvider.fax_number && (
                            <div
                              style={{
                                width: "100%",
                                height: "40px",
                                padding: "0 14px",
                                fontSize: "14px",
                                color: "#000",
                                lineHeight: "40px",
                                cursor: "pointer",
                                background: pcp === "referring_provider" ? "#dbe3ee" : "#fff",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setFaxValue(existingProvider.fax_number);
                                if (existingProvider.email) {
                                  setEmailValue(existingProvider.email);
                                }
                                setPCP("referring_provider");
                                setOpenFax(false);
                              }}
                            >
                              Referring Provider
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <button onClick={faxSend} className="common-btn blue-btn" style={{ marginLeft: "8px", border: 0 }}>
                      Send
                    </button>
                  </div>
                </Panel>
                <Panel header="E-Mail" key="e-mail">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <EditableMaterialText
                        reset={reset}
                        handleChange={(value) => {
                          setEmailValue(value);
                        }}
                        value={selectedPatient && selectedPatient.email ? selectedPatient.email : emailValue}
                        label={"E-Mail"}
                        required={true}
                        enableErrorText={enableErrorEmail}
                        isError={validateField("email", emailValue)}
                        helperText={"E-Mail is Required!"}
                      />
                    </div>
                    <button className="common-btn blue-btn" onClick={sendEmail} style={{ marginLeft: "8px", border: 0 }}>
                      Send
                    </button>
                  </div>
                </Panel>
                <Panel header="Upload" key="upload">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* button */}
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Upload {...uploadProps} className="text-right">
                          <button className="common-btn outlined-btn">Capture Image</button>
                        </Upload>
                        <button
                          className="common-btn blue-btn"
                          style={{
                            marginLeft: "0.5rem",
                          }}
                          onClick={uploadImage}
                        >
                          Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </Panel>
                <Panel header="Assignment" key="assignment">
                  <div
                    className="resourceInfo d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <AutoCompleteSearch
                      handlePopulate={(row) => setAssignmentRow(row)}
                      title="staff"
                      options={activeStaff}
                      handleSearch={(value) => changeSearch(value)}
                      placeholder="Search Staff"
                      keyLabel="name"
                      actionLabel="Search"
                      disableSearchIcon
                      backgroundWhite={"white"}
                    />
                    <button className="common-btn blue-btn" onClick={assignDocumentToStaff} style={{ marginLeft: "8px", border: 0 }}>
                      Assign
                    </button>
                  </div>
                </Panel>
                <Panel header="Print" key="print">
                  <div className="resourceInfo d-flex" style={{ alignItems: "center", justifyContent: "flex-end" }} onClick={sendPrint}>
                    <button className="common-btn" style={{ border: 0 }}>
                      Print
                    </button>
                  </div>
                </Panel>
              </Collapse>
            </div>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default FaxingSidebar;
