import { Input, Menu } from "antd";
import React from "react";
import { connect } from "react-redux";
import ButtonDropdown from "../../../common/components/EditableText/Dropdown";
import { finalizePatientPending, finalizeProcedurePending, finalizeUltrasoundProcessing } from "../../../services/api.services";
import {
  getCallback,
  getClinicCallback,
  getClinicPending,
  getPending,
  getProcedureCallback,
  getProcedurePending,
  getUltrasoundCallback,
  getUltrasoundPending,
  patient_cancel,
  procedure_cancel,
  setGlobalLoading,
  setGlobalToastr,
  getSingleProcedure,
} from "../../../store/actions";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";

const { TextArea } = Input;

class Finalize extends React.PureComponent {
  state = {
    buttonText: "Finalize",
    valueSelect: null,
    indexSelect: null,
  };

  componentDidMount() {
    if (this?.props?.type == "ClinicProcessing") {
      this.setState({ buttonText: "Confirm" });
    } else if (this?.props?.type == "UltrasoundProcessing") {
      this.setState({ buttonText: "Confirm" });
    }
    this.props.getSingleProcedure(this.props.resource?.id);
  }

  /*
  Finalize button handle
   */
  handleCommunication = async () => {
    if (this?.props?.type == "newPatient") {
      await finalizePatientPending(this.props.newPatient?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getPending({});
      await this.props.getCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    } else if (this?.props?.type == "Procedure") {
      await finalizeProcedurePending(this.props.resource?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getProcedurePending({});
      await this.props.getProcedureCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    } else if (this?.props?.type == "ClinicProcessing") {
      await finalizeProcedurePending(this.props.resource?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getClinicPending({});
      await this.props.getClinicCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    } else {
      await finalizeUltrasoundProcessing(this.props.resource?.id);
      await this.props.setGlobalLoading(true);
      await this.props.getUltrasoundPending({});
      await this.props.getUltrasoundCallback({});
      await this.props.setGlobalLoading(false);
      this.props.handleCloseSidebar();
    }
  };

  clickCancel = async (value, index) => {
    if (index !== 3) {
      if (this?.props?.type == "newPatient") {
        await this.props.setGlobalLoading(true);
        await this.props.patient_cancel(this.props.newPatient?.id, {
          reason: value,
        });
        await this.props.getPending({});
        await this.props.getCallback({});
        await this.props.setGlobalLoading(false);
      } else {
        await this.props.setGlobalLoading(true);
        await this.props.procedure_cancel(this.props.resource?.id, {
          reason: value,
        });
        await this.props.getProcedurePending({});
        await this.props.getProcedureCallback({});
        await this.props.getUltrasoundPending({});
        await this.props.getUltrasoundCallback({});
        await this.props.setGlobalToastr({
          header: "Ultrasound",
          message: "The appointment has been removed",
          type: "success",
        });
        await this.props.setGlobalLoading(false);
      }
      this.props.actionShowNote(false);
      if (this.props.onCancel) {
        this.props.onCancel();
      }
    } else {
      await this.props.getUltrasoundPending({});
      await this.props.getUltrasoundCallback({});
      this.props.actionShowNote(true);
    }
  };

  onChange = (value) => {
    this.props.setNoteCancelValue(value.target?.value);
  };

  clickNoteCancel = async () => {
    if (this?.props?.type == "newPatient") {
      await this.props.setGlobalLoading(true);
      await this.props.patient_cancel(this.props.newPatient?.id, {
        reason: this.props.noteCancelValue,
      });
      await this.props.getPending({});
      await this.props.getCallback({});
      await this.props.setGlobalLoading(false);
    } else {
      await this.props.setGlobalLoading(true);
      await this.props.procedure_cancel(this.props.resource?.id, {
        reason: this.props.noteCancelValue,
      });
      await this.props.getProcedurePending({});
      await this.props.getProcedureCallback({});
      await this.props.setGlobalLoading(false);
    }
  };

  menu = (
    <Menu>
      {[
        {
          display: "Entered in Error",
          value: "",
        },
        {
          display: "Patient deceased",
          value: "",
        },
        {
          display: "Other",
          value: "",
        },
      ].map((item, index) => {
        return (
          <Menu.Item
            key={index}
            onClick={() => {
              this.clickCancel(item, index);
            }}
          >
            <a>{item}</a>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  render() {
    return (
      <div className="resourceContent">
        <div className="resourceInfo d-flex">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            {this.props.procedure?.flag_no_reschedule === 1 ? (
              <>
                <div
                  style={{
                    width: "45%",
                    alignItems: "flex-start",
                  }}
                >
                  <EditableFormInput
                    label={"Remove All Future Appointments"}
                    handleChange={(value) => {
                      this.setState({ valueSelect: value, indexSelect: value == "Deceased" ? 0 : 1 });
                    }}
                    value={this.state.valueSelect}
                    isError={false}
                    fullWidth={true}
                    type={"check"}
                    style={{ width: "100%" }}
                    allowClear={true}
                    optionKey={"display"}
                    valueKey={"value"}
                    options={[
                      {
                        display: "Deceased",
                        value: "Deceased",
                      },
                      {
                        display: "Discharged",
                        value: "Discharged",
                      },
                    ]}
                  />
                </div>
                {this.state.valueSelect ? (
                  <div
                    style={{
                      width: "20%",
                      alignItems: "flex-start",
                    }}
                  >
                    <button
                      className="common-btn blue-btn"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        this.clickCancel(this.state.valueSelect, this.state.indexSelect);
                      }}
                    >
                      <span>Confirm Remove</span>
                    </button>
                  </div>
                ) : null}
              </>
            ) : null}
            {this.props.procedure?.flag_no_reschedule === 0 ? (
              <div
                style={{
                  width: "20%",
                  alignItems: "flex-start",
                }}
              >
                <button
                  className="common-btn blue-btn"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  onClick={this.handleCommunication}
                >
                  <span>{this.state.buttonText}</span>
                  <span>+</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
        {this.props.showNote && (
          <React.Fragment>
            <TextArea rows={4} value={this.props.noteCancelValue} style={{ marginTop: 20 }} showCount maxLength={100} onChange={this.onChange} />
            <div className="actionContent">
              <button
                onClick={() => {
                  this.clickNoteCancel();
                }}
                className="common-btn"
              >
                Save
              </button>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  resource: state.procedure.resource,
  newPatient: state.newPatient.resource,
  procedure: state.procedure.procedure,
});

const mapDispatchToProps = {
  getPending,
  getCallback,
  getProcedureCallback,
  setGlobalLoading,
  setGlobalToastr,
  getProcedurePending,
  finalizeProcedurePending,
  finalizePatientPending,
  patient_cancel,
  procedure_cancel,
  getClinicPending,
  getClinicCallback,
  getUltrasoundPending,
  getUltrasoundCallback,
  getSingleProcedure,
};

export default connect(mapStateToProps, mapDispatchToProps)(Finalize);
