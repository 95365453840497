import React, { useCallback, useEffect, useState } from "react";
import * as cornerstone from "cornerstone-core";
import * as cornerstoneFileImageLoader from "cornerstone-file-image-loader";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import { import as csTools } from "cornerstone-tools";
import * as cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import CornerstoneViewport from "react-cornerstone-viewport";
import * as dicomParser from "dicom-parser";
import Hammer from "hammerjs";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

// Configure Cornerstone tools
const scrollToIndex = csTools("util/scrollToIndex");
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneFileImageLoader.external.cornerstone = cornerstone;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.init();

const DicomViewer = ({ toolActive }) => {
  const imageSub = useSelector((state) => state.dicom?.imageSub);
  const dcmImage = useSelector((state) => state.dicom?.dcmImage);
  const urlImage = useSelector((state) => state.dicom?.urlImage);
  const [imageIds, setImageIds] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    if (imageSub && imageSub.length > 0) {
      const newImageIds = imageSub.map((r) => {
        const checkDCM = r.image_path.slice(-3);
        if (checkDCM === "dcm") {
          return `wadouri:${r.image_path}`;
        } else {
          return `${r.image_path}`;
        }
      });
      setImageIds(newImageIds);
    } else {
      const value = dcmImage ? [`wadouri:${dcmImage}`] : urlImage ? [`${urlImage}`] : null;
      setImageIds(value);
    }
  }, [imageSub, urlImage, dcmImage]);

  // Preload all images to avoid loading delays when scrolling
  const preloadImages = useCallback(() => {
    (imageIds || []).forEach((imageId) => {
      cornerstone.loadImage(imageId).catch((error) => {
        console.error("Error preloading image:", error);
      });
    });
  }, [imageIds]);

  useEffect(() => {
    if (imageIds && imageIds.length > 1) {
      preloadImages();
    }
  }, [imageIds]);

  const handleScroll = useCallback(
    debounce((event) => {
      if (!imageIds || imageIds.length == 0) return;

      // Determine scroll direction
      const direction = event.deltaY > 0 ? 1 : -1;

      // Log scroll direction
      if (direction > 0) {
        setCurrentImageIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < 0) return 0;
          if (nextIndex >= imageIds.length) return imageIds.length - 1;
          return nextIndex;
        });
      } else {
        setCurrentImageIndex((prevIndex) => {
          const nextIndex = prevIndex - 1;
          if (nextIndex < 0) return 0;
          if (nextIndex >= imageIds.length) return imageIds.length - 1;
          return nextIndex;
        });
      }
    }, 50),
    [imageIds]
  );

  const handleKeyDown = (event) => {
    if (imageIds && imageIds.length > 0) {
      if (event.key === "ArrowUp" || event.key === "ArrowRight") {
        setCurrentImageIndex((prevIndex) => {
          const nextIndex = prevIndex - 1;
          if (nextIndex < 0) return 0;
          if (nextIndex >= imageIds.length) return imageIds.length - 1;
          return nextIndex;
        });
      }
      if (event.key === "ArrowDown" || event.key === "ArrowLeft") {
        setCurrentImageIndex((prevIndex) => {
          const nextIndex = prevIndex + 1;
          if (nextIndex < 0) return 0;
          if (nextIndex >= imageIds.length) return imageIds.length - 1;
          return nextIndex;
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleScroll, imageIds, imageSub]);

  return (
    <div
      autoFocus={true}
      style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}
    >
      {imageIds && imageIds.length > 0 && (
        <CornerstoneViewport
          imageIds={imageIds}
          imageIdIndex={currentImageIndex}
          tools={[
            { name: "Zoom", mode: "active" },
            { name: "Pan", mode: "active" },
            { name: "Wwwc", mode: "active" },
            { name: "Length", mode: "active" },
            { name: "Angle", mode: "active" },
            { name: "Magnify", mode: "active" },
            { name: "Probe", mode: "active" },
            { name: "EllipticalRoi", mode: "active" },
            { name: "RectangleRoi", mode: "active" },
            { name: "FreehandRoi", mode: "active" },
          ]}
          activeTool={toolActive}
          style={{ width: "100%", height: "100%" }}
          cornerstoneOptions={{ renderer: "webgl" }}
          initialViewport={{
            scale: 1, // Adjust scale as needed to zoom out slightly and center the image
            hflip: false,
            vflip: false,
            rotation: 0,
            translation: { x: 0, y: 0 }, // Center the image by setting the translation to 0,0
          }}
          // viewportOverlayComponent={() => (
          //   <ImageOverlay currentImageIndex={currentImageIndex} totalImages={imageIds.length} imageInfo={{ width: null, height: null }} />
          // )}
        />
      )}
    </div>
  );
};

export default DicomViewer;
