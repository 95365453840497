import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import Loading from "../../../common/components/Loading";
import {
  angio_condition_type,
  angio_condition_type_choice,
  angio_condition_type_cpt,
  search_cpt_code,
  setProcedureDetails,
} from "../../../store/actions";

let timer = null;

const CptCodeLogic = ({ resource, isOffTemAndVari }) => {
  const cptCodesAngio = useSelector((state) => state.procedureDetail.cptCodesAngio);
  const angio_type_variable_list = useSelector((state) => state.procedureDetail.angio_type_variable_list);
  const selectData = useSelector((state) => state.common.searchCPT);
  const loadingSearchCPT = useSelector((state) => state.common.loadingSearchCPT);
  const angio_type_select_search = useSelector((state) => state.procedureDetail.angio_type_select_search);
  const loading_angio_type_variable = useSelector((state) => state.procedureDetail.loading_angio_type_variable);
  const angio_choice = useSelector((state) => state.procedureDetail.angio_choice);
  const detail_angio_type = useSelector((state) => state.procedureDetail.detail_angio_type);

  const [searchResult, setSearchResult] = useState([]);
  const [searchAnswer, setSearchAnswer] = useState([]);
  const [selectResult, setSelectResult] = useState([]);
  const [selectSearch, setSelectSearch] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [focusState, setFocusState] = useState(false);
  const [IDRow, setIDRow] = useState(null);
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);
  const dispatch = useDispatch();

  const modifiers = ["RT", "LT", "22", "50", "51", "52", "53", "62", "66", "73", "74", "PA", "PB", "PC", "XU"];

  const update = async (data) => {
    if (detail_angio_type?.id) {
      const arrayADD = (data || cptCodesAngio || []).map((r) => {
        return {
          cpt_code_id: r?.cpt_code_id,
          angio_procedure_type_id: detail_angio_type?.id,
          condition_serialize: r?.condition_serialize ? [...r?.condition_serialize] : [{}],
        };
      });
      await dispatch(angio_condition_type_cpt(detail_angio_type?.id, { rules: arrayADD }));
    }
  };

  const handleChange = async (type, value, index, indexICD) => {
    if (cptCodesAngio && cptCodesAngio[indexICD]) {
      if (type === "cpt_code_id") {
        cptCodesAngio[indexICD]["cpt_code_id"] = value[0];
        await dispatch(setProcedureDetails("cptCodesAngio", [...cptCodesAngio]));
        update([...cptCodesAngio]);
      } else {
        if (type == "icd_code") {
          cptCodesAngio[indexICD]["condition_serialize"][index]["icd_code"]["icd_code"] = value[1];
          cptCodesAngio[indexICD]["condition_serialize"][index]["icd_code"]["icd_name"] = value[2];
        } else {
          cptCodesAngio[indexICD]["condition_serialize"][index][type] = value === "THAN" && type === "operator" ? "THAN" : value;
        }
        if (!isOffTemAndVari) {
          if (type == "variable_name") {
            cptCodesAngio[indexICD]["condition_serialize"][index]["answer"] = undefined;
          }
          const dataCheckAnswer = (cptCodesAngio || []).map((r, index) => {
            return {
              ...r,
              condition_serialize: (r?.condition_serialize || []).map((condition) => {
                return {
                  ...condition,
                  answers: (angio_type_variable_list.find((item) => item?.id == condition?.variable_id) || {})?.answers || [],
                };
              }),
            };
          });
          await dispatch(setProcedureDetails("cptCodesAngio", [...dataCheckAnswer]));
          // fix call 2 lần vì ở đây variable_id,icd_code và variable_name,cpt_code_id sẽ dc thêm 2 lần
          if (type != "variable_id" && type != "icd_code") {
            update([...dataCheckAnswer]);
          }
        } else {
          await dispatch(setProcedureDetails("cptCodesAngio", [...cptCodesAngio]));
          // fix call 2 lần vì ở đây variable_id,icd_code và variable_name,cpt_code_id sẽ dc thêm 2 lần
          if (type != "variable_id" && type != "icd_code") {
            update([...cptCodesAngio]);
          }
        }
      }
    }
  };

  // useEffect(() => {}, [angio_type_variable_list, isOffTemAndVari]);

  const handleChangeOperator = async (value, index, indexICD) => {
    if (value === "AND NOT") {
      return;
    }
    const nextIndex = index + 1;
    if (cptCodesAngio[indexICD] && cptCodesAngio[indexICD]["condition_serialize"] && cptCodesAngio[indexICD]["condition_serialize"].length > 0) {
      const plan = cptCodesAngio[indexICD]["condition_serialize"][nextIndex];
      const paramNU = {
        variable_id: plan && typeof plan["variable_id"] != "undefined" ? plan["variable_id"] : "",
        variable_name: plan && typeof plan["variable_name"] != "undefined" ? plan["variable_name"] : "",
        answer: plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison: plan && typeof plan["comparison"] != "undefined" ? plan["comparison"] : ">=",
        operator: plan && typeof plan["operator"] != "undefined" ? plan["operator"] : "",
      };
      const paramUN = {
        icd_code: { icd_code: "", icd_name: "" },
        variable_id: undefined,
        variable_name: undefined,
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (cptCodesAngio && cptCodesAngio[indexICD]) {
        if (value !== "THAN") {
          cptCodesAngio[indexICD]["condition_serialize"][index + 1] = {
            ...paramNU,
          };
          await dispatch(setProcedureDetails("cptCodesAngio", [...cptCodesAngio]));
          update([...cptCodesAngio]);
        } else {
          const abc = cptCodesAngio[indexICD];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          cptCodesAngio[indexICD]["condition_serialize"] = [...check, paramUN];
          await dispatch(setProcedureDetails("cptCodesAngio", [...cptCodesAngio]));
          update([...cptCodesAngio]);
        }
      }
    }
  };

  useEffect(() => {
    if (detail_angio_type && Object.keys(detail_angio_type).length > 0) {
      if (!isOffTemAndVari) {
        const dataCheckAnswer = (detail_angio_type?.cpt_rules || []).map((r) => {
          return {
            ...r,
            condition_serialize: (r?.condition_serialize || []).map((condition) => {
              return {
                ...condition,
                answers: (angio_type_variable_list.find((item) => item?.id == condition?.variable_id) || {})?.answers || [],
              };
            }),
          };
        });
        dispatch(setProcedureDetails("cptCodesAngio", [...dataCheckAnswer]));
        // update([...dataCheckAnswer]);
      } else {
        dispatch(setProcedureDetails("cptCodesAngio", detail_angio_type?.cpt_rules ? detail_angio_type?.cpt_rules : []));
        // update(detail_angio_type?.cpt_rules ? detail_angio_type?.cpt_rules : []);
      }
    }
  }, [detail_angio_type, isOffTemAndVari, angio_type_variable_list]);

  useEffect(() => {
    if (!isOffTemAndVari) {
      const selectMap = (angio_type_variable_list || []).map((r) => {
        return { id: r?.id, name: r.variable_name };
      });
      setSearchResult([...selectMap]);
    } else {
      const selectMap = (angio_type_select_search || []).map((r) => {
        return { id: r?.id, name: r?.name };
      });
      const checkArrayChoice = (angio_choice || []).map((r) => {
        return { id: r?.id, name: r?.name };
      });
      setSearchResult([...selectMap]);
      setSearchAnswer([...checkArrayChoice]);
    }
  }, [angio_type_variable_list, isOffTemAndVari, angio_choice]);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult([...selectData]);
    }
  }, [selectData]);

  useEffect(() => {
    async function fetchData() {
      setLocalLoading(true);
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(search_cpt_code(selectSearch, 1));
        setLocalLoading(false);
      }, 500);
    }
    fetchData();
  }, [selectSearch]);

  const removeProvider = async (index, indexICD) => {
    const checkCPTRow = cptCodesAngio[indexICD];
    if (checkCPTRow && checkCPTRow["condition_serialize"] && checkCPTRow["condition_serialize"].length > 1) {
      if (checkCPTRow["condition_serialize"][index].operator === "THAN" || checkCPTRow["condition_serialize"][index].operator === "") {
        checkCPTRow["condition_serialize"].splice(index, 2);
      } else {
        checkCPTRow["condition_serialize"].splice(index, 1);
      }
      const check = checkCPTRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        cptCodesAngio[indexICD]["condition_serialize"] = [...check];
      } else {
        cptCodesAngio.splice(indexICD, 1);
      }
      await dispatch(setProcedureDetails("cptCodesAngio", [...cptCodesAngio]));
      update([...cptCodesAngio]);
    } else {
      cptCodesAngio[indexICD] = null;
      const checkArray = cptCodesAngio.filter((r) => {
        if (r != null) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("cptCodesAngio", [...checkArray]));
      update([...checkArray]);
    }
  };

  useEffect(() => {
    dispatch(angio_condition_type());
  }, []);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {cptCodesAngio.map((itemICD, indexICD) => {
        return (
          <div
            key={`${isOffTemAndVari ? "intra" : "answer"}-${indexICD}-tab`}
            onClick={() => {
              setIDRow(indexICD);
            }}
            style={{
              width: "100%",
              background: "rgb(219, 227, 238)",
              padding: "7px 0",
              marginBottom: "1rem",
            }}
          >
            <DataTable
              title="icd_code"
              hideHeader={true}
              sizeHeightFix={itemICD?.condition_serialize && itemICD?.condition_serialize.length > 1 ? itemICD?.condition_serialize.length * 40 : 40}
              isICD={true}
              isOverFlow={true}
              isFixHeight={true}
              hideHeaderHeight="0px"
              allActive={true}
              handleDelete={(value, index) => removeProvider(index, indexICD)}
              parentIndex={indexICD}
              handleActiveDelete={(val) => {
                const activeDeletes = [...activeDelete];
                activeDeletes[indexICD] = val;
                if (removableParent !== indexICD) {
                  activeDeletes[removableParent] = -1;
                  setRemovableParent(indexICD);
                } else {
                  if (val === -1) setRemovableParent(-1);
                }
                setActiveDelete(activeDeletes);
              }}
              activeParent={activeDelete[indexICD]}
              labels={["Variable", "Option", "Answer", "Operator"]}
              widths={["25%", "15%", "35%", "25%"]}
              columns={[
                { sortable: false, key: "variable" },
                { sortable: false, key: "option" },
                { sortable: false, key: "answer" },
                { sortable: false, key: "operator" },
              ]}
              rowData={(itemICD?.condition_serialize || []).map((item, index) => {
                return {
                  key: `${isOffTemAndVari ? "intra" : "answer"}-${index}`,
                  disableDelete: typeof item?.operator === "undefined" && true,
                  fullsize: typeof item?.operator === "undefined" && true,
                  variable:
                    typeof item?.operator === "undefined" ? (
                      <div
                        key={`${isOffTemAndVari ? "intra" : "answer"}-${index}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Select
                          key={`${isOffTemAndVari ? "intra" : "answer"}-${index}`}
                          showSearch
                          placeholder="Search ICD Code"
                          value={`${item?.icd_code?.icd_code} ${item?.icd_code?.icd_name}`}
                          onSearch={(value) => {
                            setSelectSearch(value);
                          }}
                          onDropdownVisibleChange={(open) => {
                            setFocusState(open);
                          }}
                          onBlur={() => {
                            setSelectSearch(null);
                          }}
                          autoClearSearchValue={false}
                          suffixIcon={<ExpandSVG />}
                          allowClear={false}
                          notFoundContent={
                            localLoading || loadingSearchCPT ? (
                              <div style={{ height: 100 }}>
                                <Loading />
                              </div>
                            ) : (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            )
                          }
                          onChange={(value) => {
                            handleChange("cpt_code_id", value, index, indexICD);
                            handleChange("icd_code", value, index, indexICD);
                          }}
                          style={{ width: "80%", borderBottom: 0 }}
                        >
                          {(selectResult || []).map((r, index) => (
                            <Select.Option key={r?.id} value={focusState ? [r?.id, r.code, r.short_desc] : `${r.code} ${r.short_desc}`}>
                              {r.code} {r.short_desc}
                            </Select.Option>
                          ))}
                        </Select>
                        <Select
                          placeholder={"Modifier"}
                          onChange={(value) => {
                            handleChange("modifier", value, index, indexICD);
                          }}
                          key={index}
                          value={item?.modifier}
                          suffixIcon={<ExpandSVG />}
                          allowClear={false}
                          style={{
                            width: "calc(20% - 0.75rem)",
                            borderBottom: 0,
                            marginLeft: "0.75rem",
                          }}
                        >
                          {modifiers.map((el) => (
                            <Select.Option key={`modifier-${el}`} value={el}>
                              {el}
                            </Select.Option>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      <Select
                        key={index}
                        placeholder={"Variable"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.variable_id}
                        onChange={(value) => {
                          handleChange("variable_id", value, index, indexICD);
                          handleChange(
                            "variable_name",
                            searchResult.find((r) => r?.id == value),
                            index,
                            indexICD
                          );
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(searchResult || []).map((opt, index) => (
                          <Select.Option key={`variable-${index}-${opt?.id}`} value={opt?.id}>
                            {opt?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  option:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Opt"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.comparison}
                        showArrow={false}
                        onChange={(value) => {
                          handleChange("comparison", value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["<=", "=", "!=", ">="].map((opt) => (
                          <Select.Option key={`option--${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  answer:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Answer"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.answer}
                        notFoundContent={loading_angio_type_variable ? null : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        onFocus={async () => {
                          if (isOffTemAndVari) {
                            setSearchAnswer([]);
                            if (item?.variable_id) {
                              await dispatch(angio_condition_type_choice(item?.variable_id));
                            }
                          }
                        }}
                        onChange={(value) => {
                          handleChange("answer", value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(!isOffTemAndVari ? item?.answers || [] : searchAnswer || []).map((opt) => (
                          <Select.Option
                            key={`answer-${isOffTemAndVari ? opt?.name : opt?.answer}-${opt?.id}-${isOffTemAndVari ? "active" : "none"}`}
                            value={isOffTemAndVari ? opt?.name : opt?.answer}
                          >
                            {isOffTemAndVari ? opt?.name : opt?.answer}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  operator:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Operator"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.operator || undefined}
                        onChange={(value) => {
                          handleChange("operator", value, index, indexICD);
                          handleChangeOperator(value, index, indexICD);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["OR", "AND", "THAN", "AND NOT"].map((opt) => (
                          <Select.Option key={`operator-${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                };
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CptCodeLogic;
