import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import DataTable from "../../../../common/components/DataTable/DataTable";
import { getLocal } from "../../../../helpers/Local";
import { getEvaluationsTagert, getEvaluationsUser } from "../../../../store/actions";
import { StaticResource } from "../../constant";
import EvaluationsSidebar from "./EvaluationsSidebar";
import moment from "moment";
import { Icon } from "antd";

const Evaluations = ({ data, title, loading }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [activeIndex, setRow] = useState(-1);

  const evaluationsTagert = useSelector((state) => state.common.evaluationsTagert);
  const currentUserInfo = useSelector((state) => state.common.currentUserInfo);
  const evaluationsUser = useSelector((state) => state.common.evaluationsUser);
  const [tableRows, setTableRows] = useState([]);

  const authId = getLocal("cvai-current-user");

  const dispatch = useDispatch();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, []);

  useEffect(() => {
    if (evaluationsUser.length && evaluationsUser.length > 0) {
      const userRows = evaluationsUser.map((item) => ({
        id: item.id,
        created_at: item.created_at,
        type: "Quarterly Evaluation",
        points: 0,
        status: item.status,
        ...item,
      }));
      const combinedRows = [...evaluationsTagert, ...userRows];
      setTableRows(combinedRows);
    }
  }, [evaluationsUser, evaluationsTagert]);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const handleRow = (_, index) => {
    setRow(index);
    setIsSidebar(true);
  };

  const fetchList = () => {
    dispatch(getEvaluationsUser(authId));
    dispatch(getEvaluationsTagert(authId));
  };

  const fetchData = () => {
    fetchList();
  };

  const sidebarRef = useRef();
  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="fullContainer pt-2">
          <div className="tableSection">
            <div className="tableContainer">
              <DataTable
                title="employee_evaluations"
                tableWidth={920}
                labels={["Evaluation Date", "Type", "Reason", "Points"]}
                widths={["24%", "18%", "22%", "18%"]}
                columns={[
                  { sortable: false, key: "created_at" },
                  { sortable: false, key: "type" },
                  { sortable: false, key: "reason" },
                  { sortable: false, key: "points" },
                ]}
                sorts={["", "", "", ""]}
                rowData={(tableRows || []).map((row, index) => ({
                  id: row?.id,
                  created_at: (
                    <div
                      style={{
                        display: "flex",
                        paddingLeft: "0.25rem",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ minWidth: "26px" }} />
                      {row?.status === 0 && <Icon type="warning" theme="twoTone" twoToneColor="#FF832B" />}
                      {row?.status === 1 && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
                      <p>{moment(row?.created_at).format("MM/DD/YYYY")}</p>
                    </div>
                  ),
                  type: row?.type || "Variance",
                  reason: row?.variance_type,
                  points: row?.points,
                }))}
                fetchList={fetchData}
                handleClickRow={handleRow}
                defaultActive={-1}
                disableDelete={true}
              />
            </div>
          </div>
        </div>
      </div>
      {activeIndex >= 0 && evaluationsTagert.length > 0 && (
        <EvaluationsSidebar
          ref={sidebarRef}
          currentUserInfo={currentUserInfo}
          evaluationsTagertDetail={tableRows[activeIndex]}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
        />
      )}
    </div>
  );
};

export default Evaluations;
