import React, { useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import ToastMessage from "../../../common/components/Toast";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import { setProcedureDetails, getUltrasounds, addProcedureDetails, adminReferralConditionUpdate } from "../../../store/actions";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";

const ProcedureTimes = [
  { value: 2, label: "2 week" },
  { value: 4, label: "4 weeks" },
  { value: 8, label: "8 weeks" },
  { value: 12, label: "12 weeks" },
  { value: 24, label: "24 weeks" },
  { value: 52, label: "52 weeks" },
];

const FollowUpUltrasounds = ({ resource }) => {
  const [checkNew, setCheckNew] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");
  const [isToastr, setIsToastr] = useState(false);
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const ultraSearch = useSelector((state) => state.common.ultrasounds);
  const ultrasoundsCondition = useSelector((state) => state.procedureDetail.ultrasoundsCondition);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);

  const updateProviders = async (type, index, value) => {
    const isValueSame = ultrasoundsCondition?.some((el) => {
      return el?.name_ultrasound === value?.name;
    });
    if (isValueSame) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
      return;
    } else {
      await dispatch(
        addProcedureDetails("ultrasoundsCondition", {
          name_ultrasound: value?.name,
          ultrasound: value?.id,
          interval: null,
        })
      );
      setCheckNew(true);
    }
  };

  const removeProvider = async (index) => {
    ultrasoundsCondition.splice(index, 1);
    await dispatch(setProcedureDetails("ultrasoundsCondition", [...ultrasoundsCondition]));
    setCheckNew(true);
  };

  useEffect(() => {
    if (selectedReferralCondition) {
      const providerDetail = (selectedReferralCondition.ultrasound_types || []).map((r) => {
        return {
          name_ultrasound: r?.name,
          ultrasound: r?.id,
          interval: r?.pivot?.interval ? Number(r?.pivot?.interval) : null,
        };
      });
      dispatch(setProcedureDetails("ultrasoundsCondition", [...providerDetail]));
    }
    setCheckNew(false);
  }, [selectedReferralCondition]);

  useEffect(() => {
    if (checkNew) {
      callApiUpdate();
    }
  }, [ultrasoundsCondition, checkNew]);

  useEffect(() => {
    if (ultraSearch && ultraSearch.length > 0) {
      setSearchResult(ultraSearch);
    }
  }, [ultraSearch]);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = ultraSearch.filter((r) => r?.name.toLowerCase().includes(search.toLowerCase()));
      setSearchResult(checkfilter);
    } else {
      setSearchResult(ultraSearch);
    }
  }, [search]);

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);
      const count = ultrasoundsCondition && ultrasoundsCondition.length > 0 ? total - ultrasoundsCondition.length : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, ultrasoundsCondition]);

  const callApiUpdate = () => {
    if (ultrasoundsCondition && selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0) {
      const renderArray = ultrasoundsCondition.map((r) => {
        return {
          id: r?.ultrasound,
          interval: r?.interval,
        };
      });
      dispatch(
        adminReferralConditionUpdate(selectedReferralCondition?.id, {
          value: selectedReferralCondition?.value,
          ultrasound_types: renderArray,
        })
      );
    }
  };

  const updateProviderInterval = (index, value) => {
    if (ultrasoundsCondition && ultrasoundsCondition[index]) {
      const providerDetail = ultrasoundsCondition?.map((r, mapIndex) => {
        return {
          ...r,
          interval: mapIndex === index ? value : r?.interval,
        };
      });
      dispatch(setProcedureDetails("ultrasoundsCondition", [...providerDetail]));
      setCheckNew(true);
    }
  };

  useEffect(() => {
    dispatch(getUltrasounds());
  }, []);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isToastr && <ToastMessage type="Ultrasound Already Exists" status="failed" msg="Can Not Add Same Value!" />}
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          isLargeHeight={true}
          title="Procedure-Follow-Up-Ultrasound"
          options={searchResult}
          handlePopulate={(value) => updateProviders("ultrasound", ultrasoundsCondition.length, value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Ultrasounds"
          keyLabel={"name"}
          actionLabel="Add"
          backgroundWhite={"blue"}
        />
      </div>
      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="follow_up_ultrasounds"
          labels={["Ultrasound", "Interval"]}
          widths={["60%", "40%"]}
          fullHeight={true}
          handleDelete={(value, index) => removeProvider(index)}
          columns={[
            { sortable: true, key: "ultrasound" },
            { sortable: false, key: "interval" },
          ]}
          rowData={(ultrasoundsCondition || []).map((item, index) => {
            return {
              ultrasound: item?.name_ultrasound,
              interval: (
                <Select
                  placeholder="Duration"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  value={item?.interval}
                  onChange={(value) => {
                    updateProviderInterval(index, value);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {ProcedureTimes.map((r) => (
                    <Select.Option key={r?.value} value={r?.value}>
                      {r.label}
                    </Select.Option>
                  ))}
                </Select>
              ),
            };
          })}
          emptyRows={emptyRows}
          sorts={[""]}
        />
      </div>
    </div>
  );
};

export default FollowUpUltrasounds;
