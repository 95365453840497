import { Icon } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createcpt_optimize_variables,
  createOptimizeVariables,
  createReimbursementVariables,
  getcpt_optimize_variables,
  getOptimizeVariables,
  getReimbursementVariables,
  get_cpt_utilization_rate,
  create_cpt_utilization_rate,
} from "src/services/api.services";
import { setGlobalLoading, setGlobalToastr } from "src/store/actions";
import CardBlock from "../../../common/components/Card";
import "../style.scss";
import CptCodeLogicAI from "./CptCodeLogicAI";
import OBLOptimizer from "./OBLOptimizer";
import Utilization from "./Utilization";
import CPTVariable from "./Cpt_Variable";
import VariableCaptureBAI from "./VariableAi";
// Icons

const ProcedureDetail = (props) => {
  const dispatch = useDispatch();
  const [isVariableSelected, setIsVariableSelected] = useState({
    index: null,
    selectedVariable: {
      id: null,
    },
  });
  const [isCPTSelected, setIsCPTSelected] = useState({
    index: null,
    selectedVariable: {
      id: null,
    },
  });
  const [conditionVariable, setConditionVariable] = useState([]);
  const [CPTAI, setCPTAI] = useState([]);
  const [CPTV, setCPTV] = useState([]);
  const [utilization, setUtilization] = useState([]);

  const fetchApi = async () => {
    dispatch(setGlobalLoading(true));
    const dataSuccess = await getOptimizeVariables();
    if (dataSuccess) {
      const arrFix = dataSuccess.map((r) => {
        return {
          ...r,
          keyInventory:
            r?.inventory[0]?.inventory?.name && r?.inventory[0]?.inventory?.id
              ? `${r?.inventory[0]?.inventory?.name} - ${r?.inventory[0]?.inventory?.id}`
              : null,
          cost: r?.inventory[0]?.inventory?.cost || null,
          inventory_id: r?.inventory[0]?.inventory?.id || null,
        };
      });
      setConditionVariable(arrFix);
    }
    dispatch(setGlobalLoading(false));
  };

  const fetchApiCPTAI = async () => {
    dispatch(setGlobalLoading(true));
    const dataSuccess = await getReimbursementVariables();
    if (dataSuccess) {
      const arrFix = dataSuccess.map((r) => {
        return {
          ...r,
          cpt: r?.cpt_code,
          cpt_key: r?.cpt_code?.cpt_code && r?.cpt_code?.short_desc ? `${r?.cpt_code?.short_desc} - ${r?.cpt_code?.cpt_code}` : null,
          cpts: (r?.addons || []).map((row) => {
            return {
              ...row,
              cpt: row?.cpt_code,
              amount: row?.pivot?.amount,
              cpt_code_id: row?.id,
              cpt_key: row?.cpt_code && row?.short_desc ? `${row?.short_desc} - ${row?.cpt_code}` : null,
            };
          }),
        };
      });
      setCPTAI(arrFix);
    }
    dispatch(setGlobalLoading(false));
  };

  const fetchApiUtilization = async () => {
    dispatch(setGlobalLoading(true));
    const dataSuccess = await get_cpt_utilization_rate();
    if (dataSuccess) {
      setUtilization(dataSuccess);
    }
    dispatch(setGlobalLoading(false));
  };

  const fetchApiCPTV = async () => {
    dispatch(setGlobalLoading(true));
    const dataSuccess = await getcpt_optimize_variables();
    if (dataSuccess) {
      const arrFix = dataSuccess.map((r) => {
        return {
          ...r,
          variables: (r?.optimize_variables || []).map((row) => {
            return row?.id;
          }),
          cpt_reimbursement_id: r?.id,
        };
      });
      setCPTV(arrFix);
    }
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchApi();
    fetchApiCPTAI();
    fetchApiCPTV();
    fetchApiUtilization();
  }, []);

  const handleAddNewData = async (data) => {
    dispatch(setGlobalLoading(true));
    try {
      const dataSuccess = await createOptimizeVariables(data);
      if (dataSuccess) {
        setConditionVariable([...conditionVariable, dataSuccess]);
      }
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: "Save Successfully!",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };

  const handleAddNewDataCPT = async (data) => {
    dispatch(setGlobalLoading(true));
    try {
      const dataSuccess = await createReimbursementVariables(data);
      if (dataSuccess) {
        setCPTAI([...CPTAI, dataSuccess]);
      }
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: "Save Successfully!",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };
  const handleAddNewDataCPTV = async (data) => {
    dispatch(setGlobalLoading(true));
    try {
      setCPTV([...CPTV, data]);
      const dataSuccess = await createcpt_optimize_variables(data);
      if (dataSuccess) {
        setCPTV([...CPTV, dataSuccess]);
      }
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: "Save Successfully!",
          type: "success",
        })
      );
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };

  const handleAddNewDataUtilization = async (data) => {
    setUtilization([...utilization, data]);
  };

  const addOn = (value) => {
    const tempVariable = [...CPTAI];
    if (CPTAI[isCPTSelected?.index]) {
      tempVariable.splice(isCPTSelected?.index, 1, {
        ...CPTAI[isCPTSelected?.index],
        cpts: [
          ...(CPTAI[isCPTSelected?.index]?.cpts || []),
          {
            amount: "",
            cpt_code_id: "",
            cpt_key: "",
          },
        ],
      });
      setCPTAI([...tempVariable]);
    }
  };

  const handleRowCPT = (row) => {};

  return (
    <div className="clinicWorkflow__main-section" style={{ padding: "0 0.5rem 0 1rem", height: "calc(100vh - 208px)" }}>
      <div className="fullContainer has-no-sidebar">
        <div
          className="mainContainer admin_clinical_logic"
          style={{
            marginLeft: "-10px",
            paddingTop: "0",
            paddingBottom: "0",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
          }}
        >
          <CardBlock
            title="Variable Capture"
            varient={"half"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    width: 40,
                    height: 16,
                    position: "absolute",
                    top: 2,
                    left: -35,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  {isVariableSelected?.index >= 1 ? (
                    <Icon
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      type="up-square"
                      theme="twoTone"
                      twoToneColor="#0f62fe"
                      onClick={() => {}}
                    />
                  ) : null}
                  {isVariableSelected?.index || isVariableSelected?.index === 0 ? (
                    <Icon
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                      }}
                      type="down-square"
                      theme="twoTone"
                      twoToneColor="#0f62fe"
                      onClick={() => {}}
                    />
                  ) : null}
                </div>
                <button
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    handleAddNewData({
                      question: "",
                      inventory: {},
                      keyInventory: "",
                      cost: "",
                      inventory_id: "",
                    });
                  }}
                >
                  Add Addon +
                </button>
                {/* {activeSave ? (
                  <button className="text-btn" style={{ marginLeft: "1rem" }} onClick={handleSave}>
                    save
                  </button>
                ) : null} */}
              </div>
            }
          >
            <VariableCaptureBAI
              conditionVariable={conditionVariable}
              setConditionVariable={(value) => {
                setConditionVariable(value);
              }}
              rowAction={setIsVariableSelected}
            />
          </CardBlock>
          {/*  */}
          <CardBlock
            title="CPT Code Logic"
            varient={"half"}
            other=""
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  disabled={isCPTSelected?.selectedVariable?.id ? false : isCPTSelected?.selectedVariable?.id}
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={addOn}
                >
                  Add Addon +
                </button>

                <button
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() => {
                    handleAddNewDataCPT({
                      amount: "",
                      cpts: [],
                      cpt_code_id: "",
                      cpt_key: "",
                    });
                  }}
                >
                  Add +
                </button>
              </div>
            }
          >
            <CptCodeLogicAI
              CPTAI={CPTAI}
              addOn={addOn}
              setCPTAI={(value) => {
                setCPTAI(value);
              }}
              isCPTSelected={isCPTSelected}
              rowAction={setIsCPTSelected}
            />
          </CardBlock>
          {/*  */}
          <CardBlock
            title="CPT Variable"
            other=""
            varient={"half"}
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() =>
                    handleAddNewDataCPTV({
                      cpt_code: {},
                      cpt_reimbursement_id: 12,
                      cpt_key: "",
                      variables: [11, 10],
                    })
                  }
                >
                  Add +
                </button>
              </div>
            }
          >
            <CPTVariable
              CPTV={CPTV}
              conditionVariable={conditionVariable}
              CPTAI={CPTAI}
              setCPTV={(value) => {
                setCPTV(value);
              }}
            />
          </CardBlock>

          <CardBlock
            title="Utilization rate"
            other=""
            varient={"half"}
            contentPadding="1rem"
            subAction={
              <div
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="text-btn"
                  style={{ marginLeft: "1rem" }}
                  onClick={() =>
                    handleAddNewDataUtilization({
                      cpt_reimbursement_id: null,
                      ideal_utilization_rate: "",
                      ideal_utilization_rate: null,
                      "6_month_utilization_rate": "",
                      "12_month_utilization_rate": "",
                      current_utilization_rate: "",
                    })
                  }
                >
                  Add +
                </button>
              </div>
            }
          >
            <Utilization setUtilization={setUtilization} CPTAI={CPTAI} utilization={utilization} />
          </CardBlock>
        </div>
      </div>
    </div>
  );
};

export default ProcedureDetail;
