import { Drawer, Icon, Popconfirm } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import EditableInput from "../../../../common/components/EditableText/EditableFormInput";
import PusherScan from "../../../../common/components/PusherScan";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import ToastMessage from "../../../../common/components/Toast";
import YesNo from "../../../../common/components/YesNo/YesNoOne";
import { validateField } from "../../../../services/validations";
import {
  adminUserGetSignatureImage,
  disableUser,
  enableUser,
  getUsers,
  saveDetailUsers,
  setGlobalLoading,
  getOnlyProviders,
} from "../../../../store/actions";
import AddUserDetails from "./AddUserDetail";

const QRCode = require("qrcode.react");

const ActiveSidebar = React.forwardRef(({ resource, isShow, handleCloseSidebar }, ref) => {
  const [isToastr, setIsToastr] = useState(false);
  const [reset, setReset] = useState(true);
  const [isEnable, setIsEnable] = useState(false);
  const [hidePass, setHidePass] = useState({
    password: true,
    confirmPassword: true,
  });
  const [isAddForm, setIsAddForm] = useState(false);
  const [salaryOption, setSalaryOption] = useState("Salary");
  const [signatureData, setSignatureData] = useState(undefined);
  const signatureImage = useSelector((state) => state.adminUser.signatureImage);
  const [popUpProvider, setPopUpProvider] = useState({});
  const [popUpAdmin, setPopUpAdmin] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [clickedSignature, setClickedSignature] = useState(false);
  const usersDetail = useSelector((state) => state.common.usersDetail);
  const sideNavStatus = useSelector((state) => state.common.sideNavStatus);
  const only_providers = useSelector((state) => state.common.only_providers);
  const [isReset, setIsReset] = useState(false);
  const [state, setState] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (!only_providers || only_providers.length == 0) {
      dispatch(getOnlyProviders());
    }
  }, [only_providers]);

  const openAddForm = () => {
    setIsAddForm(true);
    setConfirmDelete(false);
  };
  const closeAddForm = () => {
    setIsAddForm(false);
  };

  const saveAll = async (value) => {
    setConfirmDelete(false);
    dispatch(setGlobalLoading(true));
    const saveSuccess = await dispatch(
      saveDetailUsers(value?.id, {
        name: value?.name,
        username: value.login,
        password: value.password,
        salary: value.salary,
        exemption: value.exemptions,
        email: value.email,
        phone: value.phone,
        address: value.address,
        address_2: value.address_2,
        city: value.city,
        state: value.state,
        zipcode: value.zipcode,
        ssn: value.ssn,
        points: value.points,
        position: value.position,
        vacation_hours: value.hours,
        hire_date: value.hire,
        end_date: value.end,
        is_provider: value.isProvider,
        is_pcm: value.isPCM,
        is_admin: value.isAdmin,
        is_support: value.isSupport,
        is_billing: value.isBilling,
        is_technician: value.isTechnician,
        is_practice_provider: value.isApp,
        ops_provider_id: value.ops_provider_id,
        npi: value.npi,
        dea: value.dea,
        facility_conversion_factor: value.facility_conversion_factor,
        medicare_conversion_factor: value.medicare_conversion_factor,
        primary_benefits_withhold: value.primary_benefits_withhold,
        dependency_benefits_withhold: value.dependency_benefits_withhold,
        primary_benefits_percentage: value.primary_benefits_percentage,
        dependency_benefits_percentage: value.dependency_benefits_percentage,
        lunch_time: value.lunch_time,
        reviewed_provider_id: value.reviewed_provider_id,
      })
    );
    const loadListSuccess = await dispatch(getUsers());
    dispatch(setGlobalLoading(false));
    if (saveSuccess && loadListSuccess) {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const changeHandler = useCallback(
    debounce((value) => saveAll(value), 1000),
    []
  );

  const handleChange = (type, value) => {
    const newValue = {
      ...state,
      [type]: value,
    };
    setState(newValue);
    if (type == "password" || type == "confirm_password") {
      if (newValue["password"] == newValue["confirm_password"]) {
        if (!isAddForm) {
          isReset && changeHandler(newValue);
        }
      }
    } else {
      if (!isAddForm) {
        isReset && changeHandler(newValue);
      }
    }
  };

  const handleChangeOption = (key, value) => {
    if (key === "isProvider") {
      handleChange("isProvider", value);
    } else if (key === "isPCM") {
      handleChange("isPCM", value);
    } else if (key === "isTechnician") {
      handleChange("isTechnician", value);
    } else if (key === "isSupport") {
      handleChange("isSupport", value);
    } else if (key === "isBilling") {
      handleChange("isBilling", value);
    } else if (key === "isApp") {
      handleChange("isApp", value);
    } else {
      handleChange("isAdmin", value);
    }
  };

  const disableClickUser = async () => {
    if (!confirmDelete) {
      setConfirmDelete(true);
      return;
    }
    if (Object.keys(usersDetail).length > 0) {
      const disableSuccess = await dispatch(disableUser(usersDetail?.id));
      setConfirmDelete(false);
      const loadListSuccess = await dispatch(getUsers());
      if (disableSuccess && loadListSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  const enableClickUser = async () => {
    if (Object.keys(usersDetail).length > 0) {
      setConfirmDelete(false);
      const enableSuccess = await dispatch(enableUser(usersDetail?.id));
      const loadListSuccess = await dispatch(getUsers());
      if (enableSuccess && loadListSuccess) {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
          handleCloseSidebar();
          setState({});
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if ((usersDetail || {}).provider_id) {
      const reloadSignatureImage = () => {
        const provider_id = (usersDetail || {}).provider_id;
        dispatch(adminUserGetSignatureImage(provider_id));
      };
      reloadSignatureImage();
      const reloadSignatureImageInterval = setInterval(reloadSignatureImage, 30000);
      return () => {
        clearInterval(reloadSignatureImageInterval);
      };
    }
  }, [usersDetail]);

  const [oldSignatureImage, setOldSignatureImage] = useState(undefined);
  useEffect(() => {
    if (oldSignatureImage !== signatureImage) {
      setSignatureData(undefined);
    }
    setOldSignatureImage(signatureImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureImage]);

  useEffect(() => {
    setPopUpProvider({});
    setPopUpAdmin({});
    if (usersDetail) {
      setReset(true);
      setSignatureData(undefined);
      setConfirmDelete(false);
      setIsReset(false);
      setState({
        id: usersDetail?.id,
        isProvider: usersDetail.is_provider === -1 ? null : usersDetail.is_provider,
        isPCM: usersDetail.is_pcm === -1 ? null : usersDetail.is_pcm,
        isAdmin: usersDetail.is_admin === -1 ? null : usersDetail.is_admin,
        isSupport: usersDetail.is_support === -1 ? null : usersDetail.is_support,
        isBilling: usersDetail.is_billing === -1 ? null : usersDetail.is_billing,
        isTechnician: usersDetail.is_technician === -1 ? null : usersDetail.is_technician,
        isApp: usersDetail.is_practice_provider === -1 ? null : usersDetail.is_practice_provider,
        points: usersDetail.meta ? usersDetail.meta.points : "",
        position: usersDetail.position && usersDetail.position,
        userName: usersDetail.username,
        email: usersDetail.email,
        phone: usersDetail.phone,
        address: usersDetail.address,
        address_2: usersDetail.address_2,
        city: usersDetail.city,
        state: usersDetail.state,
        zipcode: usersDetail.zipcode,
        ssn: usersDetail.ssn,
        name: usersDetail?.name,
        login: usersDetail.username,
        salary: usersDetail.meta && usersDetail.meta.salary,
        exemptions: usersDetail.meta && usersDetail.meta.exemption,
        hours: usersDetail.vacation_hours_per_year,
        hire: usersDetail.meta && usersDetail.meta.hire_date,
        end: usersDetail.meta && usersDetail.meta.end_date,
        ops_provider_id: usersDetail.ops_provider_id,
        npi: usersDetail.npi,
        dea: usersDetail.dea,
        facility_conversion_factor: usersDetail.facility_conversion_factor,
        medicare_conversion_factor: usersDetail.medicare_conversion_factor,
        primary_benefits_withhold: usersDetail.meta && usersDetail.meta.primary_benefits_withhold,
        dependency_benefits_withhold: usersDetail.meta && usersDetail.meta.dependency_benefits_withhold,
        primary_benefits_percentage: usersDetail.meta && usersDetail.meta.primary_benefits_percentage,
        dependency_benefits_percentage: usersDetail.meta && usersDetail.meta.dependency_benefits_percentage,
        lunch_time: usersDetail.meta && usersDetail.meta.lunch_time,
        reviewed_provider_id: usersDetail.reviewed_provider_id,
      });
      setIsEnable(!!usersDetail.status);
      setTimeout(() => {
        setIsReset(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersDetail]);

  useEffect(() => {
    if (reset) {
      setReset(false);
    }
  }, [reset]);

  if (!usersDetail) {
    return null;
  }

  const startSignature = () => {
    if ((usersDetail || {}).provider_id) {
      const signatureData_ = {
        type: "admin-sign",
        signature_data: {
          provider_id: (usersDetail || {}).provider_id,
        },
      };
      setSignatureData(signatureData_);
      setClickedSignature(true);
    }
  };

  const handleScanQRCode = () => {
    setClickedSignature(false);
  };

  return (
    <Drawer
      title={!isAddForm ? usersDetail?.name : " "}
      placement="right"
      closable
      className={sideNavStatus ? "wide" : ""}
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        {isToastr && <ToastMessage type="User" status="success" msg="Changed Successfully!" />}
        <ScrollWrapper>
          {isAddForm && <AddUserDetails closeAddForm={closeAddForm} />}
          {!isAddForm && (
            <React.Fragment>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                  marginTop: "1.5rem",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Name"}
                    value={state?.name}
                    handleChange={(value) => {
                      handleChange("name", value);
                    }}
                    required={true}
                    isError={validateField("text", state?.name)}
                    helperText={"Name is Required!"}
                  />
                </div>
              </div>
              <div
                className="resourceContent"
                style={{
                  flex: "unset",
                  paddingLeft: "24px",
                  paddingRight: "20px",
                }}
              >
                <div className="resourceInfo d-flex align-items-end required-field" style={{ marginTop: "0.5rem" }}>
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Salary/Wage"}
                    value={salaryOption}
                    type={"check"}
                    handleChange={(value) => {
                      setSalaryOption(value);
                    }}
                    options={[
                      { value: "Salary", label: "Salary" },
                      { value: "Wage", label: "Wage" },
                    ]}
                    optionKey={"label"}
                    valueKey={"label"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={salaryOption}
                    value={state.salary}
                    prefix={"$"}
                    handleChange={(value) => {
                      handleChange("salary", value);
                    }}
                    required={true}
                    isError={validateField("text", state.salary)}
                    helperText={"Salary/Wage is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Primary Benefits Total"}
                    value={state.primary_benefits_withhold}
                    prefix={"$"}
                    handleChange={(value) => {
                      handleChange("primary_benefits_withhold", value);
                    }}
                    required={true}
                    isError={validateField("text", state.salary)}
                    helperText={"Primary Benefits Withhold is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Dependency Benefits Total"}
                    value={state.dependency_benefits_withhold}
                    prefix={"$"}
                    handleChange={(value) => {
                      handleChange("dependency_benefits_withhold", value);
                    }}
                    required={true}
                    isError={validateField("text", state.salary)}
                    helperText={"Dependency Benefits Withhold is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Primary Benefits Percentage"}
                    value={state.primary_benefits_percentage}
                    handleChange={(value) => {
                      handleChange("primary_benefits_percentage", value);
                    }}
                    required={true}
                    isError={validateField("text", state.salary)}
                    helperText={"Primary Benefits Percentage is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Dependency Benefits Percentage"}
                    value={state.dependency_benefits_percentage}
                    handleChange={(value) => {
                      handleChange("dependency_benefits_percentage", value);
                    }}
                    required={true}
                    isError={validateField("text", state.salary)}
                    helperText={"Dependency Benefits Percentage is Required!"}
                  />
                </div>

                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Points"}
                    value={state.points}
                    handleChange={(value) => {
                      handleChange("points", value);
                    }}
                    required={true}
                    isError={validateField("text", state.points)}
                    helperText={"Points is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    reset={reset}
                    label={"Position"}
                    value={state.position}
                    handleChange={(value) => {
                      handleChange("position", value);
                    }}
                    required={true}
                    isError={validateField("text", state.position)}
                    helperText={"Position is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Email"}
                    value={state.email}
                    handleChange={(value) => {
                      handleChange("email", value);
                    }}
                    required={true}
                    isError={validateField("email", state.email)}
                    helperText={"Email is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Phone"}
                    value={state.phone}
                    handleChange={(value) => {
                      handleChange("phone", value);
                    }}
                    required={true}
                    isError={validateField("phone", state.phone)}
                    helperText={"Phone is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Address"}
                    value={state.address}
                    handleChange={(value) => {
                      handleChange("address", value);
                    }}
                    required={true}
                    isError={validateField("text", state.address)}
                    helperText={"Address is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Address 2"}
                    value={state.address_2}
                    handleChange={(value) => {
                      handleChange("address_2", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"City"}
                    value={state.city}
                    handleChange={(value) => {
                      handleChange("city", value);
                    }}
                    required={true}
                    isError={validateField("text", state.city)}
                    helperText={"City is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"State"}
                    value={state.state}
                    handleChange={(value) => {
                      handleChange("state", value);
                    }}
                    required={true}
                    isError={validateField("text", state.state)}
                    helperText={"State is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Zipcode"}
                    value={state.zipcode}
                    handleChange={(value) => {
                      handleChange("zipcode", value);
                    }}
                    required={true}
                    isError={validateField("text", state.zipcode)}
                    helperText={"Zipcode is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"SSN"}
                    value={state.ssn}
                    handleChange={(value) => {
                      handleChange("ssn", value);
                    }}
                    required={true}
                    isError={validateField("text", state.ssn)}
                    helperText={"SSN is Required!"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Exemptions"}
                    value={state.exemptions}
                    handleChange={(value) => {
                      handleChange("exemptions", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Vacation Hours/yr"}
                    value={state.hours}
                    handleChange={(value) => {
                      handleChange("hours", value);
                    }}
                    unit={"hrs"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Hire Date"}
                    value={state.hire}
                    handleChange={(value) => {
                      handleChange("hire", value);
                    }}
                    type={"date"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"End Date"}
                    value={state.end}
                    handleChange={(value) => {
                      handleChange("end", value);
                    }}
                    type={"date"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Lunch Time"}
                    value={state.lunch_time}
                    handleChange={(value) => {
                      handleChange("lunch_time", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"Login"}
                    value={state.login}
                    handleChange={(value) => {
                      handleChange("login", value);
                    }}
                    required={true}
                    isError={validateField("text", state.userName)}
                    helperText={"User name is required"}
                  />
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    typeKeyboard={hidePass?.password ? "password" : ""}
                    isErrorTextSpacing={true}
                    label={"Password"}
                    value={state.password}
                    handleChange={(value) => {
                      handleChange("password", value);
                    }}
                  />
                  <span
                    style={{
                      width: 18,
                      position: "absolute",
                      left: 425,
                      height: "2.5rem",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setHidePass({
                        ...hidePass,
                        password: !hidePass?.password,
                      });
                    }}
                  >
                    <Icon type={hidePass?.password ? "eye" : "eye-invisible"} />
                  </span>
                </div>
                <div className="resourceInfo d-flex align-items-end required-field">
                  <EditableInput
                    typeKeyboard={hidePass?.confirmPassword ? "password" : ""}
                    isErrorTextSpacing={true}
                    label={"Confirm Password"}
                    value={state.confirm_password}
                    handleChange={(value) => {
                      handleChange("confirm_password", value);
                    }}
                  />
                  <span
                    style={{
                      width: 18,
                      position: "absolute",
                      left: 425,
                      height: "2.5rem",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setHidePass({
                        ...hidePass,
                        confirmPassword: !hidePass?.confirmPassword,
                      });
                    }}
                  >
                    <Icon type={hidePass?.confirmPassword ? "eye" : "eye-invisible"} />
                  </span>
                </div>
                <div className="resourceInfo d-flex align-items-end">
                  <EditableInput
                    isErrorTextSpacing={true}
                    label={"OPS Provider ID"}
                    value={state.ops_provider_id}
                    handleChange={(value) => {
                      handleChange("ops_provider_id", value);
                    }}
                  />
                </div>
                {state?.isProvider ? (
                  <div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"DEA"}
                        value={state.dea}
                        handleChange={(value) => {
                          handleChange("dea", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"NPI"}
                        value={state.npi}
                        handleChange={(value) => {
                          handleChange("npi", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"Medicare Conversion Factor"}
                        value={state.medicare_conversion_factor}
                        handleChange={(value) => {
                          handleChange("medicare_conversion_factor", value);
                        }}
                      />
                    </div>
                    <div className="resourceInfo d-flex align-items-end">
                      <EditableInput
                        isErrorTextSpacing={true}
                        label={"Facility Conversion Factor"}
                        value={state.facility_conversion_factor}
                        handleChange={(value) => {
                          handleChange("facility_conversion_factor", value);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {signatureImage && usersDetail ? (
                  <PusherScan
                    signType="admin-sign"
                    cardType=""
                    scanData={usersDetail}
                    isQRcode={clickedSignature}
                    handleScanQRCode={handleScanQRCode}
                    qrData={signatureData}
                  />
                ) : null}
                {signatureData ? (
                  <>
                    <hr />
                    <div className="resourceInfo d-flex" style={{ justifyContent: "center" }}>
                      <QRCode value={JSON.stringify(signatureData)} size={256} />
                    </div>
                  </>
                ) : null}
                <hr />
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Provider: </b>
                  <Popconfirm
                    placement="bottom"
                    visible={popUpProvider?.visible}
                    title={"Are you sure you want to change provider settings? Doing so will reset the schedule."}
                    onConfirm={() => {
                      handleChangeOption("isProvider", popUpProvider?.value);
                      setPopUpProvider({
                        ...popUpProvider,
                        visible: false,
                      });
                    }}
                    okText="Yes"
                    onCancel={() => {
                      setPopUpProvider({
                        ...popUpProvider,
                        visible: false,
                      });
                    }}
                    cancelText="No"
                  >
                    <YesNo
                      option={state.isProvider}
                      setOption={(value) => {
                        setPopUpAdmin({});
                        setPopUpProvider({
                          visible: true,
                          value,
                        });
                      }}
                    />
                  </Popconfirm>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>APP (Advanced Practice Provider): </b>
                  <YesNo
                    option={state.isApp}
                    setOption={(value) => {
                      handleChangeOption("isApp", value);
                    }}
                  />
                </div>
                {state?.isApp ? (
                  <div className="resourceInfo d-flex">
                    <EditableInput
                      label={"Provider"}
                      type={"check"}
                      options={only_providers}
                      value={state?.reviewed_provider_id}
                      handleChange={(value) => {
                        handleChange("reviewed_provider_id", value);
                      }}
                      optionKey={"id"}
                      valueKey={"value"}
                    />
                  </div>
                ) : null}
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Technician: </b>
                  <YesNo
                    option={state.isTechnician}
                    setOption={(value) => {
                      handleChangeOption("isTechnician", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>PCM: </b>
                  <YesNo
                    option={state.isPCM}
                    setOption={(value) => {
                      handleChangeOption("isPCM", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Admin: </b>
                  <Popconfirm
                    placement="bottom"
                    visible={popUpAdmin?.visible}
                    title={"Are you sure you want to change admin settings? Doing so will give admin right to the user"}
                    onConfirm={() => {
                      handleChangeOption("isAdmin", popUpAdmin?.value);
                      setPopUpAdmin({
                        ...popUpAdmin,
                        visible: false,
                      });
                    }}
                    okText="Yes"
                    onCancel={() => {
                      setPopUpAdmin({
                        ...popUpAdmin,
                        visible: false,
                      });
                    }}
                    cancelText="No"
                  >
                    <YesNo
                      option={state.isAdmin}
                      setOption={(value) => {
                        setPopUpProvider({});
                        setPopUpAdmin({
                          visible: true,
                          value,
                        });
                      }}
                    />
                  </Popconfirm>
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Support: </b>
                  <YesNo
                    option={state.isSupport}
                    setOption={(value) => {
                      handleChangeOption("isSupport", value);
                    }}
                  />
                </div>
                <div className="resourceInfo d-flex">
                  <b style={{ minWidth: "37%" }}>Billing: </b>
                  <YesNo
                    option={state.isBilling}
                    setOption={(value) => {
                      handleChangeOption("isBilling", value);
                    }}
                  />
                </div>
              </div>
              <div
                className="actionContent"
                style={{
                  marginTop: "2rem",
                  paddingRight: "1rem",
                  flexWrap: "wrap",
                }}
              >
                {(usersDetail || {}).provider_id && (usersDetail || {}).provider_id !== "0" ? (
                  <button className="outlined-btn" onClick={startSignature} style={{ marginTop: "0.5rem" }}>
                    New Signature
                  </button>
                ) : (
                  <></>
                )}
                <button className="outlined-btn" style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }} onClick={openAddForm}>
                  Add New User
                </button>
                {Object.keys(usersDetail).length > 0 && !isEnable ? (
                  <button onClick={enableClickUser} className="outlined-btn" style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
                    Enable User
                  </button>
                ) : (
                  <div
                    style={{
                      justifyContent: "flex-end",
                      marginLeft: "0.5rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    <button
                      className={`common-btn ${confirmDelete ? "delete-btn" : "outlined-btn"}`}
                      style={
                        confirmDelete
                          ? {
                              marginRight: 0,
                              backgroundColor: "#CA373B",
                            }
                          : {}
                      }
                      onClick={disableClickUser}
                    >
                      {confirmDelete ? "Confirm Delete?" : "Delete User"}
                    </button>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default ActiveSidebar;
