import { Icon } from "antd";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { getLocal, setLocal } from "../../../helpers/Local";
import {
  acceptForce,
  changeActiveRow,
  notify_lock,
  releaseLock,
  remove_selected_patient_resource,
  resetStateResource,
  setGlobalToastr,
} from "../../../store/actions";
import "./style.scss";

const ToastMessage = ({ type, msg, status, width, isStatic, link, exlink }) => {
  const current_appointment_id = getLocal("current_appointment_id");
  const dispatch = useDispatch();

  const varient = useMemo(() => {
    if (status === "success") {
      return "blue-box";
    } else if (status === "failed") {
      return "red-box";
    }
    return "default";
  }, [status]);

  const notifyToUser = async (link) => {
    if (link == "Accept") {
      await dispatch(releaseLock(current_appointment_id, {}));
      await setLocal("current_appointment_id", null);
      dispatch(remove_selected_patient_resource());
      dispatch(resetStateResource());
      dispatch(changeActiveRow(true));
    }
    if (link == "Acknowledge") {
      dispatch(setGlobalToastr(null));
    } else {
      await dispatch(notify_lock(current_appointment_id));
    }
  };

  const notifyExLink = async (exlink) => {
    if (exlink.split(" _ ")[0] && exlink.split(" _ ")[0] === "Override") {
      dispatch(acceptForce(true, exlink.split(" _ ")[1]));
      dispatch(setGlobalToastr(null));
    } else {
      await dispatch(releaseLock(current_appointment_id, { force: true }));
    }
  };

  return (
    <div className={`ToastMessage ${varient} ${isStatic ? "static" : ""}`} style={{ width: width ? width : 400 }}>
      <div className="ToastMessage__container">
        {status !== "failed" && <Icon type="check-circle" theme="twoTone" twoToneColor="#24A148" />}
        {status === "failed" && <Icon type="question-circle" theme="twoTone" twoToneColor="#DA1E28" />}
        <div className="ToastMessage__infoSection">
          <div className="ToastMessage__infoSection-title">{type}</div>
          {typeof msg === "object" && msg !== null && !Array.isArray(msg) ? (
            Object.values(msg).map((message, index) => (
              <div key={index} className="ToastMessage__infoSection-msg">
                {message}
              </div>
            ))
          ) : (
            <div className="ToastMessage__infoSection-msg">{msg}</div>
          )}

          {link && (
            <a
              style={{ color: "#0f62fe", textDecorationLine: "underline" }}
              onClick={() => {
                notifyToUser(link);
              }}
            >
              {link && link.split(" _ ")[0] ? link.split(" _ ")[0] : link}
            </a>
          )}
          <br></br>
          {exlink && (
            <a
              style={{ color: "#0f62fe", textDecorationLine: "underline" }}
              onClick={() => {
                notifyExLink(exlink);
              }}
            >
              {exlink && exlink.split(" _ ")[0] ? exlink.split(" _ ")[0] : exlink}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToastMessage;
