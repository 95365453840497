import { DatePicker, Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import moment from "moment";

const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const EvaluationsFilter = ({ payPeriods, selectedPayperiod, handlePayperiod, changeEmployee, setIsSidebar, setRow }) => {
  const users = useSelector((state) => state.common.users);
  const userActive = users && users["active_users"] && users["active_users"].length > 0 ? users["active_users"] : [];
  return (
    <div className="filterContainer">
      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "180px", marginLeft: "0" }}>
          <Select
            style={{
              width: "100%",
            }}
            placeholder="Filter by Pay Period"
            suffixIcon={<ExpandSVG />}
            value={selectedPayperiod}
            onChange={(payperiod) => handlePayperiod(payperiod)}
          >
            {payPeriods.map((payperiod, index) => (
              <Option key={`payperiod-${index}`} value={payperiod?.id}>
                {`${moment(payperiod?.from_date).format("MM/DD/YYYY")} - ${moment(payperiod?.to_date).format("MM/DD/YYYY")}`}
              </Option>
            ))}
          </Select>
        </div>
        <div className="statusFilter" style={{ minWidth: "260px", marginLeft: "10px" }}>
          <Select
            placeholder="Filter by Employee Name..."
            suffixIcon={<ExpandSVG />}
            allowClear={true}
            onChange={(value) => {
              changeEmployee(value);
            }}
          >
            {(userActive || []).map((item) => (
              <Option key={item?.id} value={item?.id}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
      <div
        className="statusFilterContainer"
        style={{ width: "calc(100% - 440px)", display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}
      >
        <button
          className="button-add-new-evaluation"
          onClick={() => {
            setIsSidebar && setIsSidebar(true);
            setRow && setRow(-1);
          }}
          style={{ marginRight: "10px" }}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

export default EvaluationsFilter;
