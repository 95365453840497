import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ToastMessage from "../../../common/components/Toast";
import {
  autoZip,
  detail_insurance_companies,
  edit_insurance_companies,
  payer_id_types,
  save_insurance_companies,
  search_insurance_companies,
} from "../../../store/actions";

let timer = null;

const AddCarrier = () => {
  const dispatch = useDispatch();
  const search_insurances = useSelector((state) => state.newPatient.search_insurance_companies);
  const detail_insurance = useSelector((state) => state.newPatient.detail_insurance_companies);
  const payer_id_typesCheck = useSelector((state) => state.newPatient.payer_id_types);
  const zipAutoData = useSelector((state) => state.common.zipAuto);

  const [first, setFirst] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");
  const [state, setState] = useState({});
  const [listSearch, setListSearch] = useState([]);

  const debounce = useDebounce(search, 400);

  useEffect(() => {
    fetchData(debounce);
  }, [debounce]);

  const fetchData = (query) => {
    if (!query) {
      setListSearch([]);
      return;
    }
    dispatch(search_insurance_companies(query));
  };

  useEffect(() => {
    dispatch(search_insurance_companies(""));
    dispatch(payer_id_types());
    setFirst(true);
  }, []);

  useEffect(() => {
    setListSearch(search_insurances);
  }, [search_insurances]);

  useEffect(() => {
    if (detail_insurance && detail_insurance?.id) {
      setState({
        ...detail_insurance,
      });
    }
  }, [detail_insurance]);

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      if (zipAutoData?.success) {
        if (zipAutoData?.addresses && zipAutoData?.addresses[0]) {
          const address = zipAutoData?.addresses[0];
          const params = {
            ...state,
            state: address.state_id,
            city: address.place,
          };
          setState(params);
        }
      } else {
        const params = {
          ...state,
          state: "",
          city: "",
        };
        setState(params);
      }
    }
  }, [zipAutoData]);

  const addSearch = async (value) => {
    if (value && value?.id) {
      await dispatch(detail_insurance_companies(value?.id));
    }
  };

  const handleProvider = (value, type) => {
    const params = {
      ...state,
      [type]: value,
    };
    setState(params);
    if (type === "zip") {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        await dispatch(autoZip(value));
      }, 1000);
    }
    if (params && params?.id) {
      clearTimeout(timer);
      timer = setTimeout(async () => {
        const saveSuccess = await dispatch(edit_insurance_companies(params?.id, params));
        if (saveSuccess) {
          setIsToastr(true);
          setTimeout(() => {
            setIsToastr(false);
          }, 1000);
        }
      }, 500);
    }
  };

  const handleBtnSaveClicked = async () => {
    const saveSucess = await dispatch(save_insurance_companies(state));
    if (saveSucess) {
      setIsToastr(true);
      setTimeout(() => {
        setState({});
        setIsToastr(false);
      }, 1000);
    }
  };

  const styleLeftElement = {
    width: "50%",
    paddingRight: "1rem",
    borderRight: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const styleRightElement = {
    width: "50%",
    paddingLeft: "1rem",
    borderLeft: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  return (
    <div
      className="documentOcrSetting"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <AutoCompleteSearch
        options={listSearch}
        backgroundWhite={"white"}
        handlePopulate={(value) => {
          addSearch(value);
        }}
        handleSearch={(value) => {
          setSearch(value);
        }}
        title="Healthcare-provider-location"
        placeholder="Search Insurance Companies"
        actionLabel="Search"
        keyLabel="name"
      />
      <div style={{ flex: 1, paddingTop: "1rem", width: "100%", overflow: "scroll" }}>
        {isToastr && <ToastMessage type="Add Carrier" status="success" msg="Save Carrier Successfully!" />}
        <>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              paddingTop: "5px",
            }}
          >
            <div
              style={{
                width: "50%",
                paddingRight: "1rem",
                borderRight: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Carrier Name"
                value={state?.name}
                handleChange={(value) => {
                  handleProvider(value, "name");
                }}
                fullWidth
              />
            </div>
            <div
              style={{
                width: "50%",
                paddingLeft: "1rem",
                borderLeft: "solid 1px #f0f3f6",
              }}
            >
              <EditableFormInput
                label="Address"
                value={state.address}
                handleChange={(value) => {
                  handleProvider(value, "address");
                }}
                fullWidth
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Phone Number"
                value={state.phone}
                handleChange={(value) => {
                  handleProvider(value, "phone");
                }}
                mask={"phone"}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Fax Number"
                value={state.fax}
                handleChange={(value) => {
                  handleProvider(value, "fax");
                }}
                mask={"phone"}
                fullWidth
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="Suite"
                value={state.suite}
                handleChange={(value) => {
                  handleProvider(value, "suite");
                }}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="State"
                value={state.state}
                handleChange={(value) => {
                  handleProvider(value, "state");
                }}
                fullWidth
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div style={styleLeftElement}>
              <EditableFormInput
                label="City"
                value={state.city}
                handleChange={(value) => {
                  handleProvider(value, "city");
                }}
                fullWidth
              />
            </div>
            <div style={styleRightElement}>
              <EditableFormInput
                label="Zip Code"
                value={state.zip}
                handleChange={(value) => {
                  handleProvider(value, "zip");
                }}
                fullWidth
              />
            </div>
          </div>
          <div style={{ marginTop: 10 }}>
            <AutoCompleteSearch
              backgroundWhite={"white"}
              options={payer_id_typesCheck}
              handlePopulate={(value) => {
                handleProvider(value.payer_id, "claim_payer_id");
              }}
              title="Healthcare-provider-location"
              placeholder="Search Claim Payer ID"
              actionLabel="Search"
              keyLabel="name"
              handleCurrentItem={(value) => {
                handleProvider(value.payer_id, "claim_payer_id");
              }}
            />
          </div>
          <div
            style={{
              marginTop: "1.5rem",
              marginBottom: "1.5rem",
              float: "right",
              marginLeft: "10px",
              marginRight: "0px",
            }}
          >
            <button onClick={handleBtnSaveClicked} className={"common-btn"}>
              Save
            </button>
          </div>
        </>
      </div>
    </div>
  );
};

export default AddCarrier;
