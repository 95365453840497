const CheckTools = [
  {
    name: "Ultrasound",
    require: ["product_description", "body"],
  },
  {
    name: "Local Anesthesia",
    require: ["product_description", "body"],
  },
  {
    name: "Access",
    require: ["product_description", "body"],
  },
  {
    name: "Closure Device",
    require: ["product_description", "body"],
  },
  {
    name: "Stenosis",
    require: ["post_stenosis", "body"],
  },
  {
    name: "Directional Atherectomy",
    require: ["post_stenosis", "product_description", "body", "number_of_passes"],
  },
  {
    name: "Rotational Atherectomy",
    require: ["post_stenosis", "product_description", "body", "number_of_passes"],
  },
  {
    name: "Obital Atherectomy",
    require: ["post_stenosis", "product_description", "body", "number_of_passes"],
  },
  {
    name: "Laser Atherectomy",
    require: ["post_stenosis", "product_description", "body", "number_of_passes", "frequency", "pulses_delivered", "treatment_time"],
  },
  {
    name: "Balloon Angioplasty",
    require: ["post_stenosis", "product_description", "body", "atm", "inflation_time"],
  },
  {
    name: "Thrombus",
    require: ["thrombus", "body"],
  },
  {
    name: "Dissection",
    require: ["dissection", "body"],
  },
  {
    name: "Gradient",
    require: ["psp_distal", "psp_proximal", "distal_location", "body"],
  },
  {
    name: "Balloon Expandable Covered Stent",
    require: ["post_stenosis", "product_description", "body", "atm", "inflation_time"],
  },
  {
    name: "Balloon Expandable Stent",
    require: ["post_stenosis", "product_description", "body", "atm", "inflation_time"],
  },
  {
    name: "Covered Stent",
    require: ["post_stenosis", "product_description", "body"],
  },
  {
    name: "Self Expanding Stent",
    require: ["post_stenosis", "product_description", "body"],
  },
  {
    name: "Thrombectomy",
    require: ["post_stenosis", "product_description", "body"],
  },
  {
    name: "IVUS",
    require: ["diameter", "plaque", "body", "product_description", "stenosis"],
  },
];

export default CheckTools;
