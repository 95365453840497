import React, { useEffect, useMemo, useState } from "react";
import { Input, Select, DatePicker } from "antd";
import moment from "moment";
import { ExpandSVG, SearchSVG } from "../../../common/components/icons/SVGIcon";
import AutoCompleteSearch from "src/common/components/AutoComplete/AutoCompleteSearch";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const RecordReviewFilter = ({
  valueSearch,
  providers,
  locations,
  changeDate,
  startDate,
  endDate,
  changeFilterLocation,
  changeFilterProvider,
  filterProvider,
  filterLocation,
  searchState,
  dataOption,
  onSelectChoice,
  loading,
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(searchState);
  }, [searchState]);

  const rows = useMemo(
    () =>
      (dataOption || []).map((r) => ({
        id: r?.id,
        name: `${r?.first_name || ""} ${r?.last_name || ""} - ${r?.date_of_birth ? moment(r?.date_of_birth).format("MM/DD/YYYY") : ""}`,
      })),
    [dataOption]
  );

  const changeSearch = (value) => {
    setSearch(value);
    valueSearch && valueSearch(value);
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <AutoCompleteSearch
            loading={loading}
            options={(rows || []).filter((r) => r?.name.toLowerCase().includes(search?.toLowerCase()))}
            value={search}
            onClear={onSelectChoice}
            handlePopulate={onSelectChoice}
            handleSearch={(val) => changeSearch(val)}
            placeholder="Search"
            actionLabel="Search"
            keyLabel="name"
            optionKey="id"
            backgroundWhite={"white"}
            isModified
            disableSearchIcon={search ? true : false}
            customStyle={search ? true : false}
            className={"record-review-search"}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <Select
            placeholder="Filter by Provider"
            suffixIcon={<ExpandSVG />}
            value={filterProvider}
            onChange={changeFilterProvider}
            allowClear={true}
          >
            {providers.map((items) => {
              return (
                <Option key={items?.id} value={items?.id}>
                  {items?.value}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker
            format={dateFormat}
            value={startDate && startDate !== "Invalid date" ? moment(startDate, "MM/DD/YYYY") : undefined}
            onChange={(value) => {
              if (value && value !== "Invalid date") {
                changeDate(value, "start");
              } else {
                changeDate(undefined, "start");
              }
            }}
            placeholder="Filter by Start Date"
            suffixIcon={<ExpandSVG />}
          />
        </div>
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker
            format={dateFormat}
            value={endDate && endDate !== "Invalid date" ? moment(endDate, "MM/DD/YYYY") : undefined}
            onChange={(value) => {
              if (value && value !== "Invalid date") {
                changeDate(value, "end");
              } else {
                changeDate(undefined, "end");
              }
            }}
            placeholder="Filter by End Date"
            suffixIcon={<ExpandSVG />}
          />
        </div>
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <Select
            placeholder="Filter by Location"
            value={filterLocation}
            suffixIcon={<ExpandSVG />}
            onChange={changeFilterLocation}
            allowClear={true}
          >
            {locations.map((items) => {
              return (
                <Option key={items?.id} value={items?.id}>
                  {items?.value}
                </Option>
              );
            })}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default RecordReviewFilter;
