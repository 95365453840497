import { Input } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { getCapitalizeString, useOutsideDetect } from "../../utils";
import { SearchSVG } from "../icons/SVGIcon";
import Loading from "../Loading";
import ScrollWrapper from "../ScrollBar";
import "./style.scss";
import { setGlobalToastr } from "src/store/actions";
import { useDispatch } from "react-redux";

const { Search } = Input;

const AutoCompleteSearch = ({
  customStyle,
  tabIndex,
  options,
  title,
  handleSearch,
  onClear,
  loading,
  isResult,
  placeholder,
  disableSearchIcon,
  handlePopulate,
  keyLabel,
  optionKey,
  disableOptions,
  actionLabel,
  handleCurrentItem,
  backgroundWhite,
  isModified,
  value,
  onFocus,
  resetValue,
  isEmployeeWorkFlow,
  isLargeHeight,
  isNotTargetInput,
  id,
  enableErrorText,
  className,
}) => {
  const ref = useRef(null);
  const ref1 = useRef(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [loadOptions, setLoadOptions] = useState(false);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);
  const [openResult, setOpenResult] = useState(false);
  const optionData = options ? options : [];
  const dispatch = useDispatch();
  useOutsideDetect(ref, ref1, openResult, setOpenResult);
  useEffect(() => {
    setOpenResult(isResult);
  }, [isResult]);
  useEffect(() => {
    if (value && !firstLoad) {
      setSearch(value);
      setFirstLoad(true);
    }
  }, [value]);

  useEffect(() => {
    if (resetValue) {
      setSearch("");
    }
  }, [resetValue]);

  const openSearchResult = () => {
    if (onFocus) {
      onFocus();
    }
    if (!loadOptions) {
      return;
    }
    setOpenResult(true);
  };

  const handleSelectItem = (row) => {
    if (!row?.id) {
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: "This provider needs NPI",
          type: "failed",
        })
      );
      return;
    } else {
      setSelected(row);
      if (keyLabel === "provider_full_name_username") {
        setSearch(`${row?.first_name} ${row?.last_name} - ${row?.username}`);
      } else if (keyLabel === "provider_name_suffix") {
        setSearch(`${row?.first_name} ${row?.last_name}${row?.suffix ? `, ${row?.suffix}` : ""}`);
      } else if (keyLabel === "provider_full_name") {
        setSearch(`${row?.first_name} ${row?.last_name}`);
      } else if (keyLabel === "patient_name") {
        setSearch(
          `${getCapitalizeString(row?.first_name)} ${getCapitalizeString(row?.last_name)} - ${moment(row?.date_of_birth).format("MM/DD/YYYY")}`
        );
      } else if (keyLabel === "provider_name_suffix_address") {
        setSearch(`${row?.first_name} ${row?.last_name}${row?.suffix ? `, ${row?.suffix}` : ""} ${row?.address ? row?.address : ""}`);
      } else {
        setSearch(row[keyLabel]);
        if (handleCurrentItem) {
          handleCurrentItem(row);
        }
      }
      setOpenResult(false);
      setLoadOptions(false);
      if (actionLabel !== "Add") {
        if (handlePopulate) {
          if (keyLabel && optionKey) {
            if (keyLabel === "provider_name_suffix" || keyLabel === "provider_name_suffix_address") {
              handlePopulate(`${row?.first_name} ${row?.last_name}${row?.suffix ? `, ${row?.suffix}` : ""} - ${row[optionKey]}`, row);
            } else {
              handlePopulate(`${row[keyLabel]} - ${row[optionKey]}`, row);
            }
          } else {
            handlePopulate(row);
          }
        }
      }
    }
  };

  const handleSearchItem = () => {
    if (!selected) {
      return;
    }
    if (handlePopulate) {
      handlePopulate(selected);
    }
    setSearch("");
  };

  const changeSearchInput = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
    setOpenResult(true);
    if (handleSearch) {
      handleSearch(value);
    }
    if (value === "" && onClear) {
      onClear(null);
    }
  };

  const handleKeyPress = async () => {
    if (optionData && optionData[activeIndex]) {
      setActiveIndex(-1);
      if (keyLabel !== "provider_full_name") {
        if (handleCurrentItem) {
          handleCurrentItem(optionData[activeIndex]);
        }
      }
      setOpenResult(false);
      if (handlePopulate) {
        handlePopulate(selected);
      }
      setSearch("");
    }
  };

  const onKeyDown = (type) => {
    if (type == "down") {
      if (optionData && optionData[activeIndex + 1]) {
        setSelected(optionData[activeIndex + 1]);
        setActiveIndex(activeIndex + 1);
      }
    } else {
      if (optionData && optionData[activeIndex - 1]) {
        setSelected(optionData[activeIndex - 1]);
        setActiveIndex(activeIndex - 1);
      }
    }
  };

  return (
    <div className={`atuoCompleteSearch ${isModified ? "modified" : ""} ${className}`}>
      <div className="atuoCompleteSearch__filter">
        <div
          ref={ref}
          className={`atuoCompleteSearch__filter-search origin ${disableSearchIcon ? "disable-icon" : ""} ${backgroundWhite} ${
            customStyle ? "cust" : ""
          }
          `}
        >
          <Search
            id={id}
            {...(isEmployeeWorkFlow && { className: `dependencySearch ${isNotTargetInput && "notTargetSearch"}` })}
            tabIndex={tabIndex}
            value={search}
            onKeyDownCapture={(e) => {
              if (e.keyCode == 40) {
                onKeyDown("down");
              } else if (e.keyCode == 38) {
                onKeyDown("up");
              }
            }}
            onPressEnter={handleKeyPress}
            placeholder={placeholder || "Search"}
            onChange={changeSearchInput}
            style={{
              width: "100%",
              height: isLargeHeight ? "100%" : "42.5px",
              textTransform: "capitalize",
            }}
            className={enableErrorText ? "require-search-auto-complete" : ""}
            suffix={!disableSearchIcon && <SearchSVG />}
            onFocus={openSearchResult}
          />
        </div>
        {actionLabel == "Search" ? null : (
          <div className="atuoCompleteSearch__filter-action">
            <button className="atuoCompleteSearch__filter-add" onClick={handleSearchItem}>
              <span>{actionLabel || "Add"}</span>
              <span>+</span>
            </button>
          </div>
        )}

        {!disableOptions && openResult && !!search && (
          <div ref={ref1} className="atuoCompleteSearch__filter-result" style={{ minHeight: "150px" }}>
            <div className="atuoCompleteSearch__filter-result-list">
              {loading && <Loading />}
              <ScrollWrapper css="no-padding">
                {optionData?.length > 0 &&
                  optionData?.map((row, index) => (
                    <div
                      className="atuoCompleteSearch__result-item"
                      style={{
                        backgroundColor: index == activeIndex ? "#DCE3EE" : "#fff",
                      }}
                      key={`empty-${title}-${index}`}
                      onClick={() => handleSelectItem(row)}
                    >
                      {keyLabel === "provider_name_suffix" && (
                        <p>
                          {row?.first_name} {row?.last_name}
                          {row?.suffix ? `, ${row?.suffix}` : ""}
                        </p>
                      )}
                      {keyLabel === "provider_name_suffix_address" && (
                        <p>
                          {row?.first_name} {row?.last_name}
                          {row?.suffix ? `, ${row?.suffix}` : ""} {row?.address}
                        </p>
                      )}
                      {keyLabel === "provider_full_name_username" && (
                        <p>
                          {row?.first_name} {row?.last_name} - {row?.username}
                        </p>
                      )}
                      {keyLabel === "provider_full_name" && (
                        <p>
                          {row?.first_name} {row?.last_name}
                        </p>
                      )}
                      {keyLabel === "patient_name" && (
                        <p style={{ textTransform: "capitalize" }}>
                          {`${getCapitalizeString(row?.first_name)}
                            ${getCapitalizeString(row?.last_name)} -
                            ${moment(row?.date_of_birth).format("MM/DD/YYYY")}`}
                        </p>
                      )}
                      {keyLabel !== "provider_full_name" && keyLabel !== "patient_name" && <p>{row[keyLabel]}</p>}
                    </div>
                  ))}
              </ScrollWrapper>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoCompleteSearch;
