import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import EditableFormInput from "../../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import LoginFormInput from "../../../../common/components/EditableText/LoginFormInput";
import Ratings from "../../../../common/components/Ratings";
export const RatingOptions = [
  { label: "Job Knowledge:", key: "job_score" },
  { label: "Work Quality:", key: "work_score" },
  { label: "Punctuality:", key: "punctuality_score" },
  { label: "Initiative:", key: "initiative_score" },
  { label: "Communication/Listening:", key: "communication_score" },
  { label: "Dependability:", key: "dependability_score" },
];

const EvaluationsSidebar = React.forwardRef(({ resource, isShow, evaluationsTagertDetail, handleCloseSidebar, currentUserInfo }, ref) => {
  if (!currentUserInfo || !evaluationsTagertDetail) {
    return null;
  }

  const [ratingsDetail, setRatingsDetail] = useState({
    job_score: -1,
    work_score: -1,
    punctuality_score: -1,
    initiative_score: -1,
    communication_score: -1,
    dependability_score: -1,
  });

  useEffect(() => {
    setRatingsDetail({
      job_score: evaluationsTagertDetail?.job_score || -1,
      work_score: evaluationsTagertDetail?.work_score || -1,
      punctuality_score: evaluationsTagertDetail?.punctuality_score || -1,
      initiative_score: evaluationsTagertDetail?.initiative_score || -1,
      communication_score: evaluationsTagertDetail?.communication_score || -1,
      dependability_score: evaluationsTagertDetail?.dependability_score || -1,
    });
  }, [evaluationsTagertDetail]);

  const getOverallRating = () => {
    let sum = 0;
    for (const opt of RatingOptions) {
      sum += ratingsDetail[opt.key] === -1 ? 0 : ratingsDetail[opt.key];
    }

    return Math.round(sum);
  };

  return (
    <Drawer
      title={currentUserInfo?.name + (currentUserInfo.position && "(" + currentUserInfo.position + ")") || " "}
      placement="right"
      closable
      onClose={handleCloseSidebar}
      visible={isShow}
      destroyOnClose
      key="right"
    >
      <div ref={ref} className="resourceContainer new">
        <ScrollWrapper css="x-hidden">
          <div
            className="resourceContent"
            style={{
              flex: "unset",
              paddingLeft: "24px",
              paddingRight: "20px",
            }}
          >
            {evaluationsTagertDetail && evaluationsTagertDetail?.type != "Quarterly Evaluation" && (
              <>
                <div className="resourceInfo d-flex" style={{ marginBottom: "1rem" }}>
                  <div>
                    Employee evaluations are a means to monitor progress and methods of evaluations within the organization. Employee evaluations are
                    utilized for promotion and assessment of raises or bonuses. Improvements in employment metrics are a key determinent of raises and
                    bonuses and will be directly utilized on a calculation to determine end of year increases. Raises and bonuses are not guaranteed.
                    Performance improvement and evaluations are reset at the beginning of the year with respect to raises and bonuses.
                  </div>
                </div>
                <div className="resourceInfo d-flex">
                  <EditableFormInput label="Summary" value={evaluationsTagertDetail.summary} type="area" minRows={8} fullWidth />
                </div>
                <div className="resourceInfo d-flex">
                  <EditableFormInput label="Facts" value={evaluationsTagertDetail.facts} type="area" minRows={8} fullWidth />
                </div>
                <div className="resourceInfo d-flex">
                  <EditableFormInput
                    label="Corrective Actions"
                    value={evaluationsTagertDetail.corrective_actions}
                    type="area"
                    minRows={8}
                    fullWidth
                  />
                </div>
                <div
                  className="actionContent"
                  style={{
                    marginTop: "2rem",
                    paddingRight: "1rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <button>Save</button>
                </div>
              </>
            )}
            {evaluationsTagertDetail && evaluationsTagertDetail?.type == "Quarterly Evaluation" && (
              <>
                <div className="resourceInfo">
                  {RatingOptions.map((opt, index) => (
                    <Ratings key={`ratings-${opt.key}`} isIcon={true} label={opt.label} defaultOption={ratingsDetail[opt.key]} />
                  ))}
                </div>
                <hr />
                <div className="resourceInfo d-flex between">
                  <b>Overall Rating: </b>
                  <b style={{ paddingRight: "0.5rem" }}>{getOverallRating()}</b>
                </div>
                <div className="resourceInfo d-flex" style={{ marginTop: "1rem", width: "100%" }}>
                  <LoginFormInput
                    label={"Additional Comments"}
                    type={"area"}
                    fullWidth={true}
                    value={evaluationsTagertDetail.additional_comments}
                    minRows={3}
                  />
                </div>
                <hr />
                <div className="resourceInfo d-flex" style={{ width: "100%" }}>
                  <LoginFormInput label={"Goals"} type={"area"} fullWidth={true} value={evaluationsTagertDetail.goals} minRows={3} />
                </div>
              </>
            )}
          </div>
        </ScrollWrapper>
      </div>
    </Drawer>
  );
});

export default EvaluationsSidebar;
