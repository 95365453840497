import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import { getLocal } from "../../../helpers/Local";
import { getProcedureData, search_plan_suggestion, setProcedureDetails, update_plan_condition } from "../../../store/actions";

let timer = null;

const PlanSuggestion = ({ resource, rowAction, firstProps }) => {
  const [searchResult, setSearchResult] = useState([]);
  const [selectResult, setSelectResult] = useState([]);
  const [planCheck, setPlanCheck] = useState([]);
  const [IDRow, setIDRow] = useState(null);
  const [activeDelete, setActiveDelete] = useState([]);
  const [removableParent, setRemovableParent] = useState(-1);
  const dispatch = useDispatch();

  const all_plans = useSelector((state) => state.procedure.search_plan_all);
  const conditionVariable = useSelector((state) => state.procedureDetail.conditionVariable);
  const plans = useSelector((state) => state.procedureDetail.plans);
  const selectedReferralCondition = useSelector((state) => state.adminReferralCondition.selectedReferralCondition);

  const condition_id_cookie = getLocal("id_procedure_type_icd");

  useEffect(() => {
    if (plans) {
      setPlanCheck(plans);
    } else {
      setPlanCheck([]);
    }
  }, [plans]);

  const handleChange = async (type, value, index, indexPlan) => {
    if (planCheck && planCheck[indexPlan]) {
      if (type === "side") {
        planCheck[indexPlan]["side"] = value;
      }
      if (type === "entity_id") {
        const sptValue = value && value.length > 0 ? value.split(" - ") : [];
        planCheck[indexPlan]["entity_id"] = sptValue[0];
        planCheck[indexPlan]["bundle"] = sptValue[1];
        await dispatch(setProcedureDetails("plans", [...planCheck]));
      } else {
        planCheck[indexPlan]["condition_serialize"][index][type] = value === "THAN" && type === "operator" ? "THAN" : value;
        await dispatch(setProcedureDetails("plans", [...planCheck]));
      }
    }
  };

  const handleChangeOperator = async (value, index, indexPlan) => {
    const nextIndex = index + 1;
    if (planCheck[indexPlan] && planCheck[indexPlan]["condition_serialize"] && planCheck[indexPlan]["condition_serialize"].length > 0) {
      const plan = planCheck[indexPlan]["condition_serialize"][nextIndex];
      const paramNU = {
        variable_id: plan && typeof plan["variable_id"] != "undefined" ? plan["variable_id"] : "",
        variable_name: plan && typeof plan["variable_name"] != "undefined" ? plan["variable_name"] : "",
        answer: plan && typeof plan["answer"] != "undefined" ? plan["answer"] : "",
        comparison: plan && typeof plan["comparison"] != "undefined" ? plan["comparison"] : ">=",
        operator: plan && typeof plan["operator"] != "undefined" ? plan["operator"] : "",
      };
      const paramUN = {
        variable_id: undefined,
        variable_name: undefined,
        answer: undefined,
        comparison: undefined,
        operator: undefined,
      };
      if (planCheck && planCheck[indexPlan]) {
        if (value !== "THAN") {
          planCheck[indexPlan]["condition_serialize"][index + 1] = {
            ...paramNU,
          };
          await dispatch(setProcedureDetails("plans", [...planCheck]));
        } else {
          const abc = planCheck[indexPlan];
          abc["condition_serialize"].map((item, indexMap) => {
            if (indexMap > index) {
              abc["condition_serialize"][indexMap] = null;
            }
          });
          const check = abc["condition_serialize"].filter((item) => {
            if (item != null) {
              return item;
            }
          });
          planCheck[indexPlan]["condition_serialize"] = [...check, paramUN];
          await dispatch(setProcedureDetails("plans", [...planCheck]));
        }
      }
    }
  };

  useEffect(() => {
    if (selectedReferralCondition && Object.keys(selectedReferralCondition).length > 0) {
      dispatch(setProcedureDetails("plans", selectedReferralCondition?.plan_rules ? selectedReferralCondition?.plan_rules : []));
    }
  }, [selectedReferralCondition]);

  useEffect(() => {
    const variableMap = (conditionVariable || []).map((r) => {
      return { id: r?.id, value: r.variable_name };
    });
    setSearchResult([...variableMap]);
  }, [conditionVariable]);

  useEffect(() => {
    getProcedureData();
  }, []);

  useEffect(() => {
    if (all_plans && Array.isArray(all_plans)) {
      setSelectResult([...all_plans]);
    }
  }, [all_plans]);

  useEffect(() => {
    dispatch(search_plan_suggestion());
  }, []);

  const handleClickRow = (row, index) => {
    setIDRow(index);
    if (rowAction) {
      rowAction(row);
    }
  };

  const removeProvider = async (index, indexPlan) => {
    const checkPlanRow = planCheck[indexPlan];
    if (planCheck && index == 0) {
      delete planCheck[indexPlan];
      const arrayJoin = [...planCheck].filter((r) => {
        if (r) {
          return r;
        }
      });
      await dispatch(setProcedureDetails("plans", [...arrayJoin]));
      await dispatch(update_plan_condition(condition_id_cookie, { rules: [...arrayJoin] }));
    }
    if (checkPlanRow && checkPlanRow["condition_serialize"] && checkPlanRow["condition_serialize"].length > 1) {
      if (checkPlanRow["condition_serialize"][index].operator === "THAN" || checkPlanRow["condition_serialize"][index].operator === "") {
        checkPlanRow["condition_serialize"].splice(index, 2);
      } else {
        checkPlanRow["condition_serialize"].splice(index, 1);
      }

      const check = checkPlanRow["condition_serialize"].filter((item) => {
        if (item != null) {
          return item;
        }
      });
      if (check && check.length > 0) {
        planCheck[indexPlan]["condition_serialize"] = [...check];
      } else {
        planCheck.splice(indexPlan, 1);
      }

      await dispatch(setProcedureDetails("plans", [...planCheck]));
    } else {
      if (planCheck) {
        delete planCheck[indexPlan];
        const arrayJoin = [...planCheck].filter((r) => {
          if (r) {
            return r;
          }
        });
        await dispatch(setProcedureDetails("plans", [...arrayJoin]));
        await dispatch(update_plan_condition(condition_id_cookie, { rules: [...arrayJoin] }));
      }
    }
  };

  const update = async () => {
    if (planCheck && planCheck[IDRow] && planCheck[IDRow]["condition_serialize"]) {
      const arrayADD = planCheck.map((r) => {
        return {
          ...r,
          entity_id: r?.entity_id,
          bundle: r?.bundle,
          bracket: r?.bracket || "",
          side: r?.side,
          condition_id: condition_id_cookie,
          condition_serialize: r?.condition_serialize ? [...r?.condition_serialize] : [{}],
        };
      });
      await dispatch(update_plan_condition(condition_id_cookie, { rules: arrayADD }));
    }
  };

  useEffect(() => {
    if (firstProps) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        update();
      }, 500);
    }
  }, [planCheck, firstProps]);

  const handleBracket = (value, indexAll, indexIn) => {
    const planAll = [...planCheck];
    if (planAll[indexAll] && planAll[indexAll]["condition_serialize"] && planAll[indexAll]["condition_serialize"][indexIn]) {
      planAll[indexAll]["condition_serialize"][indexIn]["bracket"] = value;
      setPlanCheck([...planAll]);
    }
  };

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {plans.map((itemPlan, indexPlan) => {
        return (
          <div
            key={indexPlan}
            onClick={() => handleClickRow(itemPlan, indexPlan)}
            style={{
              width: "100%",
              background: "rgb(219, 227, 238)",
              border: IDRow === indexPlan ? "2px solid rgb(15, 98, 254)" : "",
              padding: itemPlan?.condition_serialize?.length > 0 ? "7px 0" : 0,
              marginBottom: itemPlan?.condition_serialize?.length > 0 ? "1rem" : 0,
            }}
          >
            <DataTable
              title="plan_suggestion"
              sizeHeightFix="100%"
              isNonScrollable
              isFixHeight={true}
              isBracket={true}
              hideHeader={true}
              bracket={itemPlan?.bracket || ""}
              hideHeaderHeight="0px"
              allActive={true}
              handleDelete={(value, index) => removeProvider(index, indexPlan)}
              setBracket={(value, indexAll, indexIn) => {
                handleBracket(value, indexAll, indexIn);
              }}
              parentIndex={indexPlan}
              handleActiveDelete={(val) => {
                const activeDeletes = [...activeDelete];
                activeDeletes[indexPlan] = val;
                if (removableParent !== indexPlan) {
                  activeDeletes[removableParent] = -1;
                  setRemovableParent(indexPlan);
                } else {
                  if (val === -1) setRemovableParent(-1);
                }
                setActiveDelete(activeDeletes);
              }}
              activeParent={activeDelete[indexPlan]}
              labels={["Variable", "Option", "Value", "Operator", "Bracket"]}
              widths={["20%", "15%", "20%", "20%", "25%"]}
              columns={[
                { sortable: false, key: "variable" },
                { sortable: false, key: "option" },
                { sortable: false, key: "value" },
                { sortable: false, key: "operator" },
                { sortable: false, key: "bracket" },
              ]}
              rowData={(itemPlan?.condition_serialize || []).map((item, index) => {
                let arrayAnswer = [];
                const answerCheck = (conditionVariable || []).find((r) => {
                  return r.variable_name == item?.variable_name?.value;
                });
                if (answerCheck) {
                  const allAnswerCheck = (answerCheck.answer || []).map((item, index) => {
                    return { id: item?.id, answer: item.answer };
                  });
                  arrayAnswer = [...allAnswerCheck];
                }
                return {
                  isBracket: true,
                  bracket: <Input value={item?.bracket || ""} onChange={(e) => handleBracket(e.target?.value, indexPlan, index)} />,
                  key: index,
                  disableDelete: typeof item?.operator === "undefined" && true,
                  fullsize: typeof item?.operator === "undefined" && true,
                  isSide: typeof item?.operator === "undefined" ? true : false,
                  componentSide: (
                    <Select
                      placeholder="Side"
                      suffixIcon={<ExpandSVG />}
                      value={itemPlan?.side}
                      onChange={(value) => {
                        handleChange("side", value, index, indexPlan);
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                    >
                      {["Not applicable", "Left", "Right", "Bilateral"].map((r, index) => {
                        return (
                          <Select.Option key={r} value={r}>
                            {r}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ),
                  variable:
                    typeof item?.operator === "undefined" ? (
                      <Select
                        key={index}
                        placeholder="Plan"
                        value={`${itemPlan?.entity_id ? `${itemPlan?.entity_id} -` : ""} ${itemPlan?.bundle ? itemPlan?.bundle : ""}`}
                        suffixIcon={<ExpandSVG />}
                        onChange={(value) => {
                          handleChange("entity_id", value, index, indexPlan);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(selectResult || []).map((r, index) => {
                          return (
                            <Select.Option key={`${r?.id} - ${r?.type}`} value={`${r?.id} - ${r?.type}`}>
                              {r?.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    ) : (
                      <Select
                        placeholder={"Variable"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.variable_id}
                        onChange={(value) => {
                          handleChange("variable_id", value, index, indexPlan);
                          handleChange(
                            "variable_name",
                            searchResult.find((r) => r?.id == value),
                            index,
                            indexPlan
                          );
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(searchResult || []).map((opt, index) => (
                          <Select.Option key={`conditionVariable-${index}-${opt?.id}`} value={opt?.id}>
                            {opt?.value}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  option:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Opt"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        showArrow={false}
                        value={item?.comparison}
                        onChange={(value) => {
                          handleChange("comparison", value, index, indexPlan);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["<=", "=", "!=", ">="].map((opt) => (
                          <Select.Option key={`option--${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                  value:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Answer"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.answer}
                        onChange={(value) => {
                          handleChange("answer", value, index, indexPlan);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {(arrayAnswer || []).map(
                          (opt) =>
                            opt.answer && (
                              <Select.Option key={`answer-${opt.answer}`} value={opt.answer}>
                                {opt.answer}
                              </Select.Option>
                            )
                        )}
                      </Select>
                    ),
                  operator:
                    typeof item?.operator === "undefined" ? (
                      ""
                    ) : (
                      <Select
                        placeholder={"Operator"}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        value={item?.operator || undefined}
                        onChange={(value) => {
                          handleChange("operator", value, index, indexPlan);
                          handleChangeOperator(value, index, indexPlan);
                        }}
                        style={{ width: "100%", borderBottom: 0 }}
                      >
                        {["OR", "AND", "THAN"].map((opt) => (
                          <Select.Option key={`operator-${opt}`} value={opt}>
                            {opt}
                          </Select.Option>
                        ))}
                      </Select>
                    ),
                };
              })}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PlanSuggestion;
