const CheckGroups = {
  Iliac: [37220, 37221, 37222, 37223],
  "Femoral/Popliteal": [37224, 37225, 37226, 27227],
  "Tibial/Peroneal": [37228, 37229, 37230, 37231, 37232, 37233, 37234, 37235],
  Diagnostic: [75630, 75625, 75710, 75716, 75774],
  Catheterization: [36140, 36245, 36246, 36247, 36248],
  notFound: [],
};

export default CheckGroups;
