import React, { useEffect, useState } from "react";
import Styles from "./style.module.css";
import { Button, Form } from "antd";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { verifyOTP } from "src/services/api.services";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import { validateField } from "src/services/validations";
import moment from "moment";
import Axios from "axios";
import { AUTH_API } from "src/common/constants/constants";
import { removeLocal, setLocal } from "src/helpers/Local";
import { useDispatch } from "react-redux";
import { getCurrentPatient, getCurrentProvider, getCurrentUser, getCurrentUserInfo } from "src/store/actions";
const OtpPage = () => {
  const [otp, setOtp] = useState("");

  const location = useLocation();
  const history = useHistory();
  const [timeRemaining, setTimeRemaining] = useState(90);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const convertSecondsToMinutes = (seconds) => {
    const duration = moment.duration(seconds, "seconds");
    const minutes = Math.floor(duration.asMinutes());
    const remainingSeconds = duration.seconds();

    return ` (0${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}s)`;
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(countdown);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  const apiLoginResend = () => {
    if (location.state.username && location.state.password) {
      const grant_type = "password";
      const client_id = process.env.REACT_APP_client_id;
      const client_secret = process.env.REACT_APP_client_secret;
      Axios.post(
        AUTH_API,
        {
          grant_type,
          client_id,
          client_secret,
          username: location.state.username,
          password: location.state.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
    }
  };

  const handleResend = () => {
    apiLoginResend();
    setTimeRemaining(90);
  };
  const handleSubmit = async (e) => {
    if (location.state.user_id) {
      e.preventDefault();
      setLoading(true);
      const apiCheck = await verifyOTP({
        otp: otp,
        user_id: location.state.user_id,
      });
      if (apiCheck?.access_token) {
        setLocal("cvai-auth-token", apiCheck?.access_token);
        setLocal("cvai-auth-role", apiCheck?.providerInfo);
        if (apiCheck?.providerInfo === "user") {
          const checkLoginInfo = await dispatch(getCurrentUser());
          const checkInfo = await dispatch(getCurrentUserInfo(checkLoginInfo?.id));
          if (checkLoginInfo && checkInfo && checkLoginInfo.status === 1) {
            if (checkLoginInfo?.is_provider == 1) {
              setLocal("cvai-current-user-provider-name", checkInfo?.provider?.value || "");
              setLocal("cvai-current-user-provider-id", checkInfo?.provider?.id || "");
            } else {
              removeLocal("cvai-current-user-provider-name");
              removeLocal("cvai-current-user-provider-id");
            }
            window.open(window.location.origin, "_self");
          } else {
            removeLocal("cvai-auth-token");
            removeLocal("cvai-current-user-provider-name");
            removeLocal("cvai-current-user-provider-id");
            removeLocal("cvai-token-type");
            removeLocal("cvai-token-expires");
          }
        } else if (apiCheck?.providerInfo === "patient") {
          const checkLoginInfo = await dispatch(getCurrentPatient());
          removeLocal("cvai-current-user-provider-name");
          removeLocal("cvai-current-user-provider-id");
          if (checkLoginInfo && checkLoginInfo.status === 1) {
            history.push("/patient-dashboard");
          } else {
            removeLocal("cvai-auth-token");
            removeLocal("cvai-token-type");
            removeLocal("cvai-token-expires");
          }
        } else if (apiCheck?.providerInfo === "provider") {
          const checkLoginInfo = await dispatch(getCurrentProvider());
          removeLocal("cvai-current-user-provider-name");
          removeLocal("cvai-current-user-provider-id");
          if (checkLoginInfo && checkLoginInfo.status === 1) {
            history.push("/referral-dashboard");
          } else {
            removeLocal("cvai-auth-token");
            removeLocal("cvai-token-type");
            removeLocal("cvai-token-expires");
          }
        }
      }
      setLoading(false);
    } else {
      alert("Sorry your otp account expired. Please Login Again!");
    }
  };

  const handleSubmitKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event); // Call your submit function
    }
  };

  return (
    <div className="loginPage">
      <div className="loginMainContainer">
        <div className="splashContainer">
          <div className={Styles.login_container}>
            <div className={Styles.image_container}>
              <img src="/images/LoginImage.svg" alt="black logo" />
            </div>
            <div className={Styles.login_form_container}>
              <div className={Styles.logo_container}>
                <img src="/images/faact_logo_black.svg" alt="black logo" />
              </div>
              <div className={Styles.formContainer__heading}>
                <h1>OTP Verification</h1>
                <div>
                  <p>Enter Your OTP to login</p>
                  <Form name="otp_form" className={Styles.login_form} onFinish={handleSubmit}>
                    <label style={{ fontWeight: "bolder", color: "black", marginTop: "1rem" }}>OTP</label>
                    <EditableFormInput
                      id="otp"
                      placeholder={"Enter your OTP"}
                      isLoginScreen={true}
                      isBackgroundTransparent={true}
                      mainStyle={{ margin: "10px 0 2rem 0" }}
                      value={otp}
                      handleChange={(value) => setOtp(value)}
                      isError={validateField("text", otp)}
                      required={true}
                      fullWidth={true}
                      enableErrorText={true}
                      helperText={"OTP is required"}
                      autoFocus={true}
                    />
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: otp && otp.length > 0 ? 0 : -70,
                      }}
                    >
                      <p
                        onClick={() => {
                          if (timeRemaining == 0) {
                            handleResend();
                          }
                        }}
                        style={
                          timeRemaining == 0
                            ? {
                                fontWeight: "bold",
                                cursor: "pointer",
                                marginRight: 5,
                                color: "#1890ff",
                                textDecorationLine: "underline",
                              }
                            : {
                                fontWeight: "bold",
                                marginRight: 5,
                              }
                        }
                      >
                        Resend OTP
                      </p>
                      <p>{timeRemaining > 0 && convertSecondsToMinutes(timeRemaining)}</p>
                    </div>
                    <Form.Item className={Styles.submit_container}>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className={Styles.login_form_button}
                        onClick={handleSubmit}
                        onKeyDown={handleSubmitKeyDown}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpPage;
