import { Collapse, Icon, Select, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-lodash-debounce";
import AutoCompleteComponent from "../../../../common/components/AutoComplete";
import CardWhiteBlock from "../../../../common/components/CardWhite";
import ExaminationIntra from "../../../../common/components/Examination";
import LabelIcon from "../../../../common/components/LabelIcon";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import { IntaOperationBody } from "../../../../common/components/icons/IntaOperation";
import { IntraOperationNewBody } from "../../../../common/components/icons/IntraOperationNew";
import {
  AtherectomyDirectionalIcon,
  AtherectomyLaserIcon,
  AtherectomyRotationalIcon,
  AtherectomyTransluminalIcon,
  BalloonIcon,
  BloodVesselPlaque0,
  BloodVesselPlaque100,
  BloodVesselPlaque20,
  BloodVesselPlaque40,
  BloodVesselPlaque60,
  BloodVesselPlaque80,
  BloodVesselPlaqueStent0,
  BloodVesselPlaqueStent100,
  BloodVesselPlaqueStent20,
  BloodVesselPlaqueStent40,
  BloodVesselPlaqueStent60,
  BloodVesselPlaqueStent80,
  IVUSIcon,
  StentBalloonCoveredIcon,
  StentBalloonIcon,
  StentCoveredIcon,
  StentIcon,
  UltrasoundProbeIcon,
  GradientIcon,
} from "../../../../common/components/icons/OperationIcon";
import { DevicesSVG, DugsSVG, ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { getArteryLabel } from "../../../../common/constants/Artery";
import { getMedical } from "../../../../common/constants/Operations";
import { getVeinLabel } from "../../../../common/constants/Vein";
import { getLocal } from "../../../../helpers/Local";
import { get_ai_recommendation, save_intra_answer, setChoiceAI } from "../../../../services/api.services";
import {
  HPIGetConditions,
  activeCheckTools,
  angioPatientSnapshotLoadInit,
  blockSaveIntra,
  getActiveStaff,
  getAngiographicSupplies,
  getAppointmentInventory,
  get_angio_type_variable,
  get_intra_answer,
  load_procedurals,
  resetListProcedurals,
  saveAppointmentInventory,
  save_info_side,
  save_intra_operative,
  setGlobalLoading,
  setGlobalToastr,
  setProcedureReview,
} from "../../../../store/actions";
import { SampleAccessData, StaticResource, filterMedicationsByQuery as filterDataByQuery } from "../../constant";
import AccessDetails from "../operativeDetailsComponents/AccessDetails";
import ArteryDetail from "../operativeDetailsComponents/ArteryDetail";
import VeinDetail from "../operativeDetailsComponents/VeinDetail";
import IntaOperativeSideBar from "./IntaOperativeSidebar";
import ProcedureSearch from "./procedureSearch";
import RemoveN from "src/helpers/RemoveN";
import CheckTools from "src/helpers/CheckTools";
import YesNo from "src/common/components/YesNo";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";

const { Panel } = Collapse;
const { Option } = Select;

let timer = null;

const ProcedureOptions = [
  "Right Lower Extremity Intervention",
  "Right Iliac Intervention",
  "Right Renal Artery Intervention",
  "Right Upper Extremity Intervention",
  "Right Innominate Artery Intervention",
  "Right PICC Line Placement",
  "Right Dialysis Catheter Placement",
  "Right Carotid Angiogram",
  "Right Dialysis Intervention",
  "Left Lower Extremity Intervention",
  "Left Iliac Intervention",
  "Left Renal Artery Intervention",
  "Left Upper Extremity Intervention",
  "Left Innominate Artery Intervention",
  "Left PICC Line Placement",
  "Left Dialysis Catheter Placement",
  "Left Carotid Angiogram",
  "Left Dialysis Intervention",
];

// Inta Operation Data Structure
const SampleOperation = {
  body: "",
  medicals: [],
  details: [],
};

const IntaOperative = ({ data, title, dataCheckIntra }) => {
  const collapseEndRef = useRef(null);
  const sidebarRef = useRef();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const CPT = useSelector((state) => state.common.CPT);
  const stateActiveCheckTools = useSelector((state) => state.common.stateActiveCheckTools);
  const supplies = useSelector((state) => state.inventory.supplies);
  const appointmentInventories = useSelector((state) => state.procedure.inventories);
  const list_procedurals = useSelector((state) => state.procedure.list_procedurals);
  const allConditions = useSelector((state) => state.clinicProviderHPI.allConditions);
  const copy_intra_from_previous_visit = useSelector((state) => state.patientExamination.copy_intra_from_previous_visit);
  const is_locked = useSelector((state) => state.common.is_locked);
  const [scrollState, setScrollState] = useState(false);
  const [checkBoxState, setCheckBoxState] = useState({});
  const [group, setGroup] = useState("");
  const [getCollapseHeight, setGetCollapseHeight] = useState(0);
  const [stateErrors, setStateErrors] = useState({});
  const [allInventory, setAllInventory] = useState([]);
  const [itemNames, setItemNames] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [inputInventory, setInputInventory] = useState("");
  const [inventoryItems, setInventoryItems] = useState([]);
  const [operationIndex, setOperationIndex] = useState(0);
  const [dataCheck, setDataCheck] = useState([]);
  const [isSidebar, setIsSidebar] = useState(true);
  const [disableDetail, setDisableDetail] = useState(true);
  const [loading, setLoading] = useState("");
  const [tab, setTab] = useState("documentation");
  const [bodyTab, setBodyTab] = useState("arterial");
  const [intaOperations, setIntaOperations] = useState([]);
  const [firstTime, setFirstTime] = useState(false);
  const [currentOperation, setCurrentOperation] = useState({
    ...SampleOperation,
  });
  const [selectedOperation, setSelectedOperation] = useState("");
  const [dataCPTCode, setDataCPTCode] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const id_appoinment = getLocal("current_appointment_id");
  const debouncedSearch = useDebounce(inputInventory, 400);
  const [dataCPT, setDataCPT] = useState(null);
  const [isActiveTool, setIsActiveTool] = useState(true);
  const checkActiveAppointmentType = getLocal("checkActiveAppointmentType");
  const patient_id = getLocal("current_patient_id");
  const [isVenoGram, setIsVenoGram] = useState(false);
  const is_venogram = getLocal("content_is_venogram");
  const [groupA, setGroupA] = useState(0);
  const [groupB, setGroupB] = useState(0);

  useEffect(() => {
    dataCheckIntra && dataCheckIntra(dataCheck);
  }, [dataCheck]);

  useEffect(() => {
    if (checkActiveAppointmentType) {
      if (checkActiveAppointmentType == "active") {
        setIsActiveTool(false);
      } else {
        setIsActiveTool(true);
      }
    } else {
      setIsActiveTool(true);
    }
  }, [checkActiveAppointmentType]);

  useEffect(() => {
    setDataCPT(CPT);
  }, [CPT]);

  useEffect(() => {
    if (dataCPT && dataCPT?.appointment_type_id && !isActiveTool) {
      fetchListTypeAnswer(dataCPT?.appointment_type_id);
    }
  }, [dataCPT, isActiveTool]);

  const fetchListTypeAnswer = async (id) => {
    dispatch(get_angio_type_variable(id));
  };

  const fetchDetailAnswers = async () => {
    await dispatch(get_intra_answer(id_appoinment));
  };

  useEffect(() => {
    if (!isActiveTool) {
      fetchDetailAnswers();
    }
  }, [isActiveTool]);

  useEffect(() => {
    setIsVenoGram(is_venogram === "isHave" ? true : false);
    if (is_venogram === "isHave") {
      setBodyTab("vein");
    } else {
      setBodyTab("arterial");
    }
  }, [is_venogram]);

  useEffect(() => {
    if (!firstTime) {
      console.log("Load data from here");
      setIntaOperations([]);
      //firstTime giúp chặn việc re render dữ liệu vì ở đây đang dùng redux map thẳng ra nên khi có gọi api nó sẽ load lại làm mất dữ liệu ở local
      // fix lỗi nếu product ngày trước không đc save giờ sẽ tự động map ra .. TODO
      const fixList = (list_procedurals || []).map((r) => {
        return {
          ...r,
          details: r?.details.map((detail, idx) => {
            const productCheck = (inventoryItems || []).find((product) => product?.name === detail?.product_description) || null;
            if (productCheck) {
              return {
                item: r?.medicals[idx] || null,
                ...detail,
                product: productCheck,
              };
            } else {
              return {
                item: r?.medicals[idx] || null,
                ...detail,
              };
            }
          }),
        };
      });
      setIntaOperations(fixList);
      if (fixList[0]) {
        setCurrentOperation(fixList[0]);
      } else {
        setCurrentOperation({ ...SampleOperation });
      }
    }
  }, [list_procedurals, inventoryItems]);

  const fetchAPIAI = async () => {
    dispatch(setGlobalLoading(true));
    const dataGet = await get_ai_recommendation(id_appoinment);
    setDataCPTCode(dataGet?.recommendations || []);
    const checkboxAPI = (dataGet?.ai_choice || []).reduce((obj, name) => {
      obj[name] = true;
      return obj;
    }, {});
    setGroupA(dataGet?.group_a || 0);
    setGroupB(dataGet?.group_b || 0);
    setCheckBoxState(checkboxAPI || {});
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    dispatch(resetListProcedurals());
    fetchAPIAI();
    dispatch(getAngiographicSupplies("", "", 1));
    dispatch(getAppointmentInventory(id_appoinment));
    dispatch(angioPatientSnapshotLoadInit(id_appoinment));
    dispatch(load_procedurals(id_appoinment));
    dispatch(HPIGetConditions(id_appoinment));
    dispatch(getActiveStaff(""));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
  }, [id_appoinment]);

  useEffect(() => {
    const scrollContainer = document.querySelector(".collapseParentt").firstChild;
    const id = currentOperation?.body?.part;
    const panelHeight = 48;
    if (currentOperation?.body?.part) {
      const index = intaOperations
        .filter((r) => r?.body?.type !== "vein")
        .findIndex((operation) => {
          return operation?.body?.part === id;
        });
      const scrollHeight = panelHeight * index + 24;
      scrollContainer.scroll({
        top: scrollHeight,
        behavior: "smooth",
      });
    }
  }, [currentOperation]);

  useEffect(() => {
    const temp_names = [];
    const temp_id = [];
    const temp_items = [];
    if (appointmentInventories && appointmentInventories.length > 0) {
      (appointmentInventories || []).map((item) => {
        if (!temp_names.some((r) => r === item?.name)) {
          temp_names.push(item?.name);
        }
        if (!temp_id.some((r) => r === item?.id)) {
          temp_id.push(item?.id);
        }
        temp_items.push(item);
      });
      setItemNames(temp_names);
      setInventories(temp_id);
      setInventoryItems(temp_items);
    } else {
      setItemNames(temp_names);
      setInventories(temp_id);
      setInventoryItems(temp_items);
    }
  }, [appointmentInventories]);

  useEffect(() => {
    if (debouncedSearch) {
      const allInventories = filterDataByQuery(supplies, debouncedSearch);
      setAllInventory(allInventories);
      setLoading(false);
    }
  }, [debouncedSearch]);

  const handleCloseSidebar = () => {
    setIsSidebar(false);
  };

  const removeStateTools = async () => {
    setCurrentOperation({
      body: "",
      medicals: [],
      details: [],
    });
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(load_procedurals(id_appoinment));
    }, 300);
  };

  const handleInventorySearch = (search) => {
    setInputInventory(search);
    if (search) {
      setLoading(true);
    }
  };

  const selectItemInventory = (itemName, itemId) => {
    if (itemName === "") return;
    for (const itemId_ of inventories) {
      if (itemId_ === itemId) return;
    }
    const inventory = supplies.find((su) => su?.id === itemId);
    let listInventory = [...inventories];
    listInventory.push(itemId);
    setInventories(listInventory);
    dispatch(
      saveAppointmentInventory(id_appoinment, {
        inventory_id: listInventory,
      })
    );
    listInventory = [...itemNames];
    listInventory.push(itemName);
    setItemNames(listInventory);
    listInventory = [...inventoryItems];
    listInventory.push(inventory);
    dispatch(blockSaveIntra(true));
    setInventoryItems(listInventory);
    setFirstTime(true);
  };

  useEffect(() => {
    const operationIndexCheck = intaOperations.findIndex((inta) => inta?.body?.part === currentOperation?.body?.part);
    setOperationIndex(operationIndexCheck);
  }, [currentOperation, intaOperations]);

  const removeRowIventory = (item, index) => {
    let listItems = [...inventories];
    listItems.splice(index, 1);
    setInventories(listItems);
    dispatch(
      saveAppointmentInventory(id_appoinment, {
        inventory_id: listItems,
      })
    );
    listItems = [...itemNames];
    listItems.splice(index, 1);
    setItemNames(listItems);
    listItems = [...inventoryItems];
    listItems.splice(index, 1);
    dispatch(blockSaveIntra(true));
    setInventoryItems(listItems);
    setFirstTime(true);
  };

  const handleIntaOperation = (type) => (value) => {
    if (!currentOperation?.body?.part && value.part && currentOperation?.medicals.length > 0) {
      setCurrentOperation({
        ...currentOperation,
        [type]: { ...value, type: bodyTab },
      });
      updateOperations({
        ...currentOperation,
        [type]: { ...value, type: bodyTab },
      });
    } else if (currentOperation?.body?.part && value.part === currentOperation?.body?.part) {
      setCurrentOperation({ details: [], body: "", medicals: [] });
    } else if (currentOperation?.body?.part && value.part !== currentOperation?.body?.part) {
      const existingOperation = intaOperations.find((inta) => inta.body?.part === value.part);
      if (existingOperation) {
        setCurrentOperation(existingOperation);
      } else {
        setCurrentOperation({
          details: [],
          medicals: intaOperations.length > 0 ? [] : currentOperation?.medicals,
          [type]: { ...value, type: bodyTab },
        });
        if (!intaOperations.length && currentOperation?.medicals[0]) {
          updateOperations({
            details: [],
            medicals: currentOperation?.medicals,
            [type]: { ...value, type: bodyTab },
          });
        }
      }
    } else {
      setCurrentOperation({
        body: "",
        medicals: [],
        details: [],
        [type]: { ...value, type: bodyTab },
      });
    }
    setSelectedIndex(-1);
    setSelectedOperation("");
    setDisableDetail(true);
    setTab("tools");
  };

  const updateOperations = async (data) => {
    const existing = intaOperations.findIndex((inta) => inta?.body?.part === data?.body?.part);
    const operations = [...intaOperations];
    if (existing === -1) {
      operations.push({
        ...data,
        body: { ...(data?.body || {}), type: bodyTab },
      });
    } else {
      operations[existing] = {
        ...data,
        body: { ...(data?.body || {}), type: bodyTab },
      };
    }
    let isArrayGreater = true;
    dispatch(setProcedureReview("intra_operations", operations));
    if (intaOperations.length < operations.length) isArrayGreater = true;
    else isArrayGreater = false;
    setIntaOperations(operations);
    if (isArrayGreater) {
      if (getCollapseHeight) setGetCollapseHeight(0);
      let scrollHeightt = document.querySelector(".collapseParentt") ? document.querySelector(".collapseParentt").clientHeight : null;
      if (scrollHeightt) {
        setGetCollapseHeight(scrollHeightt);
        collapseEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    }
  };

  const handleClickLabel = (value) => {
    if (!currentOperation?.body) {
      dispatch(
        setGlobalToastr({
          header: "Add fail!",
          message: "Please pick vein!",
          type: "failed",
        })
      );
      return;
    }
    if (is_locked === false || is_locked === 0) {
      const current = { ...currentOperation };
      if (current.body && current?.body?.part) {
        current.medicals.push(value);
        current.details.push({
          body: getArteryLabel(current?.body?.part),
          item: value,
        });
        updateOperations({ ...current });
      } else {
        current.medicals = [value];
        current.body = {
          offset: {
            screenX: 456,
            screenY: 704,
            x: 176,
            y: 341,
          },
          part: "m-body",
        };
        updateOperations({ ...current });
      }
      dispatch(blockSaveIntra(true));
      setFirstTime(true);
      setCurrentOperation(current);
      setScrollState(true);
      if (scrollState) setScrollState(false);
    }
  };

  const removeArrEmpty = (value, justOne) => {
    if (justOne) {
      const newArr = (value || []).filter((r) => r);
      return newArr;
    }
    const newArr = (value || []).map((r) => {
      return {
        ...r,
        details: (r?.details || []).filter((r) => r),
        medicals: (r?.medicals || []).filter((r) => r),
      };
    });
    return newArr;
  };

  /*
  @params value: artery name
  @params currentPosition: artery object include body, medicals, details
  @params medicalLabel: medical name of object that we want to move
  */
  const handleChangeArtery = (value, currentPosition, medicalLabel) => {
    // xóa dữ liệu Intracranial Circulation và External Carotid Artery khi thay đổi vị trí
    if ((currentPosition == "l-neck-1" || currentPosition == "r-neck-1") && value !== "l-neck-1" && value !== "r-neck-1") {
      const indexRemoveIntra = intaOperations.findIndex((r) => r?.body?.part == currentPosition);
      if (indexRemoveIntra != -1 && intaOperations[indexRemoveIntra]) {
        intaOperations[indexRemoveIntra]["details"] = (intaOperations?.[indexRemoveIntra]?.["details"] || []).map((r) => {
          return {
            ...r,
            external_carotid_artery: null,
            intracranial_circulation: null,
          };
        });

        setIntaOperations([...removeArrEmpty(intaOperations)]);
      }
    }
    //
    if (is_locked === false || is_locked === 0) {
      let positionRemove = intaOperations.findIndex((inta) => inta?.body?.part === currentPosition);
      let positionAdd = intaOperations.findIndex((inta) => inta?.body?.part === value);
      /*
      if value is null then the user may want to remove the medical?
      */
      if (!value || value == "") {
        if (positionRemove !== -1) {
          if (intaOperations?.[positionRemove]?.["medicals"] && Array.isArray(intaOperations?.[positionRemove]?.["medicals"])) {
            // check remove atery when not have medical
            if (intaOperations?.[positionRemove]?.["medicals"].filter((r) => r !== valueMedical).length == 0) {
              intaOperations.splice(positionRemove, 1);
            } else {
              // check remove medical when move
              intaOperations[positionRemove]["medicals"] = intaOperations?.[positionRemove]?.["medicals"].filter(
                (r, index) => index !== selectedIndex
              );
            }
          }
          return;
        }
      }
      // have 1 label not have stenosis
      let valueMedical = medicalLabel ? medicalLabel.toLowerCase() : "";
      valueMedical = valueMedical.replace(" ", "-").replace("%", "");
      let destinationLocation = intaOperations?.[positionAdd] || {};
      let previousDetails = intaOperations?.[positionRemove]?.["details"]?.[selectedIndex];
      // have 2 label have stenosis
      let previousDetailsNext = intaOperations?.[positionRemove]?.["details"]?.[selectedIndex + 1] || [];
      let valueMedicalNext = intaOperations?.[positionRemove]?.["medicals"]?.[selectedIndex + 1] || "";
      const passCantCheck = ["Laser Atherectomy", "Ultrasound", "Local Anesthesia", "Access", "Closure Device", "Stenosis", "In-Stent"];
      // check if when u change location have stenosis we need move all
      if ((previousDetails?.stenosis || previousDetails?.post_stenosis) && !passCantCheck.some((r) => r.includes(previousDetails?.item))) {
        // add position change and merge
        if (positionAdd !== -1) {
          if (intaOperations?.[positionAdd]?.["medicals"] && Array.isArray(intaOperations?.[positionAdd]?.["medicals"])) {
            //get the copy of {body, details, medicals} at the new position
            destinationLocation.body = {
              offset: {
                screenX: 456,
                screenY: 704,
                x: 176,
                y: 341,
              },
              part: value,
            };
            destinationLocation.medicals = removeArrEmpty([...destinationLocation?.medicals, valueMedical, valueMedicalNext], true); //append the medicals with new medical
            destinationLocation.details = [...destinationLocation?.details, previousDetails, previousDetailsNext]; //copy all the details from previous location to new location
          } else {
            destinationLocation.body = {
              offset: {
                screenX: 456,
                screenY: 704,
                x: 176,
                y: 341,
              },
              part: value,
            };
            destinationLocation.medicals = removeArrEmpty([valueMedical, valueMedicalNext], true);
            destinationLocation.details = [previousDetails, previousDetailsNext];
          }
          setCurrentOperation(destinationLocation);

          setIntaOperations([...removeArrEmpty(intaOperations)]);
          updateOperations({ ...destinationLocation });
        } else {
          // if this is a new artery that does not exist yet
          let newBodyPart = { ...currentOperation };
          newBodyPart.body = {
            offset: {
              screenX: 456,
              screenY: 704,
              x: 176,
              y: 341,
            },
            part: value,
          };
          newBodyPart.medicals = removeArrEmpty([valueMedical, valueMedicalNext], true);
          newBodyPart.details = [previousDetails, previousDetailsNext];
          setCurrentOperation(newBodyPart);
          updateOperations({ ...newBodyPart });
        }

        if (positionRemove !== -1) {
          // remove current position
          if (intaOperations?.[positionRemove]?.["medicals"] && Array.isArray(intaOperations?.[positionRemove]?.["medicals"])) {
            // check remove atery when not have medical
            if (intaOperations[positionRemove]["medicals"].filter((r) => r !== valueMedical).length == 0) {
              intaOperations.splice(positionRemove, 1);
              positionAdd = positionAdd - 1;
              setTab("tools");
            } else {
              // check remove medical when move
              const medicalsAll = intaOperations?.[positionRemove]?.["medicals"] || [];
              if ((medicalsAll || []).filter((r, index) => index !== selectedIndex && index !== selectedIndex + 1).length == 0) {
                intaOperations.splice(positionRemove, 1);

                setIntaOperations([...removeArrEmpty(intaOperations)]);
                setTab("tools");
              } else {
                intaOperations[positionRemove]["medicals"] = intaOperations[positionRemove]["medicals"].filter(
                  (r, index) => index !== selectedIndex && index !== selectedIndex + 1
                );
                intaOperations[positionRemove]["details"] = intaOperations[positionRemove]["details"].filter(
                  (r, index) => index !== selectedIndex && index !== selectedIndex + 1
                );
                setTab("tools");
              }
            }
          }
        }
      } else {
        // if when location change item and item not have in array all we will push new  and remove current
        if (positionRemove !== -1) {
          // remove current position
          if (intaOperations?.[positionRemove]?.["medicals"] && Array.isArray(intaOperations?.[positionRemove]?.["medicals"])) {
            // check remove atery when not have medical
            if (intaOperations[positionRemove]["medicals"].filter((r) => r !== valueMedical).length == 0) {
              intaOperations.splice(positionRemove, 1);
              positionAdd = positionAdd - 1;
            } else {
              // check remove medical when move
              intaOperations[positionRemove]["medicals"] = intaOperations[positionRemove]["medicals"].filter((r, index) => index !== selectedIndex);
              intaOperations[positionRemove]["details"] = intaOperations[positionRemove]["details"].filter((r, index) => index !== selectedIndex);
            }
          }
        }
        // add position change and merge
        if (positionAdd !== -1) {
          if (intaOperations?.[positionAdd]?.["medicals"] && Array.isArray(intaOperations?.[positionAdd]?.["medicals"])) {
            //get the copy of {body, details, medicals} at the new position
            destinationLocation.body = {
              offset: {
                screenX: 456,
                screenY: 704,
                x: 176,
                y: 341,
              },
              part: value,
            };
            destinationLocation.medicals = removeArrEmpty([...destinationLocation?.medicals, valueMedical], true); //append the medicals with new medical
            destinationLocation.details = [...destinationLocation?.details, previousDetails]; //copy all the details from previous location to new location
          } else {
            destinationLocation.body = {
              offset: {
                screenX: 456,
                screenY: 704,
                x: 176,
                y: 341,
              },
              part: value,
            };
            destinationLocation.medicals = removeArrEmpty([valueMedical], true);
            destinationLocation.details = [previousDetails];
          }
          setCurrentOperation(destinationLocation);

          setIntaOperations([...removeArrEmpty(intaOperations)]);
          updateOperations({ ...destinationLocation });
        } else {
          // if this is a new artery that does not exist yet
          let newBodyPart = { ...currentOperation };
          newBodyPart.body = {
            offset: {
              screenX: 456,
              screenY: 704,
              x: 176,
              y: 341,
            },
            part: value,
          };
          newBodyPart.medicals = [valueMedical];
          newBodyPart.details = [previousDetails];
          setCurrentOperation(newBodyPart);
          updateOperations({ ...newBodyPart });
        }
      }
    }
  };

  const handleSelectIcon = (value, index) => {
    setDisableDetail(false);
    setSelectedOperation(value);
    setSelectedIndex(index);
    setTab("details");
  };

  const dataOnchange = (value) => {
    if (is_locked === false || is_locked === 0) {
      const operations = [...intaOperations];
      dispatch(setProcedureReview("intra_operations", operations));
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          save_info_side(id_appoinment, {
            ...value,
          })
        );
      }, 500);
    }
  };

  const handleRemoveMedical = (medical) => {
    const current = { ...currentOperation };
    if (selectedIndex === -1) {
      setSelectedOperation("");
      setDisableDetail(true);
      setTab("tools");
      return;
    } else {
      current.medicals.splice(selectedIndex, 1);
      current.details.splice(selectedIndex, 1);
      setCurrentOperation(current);
      updateOperations(current);
      setSelectedOperation("");
      setDisableDetail(true);
      setSelectedIndex(-1);
      if (
        intaOperations &&
        intaOperations[operationIndex] &&
        intaOperations[operationIndex]["medicals"] &&
        intaOperations[operationIndex]["medicals"].length == 0
      ) {
        intaOperations.splice(operationIndex, 1);
        dispatch(setProcedureReview("intra_operations", intaOperations));

        setIntaOperations([...intaOperations]);
      }
      setTab("tools");
    }
  };

  const handleMedicals = (medicals, from, to, details) => {
    const params = { ...currentOperation, medicals, details };
    setCurrentOperation(params);
    updateOperations(params);
    setSelectedIndex(-1);
    setSelectedOperation("");
    setDisableDetail(true);
    setTab("tools");
  };

  const saveCurrentOperation = (detail) => {
    const current = { ...currentOperation };
    if (current.medicals[selectedIndex]) {
      if (detail.post_stenosis) {
        const curOperation = getMedical(current.medicals[selectedIndex]);
        if (curOperation) {
          const operation = current.medicals[selectedIndex + 1] ? getMedical(current.medicals[selectedIndex + 1]) : undefined;
          if (curOperation?.type !== "status" && !operation) {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.post_stenosis.replace("%", "");
            current.details[selectedIndex + 1] = [];
          } else if (curOperation?.type !== "status" && operation?.state && operation?.state === "stenosis") {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.post_stenosis.replace("%", "");
          } else if (curOperation?.type !== "status" && !operation?.state) {
            current.medicals.splice(selectedIndex + 1, 0, "stenosis-" + detail.post_stenosis.replace("%", ""));
            current.details.splice(selectedIndex + 1, 0, []);
          }
          if (curOperation?.type === "status") {
            current.medicals[selectedIndex] = curOperation?.state + "-" + detail.post_stenosis.replace("%", "");
          }
          current.details[selectedIndex] = detail;
          setCurrentOperation(current);
          updateOperations(current);
        }
        // Todo implement
      } else if (detail.stenosis) {
        const curOperation = getMedical(current.medicals[selectedIndex]);
        if (curOperation) {
          const operation = current.medicals[selectedIndex + 1] ? getMedical(current.medicals[selectedIndex + 1]) : undefined;
          if (curOperation?.type !== "status" && !operation) {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.stenosis.replace("%", "");
            current.details[selectedIndex + 1] = [];
          } else if (curOperation?.type !== "status" && operation?.state && operation?.state === "stenosis") {
            current.medicals[selectedIndex + 1] = "stenosis-" + detail.stenosis.replace("%", "");
          } else if (curOperation?.type !== "status" && !operation?.state) {
            current.medicals.splice(selectedIndex + 1, 0, "stenosis-" + detail.stenosis.replace("%", ""));
            current.details.splice(selectedIndex + 1, 0, []);
          }
          if (curOperation?.type === "status") {
            current.medicals[selectedIndex] = curOperation?.state + "-" + detail.stenosis.replace("%", "");
          }
          current.details[selectedIndex] = detail;
          setCurrentOperation(current);
          updateOperations(current);
        }
      } else {
        if (current.details[selectedIndex]) {
          current.details[selectedIndex] = detail;
          setCurrentOperation(current);
          updateOperations(current);
        }
      }
      // Todo implement
    }
  };

  const onHandleSaveAnswer = async (value) => {
    const arrAnswers = [];
    (value || []).map((r) => {
      (r?.values || []).map((ans) => {
        if (ans?.checked) {
          arrAnswers.push({
            ...ans,
            question: r?.typeFull,
            checked_choice: ans?.option,
            comments: "",
            id_question: r?.id,
            angio_procedure_type_variable_id: r?.id,
          });
        } else {
          arrAnswers.push({
            ...ans,
            question: r?.typeFull,
            checked_choice: "",
            comments: "",
            id_question: r?.id,
            angio_procedure_type_variable_id: r?.id,
          });
        }
      });
    });
    // check to join arr checked
    const arrChecked = [];
    for (let index = 0; index < arrAnswers.length; index++) {
      const element = arrAnswers[index];
      if (
        (arrChecked && arrChecked?.length == 0) ||
        !arrChecked?.some((r) => r?.angio_procedure_type_variable_id === element?.angio_procedure_type_variable_id)
      ) {
        arrChecked.push({
          ...element,
        });
      } else {
        const indexId = arrChecked.findIndex((find) => find?.angio_procedure_type_variable_id === element?.angio_procedure_type_variable_id);
        if (indexId != -1) {
          arrChecked[indexId] = {
            ...arrChecked[indexId],
            checked_choice: `${arrChecked[indexId]["checked_choice"]},${element?.checked_choice}`,
          };
        }
      }
    }

    clearTimeout(timer);
    timer = setTimeout(async () => {
      dispatch(setGlobalLoading(true));
      await save_intra_answer(id_appoinment, {
        answers: arrChecked,
        appointment_id: Number(id_appoinment),
        angio_procedure_type_variable_id: (dataCPT && dataCPT["appointment_type_id"]) || 0,
      });
      await dispatch(get_intra_answer(id_appoinment));
      dispatch(setGlobalLoading(false));
    }, 300);
  };

  const handleSaveDocument = async () => {
    dispatch(setGlobalLoading(true));
    if (dataCheck && dataCheck.length > 0) {
      const fixArr = (dataCheck || []).map((r) => {
        return {
          ...r,
          questions: (r?.questions || []).map((q) => {
            return {
              ...q,
              comments: RemoveN(q?.comments),
            };
          }),
        };
      });
      await dispatch(
        save_intra_operative(id_appoinment, {
          patient_id: patient_id,
          conditions: fixArr,
          copy_intra_from_previous_visit: copy_intra_from_previous_visit,
        })
      );
      setDataCheck(null);
    }
    dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    if (stateActiveCheckTools) {
      saveAll();
      dispatch(activeCheckTools(false));
    }
    return () => {
      dispatch(activeCheckTools(false));
    };
  }, [stateActiveCheckTools]);

  const handleCheckboxAI = async (e, r) => {
    clearTimeout(timer);
    dispatch(setGlobalLoading(true));
    const params = { ...checkBoxState, [r]: e.target.checked };
    const output = Object.entries(params || {})
      .filter(([key, value]) => value)
      .map(([key]) => key);
    setCheckBoxState(params);
    timer = setTimeout(async () => {
      if (id_appoinment) {
        try {
          await setChoiceAI(id_appoinment, { ai_choice: output || [] });
          fetchAPIAI();
          dispatch(
            setGlobalToastr({
              header: "OBL AI optimization",
              message: "Save Successfully",
              type: "success",
            })
          );
        } catch (error) {
          dispatch(
            setGlobalToastr({
              header: "OBL AI optimization",
              message: error,
              type: "failed",
            })
          );
        }
      }
    }, 600);
  };

  useEffect(() => {
    // group A Anterior Tibial,Posterior Tibial,Peroneal,Tibioperoneal Trunk
    const groupA = ["Anterior Tibial", "Posterior Tibial", "Peroneal", "Tibioperoneal Trunk"];
    // group B SFA/Popliteal,Common Femoral Artery
    const groupB = ["SFA/Popliteal", "Common Femoral Artery"];

    // Get checked keys from checkBoxState
    const checkedKeys = Object.keys(checkBoxState).filter((key) => checkBoxState[key]);

    // Check if any selected checkboxes match Group A or Group B
    const isGroupA = groupA.some((key) => checkedKeys.includes(key));
    const isGroupB = groupB.some((key) => checkedKeys.includes(key));
    if (isGroupA && isGroupB) {
      setGroup("GROUP_A_AND_B");
    } else if (isGroupA) {
      setGroup("GROUP_A");
    } else if (isGroupB) {
      setGroup("GROUP_B");
    } else {
      setGroup(null);
    }
  }, [checkBoxState]);

  const saveAll = () => {
    const errors = {};
    let isValid = true;

    for (let i = 0; i < intaOperations.length; i++) {
      if (intaOperations[i].details) {
        intaOperations[i].details.forEach((detail, indexDetail) => {
          if (detail?.body && Object.keys(detail).length > 1 && detail?.item) {
            const dataCheck = CheckTools.find((r) => r?.name.toLowerCase().includes(detail?.item.replace("-", " ").toLowerCase()))?.require.forEach(
              (field) => {
                if (!detail[field]) {
                  isValid = false;
                  errors[`${detail?.body}`] = `${detail?.body} is required`;
                  errors[
                    `${intaOperations[i]["body"]["part"]}-${detail?.item.toLowerCase()}-${indexDetail}`
                  ] = `${detail?.body} - ${detail?.item} have ${field} is required`;
                }
              }
            );
          } else if (detail?.body && Object.keys(detail).length == 1) {
            isValid = false;
            errors[`${detail?.body}`] = `${detail?.body} is required`;
          }
        });
      }
    }
    setStateErrors(errors);
    if (isValid) {
      dispatch(
        setGlobalToastr({
          header: "OBL Intra-Operative",
          message: "Save Successfully",
          type: "success",
        })
      );
      dispatch(blockSaveIntra(false));
    } else {
      dispatch(
        setGlobalToastr({
          header: "Alert",
          type: "failed",
          message: errors,
        })
      );
      dispatch(blockSaveIntra(true));
      setFirstTime(true);
    }
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header">
        <div className="patientContainer">
          <div className="imageSection" ref={imageRef} style={{ position: "relative" }}>
            <div
              className="tabHeaders new"
              style={{
                position: "absolute",
                top: "2rem",
                left: "calc(50% + 3rem)",
                transform: "translateX(-50%)",
              }}
            >
              {!isVenoGram && (
                <div
                  className={`tabHeaders-item
                 ${bodyTab === "arterial" ? "active" : ""}`}
                  style={{ textAlign: "left" }}
                  onClick={() => {
                    setBodyTab("arterial");
                    removeStateTools("arterial");
                  }}
                >
                  Arterial Tree
                </div>
              )}
              {isVenoGram && (
                <div
                  className={`tabHeaders-item 
                  ${bodyTab === "vein" ? "active" : ""}`}
                  onClick={() => {
                    setBodyTab("vein");
                    removeStateTools("vein");
                  }}
                >
                  Vein Tree
                </div>
              )}
            </div>
            {bodyTab === "arterial" ? (
              <IntaOperationBody
                current={currentOperation}
                operations={intaOperations.filter((r) => r?.body?.type !== "vein")}
                handleClick={handleIntaOperation("body")}
              />
            ) : (
              <IntraOperationNewBody
                current={currentOperation}
                operations={intaOperations.filter((r) => r?.body?.type === "vein")}
                handleClick={handleIntaOperation("body")}
              />
            )}
          </div>
          <DndProvider backend={HTML5Backend}>
            <div className="detailSection" style={{ paddingRight: "10px" }}>
              <div className="tabHeaders new">
                <div
                  className={`tabHeaders-item ${tab === "documentation" ? "active" : ""}`}
                  style={{ textAlign: "left" }}
                  onClick={() => setTab("documentation")}
                >
                  Documentation
                </div>
                <div
                  className={`tabHeaders-item ${tab === "tools" ? "active" : ""} ${isActiveTool ? "" : "disabled"}`}
                  onClick={() => {
                    handleSaveDocument();
                    setTab("tools");
                  }}
                >
                  Tools
                </div>
                <div
                  className={`tabHeaders-item ${tab === "details" ? "active" : ""} ${disableDetail && isActiveTool && "disabled"}`}
                  onClick={() => {
                    handleSaveDocument();
                    if (!disableDetail || !isActiveTool) {
                      setTab("details");
                    }
                  }}
                >
                  Details
                </div>
                <div
                  className={`tabHeaders-item ${tab === "inventory-utilization" ? "active" : ""}`}
                  onClick={() => {
                    handleSaveDocument();
                    setTab("inventory-utilization");
                  }}
                >
                  Inventory & Utilization
                </div>
                <div
                  className={`tabHeaders-item ${tab === "ai_optimization" ? "active" : ""}`}
                  onClick={() => {
                    handleSaveDocument();
                    setTab("ai_optimization");
                  }}
                >
                  AI Optimization
                </div>
              </div>

              <CardWhiteBlock
                varient={"default"}
                title={""}
                other={tab === "documentation" ? "full-height" : "half-height"}
                cardContainerStyle={{
                  height: tab === "documentation" ? "100%" : "calc(50% - 1rem - 20px)",
                }}
                extraStyle={{
                  padding: tab === "documentation" ? "0px" : "0 1.5rem 1rem",
                  backgroundColor: tab === "documentation" ? "#f4f7fb" : "transparent",
                  height: "100%",
                }}
              >
                {tab === "inventory-utilization" ? (
                  <div
                    className="content"
                    style={{
                      padding: "28px 1rem 12px",
                    }}
                  >
                    <AutoCompleteComponent
                      title="inta-operative"
                      placeholder="Search Inventory"
                      rows={itemNames}
                      disableDelete={is_locked}
                      keyLabel={"name"}
                      keyValue={"id"}
                      disableSearchIcon={false}
                      removeItem={removeRowIventory}
                      loading={loading}
                      options={allInventory}
                      isResult={false}
                      selectItem={selectItemInventory}
                      handleSearch={handleInventorySearch}
                      minRows={14}
                      isLocked={is_locked}
                      isIntraOperative={true}
                    />
                  </div>
                ) : tab === "ai_optimization" ? (
                  <ScrollWrapper
                    css={`x-hidden
                    ${tab === "documentation" ? "no-padding" : "no-padding-obl"}
                  `}
                  >
                    <div
                      className="content"
                      style={{
                        padding: "24px 0px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        Which arterial segment has treatable lesions greater than 80%
                      </span>

                      <div style={{ marginTop: 10 }}>
                        <div
                          className="resourceInfo between half-pannel"
                          style={{ display: "flex", justifyContent: "space-between", marginBottom: 12 }}
                        >
                          <div style={{ width: "20%" }}>Access</div>
                          <div style={{ width: "50%" }}>
                            <EditableFormInput
                              label={"Access"}
                              fullWidth={true}
                              value={""}
                              type="check"
                              options={[
                                { value: "Right Common Femoral" },
                                { value: "Left Common Femoral" },
                                { value: "Right Posterior Tibial" },
                                { value: "Left Posterior Tibial" },
                                { value: "Right Anterior Tibial" },
                                { value: "Left Anterior Tibial" },
                              ]}
                              optionKey={"value"}
                              valueKey={"value"}
                              handleChange={(e) => {
                                console.log(e);
                              }}
                            />
                          </div>
                        </div>

                        {[
                          "Anterior Tibial",
                          "Posterior Tibial",
                          "Peroneal",
                          "Tibioperoneal Trunk",
                          "SFA/Popliteal",
                          "Common Femoral Artery",
                          "External Iliac",
                          "Internal Iliac",
                          "Common Iliac",
                        ].map((r, index) => (
                          <div
                            key={index}
                            className="resourceInfo between half-pannel"
                            style={{ display: "flex", justifyContent: "space-between", marginBottom: 12 }}
                          >
                            <div style={{ width: "calc(100% - 180px)" }}>{r}</div>
                            <Checkbox checked={checkBoxState[r] || false} onChange={(e) => handleCheckboxAI(e, r)}></Checkbox>
                          </div>
                        ))}
                      </div>
                    </div>
                  </ScrollWrapper>
                ) : (
                  <ScrollWrapper
                    css={`x-hidden
                    ${tab === "documentation" ? "no-padding" : "no-padding-obl"}
                  `}
                  >
                    {tab === "documentation" && (
                      <div
                        className="content"
                        style={{
                          padding: "28px 0 12px",
                          height: `calc(${dataCheck && dataCheck.length <= 2 ? "160em" : "100em"} * ${
                            dataCheck && dataCheck.length > 1 ? dataCheck.length : 1
                          })`,
                        }}
                      >
                        <ProcedureSearch
                          disable={is_locked}
                          options={allConditions}
                          updateData={(data, status) => {
                            setDataCheck(data);
                            if (!status) {
                              dispatch(blockSaveIntra(true));
                              setFirstTime(true);
                            }
                          }}
                        />
                      </div>
                    )}
                    {tab === "indication" && (
                      <div className="content" style={{ padding: "28px 0 12px" }}>
                        <div className="info d-flex">
                          <Select
                            placeholder="Procedure to be Performed..."
                            suffixIcon={<ExpandSVG />}
                            allowClear={true}
                            style={{
                              width: "25%",
                              minWidth: "260px",
                            }}
                          >
                            {ProcedureOptions.map((opt, index) => (
                              <Option key={`procedure-to-be-performed-${index}`} value={opt}>
                                {opt}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className="info d-flex">
                          <Select
                            placeholder="Clinical Indication..."
                            suffixIcon={<ExpandSVG />}
                            allowClear={true}
                            style={{
                              width: "25%",
                              minWidth: "260px",
                            }}
                          >
                            <Option value="Critical Limb Ischemia">Critical Limb Ischemia</Option>
                            <Option value="Critical Limb Ischemia1">Critical Limb Ischemia</Option>
                          </Select>
                        </div>
                      </div>
                    )}
                    {tab === "tools" && (
                      <>
                        <Collapse className="card-collapse" accordion style={{ paddingTop: "12px" }}>
                          <Panel header="Access" key="Access">
                            <div
                              className="content"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                paddingTop: "0.5rem",
                              }}
                            >
                              <LabelIcon
                                label={"Ultrasound"}
                                isDraggable={true}
                                icon={<UltrasoundProbeIcon />}
                                item="ultrasound"
                                handleClick={() => handleClickLabel("ultrasound")}
                              />
                              <LabelIcon
                                label={"Local Anesthesia"}
                                isDraggable={true}
                                icon={<DugsSVG />}
                                item="local-anesthesia"
                                handleClick={() => handleClickLabel("local-anesthesia")}
                              />
                              <LabelIcon
                                label={"Access"}
                                isDraggable={true}
                                icon={<DevicesSVG />}
                                item="access"
                                handleClick={() => handleClickLabel("access")}
                              />
                              <LabelIcon
                                label={"Closure Device"}
                                isDraggable={true}
                                icon={
                                  <img
                                    src="/images/closure-featured.png"
                                    alt="closure device"
                                    style={{
                                      width: "32px",
                                      height: "32px",
                                      marginLeft: 0,
                                      marginBottom: "5px",
                                    }}
                                  />
                                }
                                item="closure-device"
                                handleClick={() => handleClickLabel("closure-device")}
                              />
                            </div>
                          </Panel>
                          <Panel header="Diagnostic" key="Diagnostic">
                            <div
                              className="content"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                paddingTop: "0.5rem",
                              }}
                            >
                              <LabelIcon
                                label={"Stenosis 0%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaque0 />}
                                isHover={true}
                                item="stenosis-0"
                                active={!!currentOperation && currentOperation?.diagnostic === "stenosis-0"}
                                handleClick={() => handleClickLabel("stenosis-0")}
                              />
                              <LabelIcon
                                label={"Stenosis 20%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaque20 />}
                                isHover={true}
                                item="stenosis-20"
                                active={!!currentOperation && currentOperation?.diagnostic === "stenosis-20"}
                                handleClick={() => handleClickLabel("stenosis-20")}
                              />
                              <LabelIcon
                                label={"Stenosis 40%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaque40 />}
                                isHover={true}
                                item="stenosis-40"
                                active={!!currentOperation && currentOperation?.diagnostic === "stenosis-40"}
                                handleClick={() => handleClickLabel("stenosis-40")}
                              />
                              <LabelIcon
                                label={"Stenosis 60%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaque60 />}
                                isHover={true}
                                item="stenosis-60"
                                active={!!currentOperation && currentOperation?.diagnostic === "stenosis-60"}
                                handleClick={() => handleClickLabel("stenosis-60")}
                              />
                              <LabelIcon
                                label={"Stenosis 80%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaque80 />}
                                isHover={true}
                                item="stenosis-80"
                                active={!!currentOperation && currentOperation?.diagnostic === "stenosis-80"}
                                handleClick={() => handleClickLabel("stenosis-80")}
                              />
                              <LabelIcon
                                label={"Stenosis 100%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaque100 />}
                                isHover={true}
                                item="stenosis-100"
                                active={!!currentOperation && currentOperation?.diagnostic === "stenosis-100"}
                                handleClick={() => handleClickLabel("stenosis-100")}
                              />
                              <LabelIcon
                                label={"In-Stent 0%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaqueStent0 />}
                                isHover={true}
                                item="in-stent-0"
                                active={!!currentOperation && currentOperation?.diagnostic === "in-stent-0"}
                                handleClick={() => handleClickLabel("in-stent-0")}
                              />
                              <LabelIcon
                                label={"In-Stent 20%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaqueStent20 />}
                                isHover={true}
                                item="in-stent-20"
                                active={!!currentOperation && currentOperation?.diagnostic === "in-stent-20"}
                                handleClick={() => handleClickLabel("in-stent-20")}
                              />
                              <LabelIcon
                                label={"In-Stent 40%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaqueStent40 />}
                                isHover={true}
                                item="in-stent-40"
                                active={!!currentOperation && currentOperation?.diagnostic === "in-stent-40"}
                                handleClick={() => handleClickLabel("in-stent-40")}
                              />
                              <LabelIcon
                                label={"In-Stent 60%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaqueStent60 />}
                                isHover={true}
                                item="in-stent-60"
                                active={!!currentOperation && currentOperation?.diagnostic === "in-stent-60"}
                                handleClick={() => handleClickLabel("in-stent-60")}
                              />
                              <LabelIcon
                                label={"In-Stent 80%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaqueStent80 />}
                                isHover={true}
                                item="in-stent-80"
                                active={!!currentOperation && currentOperation?.diagnostic === "in-stent-80"}
                                handleClick={() => handleClickLabel("in-stent-80")}
                              />
                              <LabelIcon
                                label={"In-Stent 100%"}
                                isDraggable={true}
                                icon={<BloodVesselPlaqueStent100 />}
                                isHover={true}
                                item="in-stent-100"
                                active={!!currentOperation && currentOperation?.diagnostic === "in-stent-100"}
                                handleClick={() => handleClickLabel("in-stent-100")}
                              />
                              <LabelIcon
                                label={"IVUS"}
                                isDraggable={true}
                                icon={<IVUSIcon />}
                                isHover={true}
                                item="ivus"
                                active={!!currentOperation && currentOperation?.diagnostic === "ivus"}
                                handleClick={() => handleClickLabel("ivus")}
                              />
                              <LabelIcon
                                label={"Thrombus"}
                                isDraggable={true}
                                icon={<IVUSIcon />}
                                isHover={true}
                                item="thrombus"
                                active={!!currentOperation && currentOperation?.diagnostic === "thrombus"}
                                handleClick={() => handleClickLabel("thrombus")}
                              />
                              <LabelIcon
                                label={"Dissection"}
                                isDraggable={true}
                                icon={<IVUSIcon />}
                                isHover={true}
                                item="dissection"
                                active={!!currentOperation && currentOperation?.diagnostic === "dissection"}
                                handleClick={() => handleClickLabel("dissection")}
                              />
                              <LabelIcon
                                label={"Gradient"}
                                isDraggable={true}
                                icon={<GradientIcon />}
                                isHover={true}
                                item="gradient"
                                active={!!currentOperation && currentOperation?.diagnostic === "gradient"}
                                handleClick={() => handleClickLabel("gradient")}
                              />
                            </div>
                          </Panel>
                          {bodyTab !== "vein" && (
                            <Panel header="Atherectomy" key="Atherectomy">
                              <div
                                className="content"
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  justifyContent: "flex-start",
                                  flexWrap: "wrap",
                                  paddingTop: "0.5rem",
                                }}
                              >
                                <LabelIcon
                                  label={"Directional Atherectomy"}
                                  isDraggable={true}
                                  icon={<AtherectomyDirectionalIcon />}
                                  item="directional-atherectomy"
                                  handleClick={() => handleClickLabel("directional-atherectomy")}
                                />
                                <LabelIcon
                                  label={"Laser Atherectomy"}
                                  isDraggable={true}
                                  icon={<AtherectomyLaserIcon />}
                                  item="laser-atherectomy"
                                  handleClick={() => handleClickLabel("laser-atherectomy")}
                                />
                                <LabelIcon
                                  label={"Rotational Atherectomy"}
                                  isDraggable={true}
                                  icon={<AtherectomyRotationalIcon />}
                                  item="rotational-atherectomy"
                                  handleClick={() => handleClickLabel("rotational-atherectomy")}
                                />
                                <LabelIcon
                                  label={"Obital Atherectomy"}
                                  isDraggable={true}
                                  icon={<AtherectomyTransluminalIcon />}
                                  item="obital-atherectomy"
                                  handleClick={() => handleClickLabel("obital-atherectomy")}
                                />
                              </div>
                            </Panel>
                          )}
                          <Panel header="Angioplasty" key="Angioplasty">
                            <div
                              className="content"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                paddingTop: "0.5rem",
                              }}
                            >
                              <LabelIcon
                                label={"Balloon Angioplasty"}
                                isDraggable={true}
                                icon={<BalloonIcon />}
                                item="balloon-angioplasty"
                                handleClick={() => handleClickLabel("balloon-angioplasty")}
                              />
                            </div>
                          </Panel>
                          <Panel header="Stenting" key="Stenting">
                            <div
                              className="content"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                paddingTop: "0.5rem",
                              }}
                            >
                              <LabelIcon
                                label={"Balloon Expandable Covered Stent"}
                                isDraggable={true}
                                icon={<StentBalloonCoveredIcon />}
                                item="balloon-expandable-covered-stent"
                                handleClick={() => handleClickLabel("balloon-expandable-covered-stent")}
                              />
                              <LabelIcon
                                label={"Covered Stent"}
                                isDraggable={true}
                                icon={<StentCoveredIcon />}
                                item="covered-stent"
                                handleClick={() => handleClickLabel("covered-stent")}
                              />
                              <LabelIcon
                                label={"Balloon Expandable Stent"}
                                isDraggable={true}
                                icon={<StentBalloonIcon />}
                                item="balloon-expandable-stent"
                                handleClick={() => handleClickLabel("balloon-expandable-stent")}
                              />
                              <LabelIcon
                                label={"Self Expanding Stent"}
                                isDraggable={true}
                                icon={<StentIcon />}
                                item="self-expanding-stent"
                                handleClick={() => handleClickLabel("self-expanding-stent")}
                              />
                            </div>
                          </Panel>
                          <Panel header="Other" key="Other">
                            <div
                              className="content"
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                paddingTop: "0.5rem",
                              }}
                            >
                              <LabelIcon
                                label={"Thrombectomy"}
                                isDraggable={true}
                                icon={<IVUSIcon />}
                                isHover={true}
                                item="thrombectomy"
                                active={!!currentOperation && currentOperation?.diagnostic === "thrombectomy"}
                                handleClick={() => handleClickLabel("thrombectomy")}
                              />
                            </div>
                          </Panel>
                        </Collapse>
                        <div
                          style={{
                            width: "100%",
                            height: 50,
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-start",
                          }}
                        >
                          <button onClick={saveAll} disabled={is_locked} className="common-btn">
                            Save
                          </button>
                        </div>
                      </>
                    )}
                    {tab === "details" && (
                      <>
                        {isActiveTool ? (
                          <AccessDetails
                            saveAll={saveAll}
                            handleChangeArtery={handleChangeArtery}
                            title={"19G Access"}
                            inventories={supplies}
                            items={inventoryItems}
                            data={SampleAccessData}
                            disable={is_locked}
                            current={currentOperation}
                            activeIndex={selectedIndex}
                            activeItem={selectedOperation}
                            handleSubmit={saveCurrentOperation}
                            removeItem={handleRemoveMedical}
                            isVenoGram={isVenoGram}
                          />
                        ) : (
                          <div
                            className="resourceContent"
                            style={{
                              flex: "unset",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <div
                              className="resourceInfo"
                              style={{
                                paddingLeft: "0px",
                                display: "block",
                                paddingTop: 20,
                              }}
                            >
                              <ExaminationIntra
                                type="procedure"
                                onChangeData={(value) => {
                                  onHandleSaveAnswer(value);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </ScrollWrapper>
                )}
              </CardWhiteBlock>
              <CardWhiteBlock
                varient={"default"}
                cardContainerStyle={{
                  height: "calc(50% - 20px)",
                  padding: tab === "ai_optimization" ? 0 : "0 1.5rem 1rem",
                  marginBottom: 0,
                }}
                extraStyle={{
                  height: "calc(100% - 2.8rem)",
                  paddingBottom: 0,
                }}
                title={
                  <div
                    className="pannel-title"
                    style={{
                      marginTop: "1.5rem",
                      marginLeft: "-12px",
                      display: "flex",
                    }}
                  >
                    <Icon type="appstore" theme="twoTone" twoToneColor="#EE5396" />
                    <span
                      style={{
                        marginLeft: "8px",
                        fontSize: "18px",
                        fontWeight: "normal",
                      }}
                    >
                      {tab == "ai_optimization" ? "AI OPTIMIZATION" : "PROCEDURAL DOCUMENTATION"}
                    </span>
                  </div>
                }
                other={tab === "documentation" ? "d-none" : "half-height"}
              >
                <ScrollWrapper css="no-padding-procedural x-hidden collapseParentt">
                  {tab === "ai_optimization" ? (
                    <div style={{ display: "flex", flexDirection: "row", marginTop: 20, justifyContent: "space-between" }}>
                      {/* table left */}
                      <div
                        style={{
                          width: "calc(50% - 5px)",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {/* header */}
                        <div
                          style={{
                            width: "100%",
                            height: 40,
                            backgroundColor: "#0f62fe",
                            borderBottom: "1px solid #fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>CPT Code</span>
                        </div>
                        {/* content */}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#0f62fe",
                            flexDirection: "column",
                            padding: dataCPTCode && dataCPTCode.length > 0 ? "12px 0px 12px 0px" : 0,
                          }}
                        >
                          {(dataCPTCode || []).map((r, index) => {
                            return (
                              <span key={index} style={{ fontSize: 16, color: "#fff" }}>
                                {r?.code?.cpt_code}
                                {r?.sub ? `: $${r?.sub}` : ""}
                              </span>
                            );
                          })}
                          <span style={{ fontSize: 16, color: "#fff" }}>
                            {" "}
                            Group A: {`$${groupA}`} <br />
                            Group B: {`$${groupB}`}
                          </span>
                        </div>
                      </div>
                      {/* table right */}
                      <div
                        style={{
                          width: "calc(50% - 5px)",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        {/* header */}
                        <div
                          style={{
                            width: "100%",
                            borderBottom: "1px solid #fff",
                            height: 40,
                            backgroundColor: "#0f62fe",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <span style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>TODO</span>
                        </div>
                        {/* content */}
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#0f62fe",
                            flexDirection: "column",
                            padding: 0,
                          }}
                        >
                          {/* <span style={{ fontSize: 16, fontWeight: "bold", color: "#fff" }}>tesst</span> */}
                        </div>
                      </div>
                      {/*  */}
                    </div>
                  ) : (
                    <Collapse
                      className="card-collapse bodyPart_parent "
                      accordion
                      style={{ paddingTop: "24px" }}
                      activeKey={currentOperation?.body?.part}
                      onChange={(pannelKey) => {
                        const operation = intaOperations.find((inta) => inta?.body?.part === pannelKey);
                        if (currentOperation && operation && pannelKey !== currentOperation?.body?.part) {
                          setCurrentOperation(operation);
                          setDisableDetail(true);
                          setSelectedOperation("");
                          setSelectedIndex(-1);
                          setTab("tools");
                        } else {
                          setCurrentOperation({
                            ...SampleOperation,
                            body: "",
                            medicals: [],
                          });
                          setDisableDetail(true);
                          setSelectedOperation("");
                          setSelectedIndex(-1);
                          setTab("tools");
                        }
                      }}
                    >
                      {bodyTab === "arterial"
                        ? (intaOperations.filter((r) => r?.body?.type !== "vein") || []).map((operation, index, arr) => {
                            return (
                              <Panel
                                header={getArteryLabel(operation?.body?.part)}
                                key={operation?.body?.part}
                                id={operation?.body?.part}
                                className={
                                  operation.details.some((checkError) => stateErrors?.[checkError?.body])
                                    ? "error-validation"
                                    : [arr.length - 1].includes(index)
                                    ? `secondLastPanel`
                                    : ""
                                }
                              >
                                <ArteryDetail
                                  stateErrors={stateErrors}
                                  data={operation?.body}
                                  medicals={operation?.medicals}
                                  details={operation?.details}
                                  diagnostic={operation?.diagnostic}
                                  medical={operation?.medical}
                                  handleOperation={handleSelectIcon}
                                  handleMedicals={handleMedicals}
                                  isDraggable={true}
                                />
                              </Panel>
                            );
                          })
                        : (intaOperations.filter((r) => r?.body?.type === "vein") || []).map((operation, index) => (
                            <Panel header={getVeinLabel(operation?.body?.part)} key={operation?.body?.part}>
                              <VeinDetail
                                data={operation?.body}
                                medicals={operation?.medicals}
                                diagnostic={operation?.diagnostic}
                                medical={operation?.medical}
                                handleOperation={handleSelectIcon}
                                handleMedicals={handleMedicals}
                                isDraggable={true}
                              />
                            </Panel>
                          ))}
                      {[1, 2, 3, 4, 5, 6]?.map((item) => {
                        return (
                          <Panel header={"my header"} key={item} style={{ visibility: "hidden" }}>
                            <div></div>
                          </Panel>
                        );
                      })}
                      <div ref={collapseEndRef}></div>
                    </Collapse>
                  )}
                </ScrollWrapper>
              </CardWhiteBlock>
            </div>
          </DndProvider>
        </div>
      </div>
      {isSidebar === true && (
        <IntaOperativeSideBar
          ref={sidebarRef}
          dataOnchange={dataOnchange}
          resource={StaticResource}
          isShow={isSidebar}
          handleCloseSidebar={handleCloseSidebar}
          appointment={dataCPT}
        />
      )}
    </div>
  );
};

export default IntaOperative;
