import { Empty, Icon, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import Loading from "../../../common/components/Loading";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { search_cpt_code, search_insurance_companies } from "../../../store/actions";
import { ExpandSVG } from "src/common/components/icons/SVGIcon";

let timer = null;

const OBLOptimizer = ({ insuranceState, handleUpdate, handleAddCPT, removeInsurance, removeCPT }) => {
  const dispatch = useDispatch();
  const search_insurances = useSelector((state) => state.newPatient.search_insurance_companies);
  const loading_search_insurance_companies = useSelector((state) => state.procedureDetail.loading_search_insurance_companies);
  const [localLoading, setLocalLoading] = useState(false);
  const loadingSearchCPT = useSelector((state) => state.common.loadingSearchCPT);
  const [selectResult, setSelectResult] = useState([]);
  const [selectSearch, setSelectSearch] = useState(null);
  const selectData = useSelector((state) => state.common.searchCPT);

  const searchHandle = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(search_insurance_companies(value));
      setLocalLoading(false);
    }, 500);
  };

  useEffect(() => {
    dispatch(search_insurance_companies(""));
  }, []);

  useEffect(() => {
    if (!localLoading) {
      setSelectResult([...selectData]);
    }
  }, [selectData, localLoading]);

  const handleSearch = async (value) => {
    setSelectSearch(value);
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(search_cpt_code(value, 1));
      setLocalLoading(false);
    }, 500);
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      <ScrollWrapper css="no-padding x-hidden">
        <div style={{ paddingTop: "8px" }}>
          {(insuranceState || []).map((r, index) => (
            <React.Fragment key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  marginBottom: "8px",
                  marginTop: index > 0 ? "12px" : 0,
                  paddingTop: index > 0 ? "12px" : 0,
                }}
              >
                <div style={{ width: "calc(100% - 40px)", marginRight: "8px" }}>
                  <EditableFormInput
                    fullWidth
                    label={"Search Insurance"}
                    hideIconSelect={true}
                    type={"check"}
                    value={r?.insurance_name}
                    options={search_insurances}
                    autoClearSearchValue={false}
                    onChangeSearch={(value) => {
                      searchHandle(value);
                    }}
                    handleChange={(value) => {
                      handleUpdate("insurance_company_id", index, value);
                      handleUpdate("insurance_name", index, search_insurances && value && search_insurances.find((r) => r?.id == value)?.name);
                    }}
                    notFoundContent={
                      localLoading || loading_search_insurance_companies ? (
                        <div style={{ height: 100 }}>
                          <Loading />
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    optionKey={"id"}
                    valueKey={"name"}
                  />
                </div>

                <div
                  style={{
                    width: "32px",
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "0.75rem",
                  }}
                  onClick={() => {
                    handleAddCPT(index);
                  }}
                >
                  <button className="text-btn" style={{ marginLeft: "1rem" }}>
                    CPT+
                  </button>
                </div>
                <div
                  style={{
                    width: "32px",
                    cursor: "pointer",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: "0.75rem",
                  }}
                  onClick={() => removeInsurance(index)}
                >
                  <Icon type="delete" style={{ fontSize: "16px", marginLeft: "16px" }} />
                </div>
              </div>
              <div style={{ marginBottom: 10 }} className="resourceInfo d-flex"></div>
              {(r?.dataCPT || []).map((item, indexItem) => {
                return (
                  <div
                    key={`${index}-${indexItem}`}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginBottom: "8px",
                    }}
                  >
                    <div style={{ width: "calc(50% - 23px)", marginRight: "8px" }} className="CPT-AIO-css">
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        value={item.cpt}
                        placeholder="Search CPT Code"
                        onSearch={(value) => {
                          handleSearch(value);
                        }}
                        onBlur={() => {
                          handleSearch(false);
                        }}
                        autoClearSearchValue={false}
                        suffixIcon={<ExpandSVG />}
                        allowClear={false}
                        onChange={(value) => {
                          handleUpdate("cpt", index, value, indexItem);
                        }}
                      >
                        {(selectResult || []).map((r, index) => (
                          <Select.Option key={r?.id} value={r?.id}>
                            {r.code} {r.short_desc}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div style={{ width: "calc(50% - 23px)", marginRight: "8px" }}>
                      <EditableFormInput
                        fullWidth
                        label={"Percentage"}
                        value={item.percentage}
                        handleChange={(value) => handleUpdate("percentage", index, value, indexItem)}
                      />
                    </div>
                    <div
                      style={{
                        width: "32px",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingRight: "0.75rem",
                      }}
                      onClick={() => removeCPT(index, indexItem)}
                    >
                      <Icon type="delete" style={{ fontSize: "16px", marginLeft: "16px" }} />
                    </div>
                  </div>
                );
              })}
              <hr />
            </React.Fragment>
          ))}
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default OBLOptimizer;
