import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG } from "src/common/components/icons/SVGIcon";
import Loading from "src/common/components/Loading";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { search_cpt_code, setGlobalLoading, setGlobalToastr } from "../../../store/actions";
import EditableFormInput from "src/common/components/EditableText/EditableFormInput";
import CheckGroups from "../../../helpers/GroupsAI";
import { create_cpt_utilization_rate, delete_cpt_utilization_rate, update_cpt_utilization_rate } from "src/services/api.services";
const { Option } = Select;
let timer = null;

const Utilization = ({ CPTAI, setUtilization, utilization }) => {
  const [activeDeleteQuestion, setActiveDeleteQuestion] = useState(null);
  const [utilizationLocal, setUtilizationLocal] = useState([]);
  const [dataSort, setDataSort] = useState({});
  const dispatch = useDispatch();

  function flattenSortedData(sortedData) {
    return Object.values(sortedData).flat();
  }

  function sortDataByGroups(data) {
    const sortedData = {
      New: [],
      Iliac: [],
      "Femoral/Popliteal": [],
      "Tibial/Peroneal": [],
      Diagnostic: [],
      Catheterization: [],
    };
    data.forEach((item) => {
      const cptCode = item?.cpt_reimbursement?.cpt_code?.code ? parseInt(item.cpt_reimbursement.cpt_code.code) : null;
      let found = false;

      for (const [group, codes] of Object.entries(CheckGroups)) {
        if (codes.includes(cptCode)) {
          sortedData[group].push(item);
          found = true;
          break;
        }
      }

      if (!found) {
        sortedData.New.push(item);
      }
    });
    return Object.fromEntries(Object.entries(sortedData).filter(([_, items]) => items.length > 0));
  }

  useEffect(() => {
    setDataSort(sortDataByGroups(utilizationLocal));
  }, [utilizationLocal]);

  useEffect(() => {
    setUtilizationLocal(utilization);

    return () => {
      setUtilizationLocal([]);
    };
  }, [utilization]);

  const handleUpdateAPI = async (data, index) => {
    dispatch(setGlobalLoading(true));
    try {
      const updateSuccess = await update_cpt_utilization_rate(data, data?.id);
      const tempVariable = [...utilizationLocal];
      if (updateSuccess && updateSuccess?.id) {
        const objFix = {
          ...updateSuccess,
        };
        tempVariable.splice(index, 1, objFix);
        setUtilization([...tempVariable]);
        dispatch(setGlobalLoading(false));
        dispatch(
          setGlobalToastr({
            header: "Alert",
            message: "Update Successfully!",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };

  const handleCreateAPI = async (data, index) => {
    dispatch(setGlobalLoading(true));
    try {
      const createSuccess = await create_cpt_utilization_rate(data);
      const tempVariable = [...utilizationLocal];
      if (createSuccess && createSuccess?.id) {
        const objFix = {
          ...createSuccess,
        };
        tempVariable.splice(index, 1, objFix);
        setUtilization([...tempVariable]);
        dispatch(setGlobalLoading(false));
        dispatch(
          setGlobalToastr({
            header: "Alert",
            message: "Create Successfully!",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };

  const updateProviders = async (type, index, value, row) => {
    // create new
    if (!row?.id) {
      const tempVariable = [...utilizationLocal];
      if (type === "cpt_reimbursement_id") {
        tempVariable[index][type] = value;
        if (value && CPTAI) {
          tempVariable[index]["cpt_reimbursement_id"] = value;
          setUtilizationLocal([...tempVariable]);
        }
      }
      if (row["cpt_reimbursement_id"] && row["ideal_utilization_rate"]) {
        handleCreateAPI(tempVariable[index], index);
      }
    } else {
      const tempVariable = [...utilizationLocal];
      if (type === "cpt_reimbursement_id") {
        tempVariable[index][type] = value;
        if (value && CPTAI) {
          tempVariable[index]["cpt_reimbursement_id"] = value;
          setUtilizationLocal([...tempVariable]);
        }
      }
      if (row["cpt_reimbursement_id"] && row["ideal_utilization_rate"]) {
        handleUpdateAPI(tempVariable[index], index);
      }
    }
  };

  const removeProvider = async (index, id, groupName) => {
    if (!activeDeleteQuestion) {
      setActiveDeleteQuestion(`cpt-${index}-${id}`);
    } else {
      if (activeDeleteQuestion === `cpt-${index}-${id}`) {
        if (id) {
          dispatch(setGlobalLoading(true));
          try {
            const deleteSuccess = await delete_cpt_utilization_rate(id);
            if (deleteSuccess) {
              dataSort[groupName] = dataSort[groupName].filter((_, i) => i !== index);
              setDataSort({ ...dataSort });
              const dataJoinSort = flattenSortedData(dataSort);
              console.log(dataJoinSort, "dataJoinSort");
              setUtilizationLocal([...dataJoinSort]);
              setUtilization([...dataJoinSort]);
              dispatch(
                setGlobalToastr({
                  header: "Alert",
                  message: "Remove Successfully!",
                  type: "success",
                })
              );
              setActiveDeleteQuestion(null);
            }
          } catch (error) {
            dispatch(
              setGlobalToastr({
                header: "Alert",
                message: `${error?.message}`,
                type: "failed",
              })
            );
          }
          dispatch(setGlobalLoading(false));
        } else {
          dataSort[groupName] = dataSort[groupName].filter((_, i) => i !== index);
          setDataSort({ ...dataSort });
          const dataJoinSort = flattenSortedData(dataSort);
          setUtilizationLocal([...dataJoinSort]);
          setUtilization([...dataJoinSort]);
          dispatch(
            setGlobalToastr({
              header: "Alert",
              message: "Remove Successfully!",
              type: "success",
            })
          );
          setActiveDeleteQuestion(null);
        }
      } else {
        setActiveDeleteQuestion(`cpt-${index}-${id}`);
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {Object.entries(dataSort).map(([groupName, groupItems]) => (
        <div key={groupName}>
          {/* Group Label */}
          <div
            style={{
              padding: "8px 12px",
              background: "#f0f4f8",
              borderBottom: "1px solid #dbe3ee",
              fontWeight: "bold",
              fontSize: "16px",
              color: "#333",
            }}
          >
            {groupName} ({groupItems.length} items)
          </div>

          {/* Group Items */}
          {(groupItems || []).map((r, index) => (
            <div
              key={index}
              style={{
                background: "#dbe3ee",
                height: "auto",
                padding: 12,
                marginBottom: index !== groupItems.length - 1 ? "1rem" : "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  margin: "5px 0 8px",
                }}
              >
                <div style={{ width: "calc(100% - 32px)", marginRight: "8px", display: "flex", justifyContent: "space-between" }}>
                  <div style={{ width: "calc(35% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Select
                      key={`key-${index}`}
                      showSearch
                      placeholder="Search CPT"
                      value={r?.cpt_reimbursement_id}
                      autoClearSearchValue={false}
                      suffixIcon={<ExpandSVG />}
                      allowClear={false}
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        updateProviders(
                          "cpt_reimbursement_id",
                          utilizationLocal.findIndex((item) => item.id === r.id),
                          value,
                          r
                        );
                      }}
                      style={{ width: "100%", borderBottom: 0 }}
                    >
                      {(CPTAI || []).map((r, index) => {
                        if (!r?.id) return null;
                        return (
                          <Select.Option key={r?.id} value={r?.id}>
                            {r?.cpt?.cpt_code_name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div style={{ width: "calc(25% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <EditableFormInput
                      unit={"%"}
                      required={true}
                      label="Utilization Rate"
                      value={r.ideal_utilization_rate}
                      onCustomBlur={(value) => {
                        updateProviders(
                          "ideal_utilization_rate",
                          utilizationLocal.findIndex((item) => item.id === r.id),
                          value,
                          r
                        );
                      }}
                      fullWidth
                      focusWhite
                      handleChange={(value) => {
                        setUtilizationLocal([
                          ...utilizationLocal.map((row) => {
                            if (row.id === r.id) {
                              return { ...row, ideal_utilization_rate: value };
                            }
                            return row;
                          }),
                        ]);
                      }}
                    />
                  </div>
                  <div style={{ width: "calc(40% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div
                      style={{
                        width: "calc(33.3% - 10px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 6,
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          color: "#005ef6",
                          marginTop: -5,
                        }}
                      >
                        <p>1 month</p>
                      </div>
                      <div
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          color: "#005ef6",
                          marginTop: -5,
                        }}
                      >
                        <p>{r?.current_utilization_rate ? Math.round(r?.current_utilization_rate * 100) : 0}%</p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "calc(33.3% - 10px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 6,
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          color: "#005ef6",
                          marginTop: -5,
                        }}
                      >
                        <p>6 months</p>
                      </div>
                      <div
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          color: "#005ef6",
                          marginTop: -5,
                        }}
                      >
                        <p>{r?.["6_month_utilization_rate"] ? Math.round(r?.["6_month_utilization_rate"] * 100) : 0}%</p>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "calc(33.3% - 10px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 6,
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          color: "#005ef6",
                          marginTop: -5,
                        }}
                      >
                        <p>1 year</p>
                      </div>
                      <div
                        style={{
                          justifyContent: "flex-end",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          color: "#005ef6",
                          marginTop: -5,
                        }}
                      >
                        <p>{r?.["12_month_utilization_rate"] ? Math.round(r?.["12_month_utilization_rate"] * 100) : 0}%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <DeleteIcon
                  icon="can"
                  selected={activeDeleteQuestion === `cpt-${index}-${r?.id}`}
                  handleClick={() => removeProvider(index, r?.id, groupName)}
                />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Utilization;
