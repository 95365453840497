import { DatePicker, Input, Select } from "antd";
import React, { useState } from "react";
import AutoCompleteSearch from "../../../../common/components/AutoComplete/AutoCompleteSearch";
import { ExpandSVG } from "../../../../common/components/icons/SVGIcon";
import { bulkFinalize } from "../../../../services/api.services";

const { Search } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

const MeaningfulUseFilter = ({ autoCompleteOptions, valueSearch, providers, onChangeDate, onChangeProvider }) => {
  const [search, setSearch] = useState("");

  const changeSearch = (value) => {
    setSearch(value);
    valueSearch(value);
  };

  const handleItemClick = (item) => {
    const { first_name, last_name } = item;
    const value = `${first_name} ${last_name}`;
    if (value) {
      valueSearch(value);
    }
  };

  return (
    <div className="filterContainer">
      <div className="searchFieldContainer">
        <div className="searchField">
          <AutoCompleteSearch
            isEmployeeWorkFlow={true}
            handlePopulate={(item) => handleItemClick(item)}
            title="Patient-Search"
            options={autoCompleteOptions}
            handleSearch={(value) => changeSearch(value)}
            placeholder="Search Patient"
            keyLabel="patient_name"
            actionLabel="Search"
            backgroundWhite={"white"}
          />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter" style={{ minWidth: "200px" }}>
          <DatePicker format={dateFormat} placeholder="Filter by DOS" suffixIcon={<ExpandSVG />} onChange={onChangeDate} />
        </div>
      </div>

      <div className="statusFilterContainer">
        <div className="statusFilter">
          <button
            className="common-btn blue-btn"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={bulkFinalize}
          >
            <span>Bulk Finalize</span>
            <span>+</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MeaningfulUseFilter;
