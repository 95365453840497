import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG } from "src/common/components/icons/SVGIcon";
import Loading from "src/common/components/Loading";
import { deleteReimbursementVariables, updateReimbursementVariables } from "src/services/api.services";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { search_cpt_code, setGlobalLoading, setGlobalToastr } from "../../../store/actions";
const { Option } = Select;
let timer = null;

const CptCodeLogicAI = ({ CPTAI, rowAction, setCPTAI, isCPTSelected }) => {
  const [activeDeleteQuestion, setActiveDeleteQuestion] = useState(null);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [selectResult, setSelectResult] = useState([]);
  const [page, setPage] = useState(1);
  const selectData = useSelector((state) => state.common.searchCPT);
  const loadingSearchCPT = useSelector((state) => state.common.loadingSearchCPT);
  const [CPT, setCPT] = useState([]);
  const [focusState, setFocusState] = useState(false);
  const [selectSearch, setSelectSearch] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setCPT(CPTAI);

    return () => {
      setCPT([]);
    };
  }, [CPTAI]);

  useEffect(() => {
    if (rowAction) {
      rowAction({
        index: null,
        selectedVariable: null,
      });
    }
  }, []);

  const handleClickRow = (row, index) => {
    if (rowAction) {
      rowAction({ index, selectedVariable: row });
    }
  };

  const handleUpdateAPI = async (data, index) => {
    dispatch(setGlobalLoading(true));
    try {
      const updateSuccess = await updateReimbursementVariables(data, data?.id);
      const tempVariable = [...CPT];
      if (updateSuccess && updateSuccess?.id) {
        const objFix = {
          ...updateSuccess,
          cpt: updateSuccess?.cpt_code,
          cpts: (updateSuccess?.addons || []).map((row) => {
            return {
              ...row,
              cpt: row?.cpt_code,
              amount: row?.pivot?.amount,
              cpt_code_id: row?.id,
              cpt_key: row?.cpt_code && row?.short_desc ? `${row?.short_desc} - ${row?.cpt_code}` : null,
            };
          }),
          cpt_key:
            updateSuccess?.cpt_code?.cpt_code && updateSuccess?.cpt_code?.short_desc
              ? `${updateSuccess?.cpt_code?.short_desc} - ${updateSuccess?.cpt_code?.cpt_code}`
              : null,
        };

        tempVariable.splice(index, 1, objFix);
        setCPTAI([...tempVariable]);
        dispatch(setGlobalLoading(false));
        dispatch(
          setGlobalToastr({
            header: "Alert",
            message: "update Successfully!",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };

  const updateProviders = async (type, index, value, row, status, inx) => {
    const tempVariable = [...CPT];
    if (status && tempVariable[index]["cpts"][inx]) {
      if (type === "cpt_key") {
        tempVariable[index]["cpts"][inx][type] = value;
        if (value && selectResult) {
          const idKey = value.split(" - ")[0];
          const nameKey = value.split(" - ")[1];
          const findItem = selectResult.find((r) => r?.cpt_code == idKey && r?.short_desc == nameKey);
          if (findItem) {
            tempVariable[index]["cpts"][inx]["cpt_code_id"] = findItem?.id;
          }
        }
        setCPT([...tempVariable]);
        if (tempVariable[index]["cpts"][inx]) {
          handleUpdateAPI(tempVariable[index], index);
        }
      } else {
        tempVariable[index]["cpts"][inx][type] = value;
        setCPT([...tempVariable]);
      }
      return;
    }
    if (type === "cpt_key") {
      tempVariable[index][type] = value;
      if (value && selectResult) {
        const idKey = value.split(" - ")[0];
        const nameKey = value.split(" - ")[1];
        const findItem = selectResult.find((r) => r?.cpt_code == idKey && r?.short_desc == nameKey);
        if (findItem) {
          tempVariable[index]["cpt_code_id"] = findItem?.id;
          tempVariable[index]["cpt"] = findItem;
          tempVariable[index]["cpt_name"] = findItem?.short_desc;
        }
      }
      setCPT([...tempVariable]);
      if (tempVariable[index]) {
        handleUpdateAPI(tempVariable[index], index);
      }
    } else {
      tempVariable[index][type] = value;
      setCPT([...tempVariable]);
    }
  };

  const removeProvider = async (type, index, id, inx) => {
    if (!activeDeleteQuestion) {
      setActiveDeleteQuestion(`cpt-${index}-${inx}`);
    } else {
      if (activeDeleteQuestion === `cpt-${index}-${inx}`) {
        if (id) {
          dispatch(setGlobalLoading(true));
          try {
            const tempVariable = [...CPT];
            if ((inx || inx == 0) && tempVariable[index]["cpts"]) {
              tempVariable[index]["cpts"] = (tempVariable[index]["cpts"] || []).filter((r, i) => i !== inx);
              if (tempVariable[index]) {
                handleUpdateAPI(tempVariable[index], index);
              }
              setActiveDeleteQuestion(null);
            } else {
              tempVariable.splice(index, 1);
              const deleteSuccess = await deleteReimbursementVariables(id);
              if (deleteSuccess) {
                dispatch(
                  setGlobalToastr({
                    header: "Alert",
                    message: "Remove Successfully!",
                    type: "success",
                  })
                );
                setActiveDeleteQuestion(null);
              }
            }
            setCPT([...tempVariable]);
            setCPTAI([...tempVariable]);
          } catch (error) {
            dispatch(
              setGlobalToastr({
                header: "Alert",
                message: `${error?.message}`,
                type: "failed",
              })
            );
          }
          dispatch(setGlobalLoading(false));
        }
      } else {
        setActiveDeleteQuestion(`cpt-${index}-${inx}`);
      }
    }
  };

  useEffect(() => {
    if (!loadingLocal) {
      setSelectResult(selectData);
    }
  }, [selectData]);

  useEffect(() => {
    clearTimeout(timer);
    setLoadingLocal(true);
    setSelectResult([]);
    setPage(1);
    timer = setTimeout(() => {
      dispatch(search_cpt_code(selectSearch, page));
      setLoadingLocal(false);
    }, 1000);
  }, [selectSearch]);
  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {CPT?.map((r, index) => (
        <div
          key={index}
          style={{
            background: "#dbe3ee",
            padding: 12,
            border: index == isCPTSelected?.index ? "2px solid rgb(15, 98, 254)" : "",
            marginBottom: index !== CPT?.length - 1 ? "1rem" : 0,
          }}
          onClick={() => handleClickRow(r, index)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5px 0 8px",
            }}
          >
            <div style={{ width: "calc(100% - 42px)", marginRight: "8px", display: "flex", justifyContent: "space-between" }}>
              {/*  */}
              <div style={{ width: "calc(50% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Select
                  key={`key-${index}`}
                  showSearch
                  placeholder="Search CPT Code"
                  value={r?.cpt_key}
                  onSearch={(value) => {
                    setSelectSearch(value);
                  }}
                  onDropdownVisibleChange={(open) => {
                    setFocusState(open);
                  }}
                  onBlur={() => {
                    setSelectSearch(null);
                  }}
                  notFoundContent={
                    loadingLocal || loadingSearchCPT ? (
                      <div style={{ height: 100 }}>
                        <Loading />
                      </div>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  autoClearSearchValue={false}
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  onChange={(value) => {
                    updateProviders("cpt_key", index, value, r);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {(selectResult || []).map((r, index) => (
                    <Select.Option key={r?.id} value={`${r.code} - ${r.short_desc}`}>
                      {r.code} {r.short_desc}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="fix-height" style={{ width: "calc(50% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <EditableFormInput
                  required={true}
                  label="Reimbursement Cost"
                  value={r.amount}
                  onCustomBlur={() => {
                    if (CPT[index]) {
                      handleUpdateAPI(CPT[index], index);
                    }
                  }}
                  fullWidth
                  focusWhite
                  handleChange={(value) => {
                    updateProviders("amount", index, value, r);
                  }}
                />
              </div>
              {/*  */}
            </div>
            <DeleteIcon
              icon="can"
              selected={activeDeleteQuestion === `cpt-${index}-undefined`}
              handleClick={() => removeProvider("question", index, r?.id)}
            />
          </div>
          {(r?.cpts || []).map((cp, inx) => (
            <div
              key={inx}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                margin: "5px 0 8px",
              }}
            >
              <div style={{ width: "calc(100% - 42px)", marginRight: "8px", display: "flex", justifyContent: "space-between" }}>
                {/*  */}
                <div style={{ width: "calc(50% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Select
                    key={`key-${inx}`}
                    showSearch
                    placeholder="Search CPT Code"
                    value={cp?.cpt_key}
                    onSearch={(value) => {
                      setSelectSearch(value);
                    }}
                    onDropdownVisibleChange={(open) => {
                      setFocusState(open);
                    }}
                    onBlur={() => {
                      setSelectSearch(null);
                    }}
                    notFoundContent={
                      loadingLocal || loadingSearchCPT ? (
                        <div style={{ height: 100 }}>
                          <Loading />
                        </div>
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )
                    }
                    autoClearSearchValue={false}
                    suffixIcon={<ExpandSVG />}
                    allowClear={false}
                    onChange={(value) => {
                      updateProviders("cpt_key", index, value, r, true, inx);
                    }}
                    style={{ width: "100%", borderBottom: 0 }}
                  >
                    {(selectResult || []).map((r, index) => (
                      <Select.Option key={r?.id} value={`${r.code} - ${r.short_desc}`}>
                        {r.code} {r.short_desc}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div style={{ width: "calc(50% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <EditableFormInput
                    required={true}
                    label="Reimbursement Cost"
                    value={cp.amount}
                    onCustomBlur={() => {
                      if (CPT[index]["cpts"][inx]) {
                        handleUpdateAPI(CPT[index], index);
                      }
                    }}
                    fullWidth
                    focusWhite
                    handleChange={(value) => {
                      updateProviders("amount", index, value, r, true, inx);
                    }}
                  />
                </div>
                {/*  */}
              </div>
              <DeleteIcon
                selected={activeDeleteQuestion === `cpt-${index}-${inx}`}
                handleClick={() => removeProvider("question", index, r?.id, inx)}
              />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CptCodeLogicAI;
