import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { setGlobalLoading } from "../../../../store/actions";
import dataHeader from "./formarterData";
import dataHeaderLE from "./formarterDataLE";
import "../../../../common/components/TableDrafJS/Table.css";
import BlockComponent from "../../../../common/components/TableDrafJS/BlockComponent";
import createTable from "../../../../common/components/TableDrafJS/createTable";
import { getLocal } from "../../../../helpers/Local";
import CreateTextN from "src/helpers/CreateTextN";
import RenderFixStrongAndSpaceReview from "../../../../helpers/RenderFixStrongAndSpaceReview";

let timer = null;
const ReviewText = ({ changeValueText, dataCPT, nameUltrasound, nameSignal, finalizedDate, finalizedDateDate, providerId, providerName }) => {
  const [authName, setAuthName] = useState(null);
  const appointment_ultrasound_id = getLocal("appointment_ultrasound_id");
  const [ultrasounds_store, set_ultrasounds_store] = useState([]);
  const [loading, setLoading] = useState(false);

  const detailUltrasounds = useSelector((state) => state.common.detailUltrasounds);
  const text_template_redux = useSelector((state) => state.clinicProviderReview.text_template);
  const ultrasound_discussion_text = useSelector((state) => state.clinicProviderReview.ultrasound_discussion_text);
  const CPT = useSelector((state) => state.common.CPT);

  const ultrasound_finding = useSelector((state) => state.procedureDetail.ultrasound_type);

  const ultrasound_value = useSelector((state) => state.common.ultrasound_value);
  let contentState = null;
  let editorState = null;

  if (
    (detailUltrasounds && detailUltrasounds?.id === 5) ||
    detailUltrasounds?.id === 2 ||
    detailUltrasounds?.id === 4 ||
    detailUltrasounds?.id === 8 ||
    detailUltrasounds?.id === 10 ||
    detailUltrasounds?.id === 11 ||
    detailUltrasounds?.id === 13 ||
    detailUltrasounds?.id === 15 ||
    detailUltrasounds?.id === 24 ||
    detailUltrasounds?.id === 37 ||
    detailUltrasounds?.id === 42
  ) {
    contentState = convertFromRaw(JSON.parse(dataHeaderLE.text_template));
    editorState = EditorState.createWithContent(contentState);
  } else {
    contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
    editorState = EditorState.createWithContent(contentState);
  }

  const [text, setText] = useState("");
  const [editorData, setEditorData] = useState(editorState);
  const editorDataRef = useRef(null);
  const dispatch = useDispatch();
  //
  const [rowsLeft, set_rowsLeft] = useState([]);

  useEffect(() => {
    if (CPT?.provider_name && CPT?.provider_name.length > 0) {
      setAuthName(CPT?.provider_name);
    }
  }, [CPT]);

  useEffect(() => {
    setAuthName(nameUltrasound);
  }, [nameUltrasound]);

  useEffect(() => {
    let arrayRow = [];
    let arrayLabel = [];
    let ArrayHeader = [];
    const rowsL = [];
    if (detailUltrasounds && Object.keys(detailUltrasounds).length > 0) {
      if (ultrasounds_store && ultrasounds_store.length > 0) {
        arrayRow = ultrasounds_store.filter((r) => r.finding_name.includes("Left"));
        arrayRow = arrayRow.map((r) => {
          return {
            label: r.finding_name.replace("Left ", ""),
            [`${detailUltrasounds.headers[0]}`]: r[`${detailUltrasounds.headers[0]}`],
            [`${detailUltrasounds.headers[1]}`]: r[`${detailUltrasounds.headers[1]}`],
            [`${detailUltrasounds.headers[2]}`]: r[`${detailUltrasounds.headers[2]}`],
            [`${detailUltrasounds.headers[3]}`]: r[`${detailUltrasounds.headers[3]}`],
            [`${detailUltrasounds.headers[4]}`]: r[`${detailUltrasounds.headers[4]}`],
            [`${detailUltrasounds.headers[5]}`]: r[`${detailUltrasounds.headers[5]}`],
            [`${detailUltrasounds.headers[6]}`]: r[`${detailUltrasounds.headers[6]}`],
            [`${detailUltrasounds.headers[7]}`]: r[`${detailUltrasounds.headers[7]}`],
          };
        });
      }
      //
      if (detailUltrasounds && detailUltrasounds.headers) {
        if (detailUltrasounds?.headers.some((r) => r == "PSV/EDV")) {
          const arrayFilter = detailUltrasounds?.headers.filter((r) => r !== "PSV/EDV");
          ArrayHeader = ["", "PSV", "EDV", ...arrayFilter];
        } else {
          ArrayHeader = ["", ...detailUltrasounds?.headers];
        }
      }
      //
      for (let i = 0; i < arrayRow.length; i += 1) {
        arrayLabel = {
          ...arrayLabel,
          [0]: ArrayHeader,
          [i + 1]: [
            arrayRow[i]["label"],
            arrayRow[i][`${detailUltrasounds.headers[0]}`],
            arrayRow[i][`${detailUltrasounds.headers[1]}`],
            arrayRow[i][`${detailUltrasounds.headers[2]}`],
            arrayRow[i][`${detailUltrasounds.headers[3]}`],
            arrayRow[i][`${detailUltrasounds.headers[4]}`],
            arrayRow[i][`${detailUltrasounds.headers[5]}`],
            arrayRow[i][`${detailUltrasounds.headers[6]}`],
            arrayRow[i][`${detailUltrasounds.headers[7]}`],
          ],
        };
      }

      if (arrayRow.length + 1 > 1) {
        for (let i = 0; i < arrayRow.length + 1; i += 1) {
          const cols = [];
          for (let j = 0; j < ArrayHeader.length; j += 1) {
            cols.push(arrayLabel && arrayLabel[i] && arrayLabel[i][j]);
          }
          rowsL.push(cols);
        }
      }
      set_rowsLeft(rowsL);
    }
  }, [detailUltrasounds, ultrasounds_store]);

  // table
  const blockRenderer = (block) => {
    if (block.getType() === "atomic" && detailUltrasounds && ultrasound_finding && ultrasounds_store) {
      return {
        component: (props) => {
          return (
            <BlockComponent
              detailUltrasounds={detailUltrasounds}
              ultrasound_finding={ultrasound_finding}
              ultrasound_value={ultrasounds_store}
              props={props}
            ></BlockComponent>
          );
        },
        editable: true,
      };
    }
    return null;
  };

  const onChange = async (editorState) => {
    if (!loading) {
      const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      setText(editorState);
      editorDataRef.current = data;
      changeValueText(data);
    }
  };

  useEffect(() => {
    // map value
    if (
      ultrasound_finding &&
      ultrasound_value &&
      Object.keys(ultrasound_value).length > 0 &&
      detailUltrasounds &&
      Object.keys(detailUltrasounds).length > 0
    ) {
      const check = (ultrasound_finding || []).map((r) => {
        return {
          finding_name:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`]["finding_name"]
              ? ultrasound_value[`${r?.id}`]["finding_name"]
              : "",
          finding_id:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`]["finding_id"]
              ? ultrasound_value[`${r?.id}`]["finding_id"]
              : "",
          appointment_id: appointment_ultrasound_id,
          [`${detailUltrasounds?.headers[0]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[0]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[0]}`]
              : "",
          [`${detailUltrasounds?.headers[1]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[1]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[1]}`]
              : "",
          [`${detailUltrasounds?.headers[2]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[2]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[2]}`]
              : "",
          [`${detailUltrasounds?.headers[3]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[3]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[3]}`]
              : "",
          [`${detailUltrasounds?.headers[4]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[4]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[4]}`]
              : "",
          [`${detailUltrasounds?.headers[5]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[5]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[5]}`]
              : "",
          [`${detailUltrasounds?.headers[6]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[6]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[6]}`]
              : "",
          [`${detailUltrasounds?.headers[7]}`]:
            ultrasound_value && ultrasound_value[`${r?.id}`] && ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[7]}`]
              ? ultrasound_value[`${r?.id}`][`${detailUltrasounds?.headers[7]}`]
              : "",
        };
      });
      set_ultrasounds_store([...check]);
    }
  }, [ultrasound_value, ultrasound_finding, detailUltrasounds, appointment_ultrasound_id]);

  useEffect(() => {
    if (detailUltrasounds && detailUltrasounds.headers && ultrasounds_store && ultrasounds_store.length > 0) {
      if (
        detailUltrasounds?.id !== 5 &&
        detailUltrasounds?.id !== 2 &&
        detailUltrasounds?.id !== 4 &&
        detailUltrasounds?.id !== 8 &&
        detailUltrasounds?.id !== 10 &&
        detailUltrasounds?.id !== 11 &&
        detailUltrasounds?.id != 13 &&
        detailUltrasounds?.id !== 15 &&
        detailUltrasounds?.id !== 24 &&
        detailUltrasounds?.id !== 37 &&
        detailUltrasounds?.id !== 42
      ) {
        setEditorData(createTable(editorState, detailUltrasounds, ultrasounds_store));
      }
    }
  }, [detailUltrasounds, ultrasounds_store]);

  useEffect(() => {
    if (text) {
      const data = JSON.stringify(convertToRaw(editorData.getCurrentContent()));
      clearTimeout(timer);
      timer = setTimeout(() => {
        // table all
        const [entityTable, ...itemEntityTable] = data && data.split(`"entityMap":{`);
        const checkEntityTable = (itemEntityTable || []).map((item) => {
          return item.split(`}}`)[0];
        });

        if (checkEntityTable && checkEntityTable[0] && text_template_redux && text_template_redux.length > 5000) {
          let mix = "";
          // table in text teamplate
          const [entityTableTemplate, ...itemEntityTableTemplate] = text_template_redux && text_template_redux.split(`"entityMap":{`);
          const checkEntityTableTemplate = (itemEntityTableTemplate || []).map((item) => {
            return item.split(`}}`)[0];
          });
          if (checkEntityTableTemplate && checkEntityTableTemplate[0]) {
            mix = text_template_redux.replace(checkEntityTableTemplate[0], checkEntityTable[0]);
            const contentState = convertFromRaw(JSON.parse(mix));
            const editorState = EditorState.createWithContent(contentState);
            changeValueText(mix);
            clearTimeout(timer);
            timer = setTimeout(() => {
              setText(editorState);
            }, 1200);
          }
        }
      }, 1000);
    }
  }, [text_template_redux]);

  const renderIner = (innerHtml) => {
    // entityTable
    let data = JSON.stringify(convertToRaw(editorData.getCurrentContent()));
    const [entityTable, ...itemEntityTable] = data && data.split(`"entityMap":{`);
    const checkEntityTable = (itemEntityTable || []).map((item) => {
      return item.split(`}}`)[0];
    });
    let provider_text = "";
    let ultrasound_no_text = "";

    /*
    PROVIDER
     */
    if (providerName && providerName.length > 0) {
      provider_text = `{"key":"prvnmc","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      // header 8 Provider
      if (!data.includes(provider_text)) {
        data = data.replace(
          `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${provider_text}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
    }

    if (authName && authName.length > 0) {
      ultrasound_no_text = `{"key":"ulsnd","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      if (!data.includes(ultrasound_no_text)) {
        data = data.replace(
          `{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${ultrasound_no_text}{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
    }
    if (checkEntityTable && checkEntityTable[0]) {
      if (checkEntityTable[0].length > 10) {
        data = data.replace(
          `"entityMap":{${checkEntityTable[0]}}}}}}`,
          `"entityMap":{${checkEntityTable[0]}}}},"1":{"type":"TABLE","mutability":"IMMUTABLE","data":{"row":${rowsLeft.length},"column":${rowsLeft[0].length},"caption":"Left","cell":{${innerHtml}}}}}}`
        );
      }
    }
    // position table
    const [headTable, ...itemPositionTable] =
      data && data.split(`{"key":"cmksa","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
    const checkPositionTable = (itemPositionTable || []).map((item) => {
      return item.split(
        `{"key":"icd8j2","text":"ICD/INDICATION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":14,"style":"BOLD"},{"offset":0,"length":14,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
      )[0];
    });
    if (checkPositionTable && checkPositionTable[0]) {
      if (checkPositionTable[0].length > 0) {
        if (checkPositionTable[0].includes(`"type":"atomic"`)) {
          data = data.replace(checkPositionTable[0], "");
          data = data.replace(
            `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${checkPositionTable[0]}`
          );
        }
      }
    }
    /*
    New stuff for Lower Extremity Arteries
     */
    if (
      detailUltrasounds?.id === 5 ||
      detailUltrasounds?.id === 2 ||
      detailUltrasounds?.id === 4 ||
      detailUltrasounds?.id === 8 ||
      detailUltrasounds?.id === 10 ||
      detailUltrasounds?.id === 11 ||
      detailUltrasounds?.id === 13 ||
      detailUltrasounds?.id === 15 ||
      detailUltrasounds?.id === 24 ||
      detailUltrasounds?.id === 37 ||
      detailUltrasounds?.id === 42
    ) {
      let text_impression = "";
      // make to find if map have IMPRESSION we will active
      let activeImpression = false;
      (ultrasound_discussion_text || []).map((r, index) => {
        if (r === "IMPRESSION") {
          text_impression += `{"key":"discusion${index}","text":"IMPRESSION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`;
          // set active = true we will use createTextN to make !!new!! to new line
          activeImpression = true;
          //
        } else {
          //
          if (activeImpression) {
            let Test = JSON.parse(`[${CreateTextN(`${r}`)}]`) || null;
            if (Test && Test[0]) {
              Test.map((r) => {
                // check if type is RIGHT SIDE: and LEFT SIDE: we will import more space
                if (r.text && (r.text.includes("RIGHT SIDE:") || r.text.includes("LEFT SIDE:"))) {
                  text_impression += `{"key":"showHeaderStart${
                    r?.text
                  }","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${JSON.stringify(
                    r
                  )},{"key":"showHeaderEnd${r?.text}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                } else if (r.text) {
                  // else we will make !!new!! = new space
                  text_impression += `${JSON.stringify(r)},`;
                }
              });
            } else {
              text_impression += `{"key":"textSpaceImpress${index}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          } else {
            // if activeImpression false we just have render text form api
            text_impression += `{"key":"discusion${index}","text":"${r}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }
      });
      if (!data.includes(text_impression)) {
        data = data.replace(
          `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${text_impression}{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
    }
    /*
    End inserting new stuff for Lower extremity
     */
    // header 10 DATE FINALIZED:
    let date_signed_text = "";
    date_signed_text = `{"key":"keydatesigned","text":"${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
    if (date_signed_text && date_signed_text.length > 0 && !data.includes(date_signed_text)) {
      data = data.replace(
        `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
        `${date_signed_text}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
      );
    }

    // for loot cpt
    let cpt_text = `{"key":"txtcptspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
    if (dataCPT.length > 0) {
      for (let i = 0; i < dataCPT.length; i += 1) {
        cpt_text += `{"key":"cpttxt${i}","text":"${dataCPT && dataCPT[i] && dataCPT[i]["cpt_code"]} ${
          dataCPT && dataCPT[i] && dataCPT[i]["short_desc"]
        }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }
    }
    if (cpt_text.length > 0) {
      // header 11 CPT:
      const [header11, ...item11] =
        data &&
        data.split(
          `{"key":"cpt2c5","text":"CPT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":3,"style":"BOLD"},{"offset":0,"length":3,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader11 = (item11 || []).map((item) => {
        return item.split(`{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      if (checkHeader11 && checkHeader11[0] && checkHeader11[0] != "") {
        data = data.replace(
          `${checkHeader11[0]}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data.replace(
          `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
    }

    // for loot icd
    let icd_text = `{"key":"txticdspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
    if (CPT && CPT?.icd_codes && CPT?.icd_codes.length > 0) {
      for (let i = 0; i < CPT?.icd_codes.length; i += 1) {
        icd_text += `{"key":"icdtxt${i}","text":"${
          CPT?.icd_codes && CPT?.icd_codes[i] && CPT?.icd_codes[i]["icd_code_name"]
        }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }
    }
    if (!data.includes(icd_text) && icd_text.length > 0) {
      // header 12 ICD:
      data = data.replace(
        `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
        `${icd_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
      );
    }
    /*
    Do not include RESULTS and CONCLUSION section for Lower Extremity
     */
    // header 3 Right Result:
    if (
      detailUltrasounds?.id !== 5 &&
      detailUltrasounds?.id !== 2 &&
      detailUltrasounds?.id !== 4 &&
      detailUltrasounds?.id !== 8 &&
      detailUltrasounds?.id !== 10 &&
      detailUltrasounds?.id !== 11 &&
      detailUltrasounds?.id != 13 &&
      detailUltrasounds?.id !== 15 &&
      detailUltrasounds?.id !== 24 &&
      detailUltrasounds?.id !== 37 &&
      detailUltrasounds?.id !== 42
    ) {
      let result_r = "";
      if (CPT && CPT?.right_result) {
        result_r = `{"key":"rrsdd","text":"${CPT?.right_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        result_r = "";
      }
      if (result_r && result_r.length > 0 && !data.includes(result_r)) {
        data = data.replace(
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${result_r}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }

      // header 4 Left Result:
      let result_l = "";
      if (CPT && CPT?.left_result) {
        result_l = `{"key":"lfsdd","text":"${CPT?.left_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        result_l = "";
      }
      if (result_l && result_l.length > 0 && !data.includes(result_l)) {
        data = data.replace(
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${result_l}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }

      // header 6 Right CONCLUSION::
      let conclision_r = "";
      if (CPT && CPT?.right_conclusion) {
        conclision_r = `{"key":"rcfcsn","text":"${CPT?.right_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        conclision_r = "";
      }
      if (conclision_r && conclision_r.length > 0 && !data.includes(conclision_r)) {
        data = data.replace(
          `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${conclision_r}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }

      // header 7 Left CONCLUSION::
      let conclision_l = "";
      if (CPT && CPT?.left_conclusion) {
        conclision_l = `{"key":"lfscsn","text":"${CPT?.left_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        conclision_l = "";
      }
      if (conclision_l && conclision_l.length > 0 && !data.includes(conclision_l)) {
        data = data.replace(
          `{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${conclision_l}{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
    }

    // header 13 DISCUSSION:
    if (detailUltrasounds && detailUltrasounds.footer_template && detailUltrasounds.footer_template.includes("blocks")) {
      let valueImportFooter = `{"key":"txtdisspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${detailUltrasounds.footer_template.replace(
        `{"blocks":[`,
        ""
      )}`;
      if (valueImportFooter.includes(`"entityMap":[{`)) {
        const [enti, ...entis] = valueImportFooter && valueImportFooter.split(`],"entityMap":[{"`);
        const checkEnti = (entis || []).map((item) => {
          return item.split(`]}`)[0];
        });
        if (valueImportFooter.includes(`],"entityMap":{}}`)) {
          valueImportFooter = valueImportFooter.replace(`],"entityMap":{}}`, "");
        }
        if (checkEnti && checkEnti[0].length > 0) {
          valueImportFooter = valueImportFooter.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
        }
        if (valueImportFooter.includes(`],"entityMap":[]}`)) {
          valueImportFooter = valueImportFooter.replace(`],"entityMap":[]}`, "");
        }
      } else {
        valueImportFooter = valueImportFooter.replace(`],"entityMap":{}}`, "");
      }
      if (valueImportFooter && valueImportFooter.length > 0) {
        data = data.replace(
          `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${valueImportFooter},{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
    }

    // SIGNATURE
    const [headSig, ...itemHeadSig] =
      data && data.split(`{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
    const checkHeadSig = (itemHeadSig || []).map((item) => {
      return item.split(`{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
    });
    const textSigText = `I, ${
      providerName || ""
    } , hereby attest that the medical record entry for ${finalizedDateDate} accurately reflects signatures/notations that I made in my capacity as the provider when I treated/diagnosed the above listed beneficiary. I do hereby attest that this information is true, accurate and complete to the best of my knowledge and I understand that any falsification, omission, or concealment of material fact may subject me to administrative, civil, or criminal liability.`;

    let singalKey = `{"key":"txtporscio","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
        {"key":"textsigelectsing","text":"Electronically signed by ${providerName} on ${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtsigempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textsigsText","text":"${textSigText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

    if (providerName) {
      if (
        providerName.indexOf("Amy") > -1 ||
        providerName.indexOf("Leah") > -1 ||
        providerName.indexOf("Jennifer") > -1 ||
        providerName.indexOf("Pragna") > -1 ||
        providerName.indexOf("Chris") > -1
      ) {
        singalKey += `{"key":"txtporscioempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty3","text":"My collaborating physician is Omar Haqqani, MD.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }
    }
    if (checkHeadSig && checkHeadSig[0] && checkHeadSig[0].length > 0) {
      data = data.replace(
        `${checkHeadSig[0]},{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
        `${singalKey}{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
      );
    } else {
      if (!data.includes(singalKey)) {
        data = data.replace(
          `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
          ${singalKey}`
        );
      }
    }
    const dataMix = RenderFixStrongAndSpaceReview(data);
    if (dataMix) {
      data = JSON.stringify(dataMix);
    }
    const contentState = convertFromRaw(JSON.parse(data));
    const editorState = EditorState.createWithContent(contentState);
    changeValueText(data);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setText(editorState);
    }, 1200);
    if (data && data.length > 5000 && CPT?.icd_codes && CPT?.cpt_codes) {
      setLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  const renderNotIner = () => {
    let data = JSON.stringify(convertToRaw(editorData.getCurrentContent()));
    // for loot cpt
    let cpt_text = `{"key":"txtcptspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
    if (dataCPT.length > 0) {
      for (let i = 0; i < dataCPT.length; i += 1) {
        cpt_text += `{"key":"cpttxt${i}","text":"${dataCPT && dataCPT[i] && dataCPT[i]["cpt_code"]} ${
          dataCPT && dataCPT[i] && dataCPT[i]["short_desc"]
        }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }
    }
    /*
    Insert special text for Lower Extremity
     */
    if (
      detailUltrasounds?.id === 5 ||
      detailUltrasounds?.id === 2 ||
      detailUltrasounds?.id === 4 ||
      detailUltrasounds?.id === 8 ||
      detailUltrasounds?.id === 10 ||
      detailUltrasounds?.id === 11 ||
      detailUltrasounds?.id === 13 ||
      detailUltrasounds?.id === 15 ||
      detailUltrasounds?.id === 24 ||
      detailUltrasounds?.id === 37 ||
      detailUltrasounds?.id === 42
    ) {
      let text_impression = "";
      (ultrasound_discussion_text || []).map((r, index) => {
        if (r === "IMPRESSION") {
          text_impression += `{"key":"discusion${index}","text":"IMPRESSION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`;
        } else {
          text_impression += `{"key":"discusion${index}","text":"${r}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      });
      if (!data.includes(text_impression)) {
        data = data.replace(
          `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${text_impression}{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
    }

    if (providerName && providerName.length > 0) {
      const provider_text = `{"key":"prvnmc","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      // header 8 Provider
      if (!data.includes(provider_text)) {
        data = data.replace(
          `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${provider_text}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
    }

    if (authName && authName.length > 0) {
      const ultrasound_no_text = `{"key":"ulsnd","text":"${authName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      if (!data.includes(ultrasound_no_text)) {
        data = data.replace(
          `{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${ultrasound_no_text}{"key":"text24","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
    }

    /*
    Do not include RESULTS and CONCLUSION section for Lower Extremity
     */
    // header 3 Right Result:
    if (
      detailUltrasounds?.id !== 5 &&
      detailUltrasounds?.id !== 2 &&
      detailUltrasounds?.id !== 4 &&
      detailUltrasounds?.id !== 8 &&
      detailUltrasounds?.id !== 10 &&
      detailUltrasounds?.id !== 11 &&
      detailUltrasounds?.id != 13 &&
      detailUltrasounds?.id !== 15 &&
      detailUltrasounds?.id !== 24 &&
      detailUltrasounds?.id !== 37 &&
      detailUltrasounds?.id !== 42
    ) {
      let result_r = "";
      if (CPT && CPT?.right_result) {
        result_r = `{"key":"rrsdd","text":"${CPT?.right_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        result_r = "";
      }
      if (result_r && result_r.length > 0 && !data.includes(result_r)) {
        data = data.replace(
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${result_r}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }

      // header 4 Left Result:
      let result_l = "";
      if (CPT && CPT?.left_result) {
        result_l = `{"key":"lfsdd","text":"${CPT?.left_result}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        result_l = "";
      }
      if (result_l && result_l.length > 0 && !data.includes(result_l)) {
        data = data.replace(
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${result_l}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }

      // header 6 Right CONCLUSION::
      let conclision_r = "";
      if (CPT && CPT?.right_conclusion) {
        conclision_r = `{"key":"rcfcsn","text":"${CPT?.right_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        conclision_r = "";
      }
      if (conclision_r && conclision_r.length > 0 && !data.includes(conclision_r)) {
        data = data.replace(
          `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${conclision_r}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }

      // header 7 Left CONCLUSION::
      let conclision_l = "";
      if (CPT && CPT?.left_conclusion) {
        conclision_l = `{"key":"lfscsn","text":"${CPT?.left_conclusion}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      } else {
        conclision_l = "";
      }
      if (conclision_l && conclision_l.length > 0 && !data.includes(conclision_l)) {
        data = data.replace(
          `{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${conclision_l}{"key":"text7","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
    }

    // header 13 DISCUSSION:
    if (detailUltrasounds && detailUltrasounds.footer_template && detailUltrasounds.footer_template.includes("blocks")) {
      let valueImportFooter = `{"key":"txtdisspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${detailUltrasounds.footer_template.replace(
        `{"blocks":[`,
        ""
      )}`;
      if (valueImportFooter.includes(`"entityMap":[{`)) {
        const [enti, ...entis] = valueImportFooter && valueImportFooter.split(`],"entityMap":[{"`);
        const checkEnti = (entis || []).map((item) => {
          return item.split(`]}`)[0];
        });
        if (valueImportFooter.includes(`],"entityMap":{}}`)) {
          valueImportFooter = valueImportFooter.replace(`],"entityMap":{}}`, "");
        }
        if (checkEnti && checkEnti[0].length > 0) {
          valueImportFooter = valueImportFooter.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
        }
        if (valueImportFooter.includes(`],"entityMap":[]}`)) {
          valueImportFooter = valueImportFooter.replace(`],"entityMap":[]}`, "");
        }
      } else {
        valueImportFooter = valueImportFooter.replace(`],"entityMap":{}}`, "");
      }
      if (valueImportFooter && valueImportFooter.length > 0) {
        data = data.replace(
          `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${valueImportFooter},{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
    }

    // header 10 DATE FINALIZED:
    let date_signed_text = "";
    date_signed_text = `{"key":"keydatesigned","text":"${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
    if (date_signed_text && date_signed_text.length > 0 && !data.includes(date_signed_text)) {
      data = data.replace(
        `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
        `${date_signed_text}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
      );
    }

    if (cpt_text.length > 0) {
      // header 11 CPT:
      const [header11, ...item11] =
        data &&
        data.split(
          `{"key":"cpt2c5","text":"CPT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":3,"style":"BOLD"},{"offset":0,"length":3,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader11 = (item11 || []).map((item) => {
        return item.split(`{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      if (checkHeader11 && checkHeader11[0] && checkHeader11[0] != "") {
        data = data.replace(
          `${checkHeader11[0]}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data.replace(
          `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
    }
    // for loot icd
    let icd_text = `{"key":"txticdspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
    if (CPT && CPT?.icd_codes && CPT?.icd_codes.length > 0) {
      for (let i = 0; i < CPT?.icd_codes.length; i += 1) {
        icd_text += `{"key":"icdtxt${i}","text":"${
          CPT?.icd_codes && CPT?.icd_codes[i] && CPT?.icd_codes[i]["icd_code_name"]
        }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }
    }
    if (!data.includes(icd_text) && icd_text.length > 0) {
      // header 12 ICD:
      data = data.replace(
        `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
        `${icd_text}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
      );
    }

    // SIGNATURE
    const [headSig, ...itemHeadSig] =
      data && data.split(`{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
    const checkHeadSig = (itemHeadSig || []).map((item) => {
      return item.split(`{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
    });
    const textSigText = `I, ${
      providerName || ""
    } , hereby attest that the medical record entry for ${finalizedDateDate} accurately reflects signatures/notations that I made in my capacity as the provider when I treated/diagnosed the above listed beneficiary. I do hereby attest that this information is true, accurate and complete to the best of my knowledge and I understand that any falsification, omission, or concealment of material fact may subject me to administrative, civil, or criminal liability.`;

    let singalKey = `{"key":"txtporscio","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
        {"key":"textsigelectsing","text":"Electronically signed by ${providerName} on ${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtsigempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textsigsText","text":"${textSigText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

    if (providerName) {
      if (
        providerName.indexOf("Amy") > -1 ||
        providerName.indexOf("Leah") > -1 ||
        providerName.indexOf("Jennifer") > -1 ||
        providerName.indexOf("Pragna") > -1 ||
        providerName.indexOf("Chris") > -1
      ) {
        singalKey += `{"key":"txtporscioempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty3","text":"My collaborating physician is Omar Haqqani, MD.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }
    }
    if (checkHeadSig && checkHeadSig[0] && checkHeadSig[0].length > 0) {
      data = data.replace(
        `${checkHeadSig[0]},{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
        `${singalKey}{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
      );
    } else {
      if (!data.includes(singalKey)) {
        data = data.replace(
          `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
          ${singalKey}`
        );
      }
    }
    const dataMix = RenderFixStrongAndSpaceReview(data);
    if (dataMix) {
      data = JSON.stringify(dataMix);
    }
    //
    const contentState = convertFromRaw(JSON.parse(data));
    const editorState = EditorState.createWithContent(contentState);
    changeValueText(data);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setText(editorState);
    }, 1200);
    if (data && data.length > 5000 && CPT?.icd_codes && CPT?.cpt_codes) {
      setLoading(false);
      dispatch(setGlobalLoading(false));
    }
  };

  useEffect(() => {
    let innerHtml = "";
    setLoading(true);
    dispatch(setGlobalLoading(true));
    if (rowsLeft && rowsLeft.length > 0) {
      for (let i = 0; i < rowsLeft.length; i += 1) {
        innerHtml += `"${i}":${JSON.stringify(rowsLeft[i])}${i == rowsLeft.length - 1 ? "" : ","}`;
      }
      if (innerHtml) {
        // render table form api if have row left
        renderIner(innerHtml);
      }
    } else {
      // render not have table
      renderNotIner();
    }
  }, [
    rowsLeft,
    CPT,
    dataCPT,
    detailUltrasounds,
    authName,
    ultrasound_discussion_text,
    nameSignal,
    finalizedDate,
    finalizedDateDate,
    providerId,
    providerName,
  ]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="RichEditor-editor">
        <Editor
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorStyle={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "100%",
            backgroundColor: "#fff",
          }}
          placeholder="Patient Review"
          editorState={loading ? "" : text}
          blockRendererFn={blockRenderer}
          readOnly={true}
          onEditorStateChange={onChange}
        />
      </div>
    </div>
  );
};

export default ReviewText;
