import { Empty, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandSVG } from "src/common/components/icons/SVGIcon";
import Loading from "src/common/components/Loading";
import DeleteIcon from "../../../common/components/icons/DeleteIconButton";
import { search_cpt_code, setGlobalLoading, setGlobalToastr } from "../../../store/actions";
import { deletecpt_optimize_variables, updatecpt_optimize_variables } from "src/services/api.services";
const { Option } = Select;
let timer = null;

const Cpt_Variable = ({ CPTV, rowAction, setCPTV, conditionVariable, CPTAI }) => {
  const [activeDeleteQuestion, setActiveDeleteQuestion] = useState(null);
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [selectResult, setSelectResult] = useState([]);
  const [CPTLocal, setCPTLocal] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    setCPTLocal(CPTV);

    return () => {
      setCPTLocal([]);
    };
  }, [CPTV]);

  useEffect(() => {
    if (rowAction) {
      rowAction({
        index: null,
        selectedVariable: null,
      });
    }
  }, []);

  const handleClickRow = (row, index) => {
    if (rowAction) {
      rowAction({ index, selectedVariable: row });
    }
  };

  const handleUpdateAPI = async (data, index) => {
    dispatch(setGlobalLoading(true));
    try {
      const id = data.cpt_reimbursement_id;
      delete data.cpt_reimbursement_id;
      const updateSuccess = await updatecpt_optimize_variables(data, id);
      const tempVariable = [...CPTLocal];
      if (updateSuccess && updateSuccess?.id) {
        const objFix = {
          ...updateSuccess,
          variables: (updateSuccess?.optimize_variables || []).map((row) => {
            return row?.id;
          }),
          cpt_reimbursement_id: updateSuccess?.id,
        };
        tempVariable.splice(index, 1, objFix);
        setCPTV([...tempVariable]);
        setCPTLocal([...tempVariable]);
        dispatch(setGlobalLoading(false));
        dispatch(
          setGlobalToastr({
            header: "Alert",
            message: "update Successfully!",
            type: "success",
          })
        );
      }
    } catch (error) {
      dispatch(setGlobalLoading(false));
      dispatch(
        setGlobalToastr({
          header: "Alert",
          message: `${error?.message}`,
          type: "failed",
        })
      );
    }
  };

  const updateProviders = async (type, index, value) => {
    const tempVariable = [...CPTLocal];
    if (type === "cpt_reimbursement_id") {
      tempVariable[index][type] = value;
      if (value && CPTAI) {
        tempVariable[index]["cpt_reimbursement_id"] = value;
        setCPTLocal([...tempVariable]);
      }
    }
    if (type === "variables") {
      tempVariable[index][type] = value;
      setCPTLocal([...tempVariable]);
    }
    if (tempVariable[index]) {
      handleUpdateAPI(tempVariable[index], index);
    }
  };

  const removeProvider = async (type, index, id) => {
    if (!activeDeleteQuestion) {
      setActiveDeleteQuestion(`cpt-${index}`);
    } else {
      if (activeDeleteQuestion === `cpt-${index}`) {
        dispatch(setGlobalLoading(true));
        try {
          const deleteSuccess = await deletecpt_optimize_variables(id);
          if (deleteSuccess) {
            const tempVariable = [...CPTLocal];
            tempVariable.splice(index, 1);
            setCPTLocal([...tempVariable]);
            setCPTV([...tempVariable]);
            dispatch(
              setGlobalToastr({
                header: "Alert",
                message: "Remove Successfully!",
                type: "success",
              })
            );
            setActiveDeleteQuestion(null);
          }
        } catch (error) {
          dispatch(
            setGlobalToastr({
              header: "Alert",
              message: `${error?.message}`,
              type: "failed",
            })
          );
        }
        dispatch(setGlobalLoading(false));
      } else {
        setActiveDeleteQuestion(`cpt-${index}`);
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "scroll" }}>
      {CPTLocal?.map((r, index) => (
        <div
          key={index}
          style={{
            background: "#dbe3ee",
            padding: 12,
            marginBottom: index !== CPTLocal?.length - 1 ? "1rem" : 0,
          }}
          onClick={() => handleClickRow(r, index)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              margin: "5px 0 8px",
            }}
          >
            <div style={{ width: "calc(100% - 32px)", marginRight: "8px", display: "flex", justifyContent: "space-between" }}>
              {/*  */}
              <div style={{ width: "calc(50% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Select
                  key={`key-${index}`}
                  showSearch
                  placeholder="Search CPT"
                  value={r?.cpt_reimbursement_id}
                  autoClearSearchValue={false}
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  onChange={(value) => {
                    updateProviders("cpt_reimbursement_id", index, value, r);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {(CPTAI || []).map((r, index) => {
                    if (!r?.cpt_key) {
                      return null;
                    }
                    return (
                      <Select.Option key={r?.id} value={r?.id}>
                        {r.cpt_key}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div style={{ width: "calc(50% - 10px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Select
                  key={`key-${index}`}
                  showSearch
                  placeholder="Search Variable"
                  value={r?.variables}
                  mode="multiple"
                  className="multiple-select-variable"
                  autoClearSearchValue={false}
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  onChange={(value) => {
                    updateProviders("variables", index, value, r);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {(conditionVariable || []).map((r, index) => {
                    if (!r?.id) {
                      return null;
                    }
                    return (
                      <Select.Option key={r?.id} value={r?.id}>
                        {r?.variable_name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              {/*  */}
            </div>
            <DeleteIcon icon="can" selected={activeDeleteQuestion === `cpt-${index}`} handleClick={() => removeProvider("question", index, r?.id)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cpt_Variable;
