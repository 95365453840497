import _ from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollWrapper from "../../../../common/components/ScrollBar";
import CheckIdAngio from "../../../../helpers/CheckIdAngio";
import { getLocal } from "../../../../helpers/Local";
import {
  angioPatientSnapshotLoadInit,
  detail_angio_type,
  get_intra_answer,
  load_intra_operative,
  load_procedurals,
  patientExaminationLoadInit,
  setSidebarState,
  updateAppointmentFinalizedDate,
  get_cpt_code_review,
  choosePatientOnSchedules,
} from "../../../../store/actions";
import { StaticResource } from "../../constant";
import ReviewSidebar from "./ReviewSidebar";
import ReviewText from "./ReviewText";

const Review = ({ sidebarIcon }) => {
  const dispatch = useDispatch();
  const [isSidebar, setIsSidebar] = useState(true);
  const [activeIntra, setActiveIntra] = useState(false);
  const [valueText, set_valueText] = useState("");
  const [providerId, setProviderId] = useState(0);
  const [providerName, setProviderName] = useState("");
  const [coSign, setCoSign] = useState(0);
  const [finalizedDate, setFinalizedDate] = useState(moment().format("MM/DD/YYYY hh:mm:ss A"));
  const [finalizedDateDate, setFinalizedDateDate] = useState(moment().format("MM/DD/YYYY"));
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const CPT = useSelector((state) => state.common.CPT);
  const providers = useSelector((state) => state.common.only_providers);

  const [dataCPT, set_dataCPT] = useState([]);
  const current_provider_id = getLocal("cvai-current-user-provider-id");
  const current_provider_name = getLocal("cvai-current-user-provider-name");

  const onChangeCPT = (value) => {
    set_dataCPT(value);
  };

  useEffect(() => {
    const procedure_id = getLocal("current_appointment_id");
    const patient_id = getLocal("current_patient_id");
    dispatch(angioPatientSnapshotLoadInit(procedure_id));
    dispatch(choosePatientOnSchedules(patient_id));
    dispatch(load_procedurals(procedure_id));
    dispatch(load_intra_operative(procedure_id));
    dispatch(get_cpt_code_review(procedure_id));
  }, []);

  useEffect(() => {
    if (CPT && CheckIdAngio(CPT?.appointment_type_id) == true) {
      const procedure_id = getLocal("current_appointment_id");
      dispatch(detail_angio_type(CPT?.appointment_type_id));
      dispatch(get_intra_answer(procedure_id));
    }
    if (CPT.finalized_date) {
      setFinalizedDate(moment(CPT.finalized_date).format("MM/DD/YYYY hh:mm:ss A"));
      setFinalizedDateDate(moment(CPT.finalized_date).format("MM/DD/YYYY"));
    }
    if (current_provider_id && current_provider_name && parseInt(current_provider_id) != 110) {
      setProviderId(current_provider_id);
      setProviderName(current_provider_name);
    } else {
      if (CPT.provider_id) {
        setProviderId(CPT.provider_id);
        setProviderName(CPT.provider.value);
      }
    }
  }, [CPT]);

  useEffect(() => {
    if (CPT && CheckIdAngio(CPT?.appointment_type_id) == true) {
      setActiveIntra(true);
    } else {
      setActiveIntra(false);
    }
  }, [CPT]);

  const changeValueText = (value) => {
    set_valueText(value);
  };

  useEffect(() => {
    dispatch(setSidebarState(true));
    if (window.innerWidth <= 768) {
      setIsSidebar(false);
    }
    return () => {
      dispatch(setSidebarState(false));
    };
  }, []);

  const sidebarRef = useRef();

  const handleChangeTime = (value) => {
    const procedure_id = getLocal("current_appointment_id");
    setFinalizedDate(moment(value).format("MM/DD/YYYY hh:mm:ss A"));
    setFinalizedDateDate(moment(value).format("MM/DD/YYYY"));
    dispatch(
      updateAppointmentFinalizedDate(procedure_id, {
        finalized_date: moment(value).format("YYYY-MM-DD HH:mm:ss"),
      })
    );
  };

  const handleChangeProvider = (value) => {
    let found_provider = _.find(providers, function (provider) {
      return provider.id === value;
    });
    if (found_provider) {
      setProviderId(found_provider.id);
      setProviderName(found_provider.value);
    }
  };

  const handleChangeCoSign = (value) => {
    setCoSign(value);
  };

  return (
    <div className="clinicWorkflow__main-section">
      <div className="eventsContainer has-sub-header" style={{ paddingTop: "1rem" }}>
        <ScrollWrapper css="conditionList x-hidden">
          {patientResource && (
            <ReviewText
              coSign={coSign}
              activeIntra={activeIntra}
              dataCPT={dataCPT}
              appointment={CPT}
              finalizedDate={finalizedDate}
              finalizedDateDate={finalizedDateDate}
              providerId={providerId}
              providerName={providerName}
              changeValueText={(value) => {
                changeValueText(value);
              }}
            />
          )}
        </ScrollWrapper>
      </div>
      <ReviewSidebar
        ref={sidebarRef}
        onChangeCPT={(value) => {
          onChangeCPT(value);
        }}
        handleChangeCoSign={handleChangeCoSign}
        sidebarIcon={sidebarIcon}
        resource={StaticResource}
        isShow={isSidebar}
        status_abort={CPT?.appointment_status}
        valueText={valueText}
        finalizedDate={finalizedDate}
        finalizedDateDate={finalizedDateDate}
        handleChangeTime={handleChangeTime}
        handleChangeProvider={handleChangeProvider}
      />
    </div>
  );
};

export default Review;
