import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNewPatient as addPatient, getPrimaryProviderDetailPatient, getSinglePharmacy } from "../../../services/api.services";
import { validateField, validateSteps } from "../../../services/validations";
import {
  autoZip,
  getPending,
  getPharmacies,
  updatePatient as handleUpdatePatient,
  removeZipAuto,
  searchPcpNpe,
  selectPatient,
  setGlobalLoading,
  setStepData,
} from "../../../store/actions";
import { Checkbox } from "antd";

import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import { newPatientOptions, stateOptions } from "../../../common/constants/initialData";

import { Icon } from "antd";
import ToastMessage from "../../../common/components/Toast";
import { RedRequiredCircleSVG } from "../../../common/components/icons/SVGIcon";

const { demographics } = newPatientOptions;

let timer = null;
const FirstStepComponent = ({ checkIsInsurances, handleContinue, setStateAddForm, ...restProps }) => {
  const resource = useSelector((state) => state.newPatient.resource);
  const loading = useSelector((state) => state.newPatient.loadingResource);
  const pcpDataRef = useSelector((state) => state.newPatient.pcpDataRef);
  const loadingSearchPCP = useSelector((state) => state.newPatient.loadingSearchPCP);
  const isUpdated = useSelector((state) => state.newPatient.isUpdated);
  const zipAutoData = useSelector((state) => state.common.zipAuto);
  const errorRest = useSelector((state) => state.common.globalErrorMessage);
  const loadingPharmacies = useSelector((state) => state.common.loadingPharmacies);
  const [dataPCPRef, setDataPCPRef] = useState([]);

  useEffect(() => {
    setDataPCPRef(pcpDataRef);
  }, [pcpDataRef]);
  const tabActive = useSelector((state) => state.clinicProviderReview.tabActive);
  const dispatch = useDispatch();
  const [patient, setPatient] = useState({});
  const [isAddForm, setOpenAddForm] = useState(false);
  const [enableError, setEnableError] = useState(false);
  const [isToastr, setIsToastr] = useState(false);
  const [first, setFirst] = useState(false);
  const [reset, setReset] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [detailProvider, setDetailProvider] = useState(null);
  const [detailProviderPCP, setDetailProviderPCP] = useState(null);
  const [detailPharmacy, setDetailPharmacy] = useState(null);

  const fectchDetailProvider = async () => {
    const detail = await getPrimaryProviderDetailPatient(patient?.referral_provider);
    setDetailProvider(detail);
    const detailPCP = await getPrimaryProviderDetailPatient(patient?.primary_care_provider_id);
    setDetailProviderPCP(detailPCP);
  };

  const fetchPharmacy = async () => {
    const detailP = await getSinglePharmacy(patient?.pharmacy_id);
    setDetailPharmacy(detailP);
  };

  useEffect(() => {
    if (patient?.primary_care_provider_id || patient?.referral_provider) {
      fectchDetailProvider();
    }
    if (patient?.pharmacy_id) {
      fetchPharmacy();
    }
  }, [patient]);

  useEffect(() => {
    if (resource && resource?.id !== patient?.id) {
      let address = "";
      if (resource.address) {
        const splitedAddress = resource.address.split(" ").map((addr) => {
          if (!addr || !addr.length) {
            return "";
          }
          if (addr.length < 2) {
            return addr.charAt(0).toUpperCase();
          }
          return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
        });
        address = splitedAddress.join(" ");
      }
      setPatient({ ...resource, address });
      setOpenAddForm(false);
      setEnableError(true);
      setReset(false);
      setTimeout(() => {
        setReset(true);
      }, 1000);
    }
    if (isUpdated && resource) {
      if (validateSteps(resource, demographics)) {
        dispatch(setStepData("isUpdated", false));
      }
    }
  }, [resource, isUpdated]);

  useEffect(() => {
    if (setStateAddForm) {
      setStateAddForm(isAddForm);
    }
  }, [isAddForm]);

  useEffect(() => {
    setFirst(true);
  }, []);

  const fetchSearchNew = async () => {
    await dispatch(setGlobalLoading(true));
    await dispatch(searchPcpNpe(1, "a"));
    await dispatch(setGlobalLoading(false));
  };

  useEffect(() => {
    fetchSearchNew();
  }, []);

  useEffect(() => {
    dispatch(setGlobalLoading(loading));
  }, [loading]);

  const [referData, setReferData] = useState([]);

  useEffect(() => {
    if (restProps?.referralConditions) {
      setReferData(restProps.referralConditions);
    }
  }, [restProps]);

  const updatePatient = async (patient, check) => {
    const updatedPatient = { ...patient };
    updatedPatient["date_of_birth"] = patient["date_of_birth"] && moment(patient["date_of_birth"]).format("MM/DD/YYYY");
    updatedPatient["referral_date"] = patient?.referral_date && moment(patient?.referral_date).format("MM/DD/YYYY");
    updatedPatient["primary_care_provider_id"] = patient["primary_care_provider_id"] || patient?.pcp;
    await dispatch(setStepData("loadingResource", true));
    await dispatch(setStepData("resource", updatedPatient));
    await dispatch(setStepData("demographics", updatedPatient));
    await dispatch(handleUpdatePatient(updatedPatient));
    if (check) {
      await dispatch(getPending({}));
    }
  };

  const changeHandler = useCallback(
    debounce((patient, check) => updatePatient(patient, check), 400),
    []
  );

  const setAutoZip = async () => {
    if (zipAutoData?.success) {
      if (zipAutoData?.addresses && zipAutoData?.addresses[0]) {
        const address = zipAutoData?.addresses[0];
        if (!isAddForm) {
          const newPatient = {
            ...resource,
            state: address.state_id,
            city: address.place,
            zipcode: patient?.zipcode,
          };
          setPatient(newPatient);
          await dispatch(setStepData("demographics", newPatient));
          setReset(true);
        } else {
          const newPatient = {
            ...patient,
            state: address.state_id,
            city: address.place,
            zipcode: patient?.zipcode,
          };
          setPatient(newPatient);
          setReset(true);
        }
      }
    } else {
      if (!isAddForm) {
        const newPatient = {
          ...resource,
          state: undefined,
          city: undefined,
          zipcode: patient?.zipcode,
        };
        setPatient(newPatient);
        await dispatch(setStepData("demographics", newPatient));
        setReset(true);
      } else {
        const newPatient = {
          ...patient,
          state: undefined,
          city: undefined,
          zipcode: patient?.zipcode,
        };
        setPatient(newPatient);
        setReset(true);
      }
    }
  };

  useEffect(() => {
    if (zipAutoData && Object.keys(zipAutoData).length > 0 && first) {
      setAutoZip();
    }
  }, [zipAutoData]);

  useEffect(() => {
    if (restProps.isFormOpened) {
      openAddForm();
    }
  }, [restProps.isFormOpened]);

  const onChangePatient = (type) => (value) => {
    if (type == "pharmacy_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      const newPatient = {
        ...patient,
        [type]: id ? Number(id) : null,
        ["pharmacy_name"]: name,
      };
      dispatch(setStepData("demographics", newPatient));
      setPatient(newPatient);
      setEnableError(false);
      if (type == "gender") {
        reset && changeHandler(newPatient, true);
      } else {
        reset && changeHandler(newPatient, false);
      }
    } else if (type === "referral_provider" || type === "primary_care_provider_id") {
      let name = "";
      let id = "";
      if (value) {
        const slpitValue = value.split(" - ");
        name = slpitValue[0] || "";
        id = slpitValue[1] || "";
      }
      const newPatient = {
        ...patient,
        [`${type}_name`]: name,
        [type]: id,
      };
      setPatient(newPatient);
      setEnableError(false);
      if (!isAddForm) {
        dispatch(setStepData("demographics", newPatient));
        if (type == "gender") {
          reset && changeHandler(newPatient, true);
        } else {
          reset && changeHandler(newPatient, false);
        }
      }
    } else if (type === "skip_email") {
      const newPatient = {
        ...patient,
        ["skip_email"]: value?.target?.checked ? 1 : 0,
      };
      setPatient(newPatient);
      setEnableError(false);
      if (!isAddForm) {
        dispatch(setStepData("demographics", newPatient));
        reset && changeHandler(newPatient, false);
      }
    } else {
      const newPatient = {
        ...patient,
        [type]: value,
      };
      setPatient(newPatient);
      setEnableError(false);
      if (!isAddForm) {
        dispatch(setStepData("demographics", newPatient));
        if (type == "gender") {
          reset && changeHandler(newPatient, true);
        } else {
          reset && changeHandler(newPatient, false);
        }
      }
    }
  };

  const checkZipAuto = async (value) => {
    clearTimeout(timer);
    const newPatient = {
      ...patient,
      zipcode: value,
    };
    setPatient(newPatient);
    if (!isAddForm) {
      await dispatch(setStepData("demographics", newPatient));
      reset && changeHandler(newPatient, false);
    }
    setReset(true);
    await dispatch(setStepData("loadingResource", true));
    timer = setTimeout(async () => {
      await dispatch(autoZip(value));
      await dispatch(setStepData("loadingResource", false));
    }, 1000);
  };

  const onChangeAddress = (type) => (value) => {
    let address = "";
    if (value) {
      const splitedAddress = value.split(" ").map((addr) => {
        if (!addr || !addr.length) {
          return "";
        }
        if (addr.length < 2) {
          return addr.charAt(0).toUpperCase();
        }
        return addr.charAt(0).toUpperCase() + addr.substr(1).toLowerCase();
      });
      address = splitedAddress.join(" ");
    }

    const newPatient = {
      ...patient,
      [type]: address,
    };
    setPatient(newPatient);
    setEnableError(false);

    if (!isAddForm) {
      dispatch(setStepData("demographics", newPatient));
      reset && changeHandler(newPatient, false);
    }
  };

  const openAddForm = () => {
    setPatient({});
    dispatch(removeZipAuto());
    setOpenAddForm(true);
    setEnableError(true);
  };

  const addNewPatient = async () => {
    if (!validateSteps(patient, demographics)) {
      setEnableError(true);
      return;
    }
    await dispatch(setStepData("loadingResource", true));
    await dispatch(setStepData("isAdded", false));
    try {
      const result = await addPatient(patient);
      if (result) {
        await setIsToastr(true);
        await dispatch(selectPatient(result.data?.id || result?.data?.data?.id));
        await dispatch(getPending({}));
        if (checkIsInsurances) {
          checkIsInsurances(result.data?.id || result?.data?.data?.id);
        }
        await dispatch(removeZipAuto());
        await dispatch(setStepData("isSave", true));
      }
      await dispatch(setStepData("loadingResource", false));
    } catch (error) {
      dispatch(setStepData("loadingResource", false));
      setPatient(resource);
    }
    setTimeout(() => {
      setIsToastr(false);
    }, 2000);
  };

  const showToast = () => {
    return <ToastMessage type="New Patient" status={"success"} msg={"New patient is added successfully"} />;
  };

  const searchHandle = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      await dispatch(getPharmacies(value));
      setLocalLoading(false);
    }, 500);
  };

  useEffect(() => {
    restProps.checkIsAdding(isAddForm);
  }, [isAddForm]);

  const fetchSearch = async (value) => {
    setLocalLoading(true);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const getDetailSuccess = await dispatch(searchPcpNpe(1, value));
      if (getDetailSuccess) {
        setLocalLoading(false);
      }
    }, 500);
  };

  return (
    <>
      <div className="resourceContent pr-7px" style={{ position: "relative" }}>
        {isToastr && !errorRest && showToast()}
        {!isAddForm && (
          <React.Fragment>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"First Name"}
                value={patient?.first_name}
                handleChange={onChangePatient("first_name")}
                required={true}
                isError={validateField("first_name", patient?.first_name)}
                enableErrorText={enableError}
                helperText={"First Name is Required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Last Name"}
                value={patient?.last_name}
                handleChange={onChangePatient("last_name")}
                required={true}
                isError={validateField("text", patient?.last_name)}
                enableErrorText={enableError}
                helperText={"Last Name is Required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Deceased"}
                handleChange={onChangePatient("deceased")}
                value={patient?.deceased}
                type={"check"}
                required={true}
                isError={validateField("text", patient?.deceased)}
                enableErrorText={enableError}
                helperText={"Deceased is Required!"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "yes", name: "Yes" },
                  { value: "no", name: "No" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"DOB"}
                value={patient?.date_of_birth}
                handleChange={onChangePatient("date_of_birth")}
                required={true}
                mask={"date"}
                isError={validateField("date", patient?.date_of_birth)}
                enableErrorText={enableError}
                helperText={"Birthday should be MM/DD/YYYY"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Gender"}
                handleChange={onChangePatient("gender")}
                value={patient?.gender}
                required={true}
                isError={validateField("text", patient?.gender)}
                enableErrorText={enableError}
                helperText={"Gender is Required"}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "male", name: "Male" },
                  { value: "female", name: "Female" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Language"}
                handleChange={onChangePatient("preferred_language")}
                value={patient?.preferred_language}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "English", name: "English" },
                  { value: "Spanish", name: "Spanish" },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Marital Status"}
                handleChange={onChangePatient("marital_status")}
                value={patient?.marital_status}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "Married", name: "Married" },
                  { value: "Single", name: "Single" },
                  { value: "Divorced", name: "Divorced" },
                  { value: "Widowed", name: "Widowed" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Ethnicity"}
                handleChange={onChangePatient("ethnicity")}
                value={patient?.ethnicity}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "White", name: "White" },
                  { value: "Hispanic", name: "Hispanic" },
                  {
                    value: "Not Hispanic or Latino",
                    name: "Not Hispanic or Latino",
                  },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Phone No"}
                value={patient?.phone}
                handleChange={onChangePatient("phone")}
                required={true}
                isError={validateField("phone", patient?.phone)}
                enableErrorText={enableError}
                helperText={"Phone Number should be (XXX) XXX-XXXX format"}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Phone No"}
                value={patient?.secondary_phone}
                handleChange={onChangePatient("secondary_phone")}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput isErrorTextSpacing={true} label={"E-Mail"} value={patient?.email} handleChange={onChangePatient("email")} />
              <div style={{ marginTop: 10, marginBottom: 10 }} className="resourceInfo between half-pannel">
                <Checkbox
                  checked={patient.skip_email == 1 ? true : false}
                  onChange={onChangePatient("skip_email")}
                  style={{ width: "100%" }}
                  disabled={patient.email && patient["email"].length > 0}
                >
                  Exclude E-Mail
                </Checkbox>
              </div>
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Address"}
                value={patient?.address}
                handleChange={onChangeAddress("address")}
                required={true}
                isError={validateField("text", patient?.address)}
                enableErrorText={enableError}
                helperText={"Address is required"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label={"Apt"} value={patient?.apt_number} handleChange={onChangePatient("apt_number")} />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Zip Code"}
                value={patient?.zipcode}
                handleChange={(value) => {
                  checkZipAuto(value);
                }}
                required={true}
                isError={validateField("text", patient?.zipcode)}
                enableErrorText={enableError}
                helperText={"Zip Code is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"State"}
                value={patient?.state || undefined}
                handleChange={onChangePatient("state")}
                type={"check"}
                valueKey={"value"}
                optionKey={"id"}
                options={stateOptions || []}
                required={true}
                isError={validateField("text", patient?.state)}
                enableErrorText={enableError}
                helperText={"State is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"City"}
                value={patient?.city}
                handleChange={onChangePatient("city")}
                required={true}
                isError={validateField("text", patient?.city)}
                enableErrorText={enableError}
                helperText={"City is required"}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Diagnosis"}
                value={patient?.referral_condition || undefined}
                handleChange={onChangePatient("referral_condition")}
                required={true}
                type={"check"}
                isError={validateField("text", patient?.referral_condition)}
                optionKey={"value"}
                valueKey={"value"}
                options={referData || []}
                enableErrorText={enableError}
                helperText={"Diagnosis is required"}
              />
            </div>
            {patient?.referral_condition === "Other" && (
              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  isErrorTextSpacing={true}
                  label={"Diagnosis Specifics"}
                  value={patient?.diagnosis_specifics}
                  handleChange={onChangePatient("diagnosis_specifics")}
                />
              </div>
            )}
            <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing">
              <AutoCompleteSearch
                options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
                value={patient?.referral_provider && patient?.referral_provider_name ? `${patient?.referral_provider_name}` : undefined}
                onClear={onChangePatient("referral_provider")}
                handlePopulate={onChangePatient("referral_provider")}
                handleSearch={(val) => fetchSearch(val)}
                title="referring"
                placeholder="Referring"
                actionLabel="Search"
                keyLabel="provider_name_suffix_address"
                optionKey="id"
                backgroundWhite={"white"}
                isModified
                disableSearchIcon={!!patient?.referral_provider_name}
                customStyle={!!patient?.referral_provider_name}
              />
              {(!patient?.referral_provider || !patient?.referral_provider_name) && (
                <p className="alert_message">
                  <span>
                    <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                  </span>
                </p>
              )}
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setProviderAddForm(true)}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
              {detailProvider?.id && (
                <span
                  style={{
                    width: 18,
                    position: "absolute",
                    left: 440,
                    height: "2.5rem",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => restProps.setProviderEditForm(true, detailProvider)}
                >
                  <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                </span>
              )}
            </div>
            <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing">
              <AutoCompleteSearch
                options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
                value={
                  patient?.primary_care_provider_id && patient?.primary_care_provider_id_name
                    ? `${patient?.primary_care_provider_id_name}`
                    : undefined
                }
                onClear={onChangePatient("primary_care_provider_id")}
                handlePopulate={onChangePatient("primary_care_provider_id")}
                handleSearch={(val) => fetchSearch(val)}
                title="primary-care-provider"
                placeholder="PCP"
                actionLabel="Search"
                keyLabel="provider_name_suffix_address"
                optionKey="id"
                backgroundWhite={"white"}
                isModified
                disableSearchIcon={!!patient?.primary_care_provider_id_name}
                customStyle={!!patient?.primary_care_provider_id_name}
              />
              {(!patient?.primary_care_provider_id || !patient?.primary_care_provider_id_name) && (
                <p className="alert_message">
                  <span>
                    <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                  </span>
                </p>
              )}

              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setProviderAddForm(true)}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
              {detailProviderPCP?.id && (
                <span
                  style={{
                    width: 18,
                    position: "absolute",
                    left: 440,
                    height: "2.5rem",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => restProps.setProviderEditForm(true, detailProviderPCP)}
                >
                  <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                </span>
              )}
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Referral Date"}
                value={patient?.referral_date}
                type="date"
                handleChange={onChangePatient("referral_date")}
                required={true}
                isError={validateField("text", patient?.referral_date)}
                enableErrorText={enableError}
                helperText={"Referral Date is required"}
              />
            </div>
            <div className="resourceInfo d-flex isErrorTextSpacing">
              <AutoCompleteSearch
                options={!localLoading && !loadingPharmacies ? restProps.pharmacies || [] : []}
                value={patient?.pharmacy_name && patient?.pharmacy_id ? `${patient?.pharmacy_name} - ${patient?.pharmacy_id}` : undefined}
                onClear={onChangePatient("pharmacy_id")}
                handlePopulate={onChangePatient("pharmacy_id")}
                handleSearch={(val) => searchHandle(val)}
                title="pharmacy"
                placeholder="Pharmacy"
                actionLabel="Search"
                keyLabel="name"
                optionKey="id"
                backgroundWhite={"white"}
                isModified
                customStyle={!!patient?.pharmacy_name && !!patient?.pharmacy_id}
                disableSearchIcon={!!patient?.pharmacy_name && !!patient?.pharmacy_id}
              />
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setPharmacyAddForm && restProps.setPharmacyAddForm(true)}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
              {detailPharmacy?.id && (
                <span
                  style={{
                    width: 18,
                    position: "absolute",
                    left: 440,
                    height: "2.5rem",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => restProps.setPharmacyEditForm && restProps.setPharmacyEditForm(true, detailPharmacy)}
                >
                  <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
                </span>
              )}
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Advance Directives"}
                value={patient?.advanced_directive || undefined}
                handleChange={onChangePatient("advanced_directive")}
                required={true}
                type={"check"}
                isError={validateField("text", patient?.advanced_directive)}
                optionKey={"value"}
                options={[{ value: "No Advance Directives On File" }, { value: "Advance Directives On File" }]}
                valueKey={"value"}
                enableErrorText={tabActive ? true : false}
                helperText={"Advance Directives"}
              />
            </div>
          </React.Fragment>
        )}
        {isAddForm && (
          <React.Fragment>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                id="fname"
                label={"First Name"}
                value={patient?.first_name}
                handleChange={onChangePatient("first_name")}
                required={true}
                isError={validateField("text", patient?.first_name)}
                enableErrorText={enableError}
                helperText={"First Name is Required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Last Name"}
                id="lname"
                value={patient?.last_name}
                handleChange={onChangePatient("last_name")}
                required={true}
                isError={validateField("text", patient?.last_name)}
                enableErrorText={enableError}
                helperText={"Last Name is Required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Deceased"}
                id="deceased"
                handleChange={onChangePatient("deceased")}
                value={patient?.deceased}
                type={"check"}
                required={true}
                isError={validateField("text", patient?.deceased)}
                enableErrorText={enableError}
                helperText={"Deceased is Required!"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "yes", name: "Yes" },
                  { value: "no", name: "No" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"DOB"}
                id="dob"
                value={patient?.date_of_birth}
                handleChange={onChangePatient("date_of_birth")}
                required={true}
                mask={"date"}
                isError={validateField("date", patient?.date_of_birth)}
                enableErrorText={enableError}
                helperText={"Birthday should be MM/DD/YYYY"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Gender"}
                id="gender"
                handleChange={onChangePatient("gender")}
                value={patient?.gender}
                required={true}
                isError={validateField("text", patient?.gender)}
                enableErrorText={enableError}
                helperText={"Gender is Required"}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "male", name: "Male" },
                  { value: "female", name: "Female" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Language"}
                handleChange={onChangePatient("preferred_language")}
                value={patient?.preferred_language}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "English", name: "English" },
                  { value: "Spanish", name: "Spanish" },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Marital Status"}
                handleChange={onChangePatient("marital_status")}
                value={patient?.marital_status}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "Married", name: "Married" },
                  { value: "Single", name: "Single" },
                  { value: "Divorced", name: "Divorced" },
                  { value: "Widowed", name: "Widowed" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Ethnicity"}
                handleChange={onChangePatient("ethnicity")}
                value={patient?.ethnicity}
                type={"check"}
                optionKey={"value"}
                valueKey={"name"}
                options={[
                  { value: "White", name: "White" },
                  { value: "Hispanic", name: "Hispanic" },
                  {
                    value: "Not Hispanic or Latino",
                    name: "Not Hispanic or Latino",
                  },
                  { value: "Other", name: "Other" },
                ]}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Phone No"}
                id="phone"
                value={patient?.phone}
                handleChange={onChangePatient("phone")}
                required={true}
                isError={validateField("phone", patient?.phone)}
                enableErrorText={enableError}
                helperText={"Phone Number should be (XXX) XXX-XXXX format"}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Phone No"}
                value={patient?.secondary_phone}
                handleChange={onChangePatient("secondary_phone")}
                mask={"phone"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"E-Mail"}
                id="email"
                value={patient?.email}
                handleChange={onChangePatient("email")}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Address"}
                id="address"
                value={patient?.address}
                handleChange={onChangeAddress("address")}
                required={true}
                isError={validateField("text", patient?.address)}
                enableErrorText={enableError}
                helperText={"Address is required"}
              />
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput isErrorTextSpacing={true} label={"Apt"} value={patient?.apt_number} handleChange={onChangePatient("apt_number")} />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Zip Code"}
                id="zipcode"
                value={patient?.zipcode}
                handleChange={(value) => {
                  checkZipAuto(value);
                }}
                required={true}
                isError={validateField("text", patient?.zipcode)}
                enableErrorText={enableError}
                helperText={"Zip Code is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"State"}
                id="state"
                value={patient?.state || undefined}
                handleChange={onChangePatient("state")}
                type={"check"}
                optionKey={"id"}
                valueKey={"value"}
                options={stateOptions || []}
                required={true}
                isError={validateField("text", patient?.state)}
                enableErrorText={enableError}
                helperText={"State is required"}
              />
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"City"}
                id="city"
                value={patient?.city}
                handleChange={onChangePatient("city")}
                required={true}
                isError={validateField("text", patient?.city)}
                enableErrorText={enableError}
                helperText={"City is required"}
              />
            </div>
            <hr />
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Diagnosis"}
                id="diagnosis"
                value={patient?.referral_condition || undefined}
                handleChange={onChangePatient("referral_condition")}
                required={true}
                type={"check"}
                isError={validateField("text", patient?.referral_condition)}
                optionKey={"value"}
                valueKey={"value"}
                options={referData || []}
                enableErrorText={enableError}
                helperText={"Diagnosis is required"}
              />
            </div>
            {patient?.referral_condition === "Other" && (
              <div className="resourceInfo d-flex required-field">
                <EditableFormInput
                  isErrorTextSpacing={true}
                  label={"Diagnosis Specifics"}
                  value={patient?.diagnosis_specifics}
                  handleChange={onChangePatient("diagnosis_specifics")}
                />
              </div>
            )}
            <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing">
              <AutoCompleteSearch
                options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
                value={patient?.referral_provider && patient?.referral_provider_name ? `${patient?.referral_provider_name}` : undefined}
                id="referral_provider"
                onClear={onChangePatient("referral_provider")}
                handlePopulate={onChangePatient("referral_provider")}
                handleSearch={(val) => fetchSearch(val)}
                title="referring"
                placeholder="Referring"
                actionLabel="Search"
                keyLabel="provider_name_suffix_address"
                optionKey="id"
                backgroundWhite={"white"}
                isModified
                disableSearchIcon={!!patient?.referral_provider_name}
                customStyle={!!patient?.referral_provider_name}
              />
              {(!patient?.referral_provider || !!patient?.referral_provider_name) && (
                <p className="alert_message">
                  <span>
                    <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                  </span>
                </p>
              )}
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setProviderAddForm(true)}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            </div>
            <div className="resourceInfo d-flex required-field ins_info isErrorTextSpacing">
              <AutoCompleteSearch
                options={!localLoading && !loadingSearchPCP ? dataPCPRef || [] : []}
                value={
                  patient?.primary_care_provider_id && patient?.primary_care_provider_id_name
                    ? `${patient?.primary_care_provider_id_name}`
                    : undefined
                }
                id="primary_care_provider_id"
                onClear={onChangePatient("primary_care_provider_id")}
                handlePopulate={onChangePatient("primary_care_provider_id")}
                handleSearch={(val) => fetchSearch(val)}
                title="primary-care-provider"
                placeholder="PCP"
                actionLabel="Search"
                keyLabel="provider_name_suffix_address"
                optionKey="id"
                backgroundWhite={"white"}
                isModified
                disableSearchIcon={!!patient?.primary_care_provider_id_name}
                customStyle={!!patient?.primary_care_provider_id_name}
              />
              {(!patient?.primary_care_provider_id || !patient?.primary_care_provider_id_name) && (
                <p className="alert_message">
                  <span>
                    <RedRequiredCircleSVG /> Search using First Name, Last Name, State
                  </span>
                </p>
              )}
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setProviderAddForm(true)}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            </div>
            <div className="resourceInfo d-flex required-field">
              <EditableFormInput
                isErrorTextSpacing={true}
                label={"Referral Date"}
                id="referral_date"
                value={patient?.referral_date}
                type="date"
                handleChange={onChangePatient("referral_date")}
                required={true}
                isError={validateField("text", patient?.referral_date)}
                enableErrorText={enableError}
                helperText={"Referral Date is required"}
              />
            </div>
            <div className="resourceInfo d-flex isErrorTextSpacing">
              <AutoCompleteSearch
                options={!localLoading && !loadingPharmacies ? restProps.pharmacies || [] : []}
                value={patient?.pharmacy_name && patient?.pharmacy_id ? `${patient?.pharmacy_name} - ${patient?.pharmacy_id}` : undefined}
                onClear={onChangePatient("pharmacy_id")}
                handlePopulate={onChangePatient("pharmacy_id")}
                handleSearch={(val) => searchHandle(val)}
                title="pharmacy"
                placeholder="Pharmacy"
                actionLabel="Search"
                keyLabel="name"
                optionKey="id"
                backgroundWhite={"white"}
                isModified
                customStyle={!!patient?.pharmacy_name && !!patient?.pharmacy_id}
                disableSearchIcon={!!patient?.pharmacy_name && !!patient?.pharmacy_id}
              />
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 415,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setPharmacyAddForm && restProps.setPharmacyAddForm(true)}
              >
                <Icon type="plus-square" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
              <span
                style={{
                  width: 18,
                  position: "absolute",
                  left: 440,
                  height: "2.5rem",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => restProps.setPharmacyEditForm(true, "TODO")}
              >
                <Icon type="eye" theme="twoTone" twoToneColor="#0f62fe" className="pharmacy-icon" />
              </span>
            </div>
            <div className="resourceInfo d-flex">
              <EditableFormInput
                isErrorTextSpacing={true}
                id="advanced_directive"
                label={"Advance Directives"}
                value={patient?.advanced_directive || undefined}
                handleChange={onChangePatient("advanced_directive")}
                required={true}
                type={"check"}
                isError={validateField("text", patient?.advanced_directive)}
                optionKey={"value"}
                options={[{ value: "No Advance Directives On File" }, { value: "Advance Directives On File" }]}
                valueKey={"value"}
                enableErrorText={tabActive ? true : false}
                helperText={"Advance Directives"}
              />
            </div>
          </React.Fragment>
        )}
        {isAddForm && (
          <div className="actionContent" style={{ flexWrap: "wrap", marginTop: "1.5rem" }}>
            <button className="common-btn" style={{ marginTop: "0.5rem", marginLeft: "0.5rem" }} onClick={addNewPatient}>
              Save
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default FirstStepComponent;
