import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../common/components/ScrollBar";
import { editProviders, getProviders } from "../../../store/actions";

let timer = null;

const timeIntervals = [
  { label: "15 min", value: 15 },
  { label: "20 min", value: 20 },
  { label: "25 min", value: 25 },
  { label: "30 min", value: 30 },
  { label: "35 min", value: 35 },
  { label: "40 min", value: 40 },
  { label: "45 min", value: 45 },
  { label: "50 min", value: 50 },
  { label: "55 min", value: 55 },
  { label: "60 min", value: 60 },
];

const TimeAlottment = () => {
  const providers = useSelector((state) => state.common.providers);
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [alottments, setAlottments] = useState([]);
  const [activeProvider, setActiveProvider] = useState({});

  useEffect(() => {
    // dispatch(getProviders());
  }, []);

  useEffect(() => {
    if (search && search.length > 0) {
      const check = providers.filter((r) => r?.value.toLowerCase().includes(search.toLowerCase()));
      setSearchResult(check);
    } else {
      setSearchResult(providers);
    }
  }, [search]);

  const handlePopulate = (value) => {
    setActiveProvider(value);
    const name = value?.value;
    const alottment = {
      providerName: name,
      new_patient_interval: value.new_patient_interval || undefined,
      existing_patient_interval: value.existing_patient_interval || undefined,
    };
    setAlottments([alottment]);
    setSearch("");
  };

  const updateApi = async (params, id) => {
    await dispatch(editProviders(id, params));
    await dispatch(getProviders());
  };

  const handleChangeAlottment = (index, type, value) => {
    const temp = [...alottments];
    temp[index][type] = value;
    clearTimeout(timer);
    timer = setTimeout(() => {
      updateApi(temp[0] || {}, activeProvider?.id);
    }, 400);
    setAlottments(temp);
  };

  return (
    <div
      className="documentOcrSetting"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <AutoCompleteSearch
        options={searchResult}
        handlePopulate={handlePopulate}
        backgroundWhite={"white"}
        handleSearch={(val) => setSearch(val)}
        title="Time-alottment-detail"
        placeholder="Search Provider"
        actionLabel="Search"
        keyLabel="value"
      />
      <div style={{ flex: 1, paddingTop: "1rem", width: "100%", overflow: "scroll" }}>
        <ScrollWrapper css="no-padding x-hidden">
          <div
            style={{
              paddingTop: "5px",
            }}
          >
            {(alottments || []).map((item, index) => (
              <React.Fragment key={`time-alottments-${Math.random()}-${index}`}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ width: "calc(100%)", marginRight: "8px" }}>
                    <EditableFormInput label={"Provider Name"} value={item.providerName} noEditable={true} disabled={true} />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ width: "calc(100%)", marginRight: "8px" }}>
                    <EditableFormInput
                      label={"New Patient Interval"}
                      type={"check"}
                      value={item.new_patient_interval}
                      options={timeIntervals}
                      handleChange={(value) => handleChangeAlottment(index, "new_patient_interval", value)}
                      optionKey={"value"}
                      valueKey={"label"}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginBottom: "8px",
                  }}
                >
                  <div style={{ width: "calc(100%)", marginRight: "8px" }}>
                    <EditableFormInput
                      label={"Established Patient Interval"}
                      type={"check"}
                      value={item.existing_patient_interval}
                      options={timeIntervals}
                      handleChange={(value) => handleChangeAlottment(index, "existing_patient_interval", value)}
                      optionKey={"value"}
                      valueKey={"label"}
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </ScrollWrapper>
      </div>
    </div>
  );
};

export default TimeAlottment;
