/* eslint-disable react-hooks/exhaustive-deps */
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import "../../../../common/components/TableDrafJS/Table.css";
import BlockComponent from "../../../../common/components/TableDrafJSWordOBL/BlockComponent";
import createTable from "../../../../common/components/TableDrafJSWordOBL/createTable";
import { getArteryLabel } from "../../../../common/constants/Artery";
import { getVeinLabel } from "../../../../common/constants/Vein";
import CreateTextN from "../../../../helpers/CreateTextN";
import { getLocal } from "../../../../helpers/Local";
import { saveTextReview, setGlobalLoading } from "../../../../store/actions";
import valueFind from "./dataTableReview";
import dataHeader from "./formarterData";
import dataHeaderActiveIntra from "./formarterDataActiveIntra";
import dataHeaderStab from "./formarterDataStab";
import dataHeaderStabActiveIntra from "./formarterDataStabActiveIntra";
import dataCheckVariance from "src/helpers/CheckVariance";
import dataCheckQuestion from "src/helpers/CheckQuestion";
import DataVASCULOQOL from "../../../../data/VASCULOQOL";
import RenderFixStrongAndSpaceReview from "../../../../helpers/RenderFixStrongAndSpaceReview";
moment.tz.setDefault("America/New_York");

const valueHeader = {
  headers: ["Qualifier", "Meets Criteria"],
};

const valueHeader2 = {
  headers: ["Qualifier", "Reference"],
};

let timer = null;
let timerNew = null;

const ReviewText = ({ changeValueText, dataCPT, activeIntra, appointment, finalizedDate, finalizedDateDate, providerId, providerName, coSign }) => {
  const contentState = convertFromRaw(JSON.parse(dataHeader.text_template));
  const editorState = EditorState.createWithContent(contentState);
  const [text, setText] = useState("");
  const [editorData] = useState(editorState);
  const [first, setFirst] = useState(false);
  const dispatch = useDispatch();
  const current_appointment_id = getLocal("current_appointment_id");
  const intra = useSelector((state) => state.patientExamination.intra);
  const intraAnswers = useSelector((state) => state.common.intraAnswers);
  const icd_codes = useSelector((state) => state.patientExamination.icd_codes);
  const list_procedurals = useSelector((state) => state.procedure.list_procedurals);
  const detail_procedure = useSelector((state) => state.common.CPT);
  const detail_angio_type = useSelector((state) => state.procedureDetail.detail_angio_type);
  const patientResource = useSelector((state) => state.clinicProvider.patientResource);
  const complication_value = useSelector((state) => state.patientExamination.complication);
  const text_template_redux = useSelector((state) => state.patientExamination.text_template);
  const total_contrast_load = useSelector((state) => state.patientExamination.total_contrast_load);
  const total_radiation_time = useSelector((state) => state.patientExamination.total_radiation_time);
  const total_dap = useSelector((state) => state.patientExamination.calculated_dose_area_product);
  const first_sedation_time = useSelector((state) => state.patientExamination.first_sedation_time);
  const last_sedation_time = useSelector((state) => state.patientExamination.last_sedation_time);
  const qualified_provider_name = useSelector((state) => state.patientExamination.qualified_provider_name);
  const total_sedation_time = useSelector((state) => state.patientExamination.total_sedation_time);
  const return_followup_plans = useSelector((state) => state.patientExamination.return_followup_plans);
  const condition_plans = useSelector((state) => state.patientExamination.condition_plans);
  const additional_plan_text_lymphedema = useSelector((state) => state.patientExamination.additional_plan_text_lymphedema);
  const additional_plan_text = useSelector((state) => state.patientExamination.additional_plan_text);
  const mips = useSelector((state) => state.patientExamination.mips);
  const vascuqol6 = useSelector((state) => state.patientExamination.vascuqol6);
  const post_operative = useSelector((state) => state.patientExamination.post_operative);

  const dataState = JSON.stringify(convertToRaw(editorData?.getCurrentContent()));
  if (!first && dataState && dataState.includes("blocks")) {
    setFirst(true);
  }

  const leftRightOBL = useSelector((state) => state.patientExamination.leftRightOBL);
  const patientExamination = useSelector((state) => state.patientExamination);
  const medications = patientExamination?.medication_administration?.pre_op;
  const vitalSigns = patientExamination?.vital_signs;

  const blockRenderer = (block) => {
    if (block.getType() === "atomic" && block.key === "f7o28" && valueHeader && valueFind && leftRightOBL) {
      return {
        component: (props) => {
          return (
            <BlockComponent
              valueHeader2={valueHeader2}
              valueHeader={valueHeader}
              valueFind={valueFind}
              leftRight={leftRightOBL}
              hpi_conditions={condition_plans}
              props={props}
            ></BlockComponent>
          );
        },
        editable: false,
      };
    }
    if (block.getType() === "atomic" && block.key === "f7o68" && valueHeader && valueFind && leftRightOBL) {
      return {
        component: (props) => {
          return (
            <BlockComponent
              is_null={true}
              medications={medications || null}
              vitalSigns={vitalSigns || null}
              valueHeader2={valueHeader2}
              valueHeader={valueHeader}
              valueFind={valueFind}
              leftRight={leftRightOBL}
              hpi_conditions={condition_plans}
              props={props}
            ></BlockComponent>
          );
        },
        editable: false,
      };
    }
    return null;
  };

  const importCondition = (key, plans_row, temp_text, temp_length) => {
    switch (key) {
      case "Wound / Gangrene (CLI)": {
        // check Left
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} - The patient has signs and symptoms of CLI - Wound/Gangrene greater or equal to 2 weeks without improvement with local conservative wound care measures as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to minimize tissue loss.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} - The patient has signs and symptoms of CLI - Wound/Gangrene greater or equal to 2 weeks without improvement with local conservative wound care measures as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to minimize tissue loss.","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Intermittent Claudication": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has failed conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention as well as lifestyle limiting symptoms affecting qOL.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for PAD necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has failed conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention as well as lifestyle limiting symptoms affecting qOL.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for PAD necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        if (plans_row && plans_row.includes("Lower Extremity Arteries")) {
          const text_value = `- Patient will undergo Guideline Directed Medical Therapy (GDMT) for a 12-week period and be re-evaluated for their intermittent claudication symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Acute Limb Ischemia (ALI)": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of ALI as defined by the ACC/AHA Executive Summary.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with ALI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for ALI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment for limb salvage.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of ALI as defined by the ACC/AHA Executive Summary.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with ALI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for ALI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment for limb salvage.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Rest Pain (CLI)": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of CLI - Rest Pain as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          const text_value = `- The patient has signs and symptoms of CLI - Rest Pain as defined by the ACC/AHA Executive Summary, has arterial duplex ultrasound imaging with evidence of anatomically suitable lesions for intervention.  Conservative management of 12-weeks or greater of Guideline-Directed Management and Therapy (GDMT) as defined by the ACC/AHA Class I recommendations is not indicaed with patient with CLI as per ACC/AHA Class I recommendations guidelined and algorithm of care.  The defined ACC/AHA Class I recommendations guidelined and algorithm of care as defined for CLI necessitate the need for a diagnostic angiogram with possible angiogplasty versus stent placment to optimize arterial flow and improve symptoms.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "In-Stent or Restenosis": {
        if (plans_row && plans_row.includes("Left Peripheral Angiogram (OBL)")) {
          const text_value = `- Throughout the literature there are many studies that attempt to determine the best ways to manage a significant issue in endovascular care given the comorbidities of the patient.  There are NO guidelines or consensus.  Thus treatment of in-stent restenosis for which defined recommendations from the expert community do not exist.  At best the intervention of in-stent or restenosis is as the ACC/AHA best summarizes is a combination of clinical judgement and duplex ultrasound findings with evidence of hemodynamic significance which necessitates a diagnostic angiogram with possible angiogplasty versus stent placment to maintain primary assisted or secondary assisted patency.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        // check Right
        if (plans_row && plans_row.includes("Right Peripheral Angiogram (OBL)")) {
          const text_value = `- Throughout the literature there are many studies that attempt to determine the best ways to manage a significant issue in endovascular care given the comorbidities of the patient.  There are NO guidelines or consensus.  Thus treatment of in-stent restenosis for which defined recommendations from the expert community do not exist.  At best the intervention of in-stent or restenosis is as the ACC/AHA best summarizes is a combination of clinical judgement and duplex ultrasound findings with evidence of hemodynamic significance which necessitates a diagnostic angiogram with possible angiogplasty versus stent placment to maintain primary assisted or secondary assisted patency.`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Abdominal Aortic Aneurysm (AAA) Disease": {
        if (plans_row && plans_row.includes("Aorta, IVC, Iliac Vasculature")) {
          const text_value = `- The defined ACC/AHA recommendations for a screening duplex ultrasound for Abdominal Aortic Aneurysm (AAA) is reasonable in patients with symptomatic peripheral arterial disease (PAD). (COR IIa)`;
          return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text} ${text_value}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
        }
        return `{"key":"keypla${plans_row}","text":"${plans_row} ${temp_text}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
      }

      case "Lymphedema": {
        let text = "";
        (additional_plan_text_lymphedema || []).map((r, i) => {
          text += `{"key":"keyAdditionPlanTextlymp${i}","text":"${r}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        });
        if (plans_row) {
          return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},${text}`;
        } else {
          return `${text}`;
        }
      }
      case "Peripheral Artery Disease (PAD)": {
        let text = "";
        (additional_plan_text || []).map((r, i) => {
          text += `{"key":"keyAdditionPlanText${i}","text":"${r}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        });
        if (!plans_row) {
          return `${text}`;
        } else {
          return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},${text}`;
        }
      }
      // warning
      default:
        if (plans_row && temp_length) {
          // remove temp_text vì nó không nên add thêm cho general phía sau mà chỉ nhận từ api
          if (patientResource?.fall_injury) {
            const valueIfFall = `Balance, Strength, and Gait Training – Balance, strength, and gait training and instructions were provided to the patient`;
            return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},
            {"key":"keyplaextramore","text":"${valueIfFall}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":36,"style":"BOLD"}],"entityRanges":[],"data":{}},`;
          } else {
            return `{"key":"keypla${plans_row}","text":"${plans_row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":${temp_length},"style":"BOLD"}],"entityRanges":[],"data":{}},`;
          }
        }
    }
  };

  const onChange = (editorState) => {
    const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    const dataText = JSON.stringify(convertToRaw(text.getCurrentContent()));
    if (dataText !== data) {
      setText(editorState);
      const data = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
      changeValueText(data);
      // Comment 67 -> 78 To save load Api review text
      dispatch(setGlobalLoading(true));
      clearTimeout(timer);
      timer = setTimeout(() => {
        dispatch(
          saveTextReview(current_appointment_id, {
            text_template: data,
          })
        );
        dispatch(setGlobalLoading(false));
      }, 1000);
    }
  };

  const renderDataVariance = (dataFix, index, fillState) => {
    const textIN = dataFix[index]["text"];
    const indexFill = fillState.findIndex((fill) => fill?.type == textIN);

    // check replace variance
    if (indexFill != -1 && textIN) {
      if (fillState[indexFill]["extra"]?.length > 0) {
        return [
          ...dataFix.slice(0, index + 1),
          // go index will push data and push
          {
            key: `varisd${indexFill}empty`,
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: `varisd${indexFill}`,
            text:
              dataCheckVariance?.[textIN]?.[fillState[indexFill]["value"]] +
                ` The patient contraindication is specific to ${fillState[indexFill]["extra"]}.` || "",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          // remove 2 row 1 row key title 1 row next properly is row empty
          ...dataFix.slice(index + 1),
        ];
      } else {
        return [
          ...dataFix.slice(0, index + 1),
          {
            key: `varisd${indexFill}empty`,
            text: "",
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          {
            key: `varisd${indexFill}`,
            text: dataCheckVariance?.[textIN]?.[fillState[indexFill]["value"]] || "",
            type: "unordered-list-item",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
          ...dataFix.slice(index + 1),
        ];
      }
    } else {
      return dataFix;
    }
  };

  const updateCheck = (dataCheck) => {
    if (first) {
      let data = JSON.stringify(convertToRaw(dataCheck?.getCurrentContent()));
      // header 1 DATE OF PROCEDURE
      if (
        !data?.includes(
          `{"key":"txt1empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtd2","text":"${moment(
            detail_procedure.appointment_date
          ).format(
            "MM/DD/YYYY"
          )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )
      ) {
        data = data?.replace(
          `{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `{"key":"txt1empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtd2","text":"${moment(
            detail_procedure.appointment_date
          ).format(
            "MM/DD/YYYY"
          )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }

      // header 9 SURGEON
      if (
        !data?.includes(
          `{"key":"txt9empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"nmsi2","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )
      ) {
        data = data?.replace(
          `{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `{"key":"txt9empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"nmsi2","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text9","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }

      // header 8 INDICATION
      if (intra && intra.length > 0) {
        let intratxt = "";
        for (let i = 0; i < intra.length; i++) {
          if (intra[i] && intra[i]["questions"] && intra[i].enable_icd == 1) {
            const temp = intra[i]["text_template"];
            const head = intra[i]["condition_name"];
            if (temp && temp.includes("blocks")) {
              let valueImport = temp.replace(`{"blocks":[`, "");
              if (valueImport.includes(`],"entityMap":{`) && valueImport.includes(`}}}}}`)) {
                const [enti, ...entis] = valueImport && valueImport.split(`],"entityMap":{`);
                const checkEnti = (entis || []).map((item) => {
                  return item.split(`}}}}}`)[0];
                });
                if (checkEnti && checkEnti[0].length > 0) {
                  valueImport = valueImport.replace(`],"entityMap":{${checkEnti[0]}}}}}}`, ``);
                }
              }
              if (valueImport.includes(`"entityMap":[{`)) {
                const [enti, ...entis] = valueImport && valueImport.split(`],"entityMap":[{"`);
                const checkEnti = (entis || []).map((item) => {
                  return item.split(`]}`)[0];
                });
                if (checkEnti && checkEnti[0].length > 0) {
                  valueImport = valueImport.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
                }
              }
              if (valueImport.includes(`],"entityMap":[]}`)) {
                valueImport = valueImport.replace(`],"entityMap":[]}`, "");
              }
              if (valueImport.includes(`],"entityMap":{}}`)) {
                valueImport = valueImport.replace(`],"entityMap":{}}`, "");
              }
              if (valueImport) {
                let dataImport =
                  valueImport.slice(0, 1) == "{" &&
                  (valueImport.slice(valueImport.length - 1) == "}" || valueImport.slice(valueImport.length - 1) == ",")
                    ? JSON.parse(`[${valueImport}]`)
                    : null;
                if (dataImport && Array.isArray(dataImport)) {
                  const arrSpecial = dataImport.filter((r) => r?.text && (r?.text.includes("•") || r?.text.includes("!!new!!")));
                  if (arrSpecial && arrSpecial.length > 0) {
                    arrSpecial.map((r) => {
                      const indexSpecial = dataImport.findIndex((ri) => ri?.key && ri?.key === r.key);
                      if (indexSpecial !== -1) {
                        const valueParse = JSON.parse(`[${CreateTextN(`${r.text}`)}]`) || null;
                        if (valueParse && Array.isArray(valueParse)) {
                          dataImport.splice(indexSpecial, 1, ...valueParse);
                        }
                      }
                    });
                  }
                  //
                  let valueString = JSON.stringify(dataImport) || "";
                  if (dataImport && Array.isArray(dataImport)) {
                    const [headLi, ...itemHeadLi] = valueString && valueString.split(`!!UL!!`);
                    const checkHeadLi = (itemHeadLi || []).map((item) => {
                      return item.split(`",`)[0];
                    });
                    if (checkHeadLi) {
                      checkHeadLi.map((r) => {
                        if (r) {
                          valueString = valueString.replace(`${r}","type":"unstyled",`, `${r}","type": "unordered-list-item",`);
                        }
                      });
                      valueString = valueString.replaceAll("!!UL!!", "");
                    }
                    const valueAll = valueString.substring(1, valueString.length - 1);
                    if (head) {
                      intratxt += `${`{"key":"txttemplateempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"texthead${i}","text":"${head}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${head.length},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txttemplateempty2${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`},${valueAll},`;
                    }
                  }
                }
              }
            }
          }
        }
        intratxt =
          `{"key":"txtemptybeforeindication","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtbeforeindication","text":"The patient was reassessed and re-evaluated independently on the date of the procedure by the operating physician","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},` +
          intratxt;
        if (
          intratxt.length > 0 &&
          !data?.includes(`${intratxt}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)
        ) {
          data = data?.replace(
            `{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${intratxt}{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
        const [headerIntra, ...itemIntra] =
          data &&
          data?.split(
            `{"key":"ind0k","text":"INDICATION","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":10,"style":"BOLD"},{"offset":0,"length":10,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHeaderIntra = (itemIntra || []).map((item) => {
          return item.split(`{"key":"text8","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
        });
        if (intratxt.length > 0 && checkHeaderIntra && checkHeaderIntra[0] && checkHeaderIntra[0].length > 0) {
          data = data?.replace(`${checkHeaderIntra}`, `${intratxt}`);
        }
      }
      //
      let closureCheck = "";
      let footerCheck = "";
      // header 12, 13, 10 ,11  ACCESSS, DIAGNOSTIC INTERVENTION Closure Device
      if (list_procedurals && list_procedurals.length > 0 && !activeIntra) {
        let allUltrasound = "";
        let catheter = [];
        let headerCheck = "";
        let valueGradient = `{"key":"gradientheaderempty1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"gradienttitle","text":"Peak Systolic Pressure Gradients Measurements","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":45,"style":"BOLD"},{"offset":0,"length":45,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"gradientheaderempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"gradientTextfirst","text":"A unilateral pullback technique was utilized to obtain peak systolic pressure measurements proximal to the identified lesion and distal to the identified lesion under resting conditions. Systemic blood pressure changes were monitored simultaneously with noninvasive (cuff) blood pressure measurements to detect systemic blood pressure variations. The measurements of the pressure gradients is a direct estimation of arterial energy loss attributable to a given diseased arterial segment and the most sensitive indicator of hemodynamically significant stenosis and a marker for a trigger plaque destabilization.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"gradientTextemptyfirst","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < list_procedurals.length; i++) {
          let rowBody = "";
          let ultrasoundCheck = "";
          let plaque = "";
          if (list_procedurals?.[i]?.["body"] && list_procedurals?.[i]?.["body"]?.["type"] === "vein") {
            rowBody = getVeinLabel(list_procedurals?.[i]?.["body"]["part"]);
          } else if (list_procedurals?.[i]?.["body"] && list_procedurals?.[i]?.["body"]?.["type"] !== "vein") {
            rowBody = getArteryLabel(list_procedurals?.[i]?.["body"]?.["part"]);
          } else {
            if (list_procedurals?.[i]?.["body"]?.["part"]) {
              rowBody = getArteryLabel(list_procedurals?.[i]?.["body"]?.["part"]);
            } else {
              rowBody = "";
            }
          }
          catheter.push(rowBody);
          // map check
          let checkArray = null;
          if (
            list_procedurals?.[i]?.["medicals"].indexOf("ivus") > -1 &&
            (list_procedurals?.[i]?.["medicals"].indexOf("stenosis-0") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-20") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-40") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-60") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-80") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-100") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-100") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-80") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-60") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-40") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-20") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-0") > -1)
          ) {
            checkArray = true;
          } else if (
            list_procedurals?.[i]?.["medicals"].indexOf("ivus") === -1 &&
            (list_procedurals?.[i]?.["medicals"].indexOf("stenosis-0") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-20") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-40") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-60") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-80") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("stenosis-100") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-100") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-80") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-60") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-40") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-20") > -1 ||
              list_procedurals?.[i]?.["medicals"].indexOf("in-stent-0") > -1)
          ) {
            checkArray = false;
          }
          let checkIVUSdetail = "";
          checkIVUSdetail = (list_procedurals?.[i]?.["details"] || []).map((m, index) => {
            if (m?.["item"] === "IVUS" || (m?.["product_description"] && m?.["product_description"].toString().indexOf("IVUS") != -1)) {
              return m?.["product_description"];
            }
          });
          let additionalIVUSDetail = "";
          additionalIVUSDetail = (list_procedurals?.[i]?.["details"] || []).map((m, index) => {
            if (m?.["item"] === "IVUS" || (m?.["product_description"] && m?.["product_description"].toString().indexOf("IVUS") != -1)) {
              plaque = m?.["plaque"];
              let temp = `${m?.["product_description"]} was utilized on the vessel to demonstrate a maximum diameter of ${m?.["diameter"]} mm. `;
              if (m?.["proximal"] === true) {
                temp += `There is continuous plaque extending into the proximal vessel segment. `;
              } else {
                temp += `There is no continuous plaque extending into the proximal vessel segment. `;
              }
              if (m?.["distal"] === true) {
                temp += `There is continuous plaque extending from the distal vessel segment. `;
              } else {
                temp += `There is no continuous plaque extending from the distal vessel segment. `;
              }
              if (m?.["remodeling"] === true) {
                temp += `There is evidence of negative remodeling. `;
              } else {
                temp += `There is no evidence of negative remodeling. `;
              }
              temp += `The plaque characteristics is ${m?.["plaque"]} in nature. `;
              return temp;
            }
          });
          checkIVUSdetail = checkIVUSdetail.toString().replace("IVUS", "");
          checkIVUSdetail = checkIVUSdetail.replace(/,/g, "");
          additionalIVUSDetail = additionalIVUSDetail.toString().replace(/,/g, "");
          if (checkArray === true) {
            let trueCheck = "";
            for (let fc = 0; fc < (list_procedurals?.[i]?.["medicals"] || "").length || 0; fc++) {
              if (
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-60" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-0" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-20" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-40" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-80" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-100"
              ) {
                if (list_procedurals?.[i]?.["medicals"][fc].includes("-")) {
                  const replaceSplit = list_procedurals?.[i]?.["medicals"][fc].split("-");
                  if (replaceSplit && replaceSplit.length > 0) {
                    trueCheck += `${replaceSplit[1]}% ${replaceSplit[0]},`;
                  }
                }
              }
              if (
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-100" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-80" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-60" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-40" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-20" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-0"
              ) {
                if (list_procedurals?.[i]?.["medicals"][fc].includes("-")) {
                  const replaceSplit = list_procedurals?.[i]?.["medicals"][fc].split("-");
                  if (replaceSplit && replaceSplit.length > 0) {
                    trueCheck += `${replaceSplit[2]}% in-stent,`;
                  }
                }
              }
            }
            if (
              !data?.includes(
                `{"key":"checked${i}","text":"A catheter was placed over the wire in the ${rowBody} which demonstrates ${
                  trueCheck.includes(",") && trueCheck.split(",")[0]
                } on diagnostic angiography and ${checkIVUSdetail} Intravascular Ultrasound (IVUS). ${additionalIVUSDetail}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              )
            ) {
              let temp_text = "";

              for (let fc = 0; fc < (list_procedurals?.[i]?.["medicals"] || "").length || 0; fc++) {
                // thêm Thrombus
                const indexThrombus = (list_procedurals?.[i]?.details || []).findIndex((r) => r?.item == "Thrombus");
                if (list_procedurals?.[i]?.["medicals"][fc] == "thrombus") {
                  if (list_procedurals?.[i]?.details?.[indexThrombus]?.["thrombus"]) {
                    temp_text +=
                      " " + list_procedurals?.[i]?.details?.[indexThrombus]?.["thrombus"] + " thrombus is visualized within the arterial segment.";
                  }
                }

                // thêm Dissection
                const indexDissection = (list_procedurals?.[i]?.details || []).findIndex((r) => r?.item == "Dissection");
                if (list_procedurals?.[i]?.["medicals"][fc] == "dissection") {
                  if (list_procedurals?.[i]?.details?.[indexDissection]?.["dissection"]) {
                    temp_text +=
                      " " +
                      `A ${list_procedurals?.[i]?.details?.[indexDissection]?.["body"]} ${list_procedurals?.[i]?.details?.[indexDissection]?.["dissection"]} dissection is visualized within the arterial segment.`;
                  }
                }
              }

              headerCheck += `{"key":"hedlkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"hedlkm${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                rowBody?.length || 0
              },"style":"BOLD"},{"offset":0,"length":${
                rowBody?.length || 0
              },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"endheaderempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              headerCheck += `{"key":"checked${i}","text":"A catheter was placed over the wire in the ${rowBody} which demonstrates ${
                trueCheck.includes(",") && trueCheck.split(",")[0]
              } on diagnostic angiography and ${checkIVUSdetail} Intravascular Ultrasound (IVUS). ${additionalIVUSDetail} ${temp_text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

              // tìm index dc phép chạy
              /*const indexDetailCheck = list_procedurals?.[i]?.details.findIndex(
                (r) => r?.body === "Left Carotid Artery" || r?.body === "Right Carotid Artery"
              );
              // thêm text external_carotid_artery vs intracranial_circulation nếu trong trường hợp có dữ liệu
              if (indexDetailCheck != -1) {
                if (list_procedurals?.[i]?.details?.[indexDetailCheck]?.["external_carotid_artery"]) {
                  headerCheck += `{"key":"external_carotid_artery${rowBody}","text":"${render_external_carotid_artery(
                    list_procedurals?.[i]?.details?.[indexDetailCheck]?.["external_carotid_artery"]
                  )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                }
                if (list_procedurals?.[i]?.details?.[indexDetailCheck]?.["intracranial_circulation"]) {
                  headerCheck += `{"key":"intracranial_circulation${rowBody}","text":"${render_intracranial_circulation(
                    list_procedurals?.[i]?.details?.[indexDetailCheck]?.["intracranial_circulation"]
                  )}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                }
              }*/
            }
          } else if (checkArray === false) {
            let falseCheck = "";
            for (let fc = 0; fc < (list_procedurals?.[i]?.["medicals"] || "").length || 0; fc++) {
              if (
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-60" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-0" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-20" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-40" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-80" ||
                list_procedurals?.[i]?.["medicals"][fc] == "stenosis-100"
              ) {
                if (list_procedurals?.[i]?.["medicals"][fc].includes("-")) {
                  const replaceSplit = list_procedurals?.[i]?.["medicals"][fc].split("-");
                  if (replaceSplit && replaceSplit.length > 0) {
                    falseCheck += `${replaceSplit[1]}% ${replaceSplit[0]},`;
                  }
                }
              } else if (
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-100" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-80" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-60" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-40" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-20" ||
                list_procedurals?.[i]?.["medicals"][fc] == "in-stent-0"
              ) {
                if (list_procedurals?.[i]?.["medicals"]?.[fc] && list_procedurals?.[i]?.["medicals"]?.[fc].includes("-")) {
                  const replaceSplit = list_procedurals?.[i]?.["medicals"]?.[fc] ? list_procedurals?.[i]?.["medicals"]?.[fc].split("-") : "";
                  if (replaceSplit && replaceSplit.length > 0) {
                    falseCheck += `${replaceSplit[2]}% in-stent,`;
                  }
                }
              }
            }
            if (
              !data?.includes(
                `{"key":"checkout${i}","text":"The ${rowBody} demonstrates ${
                  falseCheck.includes(",") && falseCheck.split(",")[0]
                } on diagnostic angiography.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              ) &&
              !headerCheck.includes(
                `{"key":"checkout${i}","text":"The ${rowBody} demonstrates ${
                  falseCheck.includes(",") && falseCheck.split(",")[0]
                } on diagnostic angiography.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              )
            ) {
              headerCheck += `{"key":"hedlkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"hedlkm${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                rowBody?.length || 0
              },"style":"BOLD"},{"offset":0,"length":${
                rowBody?.length || 0
              },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"endheaderempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

              // tìm index dc phép chạy
              const indexDetailCheck = list_procedurals?.[i]?.details.findIndex(
                (r) => r?.body === "Left Carotid Artery" || r?.body === "Right Carotid Artery"
              );
              // thêm text external_carotid_artery vs intracranial_circulation nếu trong trường hợp có dữ liệu
              let temp_text = "";
              if (indexDetailCheck != -1) {
                if (list_procedurals?.[i]?.details?.[indexDetailCheck]?.["external_carotid_artery"]) {
                  temp_text += render_external_carotid_artery(list_procedurals?.[i]?.details?.[indexDetailCheck]?.["external_carotid_artery"]);
                }
                if (list_procedurals?.[i]?.details?.[indexDetailCheck]?.["intracranial_circulation"]) {
                  temp_text +=
                    " " + render_intracranial_circulation(list_procedurals?.[i]?.details?.[indexDetailCheck]?.["intracranial_circulation"]);
                }
              }

              for (let fc = 0; fc < (list_procedurals?.[i]?.["medicals"] || "").length || 0; fc++) {
                // thêm Thrombus
                const indexThrombus = (list_procedurals?.[i]?.details || []).findIndex((r) => r?.item == "Thrombus");
                if (list_procedurals?.[i]?.["medicals"][fc] == "thrombus") {
                  if (list_procedurals?.[i]?.details?.[indexThrombus]?.["thrombus"]) {
                    temp_text +=
                      " " + list_procedurals?.[i]?.details?.[indexThrombus]?.["thrombus"] + " thrombus is visualized within the arterial segment.";
                  }
                }

                // thêm Dissection
                const indexDissection = (list_procedurals?.[i]?.details || []).findIndex((r) => r?.item == "Dissection");
                if (list_procedurals?.[i]?.["medicals"][fc] == "dissection") {
                  if (list_procedurals?.[i]?.details?.[indexDissection]?.["dissection"]) {
                    temp_text +=
                      " " +
                      `A ${list_procedurals?.[i]?.details?.[indexDissection]?.["body"]} ${list_procedurals?.[i]?.details?.[indexDissection]?.["dissection"]} dissection is visualized within the arterial segment.`;
                  }
                }
              }

              // thêm Thrombectomy

              const indexThrombectomy = (list_procedurals?.[i]?.details || []).findIndex((r) => r?.item == "Thrombectomy");
              const lengthValue =
                (list_procedurals?.[i]?.details?.[indexThrombectomy]?.["body"] &&
                  list_procedurals?.[i]?.details?.[indexThrombectomy]?.["body"].length) ||
                0;
              if (indexThrombectomy != -1 && lengthValue && list_procedurals?.[i]?.details?.[indexThrombectomy]?.["post_stenosis"]) {
                footerCheck += `{"key":"textinterventionthrombectomyemtpyheader${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keyghear${i}","text":"${list_procedurals?.[i]?.details?.[indexThrombectomy]?.["body"]}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${lengthValue},"style":"BOLD"},{"offset":0,"length":${lengthValue},"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textinterventionthrombectomyemtpy${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
{"key":"textinterventionthrombectomy${i}","text":"Rheolytic thrombectomy was performed via the AngioJet. A 6 French AngioJet Peripheral Thrombectomy System was utilized under the thrombectomy setting with multiple passes through the thrombotic segments. The Power Pulse lytic delivery setting was utilized to deliver 8 mg of TPA (Alteplase) within the thrombotic segments and allowed to activate for a total of 5 minutes. The mechanical thrombectomy setting was subsequently utilized with multiple passes through the residual thrombotic segments. A diagnostic angiogram was performed that demonstrated a residual stenosis of ${list_procedurals?.[i]?.details?.[indexThrombectomy]?.["post_stenosis"]}.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }

              headerCheck += `{"key":"checkout${i}","text":"The ${rowBody} demonstrates ${
                falseCheck.includes(",") && falseCheck.split(",")[0]
              } on diagnostic angiography. ${temp_text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          }
          if (
            (list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "closure-device") ||
            (list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "local-anesthesia") ||
            (list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "access") ||
            (list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "ultrasound")
          ) {
            let check_find_id = null;
            let checkSuccess = [];
            checkSuccess = (list_procedurals?.[i]?.["medicals"] || []).map((r, index) => {
              if (r == "closure-device") {
                check_find_id = index;
                return true;
              } else if (r == "local-anesthesia" || r == "access" || r == "ultrasound") {
                check_find_id = null;
                return false;
              } else {
                return null;
              }
            });
            if (checkSuccess.some((r) => r == true)) {
              if (check_find_id !== null) {
                closureCheck += `{"key":"colosureheader${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"headercolouser${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                  rowBody?.length || 0
                },"style":"BOLD"},{"offset":0,"length":${
                  rowBody?.length || 0
                },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"kmgjan6${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                closureCheck += `{"key":"checkedcolosure${i}","text":"The procedure was terminated. The sheath was removed. ${`Utilizing standard techniques the ${
                  list_procedurals?.[i]?.["details"] &&
                  list_procedurals?.[i]?.["details"][check_find_id] &&
                  list_procedurals?.[i]?.["details"][check_find_id]["product_description"]
                    ? list_procedurals?.[i]?.["details"][check_find_id]["product_description"]
                    : ""
                } Closure Device was utilized for arterial closure.`} The patient tolerated the procedure well, there were no acute complications. All counts were correct and I was present through the entire case.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              }
            } else if (checkSuccess.some((r) => r == false)) {
              let notesValue = "";
              if (post_operative && post_operative.length > 0) {
                const notesElement = post_operative.find((element) => element.type === "Notes");
                if (notesElement) {
                  notesValue = notesElement.value.replaceAll(`"`, ``);
                }
              }
              closureCheck += `{"key":"colosureheader${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"headercolouser${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                rowBody?.length || 0
              },"style":"BOLD"},{"offset":0,"length":${
                rowBody?.length || 0
              },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"kmgjan6${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
              closureCheck +=
                `{"key":"falsecolosure${i}","text":"The procedure was terminated. ` +
                notesValue +
                ` All counts were correct and I was present through the entire case.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            }
          }

          if ((list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "ultrasound")) {
            let ultrasound_index = null;
            let access_index = null;
            (list_procedurals?.[i]?.["medicals"] || []).map((r, index) => {
              if (r === "ultrasound") {
                ultrasound_index = index;
              } else if (r === "local-anesthesia") {
                access_index = index;
              }
            });

            ultrasoundCheck += `Duplex ultrasonography using the ${
              list_procedurals?.[i]?.["details"] &&
              list_procedurals?.[i]?.["details"][ultrasound_index] &&
              list_procedurals?.[i]?.["details"][ultrasound_index]["product_description"]
                ? list_procedurals?.[i]?.["details"][ultrasound_index]["product_description"]
                : ""
            } was utilized to visualize the ${rowBody}. The vessel was patent and the plaque was mild with mild calcification. An electronic image of the ultrasound findings is contained within the patients record. ${
              list_procedurals?.[i]?.["details"] &&
              list_procedurals?.[i]?.["details"][access_index] &&
              list_procedurals?.[i]?.["details"][access_index]["product_description"]
                ? list_procedurals?.[i]?.["details"][access_index]["product_description"]
                : ""
            } was infiltrated within the overlying skin to anesthetize the surface skin overlying the ${rowBody}. `;
          }
          if ((list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "access")) {
            let access_index = null;
            (list_procedurals?.[i]?.["medicals"] || []).map((r, index) => {
              if (r === "access") {
                access_index = index;
              }
            });
            ultrasoundCheck += `A micropuncture needle from the ${
              list_procedurals?.[i]?.["details"] &&
              list_procedurals?.[i]?.["details"][access_index] &&
              list_procedurals?.[i]?.["details"][access_index]["product_description"]
                ? list_procedurals?.[i]?.["details"][access_index]["product_description"]
                : ""
            } was guided sonographically into the ${rowBody} in a retrograde fashion and this was used to pass a micropuncture wire and subsequently exchanged with a micropuncture sheath. A larger sheath was exchanged with the micropuncture sheath to maintain access within the ${rowBody} for diagnostic and intervention purposes. `;
          }
          if ((list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "gradient")) {
            const findIndexGradient = (list_procedurals?.[i]?.["details"] || []).findIndex((r) => r?.item === "Gradient");
            if (findIndexGradient != -1) {
              const valueGradientPressure =
                Number(list_procedurals?.[i]?.["details"][findIndexGradient]["psp_proximal"]) -
                Number(list_procedurals?.[i]?.["details"][findIndexGradient]["psp_distal"]);
              valueGradient += `{"key":"gradientdes${i}","text":"The proximal peak systolic pressure was ${
                list_procedurals?.[i]?.["details"][findIndexGradient]["psp_proximal"]
              } mmHg in the ${list_procedurals?.[i]?.["details"][findIndexGradient]["body"]} and ${
                list_procedurals?.[i]?.["details"][findIndexGradient]["psp_distal"]
              } mmHg in the ${
                list_procedurals?.[i]?.["details"][findIndexGradient]["distal_location"]
              }. The pressure gradient across the disease arterial segment was ${valueGradientPressure ? valueGradientPressure : ""} mmHg.`;

              if (valueGradientPressure) {
                if (valueGradientPressure > 20) {
                  // Nếu pressure gradient > 20 thì viết text sau:
                  valueGradient += ` The peak systolic gradient is greater than or equal to 20 mmHg and can reaffirm the severity of the disease arterial segment as greater than 70% or greater diameter stenosis.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"gradientTextempty2${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                } else {
                  // Nếu pressure gradient < 20 thì viết text sau:
                  valueGradient += ` The peak systolic gradient is less than 20 mmHg and can reaffirm the severity of the disease arterial segment as less than 70% diameter stenosis.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"gradientTextempty2${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                }
              }
            }
          }
          if (
            (list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "ultrasound") ||
            (list_procedurals?.[i]?.["medicals"] || []).some((r) => r == "access")
          ) {
            allUltrasound += `{"key":"unslkmempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"anes${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
              rowBody?.length || 0
            },"style":"BOLD"},{"offset":0,"length":${
              rowBody?.length || 0
            },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"txtneedleempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
            allUltrasound += `{"key":"kmsacvn${i}","text":"${ultrasoundCheck}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }

          let footerBody = "";
          let checkFooter = "";
          for (let ft = 0; ft < list_procedurals?.[i]?.["medicals"].length || 0; ft++) {
            if (
              list_procedurals?.[i]?.["medicals"]?.[ft] == "directional-atherectomy" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "laser-atherectomy" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "rotational-atherectomy" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "obital-atherectomy" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "balloon-angioplasty" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "balloon-expandable-covered-stent" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "covered-stent" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "balloon-expandable-stent" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "self-expanding-stent" ||
              list_procedurals?.[i]?.["medicals"]?.[ft] == "closure-device"
            ) {
              if (list_procedurals?.[i]?.["medicals"]?.[ft] == "directional-atherectomy") {
                footerBody = "Directional Atherectomy";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "laser-atherectomy") {
                footerBody = "Laser Atherectomy";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "rotational-atherectomy") {
                footerBody = "Rotational Atherectomy";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "obital-atherectomy") {
                footerBody = "Obital Atherectomy";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "balloon-expandable-covered-stent") {
                footerBody = "Balloon Expandable Covered Stent";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "covered-stent") {
                footerBody = "Covered Stent";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "balloon-expandable-stent") {
                footerBody = "Balloon Expandable Stent";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "self-expanding-stent") {
                footerBody = "Self Expanding Stent";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "balloon-angioplasty") {
                footerBody = "Balloon Angioplasty";
              } else if (list_procedurals?.[i]?.["medicals"]?.[ft] == "closure-device") {
                footerBody = "Closure Device";
              }
              if (
                footerBody == "Directional Atherectomy" ||
                footerBody == "Laser Atherectomy" ||
                footerBody == "Rotational Atherectomy" ||
                footerBody == "Obital Atherectomy" ||
                footerBody == "Covered Stent" ||
                footerBody == "Balloon Expandable Stent" ||
                footerBody == "Self Expanding Stent" ||
                footerBody == "Balloon Expandable Covered Stent" ||
                footerBody == "Balloon Angioplasty"
              ) {
                if (
                  footerBody == "Directional Atherectomy" ||
                  footerBody == "Laser Atherectomy" ||
                  footerBody == "Rotational Atherectomy" ||
                  footerBody == "Obital Atherectomy"
                ) {
                  let balloon_detail = "";
                  let balloon_atm = "";
                  let balloon_time = "";
                  let plaque = "";
                  let proximal = false;
                  let distal = false;
                  let remodeling = false;
                  let balloon_count = 0;
                  for (let temp = 0; temp < (list_procedurals?.[i]?.["medicals"] || "").length || 0; temp++) {
                    if (list_procedurals?.[i]?.["medicals"]?.[temp] == "ivus") {
                      plaque = (list_procedurals?.[i]?.["details"]?.[temp]?.["plaque"] || "") + " ";
                      proximal = list_procedurals?.[i]?.["details"]?.[temp]?.["proximal"] || "";
                      distal = list_procedurals?.[i]?.["details"]?.[temp]?.["distal"] || "";
                      remodeling = list_procedurals?.[i]?.["details"]?.[temp]?.["remodeling"] || "";
                      if (proximal) {
                        balloon_count++;
                      }
                      if (distal) {
                        balloon_count++;
                      }
                      if (remodeling) {
                        balloon_count++;
                      }
                    }
                  }
                  let focal_sub_text = "";
                  if (balloon_count == 1) focal_sub_text = "focal ";
                  else if (balloon_count == 2) focal_sub_text = "intermediate ";
                  else if (balloon_count == 3) focal_sub_text = "diffuse ";
                  //
                  let balloon_sec_text = `Moderate to severe ${plaque}disease of the undilatable segment of the ${focal_sub_text}lesion effected the plan to utilize a ${footerBody} per COR IIA/IIB SCAI consensus guidelines for atherectomy as a adjunctive therapy. In addition, a decision was made to use ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]
                      ? `${list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]}`
                      : ""
                  } ${footerBody} due to the high risk for dissection and the long lesion length with significant plaque and high risk for flow-limiting dissection and therefore calcified plaque modification utilizing ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]
                      ? `${list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]}`
                      : ""
                  } ${footerBody} was indicated.`;
                  for (let temp = 0; temp < list_procedurals?.[i]?.["medicals"].length || 0; temp++) {
                    if (list_procedurals?.[i]?.["medicals"]?.[temp] == "balloon-angioplasty") {
                      if (list_procedurals?.[i]?.["details"]?.[temp]?.["product_description"])
                        balloon_detail = list_procedurals?.[i]?.["details"]?.[temp]?.["product_description"] + " ";
                      if (list_procedurals?.[i]?.["details"]?.[temp]?.["atm"])
                        balloon_atm = `at ${list_procedurals?.[i]?.["details"]?.[temp]?.["atm"]} ATM `;
                      if (list_procedurals?.[i]?.["details"]?.[temp]?.["inflation_time"]) {
                        balloon_time = `${list_procedurals?.[i]?.["details"]?.[temp]?.["inflation_time"]} minutes`;
                      } else {
                        balloon_time = `2 minutes`;
                      }
                    }
                  }
                  if (
                    !data?.includes(`fptoamempty${i}`) &&
                    !data?.includes(`pfimad${i}`) &&
                    !footerCheck.includes(`fptoamempty${i}`) &&
                    !footerCheck.includes(`pfimad${i}`)
                  ) {
                    footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                      rowBody?.length || 0
                    },"style":"BOLD"},{"offset":0,"length":${
                      rowBody?.length || 0
                    },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                  }
                  checkFooter += `A ${balloon_detail}Angioplasty balloon was utilized within the ${rowBody} and insufflated ${balloon_atm}for a total of ${balloon_time}. There was minimal improvement of the lesion and was determined to be undilatable. ${balloon_sec_text} A ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]
                      ? `${list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]}`
                      : ""
                  } ${footerBody} was utilized within the ${rowBody}${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["number_of_passes"]
                      ? ` for a total of ${list_procedurals?.[i]?.["details"]?.[ft]?.["number_of_passes"]} passes`
                      : ""
                  }${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["pulses_delivered"]
                      ? ` and ${list_procedurals?.[i]?.["details"]?.[ft]?.["pulses_delivered"]} pulses`
                      : ""
                  }${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["frequency"]
                      ? ` and frequency of ${list_procedurals?.[i]?.["details"]?.[ft]?.["frequency"]} Hz`
                      : ""
                  }. ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["post_stenosis"]
                      ? `The resultant post-stenosis was ${list_procedurals?.[i]?.["details"]?.[ft]?.["post_stenosis"]}. `
                      : ""
                  }${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["treatment_time"]
                      ? `The total treatment time for the laser atherectomy was ${list_procedurals?.[i]?.["details"]?.[ft]?.["treatment_time"]} seconds.`
                      : ""
                  }`;
                } else if (
                  footerBody == "Covered Stent" ||
                  footerBody == "Balloon Expandable Stent" ||
                  footerBody == "Self Expanding Stent" ||
                  footerBody == "Balloon Expandable Covered Stent"
                ) {
                  if (
                    !data?.includes(`fptoamempty${i}`) &&
                    !data?.includes(`pfimad${i}`) &&
                    !footerCheck.includes(`fptoamempty${i}`) &&
                    !footerCheck.includes(`pfimad${i}`)
                  ) {
                    footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                      rowBody?.length || 0
                    },"style":"BOLD"},{"offset":0,"length":${
                      rowBody?.length || 0
                    },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                  }
                  checkFooter += ` A ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]
                      ? `${list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]}`
                      : ""
                  } Placement of ${footerBody} was placed for Salvage therapy within ${
                    rowBody ? `the ${rowBody}` : ""
                  } for a suboptimal or failed result from balloon dilation residual diameter stenosis greater than 50% (L33763)${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["post_stenosis"]
                      ? `. The resultant post-stenosis was ${list_procedurals?.[i]?.["details"]?.[ft]?.["post_stenosis"]}.`
                      : ""
                  }`;
                } else if (footerBody == "Balloon Angioplasty") {
                  if (
                    !data?.includes(`fptoamempty${i}`) &&
                    !data?.includes(`pfimad${i}`) &&
                    !footerCheck.includes(`fptoamempty${i}`) &&
                    !footerCheck.includes(`pfimad${i}`)
                  ) {
                    footerCheck += `{"key":"fptoamempty${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"pfimad${i}","text":"${rowBody}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                      rowBody?.length || 0
                    },"style":"BOLD"},{"offset":0,"length":${
                      rowBody?.length || 0
                    },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"fptkansh${i}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                  }

                  checkFooter += ` A ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]
                      ? `${list_procedurals?.[i]?.["details"]?.[ft]?.["product_description"]}`
                      : ""
                  } ${footerBody} balloon was utilized within the ${rowBody ? rowBody : ""} ${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["atm"]
                      ? `and insufflated at ${list_procedurals?.[i]?.["details"]?.[ft]?.["atm"]} ATM`
                      : ""
                  }${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["inflation_time"]
                      ? ` for a total of ${list_procedurals?.[i]?.["details"]?.[ft]?.["inflation_time"]} minutes`
                      : ""
                  }${
                    list_procedurals?.[i]?.["details"]?.[ft] && list_procedurals?.[i]?.["details"]?.[ft]?.["post_stenosis"]
                      ? `. The resultant post-stenosis was ${list_procedurals?.[i]?.["details"]?.[ft]?.["post_stenosis"]}.`
                      : ""
                  }`;
                }
              }
            }
          }
          if (
            footerBody == "Directional Atherectomy" ||
            footerBody == "Laser Atherectomy" ||
            footerBody == "Rotational Atherectomy" ||
            footerBody == "Obital Atherectomy" ||
            footerBody == "Covered Stent" ||
            footerBody == "Balloon Expandable Stent" ||
            footerBody == "Self Expanding Stent" ||
            footerBody == "Balloon Expandable Covered Stent" ||
            footerBody == "Balloon Angioplasty"
          ) {
            footerCheck += `{"key":"lmasjdv${i}","text":"${checkFooter}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        }
        //
        if (closureCheck.length > 0) {
          if (!data?.includes(`${closureCheck}`)) {
            data = data?.replace(
              `{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${closureCheck}{"key":"text11","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        if (valueGradient.length > 0) {
          if (!data?.includes(`${valueGradient}`)) {
            data = data?.replace(
              `{"key":"textgradient","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${valueGradient}{"key":"text14","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        if (allUltrasound.length > 0) {
          if (!data?.includes(allUltrasound)) {
            data = data?.replace(
              `{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${allUltrasound}{"key":"text12","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        if (
          headerCheck.length > 0 &&
          !data?.includes(`${headerCheck}{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)
        ) {
          data = data?.replace(
            `{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${headerCheck}{"key":"text13","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
        // CATHETER MANIPULATION
        if (catheter.length > 0) {
          let catheter_text =
            "Utilizing a control wire and catheter combination a series of complex maneuvers and manipulation were performed with IVUS and fluoroscopy with digital subtraction angiography were advanced to have the selective catheter placement within the " +
            catheter.join(", ") +
            " in a stepwise manner.";
          let catheterRenderedText = `{"key":"cathetertextempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"cathetertext","text":"${catheter_text}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          if (
            !data?.includes(
              `${catheterRenderedText}{"key":"text22","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            )
          ) {
            data = data?.replace(
              `{"key":"text22","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${catheterRenderedText}{"key":"text22","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }

        // header 10 INTERVENTION
        if (footerCheck.length > 0 && !data?.includes(footerCheck)) {
          data = data?.replace(
            `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${footerCheck}{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        } else if (footerCheck.length == 0) {
          data = data?.replace(
            `{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"textinterventionnoneempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textinterventionnone","text":"None","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"text10","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }
      // header Review Text
      if (activeIntra) {
        if (detail_angio_type && detail_angio_type.text_template && detail_angio_type.text_template.includes("blocks")) {
          let valueImportFooter = detail_angio_type.text_template.replace(`{"blocks":[`, "");
          if (valueImportFooter.includes(`],"entityMap":{`) && valueImportFooter.includes(`}}}}}`)) {
            const [enti, ...entis] = valueImportFooter && valueImportFooter.split(`],"entityMap":{`);
            const checkEnti = (entis || []).map((item) => {
              return item.split(`}}}}}`)[0];
            });
            if (checkEnti && checkEnti[0].length > 0) {
              valueImportFooter = valueImportFooter.replace(`],"entityMap":{${checkEnti[0]}}}}}}`, ``);
            }
          }
          if (valueImportFooter.includes(`"entityMap":[{`)) {
            const [enti, ...entis] = valueImportFooter && valueImportFooter.split(`],"entityMap":[{"`);
            const checkEnti = (entis || []).map((item) => {
              return item.split(`]}`)[0];
            });
            if (checkEnti && checkEnti[0].length > 0) {
              valueImportFooter = valueImportFooter.replace(`],"entityMap":[{"${checkEnti[0]}]}`, ``);
            }
          }
          if (valueImportFooter.includes(`],"entityMap":[]}`)) {
            valueImportFooter = valueImportFooter.replace(`],"entityMap":[]}`, "");
          }
          if (valueImportFooter.includes(`],"entityMap":{}}`)) {
            valueImportFooter = valueImportFooter.replace(`],"entityMap":{}}`, "");
          }
          const [headerRV, ...itemRV] =
            data && data?.split(`{"key":"xmtem3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
          const checkHeaderRV = (itemRV || []).map((item) => {
            return item.split(`{"key":"textRvC","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (checkHeaderRV && checkHeaderRV[0] && checkHeaderRV[0] != "" && valueImportFooter && valueImportFooter.length > 0) {
            data = data?.replace(
              `${checkHeaderRV[0]}{"key":"textRvC","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${valueImportFooter},{"key":"textRvC","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          } else {
            if (valueImportFooter && valueImportFooter.length > 0) {
              data = data?.replace(
                `{"key":"textRvC","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
                `${valueImportFooter},{"key":"textRvC","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
              );
            }
          }
        }
        if (intraAnswers && intraAnswers.length > 0) {
          for (let i = 0; i < intraAnswers.length; i++) {
            const elementChecked =
              (intraAnswers[i] && intraAnswers[i]["text_template"] && intraAnswers[i]["text_template"].split(",").join(" ") + " ") || "";
            if (intraAnswers[i]["angio_procedure_type_variable"] && intraAnswers[i]["angio_procedure_type_variable"]["variable_name"]) {
              if (data.includes(`[${intraAnswers[i]["angio_procedure_type_variable"]["variable_name"]}]`)) {
                //
                //
                // eslint-disable-next-line no-loop-func
                data = data.replaceAll(
                  `[${intraAnswers[i]["angio_procedure_type_variable"]["variable_name"]}]`,
                  !intraAnswers[i]["text_template"] || intraAnswers[i]["text_template"].trim() == "" ? "" : elementChecked
                );
              }
            }
          }
          // fix new!!
          const [headerFixNew, ...itemFixnew] =
            data && data?.split(`{"key":"xmtem3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
          const checkFixNew = (itemFixnew || []).map((item) => {
            return item.split(`{"key":"textRvC","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          // join String
          if (checkFixNew[0]) {
            let valueFix = `${checkFixNew[0]}`;
            valueFix = valueFix.substring(0, valueFix.length - 1);
            if (valueFix.includes(`, "entityMap":[]}`)) {
              valueFix = valueFix.replace(`, "entityMap":[]}`, "");
            } else {
              valueFix = valueFix.replace(`, "entityMap":{}}`, "");
            }
            valueFix = `[${valueFix}]`;
            let dataImport = valueFix ? JSON.parse(valueFix) : null;
            if (dataImport && Array.isArray(dataImport)) {
              const arrSpecial = dataImport.filter((r) => r?.text && (r?.text.includes("•") || r?.text.includes("!!new!!")));
              if (arrSpecial && arrSpecial.length > 0) {
                arrSpecial.map((r) => {
                  const indexSpecial = dataImport.findIndex((ri) => ri?.key && ri?.key === r.key);
                  if (indexSpecial !== -1) {
                    const valueParse = JSON.parse(`[${CreateTextN(`${r.text}`)}]`) || null;
                    if (valueParse && Array.isArray(valueParse)) {
                      dataImport.splice(indexSpecial, 1, ...valueParse);
                    }
                  }
                });
              }
              // change array => object
              let damix = dataImport;
              damix = JSON.stringify(damix).replace("[", "");
              damix = damix.substring(0, damix.length - 1);
              data = data.replace(checkFixNew[0], `${damix},`);
            }
          }
        }
      }
      // for loot cpt header 4 PROCEDURE PERFORMED
      let cpt_text = "";
      if (dataCPT.length > 0) {
        cpt_text += `{"key":"txtcptspace","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < dataCPT.length; i += 1) {
          let modifier = "";
          if (dataCPT && dataCPT[i] && dataCPT[i].pivot && dataCPT[i].pivot.modifier) {
            modifier = `(${dataCPT[i].pivot.modifier})`;
          } else modifier = "";

          cpt_text += `{"key":"cpttxt${i}","text":"${dataCPT && dataCPT[i] && dataCPT[i]["cpt_code"]} ${
            dataCPT && dataCPT[i] && dataCPT[i]["short_desc"]
          } ${modifier}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }
      // header 4 PROCEDURE PERFORMED:
      const [header4, ...item4] =
        data &&
        data?.split(
          `{"key":"pr98x","text":"PROCEDURE PERFORMED","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"BOLD"},{"offset":0,"length":19,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader4 = (item4 || []).map((item) => {
        return item.split(`{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      if (checkHeader4 && checkHeader4[0] && checkHeader4[0] != "") {
        data = data?.replace(
          `${checkHeader4[0]}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data?.replace(
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${cpt_text}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (dataCPT.length == 0) {
        data = data?.replace(
          `${checkHeader4[0]}{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text4","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // header 6 COMPLICATIONS
      let complication = "";
      const [header6, ...item6] =
        data &&
        data?.split(
          `{"key":"cop2i","text":"COMPLICATIONS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":13,"style":"BOLD"},{"offset":0,"length":13,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader6 = (item6 || []).map((item) => {
        return item.split(`{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      if (complication_value && complication_value.length > 0) {
        complication = `{"key":"txt6empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"comlak3","text":"${complication_value}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (!data?.includes(complication)) {
          data = data?.replace(
            `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `${complication}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
      }
      if (complication_value && complication_value.length == 0) {
        data = data?.replace(
          `${checkHeader6[0]}{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text6","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // total radiation time
      if (appointment?.appointment_type_id != 7 && appointment?.appointment_type_id != 10 && appointment?.appointment_type_id != 12) {
        // total contrast load
        let contrast_load = "";
        const [header7, ...item7] =
          data &&
          data?.split(
            `{"key":"ae94m","text":"TOTAL CONTRAST LOAD","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":19,"style":"BOLD"},{"offset":0,"length":19,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHeader7 = (item7 || []).map((item) => {
          return item.split(`{"key":"textcontrast","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
        });
        if (total_contrast_load && total_contrast_load.length > 0) {
          contrast_load = `{"key":"textcontrastempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textcontrastShowText","text":"${total_contrast_load} mL","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          if (!data?.includes(contrast_load)) {
            data = data?.replace(
              `{"key":"textcontrast","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
              `${contrast_load}{"key":"textcontrast","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
            );
          }
        }
        if (total_contrast_load && total_contrast_load.length == 0) {
          data = data?.replace(
            `${checkHeader7[0]}{"key":"textcontrast","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"textcontrast","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // total radiation time
        let radiation_time = "";
        const [header8, ...item8] =
          data &&
          data?.split(
            `{"key":"ae93m","text":"TOTAL RADIATION TIME","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":20,"style":"BOLD"},{"offset":0,"length":20,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHeader8 = (item8 || []).map((item) => {
          return item.split(`{"key":"textradiation","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
        });
        if (total_radiation_time && total_radiation_time.length > 0) {
          radiation_time = `{"key":"textradiationempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textradiationShowText","text":"${total_radiation_time} seconds","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          if (!data?.includes(radiation_time)) {
            data = data?.replace(
              `{"key":"textradiation","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${radiation_time}{"key":"textradiation","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
        if (total_radiation_time && total_radiation_time.length == 0) {
          data = data?.replace(
            `${checkHeader8[0]}{"key":"textradiation","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"textradiation","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
        // dose_area_product
        let dose_area = "";
        const [header12, ...item12] =
          data &&
          data?.split(
            `{"key":"aex9c3m","text":"DOSE AREA PRODUCT","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":17,"style":"BOLD"},{"offset":0,"length":17,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
          );
        const checkHeader12 = (item12 || []).map((item) => {
          return item.split(
            `{"key":"textradiationdap","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });

        dose_area = `{"key":"textradiationemptydap","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textradiationDAPShowText","text":"${total_dap} mGy*cm2","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        if (!data?.includes(dose_area)) {
          data = data?.replace(
            `{"key":"textradiationdap","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${dose_area}{"key":"textradiationdap","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }

        if (total_dap && total_dap.length == 0) {
          data = data?.replace(
            `${checkHeader12[0]}{"key":"textradiationdap","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
            `{"key":"textradiationdap","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
          );
        }
      }
      //ANESTHESIA
      let qualified_provider_text = ``;
      let first_sedation_text = ``;
      let last_sedation_text = ``;
      let total_sedation_text = ``;
      if (qualified_provider_name && qualified_provider_name.length > 0) {
        qualified_provider_text = `{"key":"textqualifiedprovidername","text":"Qualified Healthcare Provider ${qualified_provider_name}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`;
      }
      if (first_sedation_time && first_sedation_time.length > 0) {
      }
      if (first_sedation_time && first_sedation_time.length > 0) {
        first_sedation_text = `{"key":"firstsedationtimetext","text":"Sedation Start Time at ${moment(first_sedation_time).format(
          "HH:mm A"
        )}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`;
      }
      if (last_sedation_time && last_sedation_time.length > 0) {
        last_sedation_text = `{"key":"lastsedationtimetext","text":"Sedation End Time at ${moment(last_sedation_time).format(
          "HH:mm A"
        )}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`;
      }
      if (total_sedation_time) {
        total_sedation_text = `{"key":"totalsedationtimetext","text":"Total Sedation Time ${total_sedation_time} minutes","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},{"key":"lidocanetext","text":"Local Anesthesia 10 cc of 1% Lidocaine","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`;
      }
      if (
        !data?.includes(
          `${qualified_provider_text}${first_sedation_text}${last_sedation_text}${total_sedation_text}{"key":"txt10empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        )
      ) {
        data = data?.replace(
          `{"key":"txt10empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${qualified_provider_text}${first_sedation_text}${last_sedation_text}${total_sedation_text}{"key":"txt10empty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      }
      // for loot icd header 2 PREOPERATIVE DIAGNOSIS
      let icd_text = "";
      if (icd_codes && icd_codes.length > 0) {
        icd_text += `{"key":"txticd2space","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < icd_codes.length; i += 1) {
          icd_text += `{"key":"icd2txt${i}","text":"${icd_codes && icd_codes[i] && icd_codes[i]["icd_code"]} ${
            icd_codes && icd_codes[i] && icd_codes[i]["icd_name"]
          }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }
      // header 2 PREOPERATIVE DIAGNOSIS
      const [header2, ...item2] =
        data &&
        data?.split(
          `{"key":"pd985","text":"PREOPERATIVE DIAGNOSIS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":22,"style":"BOLD"},{"offset":0,"length":22,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader2 = (item2 || []).map((item) => {
        return item.split(`{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      if (checkHeader2 && checkHeader2[0] && checkHeader2[0] != "") {
        data = data?.replace(
          `${checkHeader2[0]}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data?.replace(
          `{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (icd_codes.length === 0) {
        data = data?.replace(
          `${checkHeader2[0]}{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // for loot icd header 3 POSTOPERATIVE DIAGNOSIS
      let icd_text_h3 = "";
      if (icd_codes && icd_codes.length > 0) {
        icd_text_h3 += `{"key":"txticd3space","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        for (let i = 0; i < icd_codes.length; i += 1) {
          icd_text_h3 += `{"key":"icd3txt${i}","text":"${icd_codes && icd_codes[i] && icd_codes[i]["icd_code"]} ${
            icd_codes && icd_codes[i] && icd_codes[i]["icd_name"]
          }","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }
      // header 3 POSTOPERATIVE DIAGNOSIS
      const [header3, ...item3] =
        data &&
        data?.split(
          `{"key":"pdx95","text":"POSTOPERATIVE DIAGNOSIS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":23,"style":"BOLD"},{"offset":0,"length":23,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
        );
      const checkHeader3 = (item3 || []).map((item) => {
        return item.split(`{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      if (checkHeader3 && checkHeader3[0] && checkHeader3[0] != "") {
        data = data?.replace(
          `${checkHeader3[0]}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text_h3}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      } else {
        data = data?.replace(
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${icd_text_h3}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      if (icd_codes.length === 0) {
        data = data?.replace(
          `${checkHeader3[0]}{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `{"key":"text3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // Plan
      let plan_text = "";
      if (condition_plans && Object.keys(condition_plans).length > 0) {
        for (const key in condition_plans || []) {
          // ngoại lệ cho Lymphedema
          const isPassLymphedema = key === "Lymphedema";
          const isPassPad = key === "Peripheral Artery Disease (PAD)";
          if (condition_plans.hasOwnProperty(key)) {
            const row = condition_plans[key];
            for (const sub_row of row || []) {
              if ((sub_row["plans"] && sub_row["plans"].length > 0) || isPassLymphedema || isPassPad) {
                let planRow = "";
                const textCheck =
                  condition_plans && condition_plans[key] && condition_plans[key][0] && condition_plans[key][0]["note"]
                    ? CreateTextN(condition_plans[key][0]["note"])
                    : null;
                for (const plans_row of sub_row["plans"] || []) {
                  let temp_text = "";
                  if (plans_row.indexOf("Structured Exercise Program") > -1) {
                    temp_text = `- Planned program that provides individualized recommendations for type, frequency, intensity, and duration of exercise has been provided to the patient. The program provides recommendations for exercise progression to assure that the body is consistently challenged to increase exercise intensity and levels as functional status improves over time.  The patient has been counselled and provided with a defined plan for 30 minutes of walking 3-5 times a week with pedometer or other measures.  The structured exercise program will take place in the personal setting of the patient. The program is self-directed and is a regimen similar to that of a supervised program. The patient has been counselled to ensure that they understand how to begin the program, how to maintain the program, and how to progress the difficulty of the walking (by increasing distance or speed). Program incorporates behavioral change techniques, such as health coaching and/or use of activity monitors. (COR I)`;
                  } else if (plans_row.indexOf("Supervised Exercise Program") > -1) {
                    temp_text = `- A referral has been made for a planned program that takes place in a hospital or outpatient facility in which intermittent walking exercise is used as the treatment modality. Training is performed for a minimum of 30 to 45 min per session, in sessions performed at least 3 times/wk for a minimum of 12 wk. Training involves intermittent bouts of walking to moderate-to-maximum claudication, alternating with periods of rest. Warm-up and cool-down periods precede and follow each session of walking. (COR I)`;
                  } else if (plans_row.indexOf("Smoking Counseling") > -1 && key !== "General") {
                    temp_text = `- The patient was made aware of the risks of smoking including heart disease, peripheral artery disease, increased risk of cancers and lung disease. The patient has been counselled to quit smoking to ensure preventing worsening of vascular disease. The patient has been counselled on the numerous ways to quit smoking including cold turkey, medications, nicotine patches and therapy. The patient verbalizes their understanding.`;
                  } else if (plans_row.indexOf("Aspirin") > -1) {
                    temp_text = `- Antiplatelet therapy with aspirin alone (range 75–325 mg per day) is recommended to reduce MI, stroke, and vascular death in patients with symptomatic PAD. (COR I)`;
                  } else if (plans_row.indexOf("Plavix") > -1) {
                    temp_text = `- Antiplatelet therapy with clopidogrel alone (75 mg per day) is recommended to reduce MI, stroke, and vascular death in patients with symptomatic PAD. (COR I)`;
                  } else if (plans_row.indexOf("Cilostazol") > -1) {
                    temp_text = `- Cilostazol is an effective therapy to improve symptoms and increase walking distance in patients with claudication. (COR I)`;
                  } else if (plans_row.indexOf("Chantix") > -1) {
                    temp_text = `- Patients with PAD who smoke cigarettes require a plan for quitting that includes pharmacotherapy (ie, varenicline, bupropion, and/or nicotine replacement therapy) (COR I)`;
                  } else if (plans_row.indexOf("Wellbutrin") > -1) {
                    temp_text = `- Patients with PAD who smoke cigarettes require a plan for quitting that includes pharmacotherapy (ie, varenicline, bupropion, and/or nicotine replacement therapy) (COR I)`;
                  } else if (plans_row.indexOf("Atorvastatin") > -1) {
                    temp_text = `- Treatment with a statin medication is indicated for all patients with PAD and vascular disease. (COR I)`;
                  } else {
                    temp_text = "";
                  }
                  let temp_length = 0;
                  if (plans_row.indexOf("Structured Exercise Program") > -1) {
                    temp_length = "Structured Exercise Program".length + 1;
                  } else if (plans_row.indexOf("Supervised Exercise Program") > -1) {
                    temp_length = "Supervised Exercise Program".length + 1;
                  } else if (plans_row.indexOf("Smoking Counseling") > -1) {
                    temp_length = "Smoking Counseling".length + 1;
                  } else if (plans_row.indexOf("Overweight Counseling") > -1) {
                    temp_length = "Overweight Counseling".length + 1;
                  } else if (plans_row.indexOf("Alcohol Counseling") > -1) {
                    temp_length = "Alcohol Counseling".length + 1;
                  } else if (plans_row.indexOf("Hypertension Counseling") > -1) {
                    temp_length = "Hypertension Counseling".length + 1;
                  } else if (plans_row.indexOf("Pain Management") > -1) {
                    temp_length = "Pain Management".length + 1;
                  } else {
                    temp_length = plans_row.length + 1;
                  }
                  // check index key to add condition
                  planRow += importCondition(key, plans_row, temp_text, temp_length) || "";
                }
                // ngoại lệ cho Lymphedema
                if (isPassLymphedema || isPassPad) {
                  planRow += importCondition(key, null, null, null, 0) || "";
                }
                plan_text += `{"key":"text9xempty${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                  key.length + 1
                },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textkeyEnd${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${
                  textCheck ? `${textCheck},` : ""
                }${planRow ? `${planRow}` : ""}`;
              } else {
                if (condition_plans && condition_plans[key] && condition_plans[key][0] && condition_plans[key][0]["note"]) {
                  const textCheck =
                    condition_plans && condition_plans[key] && condition_plans[key][0] && condition_plans[key][0]["note"]
                      ? CreateTextN(condition_plans[key][0]["note"])
                      : null;
                  plan_text += `{"key":"text9x${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                    key.length + 1
                  },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textkeyEnd${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${
                    textCheck ? `${textCheck},` : ""
                  }
                {"key":"keyplanone${key}","text":"None","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                } else {
                  plan_text += `{"key":"text9x${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keyid${key}","text":"${key}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":${
                    key.length + 1
                  },"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textkeyEnd${key}","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"keyplanone${key}","text":"None","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
                }
              }
            }
          }
        }
        if (plan_text && plan_text.length > 0) {
          // header 9
          const [head9, ...itemHead9] =
            data &&
            data?.split(
              `{"key":"fjqg5","text":"PLAN","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":4,"style":"BOLD"},{"offset":0,"length":4,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`
            );
          const checkHead9 = (itemHead9 || []).map((item) => {
            return item.split(`{"key":"text9x","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
          });
          if (checkHead9 && checkHead9[0] && checkHead9[0].length > 0) {
            data = data?.replace(`${checkHead9[0]},`, `${plan_text}`);
          } else {
            data = data?.replace(
              `{"key":"text9x","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
              `${plan_text}{"key":"text9x","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
            );
          }
        }
      }

      //MIPS
      let mips_text = "";
      if (mips.length > 0) {
        for (let row of mips || []) {
          mips_text += `{"key":"keymip${row}","text":"${row}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        }
      }

      if (mips_text && mips_text.length > 0) {
        // header 19
        if (!data.includes(mips_text)) {
          data = data.replace(
            `{"key":"text25","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"mipssd","text":"MIPS COMPLIANCE - MIPS ASSESSMENTS","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":35,"style":"BOLD"},{"offset":0,"length":35,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"mipssdempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},${mips_text}{"key":"text25","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      //
      let provider_text = "";
      if (providerName.length > 0) {
        provider_text = `{"key":"prvnmc","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        // header 18 PROVIDER:
        if (!data?.includes(provider_text)) {
          data = data?.replace(
            `{"key":"text18","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${provider_text}{"key":"text18","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      }

      // cosign
      let textCosign = ``;
      if (coSign === true) {
        textCosign = `{"key":"textcosign","text":"Physician Cosign: Omar Haqqani MD","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textcosignempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textcosign2","text":"I, Omar Haqqani, MD, reviewed the medical record and verified the medical necessity of E/M service provided by ${providerName} and have throughly reviewed and amended any area of the clinician care plan.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textcosignempty2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
      }

      // SIGNATURE
      const [headSig, ...itemHeadSig] =
        data && data?.split(`{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
      const checkHeadSig = (itemHeadSig || []).map((item) => {
        return item.split(`{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`)[0];
      });
      const textSigText = `I, ${
        providerName || ""
      } , hereby attest that the medical record entry for ${finalizedDateDate} accurately reflects signatures/notations that I made in my capacity as the provider when I treated/diagnosed the above listed beneficiary. I do hereby attest that this information is true, accurate and complete to the best of my knowledge and I understand that any falsification, omission, or concealment of material fact may subject me to administrative, civil, or criminal liability.`;

      const singalKey = ` ${textCosign}
      {"key":"txtporscioempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscio","text":"${providerName}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtporscioempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textsigelectsing","text":"Electronically signed by ${providerName} on ${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"txtsigempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textsigsText","text":"${textSigText}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;

      if (checkHeadSig && checkHeadSig[0] && checkHeadSig[0].length > 0) {
        data = data?.replace(
          `${checkHeadSig[0]},{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          `${singalKey}{"key":"textsigsmallempty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
        );
      } else {
        if (!data?.includes(singalKey)) {
          data = data?.replace(
            `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `{"key":"textsigsmall","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},
          ${singalKey}`
          );
        }
      }
      //
      let follow_apt_text = "";
      if (return_followup_plans && return_followup_plans.length > 0) {
        (return_followup_plans || []).map((item, index) => {
          follow_apt_text += `{"key":"keyfollowup${index}","text":"${item}","type":"unordered-list-item","depth":0,"inlineStyleRanges":[{"offset":0,"length":0,"style":""}],"entityRanges":[],"data":{}},`;
        });
      }
      // DAte FINALIZED
      let date_signed_text = "";
      date_signed_text = `{"key":"keydatesigned","text":"${finalizedDate}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`;

      const [headDate, ...itemHeadDate] = data && data?.split(`{"key":"keydatesigned",`);
      const checkHeadDate = (itemHeadDate || []).map((item) => {
        return item.split(`,"data":{}}`)[0];
      });
      if (checkHeadDate && checkHeadDate[0] && checkHeadDate[0].length > 0) {
        data = data?.replace(`{"key":"keydatesigned",${checkHeadDate[0]},"data":{}}`, `${date_signed_text}`);
      } else {
        data = data?.replace(
          `{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`,
          `${date_signed_text},{"key":"text20","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}`
        );
      }
      // make more
      // remove text nếu ethnicity == white
      if (patientResource?.ethnicity === "White") {
        data = data.replace(
          `{"key":"amosduj2","text":"AMPLIFIERS OF CARDIOVASCULAR AND LIMB-RELATED RISK IN PATIENTS WITH PERIPHERAL ARTERY DISEASE","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":93,"style":"BOLD"},{"offset":0,"length":93,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textemptyamos1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textasmosduj1","text":"Clinicians require to actively pursue evidence of health disparities in diagnosis, treatment, and outcomes for patients with PAD and use efforts to limit the impact of these disparities on clinical outcomes. (COR I)","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyamos2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textasmosduj2","text":"Non-White patients in the United States have high rates of risk-amplifying comorbidities of PAD and higher risk of adverse outcomes. In the United States, Non-White patients have a higher risk of limb amputation compared with White populations.  In addition, disparities in medical insurance and access to experienced health care centers providing advanced care for PAD and limb salvage procedures may contribute to the increased potential for lower extremity amputation. The disproportionately higher risk of amputation among patients of underrepresented racial and ethnic groups may also reflect systemic bias as studies have shown that Non-White patients with PAD are more likely to undergo amputation without attempts at revascularization and are more frequently treated with proximal amputation than White patients.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyamos3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          ""
        );
      }
      //

      if (vascuqol6 && Array.isArray(vascuqol6) && vascuqol6.length > 0) {
        // make text VASCULOQOL
        let textVASCULOQOL = `{"key":"textemptyVasculoqolck2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
        let valueTotal = 0;

        vascuqol6.map((r) => {
          // lấy index của VAS
          const indexVAS = DataVASCULOQOL.findIndex((find) => find?.key === r?.type);
          if (indexVAS != -1) {
            // lấy index value trong VAS
            const indexValueVAS = DataVASCULOQOL[indexVAS]["dataChoices"].findIndex((find) => find === r?.value);
            const valueVas = indexValueVAS + 1 > 0 ? indexValueVAS + 1 : 0;
            valueTotal = valueTotal + Number(valueVas);
            textVASCULOQOL += `{"key":"${r?.type}","text":"${DataVASCULOQOL[indexVAS]["question"]} [${r?.value}] [${valueVas}]","type":"unordered-list-item","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`;
          }
        });
        textVASCULOQOL += `{"key":"valueTotalVASEmpty","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"valueTotalVAS","text":"Total Score: ${valueTotal}","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":13,"length":20,"style":"BOLD"}],"entityRanges":[],"data":{}},`;

        const [headVAS, ...itemHeadVAS] =
          data &&
          data.split(`{"key":"textemptyVasculoqol2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`);
        const checkHeadVAS = (itemHeadVAS || []).map((item) => {
          return item.split(
            `{"key":"textemptyVasculoqol3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          )[0];
        });

        if (checkHeadVAS && checkHeadVAS[0] && checkHeadVAS[0].length > 0) {
          data = data.replace(`${checkHeadVAS[0]}`, `${textVASCULOQOL}`);
        } else {
          data = data.replace(
            `{"key":"textemptyVasculoqol2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
            `${textVASCULOQOL}{"key":"textemptyVasculoqol3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`
          );
        }
      } else {
        // remove nếu ko có vas
        data = data.replace(
          `{"key":"vasculoqoltitle","text":"VASCULAR QUALITY OF LIFE QUESTIONNAIRE (VASCUQOL-6)","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":51,"style":"BOLD"},{"offset":0,"length":51,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},{"key":"textemptyVasculoqol1","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textVasculoqol1","text":"The VascuQoL-6 aims to assess QoL in PAD regardless of disease severity (ie. in intermittent claudication and in critical limb ischemia).  The main goal of vascular surgical treatment efforts in peripheral arterial disease (PAD) is limb salvage in critical limb ischemia and improvement of walking ability in intermittent claudication. The treatment effort outcomes require to address the PAD patient’s own perception about his or her actual functional walking capacity, pain discomfort, or the social and emotional implications of living with PAD is a essential measures to provide clinically useful additional decision-basis and also important information about the results of different medical interventions.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyVasculoqol2","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"textemptyVasculoqol3","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},`,
          ""
        );
      }
      data = data.replace(
        `{"key":"eiriwenrhj","text":"DETERMINATION CRITERIA:","type":"unstyled","depth":0,"inlineStyleRanges":[{"offset":0,"length":22,"style":"BOLD"},{"offset":0,"length":22,"style":"UNDERLINE"}],"entityRanges":[],"data":{}},`,
        ""
      );
      // remove space header
      const valueObject = JSON.parse(data);
      if (valueObject?.blocks) {
        let dataCheck = valueObject?.blocks || [];
        //  checkCondition Variance and make data to filter
        const IndexCondition = (intra || []).findIndex((r) => r?.condition_name === "Peripheral Artery Disease (PAD)");
        if (IndexCondition !== -1) {
          let fillState = [];
          const valueQuest = intra?.[IndexCondition]?.["questions"] || [];
          valueQuest.map((r) => {
            if (r?.variance) {
              fillState.push({
                value: r?.variance,
                extra: r?.variance_extra,
                name: r?.question,
                type: dataCheckQuestion?.[r?.question] || "",
              });
            }
          });
          let dataFix = dataCheck;
          // make replace form key variance -> next key variance, its will delete 2 row
          const indexCilo = dataFix.findIndex((inx) => inx?.text == "Cilostazol Therapy");
          if (indexCilo != -1) {
            dataFix = renderDataVariance(dataFix, indexCilo, fillState);
          }
          const indexAnti = dataFix.findIndex((inx) => inx?.text == "Antiplatelet Therapy");
          if (indexAnti != -1) {
            dataFix = renderDataVariance(dataFix, indexAnti, fillState);
          }
          const IndexStat = dataFix.findIndex((inx) => inx?.text == "Statin Therapy");
          if (IndexStat != -1) {
            dataFix = renderDataVariance(dataFix, IndexStat, fillState);
          }
          const IndexAce = dataFix.findIndex((inx) => inx?.text == "ACE Inhibitor or Angiotensin Receptor Blocker Therapy");
          if (IndexAce != -1) {
            dataFix = renderDataVariance(dataFix, IndexAce, fillState);
          }
          let allData = {
            blocks: dataFix,
            entityMap: valueObject?.entityMap || {},
          };
          data = JSON.stringify(allData);
        }
      }

      // end
      const contentState = convertFromRaw(JSON.parse(data));
      const editorState = EditorState.createWithContent(contentState);
      // create table
      changeValueText(data);
      clearTimeout(timerNew);
      timerNew = setTimeout(() => {
        const checkRender = createTable(editorState, valueHeader, valueFind, valueHeader2);
        let tableAtomic = JSON.stringify(convertToRaw(checkRender.getCurrentContent()));
        const dataStrong = RenderFixStrongAndSpaceReview(tableAtomic);
        tableAtomic = JSON.stringify(dataStrong);
        tableAtomic = tableAtomic.replaceAll(`anally`, `anatomically`);
        changeValueText(tableAtomic);
        const contentStateWithTable = convertFromRaw(JSON.parse(tableAtomic));
        const editorStateWithTable = EditorState.createWithContent(contentStateWithTable);
        setText(editorStateWithTable);
      });
    }
  };

  const render_intracranial_circulation = (value) => {
    const valueCheck = {
      Normal:
        "The ipsilateral intracranial carotid circulation angiography was performed which demonstrates no evidence of significant stenosis within the anterior, middle and posterior intracranial circulatory segments.",
      Moderate:
        "The ipsilateral intracranial carotid circulation angiography was performed which demonstrates moderate stenosis within the anterior, middle and posterior intracranial circulatory segments.",
      Severe:
        "The ipsilateral intracranial carotid circulation angiography was performed which demonstrates severe stenosis within the anterior, middle and posterior intracranial circulatory segments.",
      Occluded:
        "The ipsilateral intracranial carotid circulation angiography was performed which demonstrates occlusions within the anterior, middle and posterior intracranial circulatory segments.",
    };
    return `${valueCheck?.[value]}` || "";
  };

  const render_external_carotid_artery = (value) => {
    const valueCheck = {
      Normal: "The ipsilateral external carotid artery circulation angiography was performed which demonstrates no evidence of significant stenosis.",
      Moderate: "The ipsilateral external carotid artery circulation angiography was performed which demonstrates moderate stenosis.",
      Severe: "The ipsilateral external carotid artery circulation angiography was performed which demonstrates severe stenosis.",
      Occluded: "The ipsilateral external carotid artery circulation angiography was performed which demonstrates occlusions.",
    };

    return `${valueCheck?.[value]}` || "";
  };

  useEffect(() => {
    if (first) {
      if (activeIntra) {
        if (appointment?.appointment_type_id != 7 && appointment?.appointment_type_id != 11 && appointment?.appointment_type_id != 12) {
          const data = dataHeaderActiveIntra.text_template;
          const contentState = convertFromRaw(JSON.parse(data));
          const editorState = EditorState.createWithContent(contentState);
          setTimeout(() => {
            updateCheck(editorState);
          }, 500);
        } else {
          // dataHeaderStabActiveIntra
          const data = dataHeaderStabActiveIntra.text_template;
          const contentState = convertFromRaw(JSON.parse(data));
          const editorState = EditorState.createWithContent(contentState);
          setTimeout(() => {
            updateCheck(editorState);
          }, 500);
        }
      } else {
        if (appointment?.appointment_type_id != 7 && appointment?.appointment_type_id != 11 && appointment?.appointment_type_id != 12) {
          const data = dataHeader.text_template;
          const contentState = convertFromRaw(JSON.parse(data));
          const editorState = EditorState.createWithContent(contentState);

          setTimeout(() => {
            updateCheck(editorState);
          }, 500);
        } else {
          const data = dataHeaderStab.text_template;
          const contentState = convertFromRaw(JSON.parse(data));
          const editorState = EditorState.createWithContent(contentState);
          setTimeout(() => {
            updateCheck(editorState);
          }, 500);
        }
      }
    }
  }, [
    first,
    intra,
    list_procedurals,
    dataCPT,
    appointment,
    patientResource,
    complication_value,
    detail_procedure,
    text_template_redux,
    condition_plans,
    activeIntra,
    detail_angio_type,
    valueFind,
    intraAnswers,
    finalizedDate,
    finalizedDateDate,
    providerName,
    total_dap,
    total_contrast_load,
    total_radiation_time,
    total_sedation_time,
    coSign,
  ]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className="RichEditor-editor rm-toolbar">
        <Editor
          toolbar={{
            options: ["inline", "list"],
            inline: {
              options: ["bold", "italic", "underline"],
            },
            list: {
              options: ["unordered", "ordered"],
            },
          }}
          editorStyle={{
            border: "1px solid #f2f2f2",
            padding: "10px",
            height: "100%",
            backgroundColor: "#fff",
          }}
          placeholder="Patient Review"
          editorState={text}
          blockRendererFn={blockRenderer}
          onEditorStateChange={onChange}
        />
      </div>
    </div>
  );
};

export default ReviewText;
