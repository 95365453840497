import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import EditableFormInput from "../../../common/components/EditableText/EditableFormInput";
import ScrollWrapper from "../../../common/components/ScrollBar";
import ToastMessage from "../../../common/components/Toast";
import { searchPcpNpe, setGlobalLoading } from "../../../store/actions";

let timer = null;

const OutgoingReferrals = () => {
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedValue, setSelectedValue] = useState({});
  const [searchResult, setSearchResult] = useState([]);
  const [priors, setPriors] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (search && search.length > 0) {
      fetchSearch(search);
    } else {
      setSearchResult([]);
    }
  }, [search]);

  const addSearchResult = (value) => {
    if (!priors?.some((el) => el.full_name === value.full_name)) {
      setPriors([...priors, value]);
      setSelectedValue(value);
    } else {
      setIsToastr(true);
      setTimeout(() => {
        setIsToastr(false);
      }, 2000);
    }
  };

  const styleLeftElement = {
    width: "50%",
    paddingRight: "1rem",
    borderRight: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const styleRightElement = {
    width: "50%",
    paddingLeft: "1rem",
    borderLeft: "solid 1px #f0f3f6",
    paddingTop: "0.5rem",
  };

  const fetchSearch = async (value) => {
    dispatch(setGlobalLoading(true));
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const getDetailSuccess = await dispatch(searchPcpNpe(1, value));
      if (getDetailSuccess) {
        setSearchResult([...getDetailSuccess]);
        dispatch(setGlobalLoading(false));
      } else {
        setSearchResult([]);
        dispatch(setGlobalLoading(false));
      }
    }, 500);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isToastr && <ToastMessage type="Referral Already Exists" status="failed" msg="Can Not Add Same Value!" />}
      <div style={{ width: "100%", minheight: "50px", marginBottom: "1rem" }}>
        <AutoCompleteSearch
          isLargeHeight={true}
          title="outgoing-referrals-Search"
          value={search}
          backgroundWhite={"white"}
          options={searchResult}
          handlePopulate={(value) => addSearchResult(value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Referrals"
          keyLabel="full_name"
          actionLabel="Add"
        />
      </div>
      <ScrollWrapper css="no-padding x-hidden mix-outgoing-top-20">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            paddingTop: "5px",
          }}
        >
          <div
            style={{
              width: "50%",
              paddingRight: "1rem",
              borderRight: "solid 1px #f0f3f6",
            }}
          >
            <EditableFormInput label="First Name" value={selectedValue?.first_name} fullWidth />
          </div>
          <div
            style={{
              width: "50%",
              paddingLeft: "1rem",
              borderLeft: "solid 1px #f0f3f6",
            }}
          >
            <EditableFormInput label="Last Name" value={selectedValue?.last_name} fullWidth />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div style={styleLeftElement}>
            <EditableFormInput label="Phone Number" value={selectedValue?.telephone_number} mask={"phone"} fullWidth />
          </div>
          <div style={styleRightElement}>
            <EditableFormInput label="Address" value={selectedValue?.address_1} fullWidth />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div style={styleLeftElement}>
            <EditableFormInput label="State" value={selectedValue?.state} fullWidth />
          </div>
          <div style={styleRightElement}>
            <EditableFormInput label="Zip Code" value={selectedValue?.zipcode} fullWidth />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div style={styleLeftElement}>
            <EditableFormInput label="City" value={selectedValue?.city} fullWidth />
          </div>
        </div>
      </ScrollWrapper>
    </div>
  );
};

export default OutgoingReferrals;
