import { Select } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteSearch from "../../../common/components/AutoComplete/AutoCompleteSearch";
import DataTable from "../../../common/components/DataTable/WhiteHeaderTable";
import { ExpandSVG } from "../../../common/components/icons/SVGIcon";
import ToastMessage from "../../../common/components/Toast";
import { addProcedureDetails, getProviders, setProcedureDetails, updateDetailProcedureData } from "../../../store/actions";

const ProcedureTimes = [
  { value: 30, label: "30 mins" },
  { value: 60, label: "60 mins" },
  { value: 90, label: "90 mins" },
  { value: 120, label: "120 mins" },
  { value: 150, label: "150 mins" },
  { value: 180, label: "180 mins" },
  { value: 210, label: "210 mins" },
  { value: 240, label: "240 mins" },
  { value: 270, label: "270 mins" },
  { value: 300, label: "300 mins" },
];

const ProcedureProviders = ({ resource }) => {
  const tableRef = useRef(null);
  const [isToastr, setIsToastr] = useState(false);
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const dispatch = useDispatch();

  const providers = useSelector((state) => state.common.providers);
  const providersState = useSelector((state) => state.procedureDetail.providers);
  const detailProcedureData = useSelector((state) => state.common.detailProcedureData);

  useEffect(() => {
    if (!providers || providers.length === 0) {
      dispatch(getProviders());
    }
  }, [providers]);

  const updateProviders = async (type, index, value) => {
    if (type === "physician") {
      if (
        !providersState?.some((el) => {
          return el.physician_name === value?.value;
        })
      ) {
        await dispatch(
          addProcedureDetails("providers", {
            physician_name: value?.value,
            physician: value?.id,
            duration: "",
          })
        );
      } else {
        setIsToastr(true);
        setTimeout(() => {
          setIsToastr(false);
        }, 2000);
      }
    } else {
      providersState[index][type] = value;
      await dispatch(setProcedureDetails("providers", [...providersState]));
    }
    callApiUpdate();
  };
  const removeProvider = async (index) => {
    providersState.splice(index, 1);
    await dispatch(setProcedureDetails("providers", [...providersState]));
    callApiUpdate();
  };

  useEffect(() => {
    if (detailProcedureData) {
      const providerDetail = (detailProcedureData?.providers || []).map((r) => {
        return {
          physician: r?.id,
          physician_name: r?.value,
          duration: r?.pivot?.interval === 0 ? undefined : r?.pivot?.interval,
        };
      });
      dispatch(setProcedureDetails("providers", [...providerDetail]));
    }
  }, [detailProcedureData]);

  useEffect(() => {
    if (search && search.length > 0) {
      const checkfilter = providers.filter((r) => r?.value.toLowerCase().includes(search.toLowerCase()));
      setSearchResult(checkfilter);
    } else {
      setSearchResult(providers);
    }
  }, [search]);

  useEffect(() => {
    if (providers) {
      setSearchResult(providers);
    }
  }, [providers]);

  useEffect(() => {
    // dispatch(getProviders());
  }, []);

  const callApiUpdate = () => {
    if (providersState && detailProcedureData && Object.keys(detailProcedureData).length > 0) {
      const renderArray = providersState.map((r) => {
        return `"${r.physician}":{"interval":${r.duration}}`;
      });
      dispatch(
        updateDetailProcedureData(detailProcedureData?.id, {
          value: detailProcedureData?.value,
          sort: detailProcedureData?.sort,
          side: detailProcedureData?.side,
          is_first_case: detailProcedureData?.is_first_case,
          number_of_week_followup: detailProcedureData?.number_of_week_followup,
          range_between_procedure: detailProcedureData?.range_between_procedure,
          providers: `{${renderArray.join()}}`,
        })
      );
    }
  };

  const emptyRows = useMemo(() => {
    if (tableRef && tableRef.current) {
      const total = Math.floor(tableRef.current.clientHeight / 40);
      const count = providersState && providersState.length > 0 ? total - providersState.length : total;

      return new Array(count).fill(1);
    }
    return [];
  }, [tableRef, providersState]);

  if (resource === null || resource === undefined) {
    return null;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isToastr && <ToastMessage type="Provider Already Exists" status="failed" msg="Can Not Add Same Value!" />}
      <div style={{ width: "100%", minheight: "50px" }}>
        <AutoCompleteSearch
          isLargeHeight={true}
          title="Procedure-Provider-Search"
          options={searchResult}
          handlePopulate={(value) => updateProviders("physician", providersState.length, value)}
          handleSearch={(val) => setSearch(val)}
          placeholder="Search Providers"
          keyLabel="value"
          actionLabel="Add"
          backgroundWhite={"blue"}
        />
      </div>
      <div ref={tableRef} style={{ flex: 1, width: "100%" }}>
        <DataTable
          title="procedure_provider"
          labels={["Provider", "Duration"]}
          widths={["50%", "50%"]}
          fullHeight={true}
          columns={[
            { sortable: true, key: "provider" },
            { sortable: true, key: "duration" },
          ]}
          handleDelete={(value, index) => removeProvider(index)}
          rowData={(providersState || []).map((item, index) => {
            return {
              provider: item.physician_name,
              duration: (
                <Select
                  className={!item?.duration ? "select-error" : ""}
                  placeholder="Duration"
                  suffixIcon={<ExpandSVG />}
                  allowClear={false}
                  value={item.duration}
                  onChange={(value) => {
                    updateProviders("duration", index, value);
                  }}
                  style={{ width: "100%", borderBottom: 0 }}
                >
                  {ProcedureTimes.map((r) => (
                    <Select.Option key={r?.value} value={r?.value}>
                      {r.label}
                    </Select.Option>
                  ))}
                </Select>
              ),
            };
          })}
          emptyRows={emptyRows}
          sorts={["", ""]}
        />
      </div>
    </div>
  );
};

export default ProcedureProviders;
